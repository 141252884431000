import PlatformPageTech from '../actionTypes/platformPageTech'
import { platformPageTech as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const getPlatformPageTechs = bindRequestLogic({
  clientFunc: clientApi.getPlatformPageTechs,
  type: PlatformPageTech.GET_LIST_PLATFORM_PAGE_TECH_REQUEST,
  successType: PlatformPageTech.GET_LIST_PLATFORM_TECH_PAGE_SUCCESS,
  failType: PlatformPageTech.GET_DETAIL_PLATFORM_TECH_PAGE_FAILED,
  isQueryParam: true
})

const addPlatformPageTech = bindRequestLogic({
  clientFunc: clientApi.addPlatformPageTech,
  type: PlatformPageTech.ADD_PLATFORM_TECH_PAGE_REQUEST,
  successType: PlatformPageTech.ADD_PLATFORM_TECH_PAGE_SUCCESS,
  failType: PlatformPageTech.ADD_PLATFORM_TECH_PAGE_FAILED
})

const getDetailPlatformPage = bindRequestLogic({
  clientFunc: clientApi.getPlatformPageTech,
  type: PlatformPageTech.GET_DETAIL_PLATFORM_TECH_PAGE_REQUEST,
  successType: PlatformPageTech.GET_DETAIL_PLATFORM_TECH_PAGE_SUCCESS,
  failType: PlatformPageTech.GET_DETAIL_PLATFORM_TECH_PAGE_FAILED
})

const updatePlatformPageTech = bindRequestLogic({
  clientFunc: clientApi.updatePlatformPageTech,
  type: PlatformPageTech.UPDATE_PLATFORM_TECH_PAGE_REQUEST,
  successType: PlatformPageTech.UPDATE_PLATFORM_TECH_PAGE_SUCCESS,
  failType: PlatformPageTech.UPDATE_PLATFORM_TECH_PAGE_FAILED
})

const deletePlatformPageTech = bindRequestLogic({
  clientFunc: clientApi.deletePlatformPageTech,
  type: PlatformPageTech.DELETE_PLATFORM_TECH_PAGE_REQUEST,
  successType: PlatformPageTech.DELETE_PLATFORM_TECH_PAGE_SUCCESS,
  failType: PlatformPageTech.DELETE_PLATFORM_TECH_PAGE_FAILED
})

const getSystemPlatformPageTechs = bindRequestLogic({
  clientFunc: clientApi.getSystemPlatformPageTechs,
  type: PlatformPageTech.GET_SYSTEM_PLATFORM_PAGE_TECHS_REQUEST,
  successType: PlatformPageTech.GET_SYSTEM_PLATFORM_PAGE_TECHS_SUCCESS,
  failType: PlatformPageTech.GET_SYSTEM_PLATFORM_PAGE_TECHS_FAILED,
  isQueryParam: true
})

export default [
  getPlatformPageTechs,
  addPlatformPageTech,
  updatePlatformPageTech,
  deletePlatformPageTech,
  getDetailPlatformPage,
  getSystemPlatformPageTechs
]
