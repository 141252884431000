import BaseApi from './base'

class Role extends BaseApi {
  getGroups = () => this.client.get(`admin/groups`)

  getRoles = (query) => this.client.get(`admin/roles?${query}`)

  getPermissions = (query) => this.client.get(`admin/permissions?${query}`)

  getRoleDetail = (id) => this.client.get(`admin/roles/${id}`)

  addRole = (data) => this.client.post('admin/roles', data)

  updateRole = (id, data) => this.client.patch(`admin/roles/${id}`, data)

  deleteRole = (id) => this.client.delete(`admin/roles/${id}`)

  deleteMultipleRole = (data) =>
    this.client.delete(`admin/roles/bulk-delete`, { data })

  addRoleTranslation = (id, data) =>
    this.client.post(`admin/roles/${id}/translation`, data)

  updateRoleTranslation = (id, data) =>
    this.client.patch(`admin/roles/${id}/translation/${data?.translationId}`, {
      translationName: data?.translationName
    })

  deleteRoleTranslation = (id, data) =>
    this.client.delete(`admin/roles/${id}/translation/${data?.translationId}`)

  getSystemRoles = (query) => this.client.get(`admin/system/roles?${query}`)
}

export default new Role()
