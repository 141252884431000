import keyMirror from 'keymirror'

export default keyMirror({
  GET_SHOWCASE_PRIVACY_POLICY_REQUEST: null,
  GET_SHOWCASE_PRIVACY_POLICY_SUCCESS: null,
  GET_SHOWCASE_PRIVACY_POLICY_FAILED: null,

  UPDATE_SHOWCASE_PRIVACY_POLICY_REQUEST: null,
  UPDATE_SHOWCASE_PRIVACY_POLICY_SUCCESS: null,
  UPDATE_SHOWCASE_PRIVACY_POLICY_FAILED: null
})
