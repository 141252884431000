import keyMirror from 'keymirror'

export default keyMirror({
  GET_KEY_USAGE_SYSTEM_LOGS_REQUEST: null,
  GET_KEY_USAGE_SYSTEM_LOGS_SUCCESS: null,
  GET_KEY_USAGE_SYSTEM_LOGS_FAILED: null,

  UPDATE_KEY_USAGE_SYSTEM_LOGS_REQUEST: null,
  UPDATE_KEY_USAGE_SYSTEM_LOGS_SUCCESS: null,
  UPDATE_KEY_USAGE_SYSTEM_LOGS_FAILED: null,

  CREATE_KEY_USAGE_SYSTEM_LOG_REQUEST: null,
  CREATE_KEY_USAGE_SYSTEM_LOG_SUCCESS: null,
  CREATE_KEY_USAGE_SYSTEM_LOG_FAILED: null,

  DELETE_KEY_USAGE_SYSTEM_LOG_REQUEST: null,
  DELETE_KEY_USAGE_SYSTEM_LOG_SUCCESS: null,
  DELETE_KEY_USAGE_SYSTEM_LOG_FAILED: null,

  IMPORT_KEY_USAGE_SYSTEM_LOGS_REQUEST: null,
  IMPORT_KEY_USAGE_SYSTEM_LOGS_SUCCESS: null,
  IMPORT_KEY_USAGE_SYSTEM_LOGS_FAILED: null,

  UPDATE_FILTER_KEY_USAGE_SYSTEM_LOG_REQUEST: null,
  UPDATE_FILTER_KEY_USAGE_SYSTEM_LOG_SUCCESS: null,

  CLEAR_FILTER_KEY_USAGE_SYSTEM_LOG_REQUEST: null,
  CLEAR_FILTER_KEY_USAGE_SYSTEM_LOG_SUCCESS: null
})
