import { combineReducers } from 'redux'
import NotificationTemplateTypes from 'store/actionTypes/notificationTemplate'
import { createHandleRequest } from './helpers'

const getNotificationTemplates = createHandleRequest(
  NotificationTemplateTypes.GET_NOTIFICATION_TEMPLATES_REQUEST,
  NotificationTemplateTypes.GET_NOTIFICATION_TEMPLATES_SUCCESS,
  NotificationTemplateTypes.GET_NOTIFICATION_TEMPLATES_FAILED
)

export default combineReducers({
  getNotificationTemplates
})
