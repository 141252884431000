import { combineReducers } from 'redux'
import ShowcaseAnonymizeVisitor from 'store/actionTypes/showcaseAnonymizeVisitor'
import { createHandleRequest } from './helpers'

const getAnonymizedVisitors = createHandleRequest(
  ShowcaseAnonymizeVisitor.GET_ANONYMIZE_VISITOR_REQUEST,
  ShowcaseAnonymizeVisitor.GET_ANONYMIZE_VISITOR_SUCCESS,
  ShowcaseAnonymizeVisitor.GET_ANONYMIZE_VISITOR_FAILED
)

const cancelAnonymizeVisitor = createHandleRequest(
  ShowcaseAnonymizeVisitor.CANCEL_ANONYMIZE_VISITOR_REQUEST,
  ShowcaseAnonymizeVisitor.CANCEL_ANONYMIZE_VISITOR_SUCCESS,
  ShowcaseAnonymizeVisitor.CANCEL_ANONYMIZE_VISITOR_FAILED
)

export default combineReducers({
  getAnonymizedVisitors,
  cancelAnonymizeVisitor
})
