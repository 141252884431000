import { combineReducers } from 'redux'
import ShowcaseLogsTypes from 'store/actionTypes/showcaseContactLogs'
import { createHandleRequest } from './helpers'

const getContactLogs = createHandleRequest(
  ShowcaseLogsTypes.GET_SHOWCASE_LOGS_REQUEST,
  ShowcaseLogsTypes.GET_SHOWCASE_LOGS_SUCCESS,
  ShowcaseLogsTypes.GET_SHOWCASE_LOGS_FAILED
)

const exportShowcaseContactLogs = createHandleRequest(
  ShowcaseLogsTypes.EXPORT_SHOWCASE_CONTACT_LOG_REQUEST,
  ShowcaseLogsTypes.EXPORT_SHOWCASE_CONTACT_LOG_SUCCESS,
  ShowcaseLogsTypes.EXPORT_SHOWCASE_CONTACT_LOG_FAILED
)

const updateAnonymizeVisitor = createHandleRequest(
  ShowcaseLogsTypes.UPDATE_ANONYMIZE_VISITOR_REQUEST,
  ShowcaseLogsTypes.UPDATE_ANONYMIZE_VISITOR_SUCCESS,
  ShowcaseLogsTypes.UPDATE_ANONYMIZE_VISITOR_FAILED
)

export default combineReducers({
  getContactLogs,
  exportShowcaseContactLogs,
  updateAnonymizeVisitor
})
