import odooSynchronization from 'store/actionTypes/odooSynchronization'
import UserTypes from 'store/actionTypes/user'

const initData = {
  list: []
}

const perActivity = (state = initData, action = {}) => {
  const { payload } = action
  switch (action.type) {
    case odooSynchronization.GET_ODOO_SYNCHRONIZATIONS_SUCCESS:
      return {
        ...state,
        list: payload?.odooSync ? [payload?.odooSync] : payload?.items
      }
    case UserTypes.LOGOUT_SUCCESS:
      return initData
    default:
      return state
  }
}

export default perActivity
