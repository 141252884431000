import { combineReducers } from 'redux'
import ElectronicKeyTypes from 'store/actionTypes/electronicKey'
import { createHandleRequest } from './helpers'

const getElectronicKeys = createHandleRequest(
  ElectronicKeyTypes.GET_ELECTRONIC_KEYS_REQUEST,
  ElectronicKeyTypes.GET_ELECTRONIC_KEYS_SUCCESS,
  ElectronicKeyTypes.GET_ELECTRONIC_KEYS_FAILED
)

export default combineReducers({
  getElectronicKeys
})
