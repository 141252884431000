/**
 * Utility functions for the application.
 * @module utils
 */
import {
  COMPANY_DETAIL_BUTTONS,
  COMPANY_FILTER_TYPE,
  COMPANY_STATUSES,
  COMPANY_TYPES,
  DATE_FORMAT,
  DEFAULT_LANGUAGE_ISO_CODE,
  DEVICE_TYPES,
  GENDER_LIST,
  LANGUAGE_LIST,
  MAPPING_LANGUAGE,
  MAPPING_MAIN_PICTURE_FILE_TYPES,
  PILOT_STATUSES,
  PREFIX_BASE64,
  PREFIX_IMAGE_BASE64,
  SECTION_PAGE_LIST,
  SECTION_PAGE_VALUES,
  SUBSCRIBER_TYPES,
  SUBSCRIPTION_STATUSES,
  SUPER_ADMIN,
  VEHICLE_TYPE_LIST
} from 'constants/common'
import defaultUser from 'assets/img/default-user.png'
import defaultCompany from 'assets/img/default-company.png'
import defaultCompanySelection from 'assets/img/default-company-selection.png'
import { hasFlag } from 'country-flag-icons'
import DOMPurify from 'dompurify'
import { toast } from 'react-toastify'
import IntlMessages from 'helpers/IntlMessages'
import { SYSTEM_CONFIG_CODE } from 'constants/systemConfig'

/**
 * Get the current user from local storage.
 * @returns {Object|null} - The current user object if found, or null if not found.
 */
export const getCurrentUser = () => {
  let user = null
  try {
    user =
      localStorage.getItem('gogo_current_user') != null
        ? JSON.parse(localStorage.getItem('gogo_current_user'))
        : null
  } catch (error) {
    user = null
  }
  return user
}

/**
 * Convert an image flag to a base64 encoded string.
 * If the flag already starts with the base64 prefix, return the flag as is.
 * Otherwise, prepend the base64 prefix to the flag and return the result.
 *
 * @param {string} flag - The image flag to be converted.
 * @returns {string} - The base64 encoded string representation of the image flag.
 */
export const showImageAsBase64 = (flag) => {
  if (flag.includes(PREFIX_BASE64)) {
    return `${flag}`
  }
  return `${PREFIX_IMAGE_BASE64},  ${flag}`
}

/**
 * Splits an array into chunks of a specified size.
 *
 * @param {Array} arr - The array to be split into chunks.
 * @param {number} size - The size of each chunk.
 * @returns {Array} - An array of chunks.
 */
export const chunk = (arr, size) => {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  )
}

/**
 * Get the flag object from the name.
 *
 * @param {string} name - The name of the flag.
 * @returns {Object|null} - The flag object if found, or null if not found.
 */
export const getFlagFromName = (name) => {
  return LANGUAGE_LIST.find((item) => item.label === name)
}

/**
 * Convert a list of data to options format.
 *
 * @param {Array} data - The list of data to be converted.
 * @returns {Array} - The converted options array.
 */
export const convertListToOptions = (data) => {
  return data.map((item) => {
    return {
      isDefault: item.isDefault,
      value: item.isoCode,
      label: item.name,
      id: item.id
    }
  })
}

/**
 * Retrieves the label of a gender based on its ID.
 *
 * @param {number} genderId - The ID of the gender.
 * @returns {string} The label of the gender.
 */
export const getGender = (genderId) => {
  const selectedGender = GENDER_LIST.find((v) => +v.value === +genderId)
  if (!selectedGender) {
    return ''
  }
  return selectedGender.label
}

/**
 * Generates an array of numbers in a given range.
 *
 * @param {number} start - The starting number of the range.
 * @param {number} end - The ending number of the range.
 * @returns {number[]} - The generated array of numbers.
 */
export const generateArray = (start, end) => {
  const length = end - start
  return Array.from({ length }, (_, i) => start + 1 + i)
}

/**
 * Retrieves a list of pages based on the current page and total pages.
 *
 * @param {Object} options - The options for retrieving the page list.
 * @param {number} options.currentPage - The current page number.
 * @param {number} options.totalPages - The total number of pages.
 * @returns {number[]} - The list of pages.
 */
export const getPageList = ({ currentPage = 1, totalPages = 1 }) => {
  if (totalPages <= 1 || currentPage > totalPages) {
    return []
  }

  let pageListSplit
  if (currentPage >= 4) {
    const isLastPage = currentPage === totalPages - 1
    const start = isLastPage ? currentPage - 2 : currentPage - 3
    const pageList = generateArray(start, totalPages)
    pageListSplit = isLastPage ? chunk(pageList, 3) : chunk(pageList, 5)
  } else {
    const pageList = generateArray(0, totalPages)
    pageListSplit = chunk(pageList, 5)
  }

  return pageListSplit.find((item) => item.includes(currentPage))
}

export const getSelectedItemInOptions = (id, list) => {
  return list.find((item) => item.id === id)
}

/**
 * Convert groups data to options format.
 *
 * @param {Array} data - The groups data to be converted.
 * @returns {Array} - The converted options array.
 */
export const convertGroupsToOptions = (data) => {
  return data.map((item) => {
    return {
      value: item.code,
      label: item.name,
      id: item.code
    }
  })
}

/**
 * Adds an item to an array.
 *
 * @param {*} value - The value to add to the array.
 * @param {Array} items - The array to which the value should be added.
 * @returns {Array} - The updated array with the added value.
 */
export const addItemToArray = (value, items) => {
  const checkedItems = [...items]
  const index = checkedItems.indexOf(value)
  if (index === -1) {
    checkedItems.push(value)
  } else {
    checkedItems.splice(index, 1)
  }
  return checkedItems
}

/**
 * Get the language and name by ID from a list of role translations.
 *
 * @param {string} languageId - The language ID.
 * @param {Array} roleTranslations - The list of role translations.
 * @returns {Object} - The language and name information.
 */
export const getLanguageAndNameById = (languageId, roleTranslations) => {
  const roleTranslation = roleTranslations.find(
    (translation) => translation.language === languageId
  )
  if (roleTranslation) {
    const { id, isoCode, name } = roleTranslation.languages
    const defaultLanguage = { id, value: isoCode, label: name }
    return {
      defaultLanguage,
      translationName: roleTranslation.translationName
    }
  }
  return {}
}

/**
 * Get the permission for a module based on the module name and role permissions.
 *
 * @param {string} module - The module name.
 * @param {Array} rolePermissions - The list of role permissions.
 * @returns {Object} - The permission object for the module.
 */
export const getPermissionForModule = (module, rolePermissions) => {
  const permission = {
    isViewList: false,
    isManage: false,
    isViewDetail: false,
    isViewSheet: false,
    isUse: false,
    isSend: false
  }

  const permissionCheckValues = {
    isManage: `Manage_${module}`,
    isViewList: `ViewList_${module}`,
    isViewDetail: `View_${module}`,
    isViewSheet: `Sheet_${module}`,
    isUse: `Use_${module}`,
    isSend: `Send_${module}`
  }

  const permissionValues = []

  rolePermissions.forEach((item) => {
    const rolePermissionArr = item.permission.split(/_(.*)/s)
    if (module === rolePermissionArr[1]) {
      permissionValues.push(item.permission)
    }
  })

  Object.keys(permission).forEach((key) => {
    const valueCheck = permissionCheckValues[key]
    if (permissionValues.includes(valueCheck)) {
      permission[key] = true
    }
  })

  return permission
}

/**
 * Get the permissions for tabs based on the tab configuration and role permissions.
 *
 * @param {Array} tabs - The array of tab configurations.
 * @param {Array} rolePermissions - The list of role permissions.
 * @returns {Object} - The permissions object for tabs.
 */
export const getTabPermissions = (tabs, rolePermissions) => {
  if (!tabs || !rolePermissions) return {}

  return tabs.reduce((acc, curr) => {
    const { permission } = curr
    if (!permission) {
      return acc
    }
    return {
      ...acc,
      [permission]: getPermissionForModule(permission, rolePermissions)
    }
  }, {})
}

/**
 * Get the selected language from a list of options based on the ID.
 *
 * @param {string} id - The ID of the selected language.
 * @param {Array} list - The list of language options.
 * @returns {Object|null} - The selected language if found, or null if not found.
 */
export const getSelectedLanguageInOptions = (id, list) => {
  return list.find((item) => item.id === id)
}

/**
 * Convert a list of languages to options format.
 *
 * @param {Array} languages - The list of languages.
 * @returns {Array} - The converted options array.
 */
export const getLanguageOptions = (languages) => {
  return languages.map((language) => {
    return {
      value: language.isoCode,
      label: language.name,
      id: language.id
    }
  })
}

/**
 * Get the system parameter by key from a list of system parameters.
 *
 * @param {string} code - The code of the system parameter.
 * @param {Array} list - The list of system parameters.
 * @returns {Object|null} - The system parameter if found, or null if not found.
 */
export const getSystemParameterByKey = (code, list) => {
  const systemParameter = list.find((item) => item.code === code)
  return systemParameter || null
}

/**
 * Get the default avatar based on the key.
 *
 * @param {string} key - The key for the default avatar.
 * @returns {string} - The URL of the default avatar.
 */
export const getDefaultAvatar = (key) => {
  switch (key) {
    case SYSTEM_CONFIG_CODE.defaultAvatarCompany:
      return defaultCompany
    case SYSTEM_CONFIG_CODE.defaultImageCompanySelection:
      return defaultCompanySelection
    default:
      return defaultUser
  }
}

/**
 * Convert system config data to a list of values.
 *
 * @param {Object} data - The system config data.
 * @param {Array} list - The list of system parameters.
 * @returns {Object} - The converted system config data.
 */
export const convertSystemConfigData = (data, list) => {
  const values = []
  Object.keys(data).forEach((key) => {
    const systemParameter = getSystemParameterByKey(key, list)
    const { id } = systemParameter
    values.push({ id, value: data[key] })
  })
  return { items: values }
}

/**
 * Get the default gender based on the gender ID.
 *
 * @param {number} genderId - The gender ID.
 * @returns {Object|null} - The default gender object if found, or null if not found.
 */
export const getDefaultGender = (genderId) => {
  return GENDER_LIST.find((v) => v.value === genderId)
}

/**
 * Convert the order by key and value to a string format.
 *
 * @param {string} key - The key for the order by.
 * @param {string} value - The value for the order by.
 * @returns {string} - The converted order by string.
 */
export const convertOrderBy = (key, value) => {
  return `${key}:${value}`
}

/**
 * Convert a list of language constants to options format.
 *
 * @param {Array} data - The list of language constants.
 * @returns {Array} - The converted options array.
 */
export const convertListLanguageConstantToOptions = (data) => {
  const options = []
  data.forEach((item) => {
    const index = MAPPING_LANGUAGE[item[1]] || item[1]
    if (hasFlag(index.toUpperCase())) {
      options.push({
        key: index,
        value: item[3],
        label: item.name,
        nativeName: item.local
      })
    }
  })
  return options
}

/**
 * Get the approval company status based on the status code.
 *
 * @param {string} status - The status code.
 * @returns {string} - The approval company status.
 */
export const getApprovalCompanyStatus = (status) => {
  return status ? COMPANY_STATUSES[status] : COMPANY_STATUSES.to_approve
}

/**
 * Get the approval pilot status based on the status code.
 *
 * @param {string} status - The status code.
 * @returns {string} - The approval pilot status.
 */
export const getApprovalPilotStatus = (status) => {
  return status ? PILOT_STATUSES[status] : PILOT_STATUSES.requested
}

/**
 * Convert a country object to default options format.
 *
 * @param {Object} object - The country object.
 * @returns {Object} - The converted default options object.
 */
export const convertCountryToDefaultOptions = (object) => {
  const { id, isoCode, name } = object
  return { id, value: isoCode, label: name }
}

// export const convertSubscriberTypesToDefaultOptions = (
//   companySubscriberTypes
// ) => {
//   const defaultOptions = []
//   companySubscriberTypes.forEach((companySubscriberType) => {
//     defaultOptions.push({
//       value: companySubscriberType.subscriberType,
//       label: SUBSCRIBER_TYPES[companySubscriberType.subscriberType]
//     })
//   })
//   return defaultOptions
// }

/**
 * Renders company buttons based on the status.
 *
 * @param {string} status - The status of the company.
 * @returns {Array} An array of objects representing the buttons.
 */
export const renderCompanyButtonByStatus = (status) => {
  switch (status) {
    case 'to_approve':
      return [
        {
          value: 'approved',
          label: COMPANY_DETAIL_BUTTONS.approve
        },
        {
          value: 'refused',
          label: COMPANY_DETAIL_BUTTONS.refuse
        }
      ]
    case 'approved':
      return [
        {
          value: 'refused',
          label: COMPANY_DETAIL_BUTTONS.refuse
        },
        {
          value: 'canceled',
          label: COMPANY_DETAIL_BUTTONS.cancel
        }
      ]
    case 'refused':
      return [
        {
          value: 'approved',
          label: COMPANY_DETAIL_BUTTONS.approve
        }
      ]
    default:
      return []
  }
}

/**
 * Converts a custom list to an options array.
 *
 * @param {string} key - The key to use as the label for each option.
 * @param {Array} list - The custom list to convert.
 * @returns {Array} - The converted options array.
 */
export const convertCustomListToOptions = (key, list) => {
  if (!list) {
    return []
  }
  return list.map((item) => {
    return {
      value: item.id,
      label: item[key]
    }
  })
}

export const convertObjectToDefaultItem = (key, object) => {
  if (!object) {
    return {}
  }
  return {
    value: object.id,
    label: object[key]
  }
}

/**
 * Get a list of unique items based on their ids.
 *
 * @param {Array} list - The list of items.
 * @returns {Array} - The list of unique items.
 */
export const getUniqueItem = (list) => {
  const uniqueIds = new Set()
  const uniqueList = list.filter((item) => {
    if (!uniqueIds.has(item.id)) {
      uniqueIds.add(item.id)
      return true
    }
    return false
  })

  return uniqueList
}

/**
 * Returns a new array with unique items based on their id property.
 *
 * @param {Array} list - The array of items.
 * @returns {Array} - The new array with unique items.
 */
export const capitalize = (str = '') => {
  return str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
}

/**
 * Splits a camel case string into separate words.
 *
 * @param {string} str - The camel case string to be split.
 * @returns {string} - The split string with spaces between words.
 */
export const splitCamelCase = (str = '') => {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2')
}

/**
 * Returns true if the passed object is a JavaScript array, otherwise false.
 *
 * @param {Object} value The object to test
 * @return {Boolean}
 */
export const isArray = (value) => {
  return toString.apply(value) === '[object Array]'
}

/**
 * Returns true if the passed object is a JavaScript Object, otherwise false.
 *
 * @param {Object} value The object to test
 * @return {Boolean}
 */
export const isObject = (value) => {
  return value && typeof value === 'object'
}

/**
 * Return true if passed object is empty.
 *
 * @param {Mixed} value The value to test
 * @returns {boolean}
 */
export const isEmptyObject = (value) => {
  if (!isObject(value)) {
    return false
  }
  if (value instanceof Date) {
    return false
  }
  if (Object.keys(value).length) {
    return false
  }
  return true
}

/**
 * Returns true if the passed value is empty. The value is deemed to be
 * empty if it is * null * undefined * an empty array * a zero length * an empty object
 * string (Unless the allowBlank parameter is true)
 *
 * @param {Mixed} value The value to test
 * @param {Boolean} allowBlank (optional) true to allow empty strings (defaults to false)
 * @return {Boolean}
 */
export const isEmpty = (value, allowBlank = false) => {
  return (
    value === null ||
    value === undefined ||
    (isArray(value) && !value.length) ||
    (!allowBlank ? value === '' : false) ||
    isEmptyObject(value)
  )
}

export const convertAppUserFilter = (filterData) => {
  const { selectedCountry, ...newFilterData } = filterData
  return {
    ...newFilterData,
    countryId: selectedCountry?.id
  }
}

export const convertCompanyFilter = (filterData) => {
  const {
    selectedCompanyType,
    selectedCountry,
    selectedStatus,
    selectedSubscriberType,
    selectedOrderBy,
    ...newFilterData
  } = filterData
  const filterDataFormatted = {
    ...newFilterData,
    companyType: selectedCompanyType?.value?.toLowerCase(),
    country: selectedCountry?.id,
    status: selectedStatus?.value,
    subscriberType: selectedSubscriberType?.value
  }
  if (selectedOrderBy?.value) {
    filterDataFormatted.sortBy = `${selectedOrderBy.value}:asc`
  }
  return filterDataFormatted
}

export const convertUserFilter = (filterData) => {
  const { selectedStatus, ...newFilterData } = filterData
  return {
    ...newFilterData,
    status: selectedStatus?.value
  }
}

export const convertVehicleFilter = (filterData) => {
  const { selectedSortBy, selectedVehicleType, ...newFilterData } = filterData
  const filterDataFormatted = { ...newFilterData }
  if (typeof filterDataFormatted?.linkType !== 'undefined') {
    filterDataFormatted.filterType = filterDataFormatted?.linkType
      ? COMPANY_FILTER_TYPE.EQUAL
      : COMPANY_FILTER_TYPE.NOT_EQUAL

    filterDataFormatted.linkType = 1
  }
  if (selectedSortBy?.value) {
    filterDataFormatted.sortBy = `${selectedSortBy.value}:asc`
  }
  return filterDataFormatted
}

export const convertElectronicKeyFilter = (filterData) => {
  const { selectElectronicKeyModel, ...newFilterData } = filterData
  const filterDataFormatted = {
    ...newFilterData,
    electronicKeyModel: selectElectronicKeyModel?.value
  }
  return filterDataFormatted
}

export const convertTextFilter = (filterData) => {
  const {
    selectedLanguage,
    selectedModule,
    selectedSearchScope,
    ...newFilterData
  } = filterData
  const filterDataFormatted = {
    ...newFilterData,
    languageTranslationId: selectedLanguage?.id,
    moduleId: selectedModule?.id,
    searchScope: selectedSearchScope?.value
  }
  return filterDataFormatted
}

export const convertRoleFilter = (filterData) => {
  const { selectedGroupCode, ...newFilterData } = filterData
  const filterDataFormatted = {
    ...newFilterData,
    groupCode: selectedGroupCode?.value
  }
  return filterDataFormatted
}

export const showSpecificDataCompanyFilter = (key, value) => {
  switch (key) {
    case 'selectedCompanyType':
      return COMPANY_TYPES[value]
    case 'selectedSubscriberType':
      return SUBSCRIBER_TYPES[value]
    default:
      return COMPANY_STATUSES[value]
  }
}

export const getAddress = (data = {}) => {
  let result = data.zipCode ?? data.zipCodeAddress ?? ''
  const city = data.city ?? data.cityAddress ?? ''
  const country = data.country ?? data.countryAddress ?? ''
  if (city) {
    result = result ? `${result} - ${city}` : city
  }
  if (country) {
    result = result ? `${result} (${country.name})` : country.name
  }
  return result
}

export const getTranslationBy = (item, language) => {
  return (
    item.translations?.find(
      (translation) => translation?.language?.id === language?.id
    ) ?? {}
  )
}

export const getTranslationForComponent = (item, language) => {
  return (
    item.translations?.find(
      (translation) => translation?.languageId === language?.id
    ) ?? {}
  )
}

export const getTranslationForText = (item = {}, language = {}) => {
  if (language.isoCode === DEFAULT_LANGUAGE_ISO_CODE) {
    return { translationText: item.originalText }
  }
  return getTranslationBy(item, language)
}

export const getTranslationForMessage = (item = {}, language = {}) => {
  if (language?.isoCode === DEFAULT_LANGUAGE_ISO_CODE) {
    return { translationMessage: item.originalMessage }
  }
  return getTranslationBy(item, language)
}

export const getTranslationForLabelUsageSystemLog = (
  item = {},
  language = {}
) => {
  if (language?.isoCode === DEFAULT_LANGUAGE_ISO_CODE) {
    return { labelUsageSystemLogTranslation: item.labelUsageSystemLog }
  }
  return getTranslationBy(item, language)
}

export const getTranslationForLabel = (item = {}, language = {}) => {
  if (language.isoCode === DEFAULT_LANGUAGE_ISO_CODE) {
    return { originalName: item.originalName, helpText: item.helpText }
  }
  return getTranslationBy(item, language)
}

export const rearrangeTranslates = (translations = []) => {
  translations.sort((firstEl) => {
    if (firstEl.language?.isoCode === DEFAULT_LANGUAGE_ISO_CODE) {
      return -1
    }
    return 0
  })
  return translations
}

export const normalSanitizedData = (data) => ({
  __html: data
})

export const sanitizedData = (data) => ({
  __html: DOMPurify.sanitize(data)
})

export const convertMailTemplateFilter = (filterData) => {
  const filterDataFormatted = { ...filterData }
  if (!filterDataFormatted?.moduleId) {
    delete filterDataFormatted?.moduleId
  }
  return filterDataFormatted
}

export const getExtension = (filename) => {
  if (!filename) {
    return ''
  }
  const extensionValue = filename.split('.').pop()
  if (!extensionValue) {
    return ''
  }
  const extensionArr = extensionValue.split('?')
  return extensionArr[0]
}

export const getType = (type) => {
  if (!type) {
    return ''
  }
  const arrType = type.split('/')
  return arrType[1]
}

export const formatSectionObject = (values) => {
  const newValues = { ...values }
  if (!values.createdOn) {
    delete newValues.createdOn
  }
  if (!values.timeToRead) {
    delete newValues.timeToRead
  }
  if (!values.timeToRead) {
    delete newValues.timeToRead
  }
  return newValues
}

export const convertSectionObject = (values, media) => {
  const { mainPicture } = values
  const type = getType(mainPicture?.type)
  let newValues = {
    ...values,
    urlStaticPicture: '',
    urlAnimatedPicture: '',
    urlVideo: ''
  }
  if (!type) {
    return values
  }

  if (MAPPING_MAIN_PICTURE_FILE_TYPES.STATIC_PICTURE.includes(type)) {
    newValues = { ...newValues, urlStaticPicture: media }
  } else if (MAPPING_MAIN_PICTURE_FILE_TYPES.ANIMATED_PICTURE.includes(type)) {
    newValues = { ...newValues, urlAnimatedPicture: media }
  } else if (MAPPING_MAIN_PICTURE_FILE_TYPES.VIDEO.includes(type)) {
    newValues = { ...newValues, urlVideo: media }
  }
  return formatSectionObject(newValues)
}

export const convertSectionObjectMobile = (values, media) => {
  const { mainPictureMobile } = values
  const type = getType(mainPictureMobile?.type)
  let newValues = {
    ...values,
    urlMobileStaticPicture: '',
    urlMobileAnimatedPicture: '',
    urlMobileVideo: ''
  }
  if (!type) {
    return values
  }

  if (MAPPING_MAIN_PICTURE_FILE_TYPES.STATIC_PICTURE.includes(type)) {
    newValues = { ...newValues, urlMobileStaticPicture: media }
  } else if (MAPPING_MAIN_PICTURE_FILE_TYPES.ANIMATED_PICTURE.includes(type)) {
    newValues = { ...newValues, urlMobileAnimatedPicture: media }
  } else if (MAPPING_MAIN_PICTURE_FILE_TYPES.VIDEO.includes(type)) {
    newValues = { ...newValues, urlMobileVideo: media }
  }
  return formatSectionObject(newValues)
}

export const getDateFormat = (dateFormats, type) => {
  const data = dateFormats.find((dateFormat) => dateFormat.code === type)
  return { id: data?.id ?? '', value: data?.translationValue || data?.value }
}

export const getTranslationForDatetimeFormat = (item, language) => {
  return (
    item.translations?.find(
      (translation) => translation?.language?.id === language.id
    ) ?? {}
  )
}

export const getPages = (sectionPages) => {
  if (!sectionPages) {
    return ''
  }
  return sectionPages
    .map((sectionPage) => SECTION_PAGE_VALUES[sectionPage?.page])
    .join(', ')
}

export const getSelectedPages = (sectionPages) => {
  const values = sectionPages.map((sectionPage) => sectionPage.page)
  if (values.length > 0) {
    return SECTION_PAGE_LIST.filter((item) => values.includes(item.value))
  }
  return []
}

export const getTranslationItem = (translation, selectedLanguage, item) => {
  const {
    thumbnailTitle,
    baliseTitle,
    baliseTitleThumbnail,
    thumbnailDescription,
    baliseMetaDescriptionThumbnail,
    baliseMetaDescription,
    headerTitle,
    headerDescription,
    content1,
    content2,
    content3,
    content4,
    content5,
    url
  } = translation
  const formInitValue = {
    thumbnailTitleTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.thumbnailTitle
        : thumbnailTitle,

    baliseTitleThumbnailTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.baliseTitleThumbnail
        : baliseTitleThumbnail,

    baliseTitleTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.baliseTitle
        : baliseTitle,

    thumbnailDescriptionTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.thumbnailDescription
        : thumbnailDescription,

    baliseMetaDescriptionThumbnailTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.baliseMetaDescriptionThumbnail
        : baliseMetaDescriptionThumbnail,

    baliseMetaDescriptionTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.baliseMetaDescription
        : baliseMetaDescription,

    headerTitleTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.headerTitle
        : headerTitle,

    headerDescriptionTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.headerDescription
        : headerDescription,

    urlTranslate:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE ? item?.url : url,

    contentTranslate1:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.content1
        : content1,

    contentTranslate2:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.content2
        : content2,

    contentTranslate3:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.content3
        : content3,

    contentTranslate4:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.content4
        : content4,

    contentTranslate5:
      selectedLanguage?.isoCode === DEFAULT_LANGUAGE_ISO_CODE
        ? item?.content5
        : content5
  }

  Object.keys(formInitValue).forEach((key) => {
    formInitValue[key] = formInitValue[key] || ''
  })

  return formInitValue
}

export const checkIsVideo = (type) => {
  if (!type) {
    return false
  }
  const arrType = type.split('/')
  if (arrType[0] === 'video') {
    return true
  }
  return false
}

export const getFileInResponse = (response, fileName) => {
  const file = response.find((item) => item.originalFileName === fileName)
  return file?.fileKey
}

export const getDataFromS3 = (response, values) => {
  let newValues = { ...values }
  const tempMainPicture = getFileInResponse(response, values?.mainPicture?.name)
  const tempMainPictureMobile = getFileInResponse(
    response,
    values?.mainPictureMobile?.name
  )
  const tempAuthorAvatar = getFileInResponse(
    response,
    values?.authorAvatar?.name
  )
  if (tempMainPicture) {
    newValues = convertSectionObject(newValues, tempMainPicture)
  }
  if (tempMainPictureMobile) {
    newValues = convertSectionObjectMobile(newValues, tempMainPictureMobile)
  }
  if (tempAuthorAvatar) {
    newValues = { ...newValues, authorAvatar: tempAuthorAvatar }
  }
  return newValues
}

export const getImageKeyFromS3 = (response) => {
  return response?.s3Uploads ? response?.s3Uploads[0]?.fileKey : ''
}

export const getImageKeyFromS3WithUrl = (response) => {
  return response?.s3Uploads ? response?.s3Uploads[0]?.publicUrl : ''
}

export const commonUploadMedia = (
  uploadMedia,
  values,
  callback,
  data,
  fieldKey = 'avatar',
  id = null,
  isMediaKit = false,
  isGetUrl = false
) => {
  const formData = new FormData()
  formData.append('files', data)
  formData.append('isMediaKit', isMediaKit)

  uploadMedia(formData, {
    onSuccess: (res) => {
      const response = res?.response
      const media = isGetUrl
        ? getImageKeyFromS3WithUrl(response)
        : getImageKeyFromS3(response)
      const newValues = { ...values }
      newValues[fieldKey] = media
      if (id) {
        callback(id, newValues)
      } else {
        callback(newValues)
      }
      return media
    },
    onError: (error) => {
      toast.error(error)
    }
  })
}

/**
 * Formats a number as a currency string.
 *
 * @param {string} num - The number to format.
 * @returns {string} The formatted currency string.
 */
export const currencyFormat = (num) => {
  return num?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

/**
 * Filter out values from an object
 * @param obj
 * @param removeValues - array of values to filter out
 * @param removeKeys - array of keys to filter out
 *
 * Example:
 * filterOutNullValues({ a: 1, b: null, c: undefined, d: 0, e: '' }, [null, undefined, ''])
 * { a: 1, d: 0, e: '' }
 *
 * filterOutNullValues({ a: 1, b: 2, d: 0, e: '' }, [], ['b'])
 * { a: 1, d: 0, e: '' }
 *
 */
export const filterOutValues = (obj, removeValues = [], removeKeys = []) => {
  if (!obj) return obj

  const newObj = {}
  Object.keys(obj).forEach((key) => {
    if (removeKeys?.includes(key)) return

    if (!removeValues?.includes(obj[key])) newObj[key] = obj[key]
  })

  return newObj
}

/**
 * Replace a value in an object at a given path, and return a new object
 * Note: if the parent path does not exist, the parent will be created as an empty object
 * @param obj - object to replace value in
 * @param path - path to value, e.g. 'user.name'
 * @param value - value to replace with
 */
export const replaceValueAtPath = (obj, path, value) => {
  const newObj = { ...obj }
  const pathArray = path.split('.')
  let currentObj = newObj
  try {
    pathArray.forEach((key, index) => {
      if (index === pathArray.length - 1) {
        currentObj[key] = value
        return
      }

      if (!currentObj[key]) currentObj[key] = {}

      currentObj = currentObj[key]
    })
    return newObj
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(
      'Could not update store, please try to clear `persist:store` in local storage'
    )
    // eslint-disable-next-line no-console
    console.warn(e)
    return obj
  }
}

/**
 * Get a value from an object at a given path
 * @param obj - object to get value from
 * @param path - path to value, e.g. 'user.name'
 * @param defaultValue - default value to return if path is not found
 * @returns {*}
 */
export const getValueAtPath = (obj, path, defaultValue) => {
  if (!obj) return defaultValue
  if (!path) return obj

  try {
    const pathArray = path.split('.')
    let currentObj = obj
    pathArray.forEach((key) => {
      currentObj = currentObj[key]
    })
    return currentObj || defaultValue
  } catch (e) {
    return defaultValue
  }
}

/**
 * Get label of subscription status by status value
 * @param status - status value e.g. 'requested_by_cixi'
 * @returns {string}
 */
export const getSubscriptionStatus = (status) =>
  SUBSCRIPTION_STATUSES.find((s) => s.value === status)?.label || ''

/**
 * Get label of vehicle type by type value
 * @param type - type value e.g. '1', '2'
 * @returns {*|string}
 */
export const getVehicleType = (type) =>
  VEHICLE_TYPE_LIST.find((s) => s.value === type)?.label || ''

/**
 * Replace a key in an object with a new key, and return a new object
 * @param obj - object to replace key in
 * @param keyPairs - array with old key and new key, e.g. [{ oldKey: 'name', newKey: 'fullName' }]
 * @returns {*}
 */
export const changeKey = (obj, keyPairs) => {
  if (!obj || !keyPairs) return obj

  const newObj = { ...obj }
  keyPairs.forEach((keyPair) => {
    const { oldKey, newKey } = keyPair
    newObj[newKey] = newObj[oldKey]
    delete newObj[oldKey]
  })
  return newObj
}

/**
 * Convert object to option for select
 * @param obj - object to convert e.g. { 1: 'Option 1', 2: 'Option 2' }
 * @returns {{label: *, value: *}[]|*[]}
 */
export const convertObjectToOptions = (obj) => {
  if (!obj) return []

  return Object.keys(obj).map((key) => ({ label: obj[key], value: key }))
}

/**
 * Downloads a file from the given URL with the specified file name.
 *
 * @param {string} url - The URL of the file to download.
 * @param {string} fileName - The name to be used for the downloaded file.
 */
export const downloadUrl = (url, fileName) => {
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * Downloads a file with the given data and file name.
 *
 * @param {any} data - The data to be downloaded.
 * @param {string} fileName - The name of the file to be downloaded.
 */
export const downloadFile = (data, fileName) => {
  const blob = new Blob([data])
  const url = window.URL.createObjectURL(blob)
  downloadUrl(url, fileName)
}

/**
 * Print a list of device types based on type: 1,2
 * @param type
 * @returns {*|string}
 */
export const getDeviceType = (type) => {
  if (!type) return ''

  const keys = type.split(',')
  return keys.map((key, idx) => (
    // eslint-disable-next-line react/no-array-index-key
    <span key={idx}>
      {DEVICE_TYPES[key]}
      {idx !== keys.length - 1 ? ', ' : ''}
    </span>
  ))
}

/**
 * Checks if the given name is the super admin.
 *
 * @param {string} name - The name to be checked.
 * @return {boolean} Returns true if the name is the super admin, false otherwise.
 */
export const isSuperAdmin = (name) => {
  return name === SUPER_ADMIN
}

export const convertKeyUsageSystemLog = (detail) => {
  const label = detail?.keyUsageSystemLogObject?.labelUsageSystemLog
  let content = ''
  let labelFormatted = label
  if (detail?.contentUsageSystemLog) {
    try {
      const contentUsageSystemLog = JSON.parse(detail?.contentUsageSystemLog)
      const { key, val } = contentUsageSystemLog

      content = val

      labelFormatted = label.replace(`{${key}}`, val)
    } catch (e) {
      labelFormatted = label
    }
  }
  return { content, labelFormatted }
}

/**
 * Converts an object into an array of options.
 *
 * @param {Object} obj - The object to convert.
 * @returns {Array} - The array of options.
 */
export const toArrayOptions = (obj) => {
  if (!obj) {
    return []
  }
  return Object.keys(obj).map((key) => ({ label: obj[key], value: key }))
}

/**
 * Converts an object into an options object.
 *
 * @param {Object} obj - The input object.
 * @returns {Object} - The options object.
 */
export const toObjectOptions = (obj) => {
  if (!obj) {
    return {}
  }
  return Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      [key]: { label: obj[key], value: key }
    }),
    {}
  )
}

/**
 * Left pad a number to 2 digits
 *
 * @param {number} number - number to pad
 * @returns {string} padded number as a string
 */
export const padTo2Digits = (number) => {
  return `${number}`.padStart(2, '0')
}

/**
 * Convert minutes to time in the format hh:mm.
 *
 * @param {number} minutes - minutes to convert
 * @returns {string} time in hh:mm format
 */
export const convertMinutesToTime = (minutes = 0) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  const remainingHours = hours % 24

  return `${remainingHours}h ${remainingMinutes}mn`
}

/**
 * Convert seconds to time in the format hh:mm:ss.
 *
 * @param {number} seconds - seconds to convert
 * @returns {string} time in hh:mm:ss format
 */
export const convertSecondsToTime = (seconds = 0) => {
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(seconds / 3600)
  const remainingSeconds = seconds % 60
  const remainingMinutes = minutes % 60
  const remainingHours = hours % 24

  return `${padTo2Digits(remainingHours)}:${padTo2Digits(
    remainingMinutes
  )}:${padTo2Digits(remainingSeconds)}`
}

/**
 * Format the period of a vehicle trip
 *
 * @param {Function} parseDateFn - Function to parse date
 * @param {Object} vehicleTrip - Object containing trip begin and end dates
 * @returns {string} - Formatted string representing the period of the vehicle trip
 */
export const formatPeriodVehicleTrip = (parseDateFn, vehicleTrip) => {
  const { dtTripBegin, dtTripEnd } = vehicleTrip || {}
  if (!dtTripBegin && !dtTripEnd) {
    return ''
  }
  const dtBegin = parseDateFn(dtTripBegin, {
    formatType: DATE_FORMAT.SHORT_BY_DASH
  })
  const timeBegin = parseDateFn(dtTripBegin, {
    formatType: DATE_FORMAT.TIME_LONG
  })
  const dtEnd = parseDateFn(dtTripEnd, {
    formatType: DATE_FORMAT.SHORT_BY_DASH
  })
  const timeEnd = parseDateFn(dtTripEnd, { formatType: DATE_FORMAT.TIME_LONG })
  const fromLabel = <IntlMessages id='vehicle_trip.from' />
  const toLabel = <IntlMessages id='vehicle_trip.to' />

  if (dtBegin === dtEnd) {
    return (
      <>
        {dtBegin} {fromLabel} {timeBegin} {toLabel} {timeEnd}
      </>
    )
  }
  return (
    <>
      {dtBegin} {fromLabel} {timeBegin} {toLabel} {dtEnd} {timeEnd}
    </>
  )
}

/**
 * Convert a number to a round precision.
 *
 * @param {number} num Number to convert
 * @param {number} [digits=2] Number of digits to keep after the decimal point
 * @returns {number} Number rounded to the given precision
 */
export const toRound = (num, digits = 2) => {
  if (num === undefined || num === null) {
    return null
  }
  const precision = digits >= 0 ? Math.min(digits, 292) : Math.max(digits, -292)
  if (precision) {
    let pair = `${num}e`.split('e')
    const value = Math.round(`${pair[0]}e${+pair[1] + precision}`)

    pair = `${value}e`.split('e')
    return +`${pair[0]}e${+pair[1] - precision}`
  }
  return Math.round(num)
}

/**
 * Convert a number to a percentage.
 *
 * @param {number} num Number to convert
 * @returns {number} Number as a percentage (0-100) or null if no number provided
 */
export const toPercent = (num) => {
  if (num === undefined || num === null) {
    return null
  }
  if (typeof num === 'string') {
    return parseFloat(num) * 100
  }
  return num * 100
}

/**
 * Parse a JSON string safely.
 *
 * @param {string} str JSON string to parse
 * @returns {object|string} Parsed object if the string is a valid JSON, the original string otherwise
 */
export const jsonParse = (str) => {
  try {
    return JSON.parse(str)
  } catch (error) {
    return str
  }
}

export const isEmptyData = (value) => {
  return (
    value == null || (typeof value === 'string' && value.trim().length === 0)
  )
}

/**
 * Checks if the provided start and end dates form a valid period.
 *
 * @returns {boolean} Returns true if the period is valid, false otherwise.
 */
export function checkValidPeriod() {
  const { startDate, endDate } = this.parent || {}
  if (!endDate || !startDate) {
    return true
  }

  return (
    new Date(endDate).setHours(23, 59, 59, 999) >
    new Date(startDate).setHours(0, 0, 0, 0)
  )
}

/**
 * Renders the name of a vehicle.
 *
 * @param {Object} vehicle - The vehicle object.
 * @param {string} vehicle.id - The ID of the vehicle.
 * @param {Object} vehicle.brand - The brand of the vehicle.
 * @param {string} vehicle.brand.name - The name of the brand.
 * @param {Object} vehicle.vehicleModel - The model of the vehicle.
 * @param {string} vehicle.vehicleModel.nameVehicleModel - The name of the vehicle model.
 * @param {Object} vehicle.vehicleType - The type of the vehicle.
 * @param {string} vehicle.vehicleType.label - The label of the vehicle type.
 * @returns {string} - The rendered vehicle name.
 */
export const renderVehicleName = (vehicle = {}) => {
  const { id, brand, vehicleModel, vehicleType } = vehicle
  if (!id) {
    return ''
  }
  const brandName = brand?.name || ''
  const modelName = vehicleModel?.nameVehicleModel || ''
  const vehicleTypeLabel = vehicleType?.label || ''
  return `${brandName} ${modelName} (${vehicleTypeLabel})`
}

/**
 * Checks if a given string is a number.
 *
 * @param {string} str - The string to be checked.
 * @returns {boolean} - Returns true if the string is a number, otherwise returns false.
 */
export const isNumber = (str) => {
  return /^\d+$/.test(str)
}

/**
 * Converts a string to its corresponding digit representation.
 *
 * @param {string} str - The string to convert.
 * @param {boolean} isNoLeadingZero - Whether to remove leading zeros from the result.
 * @returns {string} - The digit representation of the string.
 */
export const toDigit = (str, isNoLeadingZero) => {
  if (!str) {
    return ''
  }
  if (isNoLeadingZero) {
    return str.replace(/[^0-9]|^0(?=[0-9])/g, '').trim()
  }
  return str.replace(/[^0-9]/g, '').trim()
}

/**
 * Converts the given value to a number.
 *
 * @param {any} str - The value to be converted.
 * @returns {number|null} - The converted number, or null if conversion fails.
 */
export const toNumber = (str) => {
  if (typeof str === 'number') {
    return str
  }
  const digit = toDigit(str, true)
  return digit ? Number(digit) : null
}

// export const onlyInputPositiveInteger = (value) => {
//   if (!value) {
//     return ''
//   }
//   return value.replace(/[^0-9]/g, '')
// }

export const base64ToFile = (url, filename, mimeType) => {
  if (url.startsWith('data:')) {
    const arr = url.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[arr.length - 1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    const file = new File([u8arr], filename, { type: mime || mimeType })
    return Promise.resolve(file)
  }
  return fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }))
}

export const showToastError = (message, id) => {
  if (!toast.isActive(message)) {
    toast.error(message, { toastId: id }) // Use message as the toastId
  }
}
