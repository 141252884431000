import BaseApi from './base'

class DateTimeFormat extends BaseApi {
  getDateTimeFormats = (query) =>
    this.client.get(`admin/date-time-config?${query}`)

  updateDateTimeFormat = (id, data) => {
    this.client.patch(`admin/date-time-config/${id}/translation`, data)
  }

  getDateTimeFormatsByLanguage = (query) =>
    this.client.get(`admin/date-time-config/language?${query}`)
}

export default new DateTimeFormat()
