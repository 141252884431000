import keyMirror from 'keymirror'

export default keyMirror({
  UPDATE_FILTER_ANONYMIZE_VISITOR_SUCCESS: null,
  CLEAR_FILTER_ANONYMIZE_VISITOR_SUCCESS: null,

  GET_ANONYMIZE_VISITOR_REQUEST: null,
  GET_ANONYMIZE_VISITOR_SUCCESS: null,
  GET_ANONYMIZE_VISITOR_FAILED: null,

  CANCEL_ANONYMIZE_VISITOR_REQUEST: null,
  CANCEL_ANONYMIZE_VISITOR_SUCCESS: null,
  CANCEL_ANONYMIZE_VISITOR_FAILED: null
})
