import { DEFAULT_PERS_PACKAGE_FILTER } from 'constants/common'
import PersPackageTypes from 'store/actionTypes/persPackage'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  current: {},
  pagination: {},
  filter: DEFAULT_PERS_PACKAGE_FILTER
}

const persPackage = (state = initialData, action) => {
  switch (action.type) {
    case PersPackageTypes.GET_PERS_PACKAGES_SUCCESS:
      return {
        ...state,
        list: action.payload?.persPackage?.items,
        pagination: action.payload?.persPackage?.meta
      }
    case PersPackageTypes.GET_PERS_PACKAGE_BY_ID_SUCCESS:
      return { ...state, current: action.payload }
    case PersPackageTypes.UPDATE_FILTER_PERS_PACKAGE_SUCCESS:
      return { ...state, filter: action.payload }
    case PersPackageTypes.CLEAR_FILTER_PERS_PACKAGE_SUCCESS:
      return { ...state, filter: initialData.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default persPackage
