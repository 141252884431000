import NotificationRequestTypes from 'store/actionTypes/notificationRequest'
import UserTypes from 'store/actionTypes/user'
import {
  DEFAULT_NOTIFICATION_REQUEST_FILTER,
  DEFAULT_NOTIFICATION_SENT_FILTER
} from 'constants/common'

const initData = {
  listRequest: [],
  listSent: [],
  pagination: {},
  notificationRequestFilter: DEFAULT_NOTIFICATION_REQUEST_FILTER,
  notificationSentFilter: DEFAULT_NOTIFICATION_SENT_FILTER
}

const notificationRequest = (state = initData, action) => {
  switch (action.type) {
    case NotificationRequestTypes.GET_LIST_NOTIFICATION_REQUEST_SUCCESS:
      return {
        ...state,
        listRequest: action.payload?.items,
        pagination: action.payload?.meta
      }
    case NotificationRequestTypes.GET_LIST_NOTIFICATION_SENT_SUCCESS:
      return { ...state, listSent: action.payload?.items }
    case NotificationRequestTypes.UPDATE_FILTER_NOTIFICATION_REQUEST_SUCCESS:
      return { ...state, notificationRequestFilter: action.payload }
    case NotificationRequestTypes.CLEAR_FILTER_NOTIFICATION_REQUEST_SUCCESS:
      return {
        ...state,
        notificationRequestFilter: initData.notificationRequestFilter
      }
    case NotificationRequestTypes.UPDATE_FILTER_NOTIFICATION_SENT_SUCCESS:
      return { ...state, notificationSentFilter: action.payload }
    case NotificationRequestTypes.CLEAR_FILTER_NOTIFICATION_SENT_SUCCESS:
      return {
        ...state,
        notificationSentFilter: initData.notificationSentFilter
      }
    case UserTypes.LOGOUT_SUCCESS:
      return initData
    default:
      return state
  }
}

export default notificationRequest
