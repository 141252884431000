import Types from '../actionTypes/companyPerLink'
import { companyPerLink as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const getCompanyPersLink = bindRequestLogic({
  clientFunc: clientApi.getCompanyPersLink,
  type: Types.GET_COMPANY_PERS_LINK_REQUEST,
  successType: Types.GET_COMPANY_PERS_LINK_SUCCESS,
  failType: Types.GET_COMPANY_PERS_LINK_FAILED,
  isQueryParam: true
})

const addCompanyPerLink = bindRequestLogic({
  clientFunc: clientApi.addCompanyPerLink,
  type: Types.ADD_COMPANY_PER_LINK_REQUEST,
  successType: Types.ADD_COMPANY_PER_LINK_SUCCESS,
  failType: Types.ADD_COMPANY_PER_LINK_FAILED
})

const updateCompanyPerLink = bindRequestLogic({
  clientFunc: clientApi.updateCompanyPerLink,
  type: Types.UPDATE_COMPANY_PER_LINK_REQUEST,
  successType: Types.UPDATE_COMPANY_PER_LINK_SUCCESS,
  failType: Types.UPDATE_COMPANY_PER_LINK_FAILED
})

const deleteCompanyPerLink = bindRequestLogic({
  clientFunc: clientApi.deleteCompanyPerLink,
  type: Types.DELETE_COMPANY_PER_LINK_REQUEST,
  successType: Types.DELETE_COMPANY_PER_LINK_SUCCESS,
  failType: Types.DELETE_COMPANY_PER_LINK_FAILED
})

export default [
  getCompanyPersLink,
  deleteCompanyPerLink,
  updateCompanyPerLink,
  addCompanyPerLink
]
