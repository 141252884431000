import keyMirror from 'keymirror'

export default keyMirror({
  GET_APP_USERS_REQUEST: null,
  GET_APP_USERS_SUCCESS: null,
  GET_APP_USERS_FAILED: null,

  GET_APP_USER_DETAIL_REQUEST: null,
  GET_APP_USER_DETAIL_SUCCESS: null,
  GET_APP_USER_DETAIL_FAILED: null,

  DELETE_APP_USER_REQUEST: null,
  DELETE_APP_USER_SUCCESS: null,
  DELETE_APP_USER_FAILED: null,

  ADD_APP_USER_REQUEST: null,
  ADD_APP_USER_SUCCESS: null,
  ADD_APP_USER_FAILED: null,

  UPDATE_APP_USER_REQUEST: null,
  UPDATE_APP_USER_SUCCESS: null,
  UPDATE_APP_USER_FAILED: null,

  UPDATE_ROLE_USER_REQUEST: null,
  UPDATE_ROLE_USER_SUCCESS: null,
  UPDATE_ROLE_USER_FAILED: null,

  GET_ROLE_USERS_REQUEST: null,
  GET_ROLE_USERS_SUCCESS: null,
  GET_ROLE_USERS_FAILED: null,

  UPDATE_FILTER_APP_USER_SUCCESS: null,
  CLEAR_FILTER_APP_USER_SUCCESS: null,

  GET_USER_SUBSCRIPTION_REQUEST: null,
  GET_USER_SUBSCRIPTION_SUCCESS: null,
  GET_USER_SUBSCRIPTION_FAILED: null,
  UPDATE_FILTER_USER_SUBSCRIPTION_SUCCESS: null,
  CLEAR_FILTER_USER_SUBSCRIPTION_SUCCESS: null,

  GET_USER_VEHICLES_REQUEST: null,
  GET_USER_VEHICLES_SUCCESS: null,
  GET_USER_VEHICLES_FAILED: null,

  GET_LIST_VEHICLES_SHARING_EXTENSION_REQUEST: null,
  GET_LIST_VEHICLES_SHARING_EXTENSION_SUCCESS: null,
  GET_LIST_VEHICLES_SHARING_EXTENSION_FAILED: null,

  GET_DETAIL_VEHICLES_SHARING_REQUEST: null,
  GET_DETAIL_VEHICLES_SHARING_SUCCESS: null,
  GET_DETAIL_VEHICLES_SHARING_FAILED: null,

  GET_SUBSCRIPTION_ALLOWED_PILOTS_REQUEST: null,
  GET_SUBSCRIPTION_ALLOWED_PILOTS_SUCCESS: null,
  GET_SUBSCRIPTION_ALLOWED_PILOTS_FAILED: null,

  FORCE_STATUS_TEMPORAL_EXTENSION_REQUEST: null,
  FORCE_STATUS_TEMPORAL_EXTENSION_SUCCESS: null,
  FORCE_STATUS_TEMPORAL_EXTENSION_FAILED: null,

  FORCE_STATUS_PERMANENT_EXTENSION_REQUEST: null,
  FORCE_STATUS_PERMANENT_EXTENSION_SUCCESS: null,
  FORCE_STATUS_PERMANENT_EXTENSION_FAILED: null,

  FORCE_STATUS_CHECK_IN_REQUEST: null,
  FORCE_STATUS_CHECK_IN_SUCCESS: null,
  FORCE_STATUS_CHECK_IN_FAILED: null,

  FORCE_STATUS_CHECK_OUT_REQUEST: null,
  FORCE_STATUS_CHECK_OUT_SUCCESS: null,
  FORCE_STATUS_CHECK_OUT_FAILED: null,

  GET_STATUS_USERS_REQUEST: null,
  GET_STATUS_USERS_SUCCESS: null,
  GET_STATUS_USERS_FAILED: null,

  GET_DIRECT_VEHICLES_BY_USER_ID_REQUEST: null,
  GET_DIRECT_VEHICLES_BY_USER_ID_SUCCESS: null,
  GET_DIRECT_VEHICLES_BY_USER_ID_FAILED: null,

  CANCEL_DIRECT_VEHICLE_REQUEST: null,
  CANCEL_DIRECT_VEHICLE_SUCCESS: null,
  CANCEL_DIRECT_VEHICLE_FAILED: null,

  GET_COMM_SYSTEM_LOGS_REQUEST: null,
  GET_COMM_SYSTEM_LOGS_SUCCESS: null,
  GET_COMM_SYSTEM_LOGS_FAILED: null,

  UPSERT_GDPR_REQUEST: null,
  UPSERT_GDPR_SUCCESS: null,
  UPSERT_GDPR_FAILED: null,

  GET_GDPR_REQUEST: null,
  GET_GDPR_SUCCESS: null,
  GET_GDPR_FAILED: null
})
