import BaseApi from './base'

class VehicleModel extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  getVehicleModels = (query) => this.client.get(`vehicle-model?${query}`)

  getVehicleModelDetail = (id) => this.client.get(`vehicle-model/${id}`)

  addVehicleModel = (data) => this.client.post('vehicle-model', data)

  updateVehicleModel = (id, data) =>
    this.client.patch(`vehicle-model/${id}`, data)

  deleteVehicleModel = (id) => this.client.delete(`vehicle-model/${id}`)

  getVehicleModelsByVehicleBrand = (id) =>
    this.client.get(`vehicle-model/brand/${id}`)

  getSystemVehicleModels = (query) =>
    this.client.get(`systems/vehicle-model?${query}`)
}

export default new VehicleModel()
