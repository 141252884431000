import React from 'react'
import SystemMessages from 'helpers/SystemMessages'
import IntlMessages from 'helpers/IntlMessages'
import moment from 'moment-timezone'
import { toArrayOptions } from 'helpers/utils'

export const SECTION_KEYS = {
  CixiCenterContent: 'cixicenter_content'
}

export const DEFAULT_LANGUAGE_ISO_CODE = 'eng'

export const REGEX_PASSWORD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}/

export const DATE_FORMAT_MEDIUM = 'MMM DD YYYY'

export const DATE_FORMAT_LONG = 'YYYY-MM-DD HH:mm:ss'

export const DATE_FORMAT = {
  NONE: 'none',
  SHORT: 'dateFormatShort',
  MEDIUM: 'dateFormatMedium',
  LONG: 'dateFormatLong',
  TO_SERVER: 'dateFormatToServer',
  TIME_LONG: 'timeFormatLong',
  SHORT_BY_DASH: 'dateFormatShortByDash',
  PRO_CENTER_ADMIN: 'dateFormatProcenterAdmin'
}

export const DATE_PICKER_FORMAT = {
  short: 'MM/dd/yyyy',
  shortByDash: 'yyyy-MM-dd'
}

export const PAGINATION = {
  offset: 0,
  firstPage: 1,
  limit: 10,
  unlimited: 0,
  maximumLimit: 1000
}

export const LEFT_TO_RIGHT = 'left to right'

export const RIGHT_TO_LEFT = 'right to left'

export const DEFAULT_LANGUAGE = 'English'

export const SORT_DESC = 'desc'

export const SORT_ASC = 'asc'

export const LOGIN_ERROR_MESSAGE = {
  err_user_not_found: <SystemMessages id='login.err_user_not_found' />,
  err_email_or_password_incorrect: (
    <SystemMessages id='login.err_email_or_password_incorrect' />
  ),
  err_invalid_account: <SystemMessages id='login.err_invalid_account' />
}

export const MESSAGE_VALIDATION_PASSWORD = {
  schema: <SystemMessages id='password.incorrect' />,
  match: <SystemMessages id='password.not_match' />,
  newPassRequired: <SystemMessages id='password.new_pass_required' />,
  confirmPassRequired: <SystemMessages id='password.confirm_pass_required' />
}

export const SAVE_SUCCESS_MESSAGE = <SystemMessages id='save_success_message' />

export const SAVE_FAILED_MESSAGE = <SystemMessages id='save_failed_message' />

export const CREATE_SUCCESS_MESSAGE = (
  <SystemMessages id='create_success_message' />
)

export const CREATE_FAILED_MESSAGE = (
  <SystemMessages id='create_failed_message' />
)

export const CREATE_TRANSLATION_SUCCESS_MESSAGE = (
  <SystemMessages id='create_translation_success_message' />
)

export const CREATE_TRANSLATION_FAILED_MESSAGE = (
  <SystemMessages id='create_translation_failed_message' />
)

export const UPDATE_SUCCESS_MESSAGE = (
  <SystemMessages id='update_success_message' />
)

export const UPDATE_FAILED_MESSAGE = (
  <SystemMessages id='update_failed_message' />
)

export const UPDATE_TRANSLATION_SUCCESS_MESSAGE = (
  <SystemMessages id='update_translation_success_message' />
)

export const UPDATE_TRANSLATION_FAILED_MESSAGE = (
  <SystemMessages id='update_translation_failed_message' />
)

export const DELETE_SUCCESS_MESSAGE = (
  <SystemMessages id='delete_success_message' />
)

export const DELETE_FAILED_MESSAGE = (
  <SystemMessages id='delete_failed_message' />
)

export const DELETE_TRANSLATION_SUCCESS_MESSAGE = (
  <SystemMessages id='delete_translation_success_message' />
)
export const DELETE_TRANSLATION_FAILED_MESSAGE = (
  <SystemMessages id='delete_translation_failed_message' />
)

export const UPDATE_LABELS_SUCCESS_MESSAGE = (
  <SystemMessages id='update_labels_success_message' />
)

export const UPDATE_LABELS_FAILED_MESSAGE = (
  <SystemMessages id='update_labels_failed_message' />
)

export const UPDATE_MESSAGES_SUCCESS_MESSAGE = (
  <SystemMessages id='update_messages_success_message' />
)

export const UPDATE_MESSAGES_FAILED_MESSAGE = (
  <SystemMessages id='update_messages_failed_message' />
)

export const UPDATE_TEXTS_SUCCESS_MESSAGE = (
  <SystemMessages id='update_texts_success_message' />
)

export const UPDATE_TEXTS_FAILED_MESSAGE = (
  <SystemMessages id='update_texts_failed_message' />
)

export const IMPORT_SUCCESS_MESSAGE = (
  <SystemMessages id='import_success_message' />
)

export const IMPORT_FAILED_MESSAGE = (
  <SystemMessages id='import_failed_message' />
)

export const PREFIX_BASE64 = ';base64'
export const PREFIX_IMAGE_BASE64 = 'data:image/png;base64'
export const LANGUAGE_LIST = [
  {
    label: 'English',
    value: 'eng',
    nativeName: 'English',
    key: 0,
    flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAABLAQMAAABnbRrWAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABlBMVEX////OESShKa/1AAAAAWJLR0QAiAUdSAAAAAd0SU1FB+YEEw0yASlK+zoAAAAhSURBVDjLY2AAA8b/fxhQwKjAiBD4jwp+jDCB0eQwggUACC5lxyHmcj0AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDQtMTlUMTM6NTA6MDErMDA6MDBmnE1DAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTA0LTE5VDEzOjUwOjAxKzAwOjAwF8H1/wAAAABJRU5ErkJggg=='
  },
  {
    label: 'French',
    value: 'fra',
    nativeName: 'French',
    key: 1,
    flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAABTBAMAAABACBVQAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAD1BMVEUAJlRUbYz////eYG7OESZmtw3fAAAAAWJLR0QCZgt8ZAAAAAd0SU1FB+YBFQEdGcyrcskAAAAzSURBVFjD7cshAQAgEASwowpfBEH/TBQ4i9v8kmLtYm5x4vu+7/u+7/u+7/u+7/u+/+k/XcG18y207eUAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDEtMjFUMDE6Mjk6MjUrMDA6MDDmiE0oAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTAxLTIxVDAxOjI5OjI1KzAwOjAwl9X1lAAAAABJRU5ErkJggg=='
  },
  {
    label: 'German',
    value: 'deu',
    nativeName: 'German',
    key: 2,
    flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAABLAgMAAAAgzWAGAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAADFBMVEUAAADdAAD/zgD///9i/kqcAAAAAWJLR0QDEQxM8gAAAAd0SU1FB+EICgkYHjnEV5cAAAApSURBVEjHY2AYBaNgFJAOQvEDh1EFowpGFWBRsAo/aBhVMKpgVAGmAgBrTxbnZ9bOpwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxNy0wOC0xMFQwOToyNDozMCswMDowMMk6o/kAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTctMDgtMTBUMDk6MjQ6MzArMDA6MDC4ZxtFAAAAAElFTkSuQmCC'
  },
  {
    label: 'Japan',
    value: 'jpa',
    nativeName: 'Japan',
    key: 3,
    flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAABTCAMAAACF+PhRAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABOFBMVEX////78fPxy9TrssDop7fkmav55+vkmqzTWXbDHES8AC3UWnflm6356Oz+/f3qrrzPSWm9Ay+9BDDPSmrqr73//v7HKlDHK1Doqbj01t3NQGLNQmP12N/mobK/CjW+CDTikKT++vvVX3vWYn7++/z89ffQS2vORGX9+frUW3jVXnrikaTjlKfyzte+BzPz0NjLOFvKNVnkmKrEH0fEHUXlnK3mnq/JM1fKN1r01dz0197egpjfhZu+CTT22+Hrsb/rs8Dhi5/hjaHeg5nNQWPORWbfhpvKNFjlna7mn7DEHkbLOVzz0dnjk6bkl6nVYX3+/P3RUG/QTGzQTWz99vjWZH/XZ4K/CzbOQ2T12uHpq7rILFHILlPprbu9BTH56e3UXHnUXXr56u788/XyzNXrtMHoqLfyzdZu4LVCAAAAAWJLR0QAiAUdSAAAAAd0SU1FB+YEEw00EWKnTNgAAAGiSURBVGje7ZmHTgJBEIaPJjIiIogFUSwURQUUxS4qFsSOvRcE3/8NjDGSaIDbSWbuEjLfC3zZ3OzMzn+aJgiCIAiCUMNitdkddpvVYri5zdnugl9cHe5O49SeLm83/MXn7/EY4g7Ye6Eeff0GyAeC0IjBELd8aBgaEx5hdY+OQXPGI3zyqB/0iEXZTu4FfeITTMU+CSokeOxToMY0h3wmqWhPphgaXBpUmaVve3Ogjpu83jMI+zx13S8AhiyxfRFlX6KVLwOOFVL7KtK+RmoPIu1x0ooPI+3rlLMuBFg2CO05tH3TpEb3g5PQvoW2bxPa82h7vmXOjv/u7papeXPvu7m9TttB2ndJpwz2yu3R7o6mzndtHyVPE7+sCij7gZlv2iL5LudE2Av0u8yhmbuMllLd48JHHGvksaL9hGeFTijJT5myi7NzBXmMLbmJlnTlF5d8sVHkSkd+zZhZfed1N83mqoM7LgzdNh6rdwZkpfcPdd2PT8Zk1IFcyfdP/VyyBYwLyV8Kr8WaOvOWfTf8D0H5o1J1VCufZU0QBEEQBKHGF/28ahI3KDeEAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTA0LTE5VDEzOjUyOjE3KzAwOjAwzROo2gAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0wNC0xOVQxMzo1MjoxNyswMDowMLxOEGYAAAAASUVORK5CYII='
  }
]

export const UNAUTHORIZED = 401

export const USER_STATUS = Object.freeze({
  block: 'block',
  active: 'active'
})

export const USER_STATUS_LIST = [
  { label: 'All', value: '' },
  { label: 'Active', value: USER_STATUS.active },
  { label: 'Block', value: USER_STATUS.block }
]

export const DEFAULT_USER_STATUS_LIST = { label: 'All', value: '' }
export const BLOCK_SELECTED_USER = 'Block selected user(s)'
export const DELETE_SELECTED_USER = 'Delete selected user(s)'
export const UNBLOCK_SELECTED_USER = 'Unblock selected user(s)'

export const USER_ACTIONS = [BLOCK_SELECTED_USER, UNBLOCK_SELECTED_USER]

export const SEARCH_IN = [
  {
    value: 'translated',
    label: 'Only translated strings'
  },
  {
    value: 'not_translated',
    label: 'Only untranslated strings'
  },
  {
    value: 'both',
    label: 'Both translated and untranslated strings'
  }
]

export const DEFAULT_GENDER = { label: 'Male', value: 1 }

export const GENDER_LIST = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 }
]

export const DEFAULT_GROUP = {
  id: 0,
  label: 'All',
  value: 0
}

export const FLEET_MANAGER = 'fleet_manager_member'

export const SEARCH_SCOPE = {
  value: 'both',
  label: 'Both translated and untranslated strings'
}

export const MAPPING_TEXT_FILTER = Object.freeze({
  originalText: <IntlMessages id='texts.string' />,
  keyword: <IntlMessages id='texts.keyword' />,
  selectedModule: <IntlMessages id='texts.module' />,
  selectedLanguage: <IntlMessages id='texts.language' />,
  selectedSearchScope: <IntlMessages id='texts.search_in' />
})

export const MAPPING_LABEL_FILTER = Object.freeze({
  name: <IntlMessages id='labels.string' />,
  keyword: <IntlMessages id='keyword' />,
  selectedModule: <IntlMessages id='module' />,
  selectedLanguage: <IntlMessages id='labels.language' />,
  selectedSearchScope: <IntlMessages id='labels.search_in' />,
  selectedLabelInterfacePage: <IntlMessages id='labels.page' />
})

export const SPECIAL_LABEL_FILTER = [
  'selectedLanguage',
  'selectedSearchScope',
  'selectedLabelInterfacePage'
]

export const USER_FILTER = Object.freeze({
  name: <IntlMessages id='users.name' />,
  email: <IntlMessages id='users.email' />,
  selectedStatus: <IntlMessages id='users.status' />
})

export const MAPPING_VEHICLE_SHARING_FILTER = Object.freeze({
  sharingId: <IntlMessages id='sharing_management.sharing_id' />,
  pilotName: <IntlMessages id='sharing_management.pilot_name' />,
  selectedSharingType: <IntlMessages id='sharing_management.sharing_type' />,
  selectedSharingStatus: (
    <IntlMessages id='sharing_management.sharing_status' />
  ),
  selectedVehicleType: <IntlMessages id='sharing_management.vehicle_type' />
})

export const MAPPING_ELECTRONIC_KEYS_TAB_VEHICLE_FILTER = Object.freeze({
  nameVehicle: <IntlMessages id='electronicKeys.vehicle_name' />,
  vehicleType: <IntlMessages id='electronicKeys.vehicle_type' />,
  registrationNumber: (
    <IntlMessages id='electronicKeys.vehicle_register_number' />
  ),
  status: <IntlMessages id='electronicKeys.status' />,
  isCurrent: <IntlMessages id='electronicKeys.is_current' />
})

export const MAPPING_LOG_MAIL_FILTER = Object.freeze({
  templateCode: <IntlMessages id='logMails.template_code' />,
  selectedModule: <IntlMessages id='logMails.module' />,
  sentFrom: <IntlMessages id='logMails.from' />,
  sentTo: <IntlMessages id='logMails.to' />,
  recipient: <IntlMessages id='logMails.recipient' />,
  sender: <IntlMessages id='logMails.sender' />,
  subject: <IntlMessages id='logMails.subject' />,
  status: <IntlMessages id='logMails.status' />
})

export const LOG_MAIL_STATUS_LABEL = Object.freeze({
  pending: <IntlMessages id='logMails.status_to_send' />,
  done: <IntlMessages id='logMails.status_sent' />,
  fail: <IntlMessages id='logMails.status_failed' />
})

export const DATE_LOG_MAIL_FILTER = ['sentFrom', 'sentTo']

export const MAPPING_LANGUAGE = Object.freeze({
  en: 'gb',
  uk: 'gb',
  ja: 'jp',
  hi: 'in',
  zh: 'cn',
  vi: 'vn'
})

export const MAPPING_LOG_ROUTINE_FILTER = Object.freeze({
  selectedCodeRoutine: <IntlMessages id='log_routine.code' />,
  sentFrom: <IntlMessages id='log_routine.date_from' />,
  sentTo: <IntlMessages id='log_routine.date_to' />,
  selectedModule: <IntlMessages id='log_routine.module' />
})

export const MAPPING_PERS_PACKAGE_FILTER = Object.freeze({
  packageId: <IntlMessages id='per_package.package_id' />,
  statusReceptionPackage: <IntlMessages id='per_package.status_reception' />,
  persSerialNumber: <IntlMessages id='per_package.pers_serial_number' />,
  statusAnalyzePackage: <IntlMessages id='per_package.status_analyze' />
})

export const SELECT_LOG_ROUTINE_FILTER = ['selectedCodeRoutine']

export const DATE_LOG_ROUTINE_FILTER = ['sentFrom', 'sentTo']

export const DEFAULT_VEHICLE_TYPE = {
  label: <IntlMessages id='vehicle_model.vigoz' />,
  value: '1'
}

export const VEHICLE_TYPE_LIST = [
  { label: <IntlMessages id='vehicle_model.vigoz' />, value: '1' },
  { label: <IntlMessages id='vehicle_model.eBike' />, value: '2' }
]

export const VEHICLE_TYPE_VALUES = Object.freeze({
  1: <IntlMessages id='vehicle_model.vigoz' />,
  2: <IntlMessages id='vehicle_model.eBike' />
})

export const MAPPING_VEHICLE_MODEL_FILTER = Object.freeze({
  nameVehicleModel: <IntlMessages id='vehicle_model.name' />,
  vehicleType: <IntlMessages id='vehicle_model.type' />,
  vehicleBrand: <IntlMessages id='vehicle_model.brand' />
})

export const MAPPING_VEHICLE_TYPE_FILTER = Object.freeze({
  label: <IntlMessages id='vehicle_types.label' />
})

export const MAPPING_MESSAGE_FILTER = Object.freeze({
  message: <IntlMessages id='messages.string' />,
  keyword: <IntlMessages id='messages.keyword' />,
  selectedModule: <IntlMessages id='messages.module' />,
  selectedLanguage: <IntlMessages id='messages.language' />,
  selectedSearchScope: <IntlMessages id='messages.search_in' />
})

export const MAPPING_MODEL_PER_FILTER = Object.freeze({
  label: <IntlMessages id='modelPers.name' />
})

export const MAPPING_SOFTWARE_PERS_FILTER = Object.freeze({
  label: <IntlMessages id='software_per.name' />
})

export const MAPPING_PERS_FILTER = Object.freeze({
  nuSerialPersNumber: <IntlMessages id='per.pers_serial_number' />,
  nameVehicle: <IntlMessages id='per.vehicle_name' />,
  vehicleSerialNumber: <IntlMessages id='per.vehicle_serial_number' />,
  vehicleRegistrationNumber: (
    <IntlMessages id='per.vehicle_registration_number' />
  ),
  odooPersId: <IntlMessages id='per.odoo_id' />,
  selectModelPer: <IntlMessages id='per.model_pers' />,
  selectSoftwarePer: <IntlMessages id='per.software_pers' />,
  selectVehicleBrand: <IntlMessages id='per.vehicle_brand' />
})

export const MAPPING_CIXI_REFERENT_FILTER = Object.freeze({
  name: <IntlMessages id='cixiReferents.name' />,
  email: <IntlMessages id='cixiReferents.email' />
})

export const MAPPING_ELECTRONIC_KEY_MODEL_FILTER = Object.freeze({
  nameElectronicKeyModel: <IntlMessages id='electronicKeyModels.name' />,
  vehicleTypeId: <IntlMessages id='electronicKeyModels.type' />
})

export const SUBSCRIBER_TYPE_OPTIONS = [
  { value: 'COMPANY_BTOC', label: <IntlMessages id='company_btoc' /> },
  { value: 'COMPANY_BTOE', label: <IntlMessages id='company_btoe' /> }
]

export const COMPANY_TYPE_OPTIONS = [
  { value: 'independent', label: <IntlMessages id='independent' /> },
  { value: 'mother', label: <IntlMessages id='mother' /> },
  { value: 'children', label: <IntlMessages id='children' /> }
]

export const SUBSCRIBER_TYPES = Object.freeze({
  COMPANY_BTOC: <IntlMessages id='company_btoc' />,
  COMPANY_BTOE: <IntlMessages id='company_btoe' />
})

export const DEFAULT_COMPANY_TYPE = 'independent'

export const COMPANY_TYPES = Object.freeze({
  independent: <IntlMessages id='independent' />,
  mother: <IntlMessages id='mother' />,
  children: <IntlMessages id='children' />
})

export const COMPANY_TYPE_VALUES = Object.freeze({
  independent: 'independent',
  mother: 'mother',
  children: 'children'
})

export const COMPANY_STATUS_OPTIONS = [
  { value: 'to_approve', label: <IntlMessages id='status.to_approve' /> },
  { value: 'approved', label: <IntlMessages id='status.approved' /> },
  { value: 'refused', label: <IntlMessages id='status.refused' /> },
  { value: 'canceled', label: <IntlMessages id='status.canceled' /> }
]

export const COMPANY_STATUSES = Object.freeze({
  to_approve: <IntlMessages id='status.to_approve' />,
  approved: <IntlMessages id='status.approved' />,
  refused: <IntlMessages id='status.refused' />,
  canceled: <IntlMessages id='status.canceled' />
})

export const PILOT_STATUS_OPTIONS = [
  { value: 'requested', label: <IntlMessages id='status.requested' /> },
  { value: 'approved', label: <IntlMessages id='status.approved' /> },
  { value: 'refused', label: <IntlMessages id='status.refused' /> },
  { value: 'canceled', label: <IntlMessages id='status.canceled' /> }
]

export const PILOT_STATUSES = Object.freeze({
  requested: <IntlMessages id='status.requested' />,
  approved: <IntlMessages id='status.approved' />,
  refused: <IntlMessages id='status.refused' />,
  canceled: <IntlMessages id='status.canceled' />
})

export const MAPPING_COMPANY_FILTER = Object.freeze({
  companyName: 'Name',
  zipCode: 'Zip Code',
  selectedCountry: 'Country',
  selectedCompanyType: 'Company Type',
  selectedSubscriberType: 'SubscriberType',
  selectedStatus: 'Status',
  companyCategoryId: 'Company Category'
})

export const MAPPING_COMPANY_VEHICLE_FILTER = Object.freeze({
  registrationNumber: <IntlMessages id='vehicles.registration_number' />,
  vehicleName: <IntlMessages id='vehicles.name' />,
  serialNumber: <IntlMessages id='vehicles.serial_number' />,
  type: <IntlMessages id='vehicles.type' />,
  brandId: <IntlMessages id='vehicles.brand' />,
  status: <IntlMessages id='vehicles.status' />
})

export const MAPPING_COMPANY_SUBSCRIPTIONS_FILTER = Object.freeze({
  subscriber: <IntlMessages id='subscription.subscriber' />,
  subscriptionRef: <IntlMessages id='subscription.subscription' />,
  status: <IntlMessages id='subscription.status' />
})

export const COMPANY_DETAIL_BUTTONS = Object.freeze({
  approve: <IntlMessages id='button.approve' />,
  refuse: <IntlMessages id='button.refuse' />,
  cancel: <IntlMessages id='button.cancel' />
})

export const MAPPING_APP_USER_FILTER = Object.freeze({
  odooId: <IntlMessages id='odoo_id' />,
  name: <IntlMessages id='app_users.name' />,
  email: <IntlMessages id='app_users.email' />,
  selectedCountry: <IntlMessages id='app_users.country' />
})

export const SORT_BY_VEHICLE_OPTIONS = [
  {
    value: 'registrationNumber',
    label: <IntlMessages id='vehicles.registration_number' />
  },
  { value: 'name', label: <IntlMessages id='vehicles.name' /> }
]

export const MAPPING_VEHICLE_FILTER = Object.freeze({
  vehicleType: <IntlMessages id='vehicles.type' />,
  nameVehicle: <IntlMessages id='vehicles.name' />,
  serialNumber: <IntlMessages id='serial_number' />,
  registrationNumber: <IntlMessages id='vehicles.registration_number' />,
  selectedSortBy: <IntlMessages id='pages.order_by' />,
  vehicleBrand: <IntlMessages id='brand' />,
  companyId: <IntlMessages id='vehicles.company_linked' />,
  linkType: <IntlMessages id='vehicles.company_link_type' />
})

export const MAPPING_ELECTRONIC_KEY_FILTER = Object.freeze({
  selectElectronicKeyModel: <IntlMessages id='electronicKeys.model' />,
  nuSerialElectronicKeyNumber: <IntlMessages id='serial_number' />,
  vehicleName: <IntlMessages id='electronicKeys.vehicleName' />,
  vehicleRegisterNumber: (
    <IntlMessages id='electronicKeys.vehicleRegisterNumber' />
  )
})

export const MAPPING_PLATFORM_PAGE_TECH_KEY_FILTER = Object.freeze({
  labelPlatformPage: <IntlMessages id='platform_page_tech.label' />,
  keyPlatformPageTechs: <IntlMessages id='platform_page_tech.key' />,
  selectedPlatformTechs: <IntlMessages id='platform_page_tech.platform_tech' />
})

export const MAPPING_KEY_USAGE_SYSTEM_LOG_FILTER = Object.freeze({
  type: <IntlMessages id='key_usage_system_log.type' />,
  languageId: <IntlMessages id='key_usage_system_log.language' />,
  moduleId: <IntlMessages id='key_usage_system_log.module' />,
  searchIn: <IntlMessages id='key_usage_system_log.search_in' />,
  keyword: <IntlMessages id='key_usage_system_log.keyword' />,
  originalName: <IntlMessages id='key_usage_system_log.string' />
})

export const MAPPING_USAGE_SYSTEM_LOG_FILTER = Object.freeze({
  type: <IntlMessages id='usage_system_log.type' />,
  sender: <IntlMessages id='usage_system_log.sender' />,
  logLabel: <IntlMessages id='usage_system_log.log_label' />
})

export const MAPPING_COMM_SYSTEM_LOG_FILTER = Object.freeze({
  type: <IntlMessages id='comm_system_log.type' />,
  persSerialNumber: <IntlMessages id='comm_system_log.pers_serial_number' />
})

export const MAPPING_COMPANY_ROLE_FILTER = Object.freeze({
  title: <IntlMessages id='company_role.title' />
})

export const MAPPING_PER_ALERT_FILTER = Object.freeze({
  alert: <IntlMessages id='per_alert.alert' />,
  topicAlert: <IntlMessages id='per_alert.topic_alert' />,
  groupAlert: <IntlMessages id='per_alert.group_alert' />
})

export const MAPPING_PER_JOURNEY_LOG_FILTER = Object.freeze({
  statusTrip: <IntlMessages id='per_journey_log.status_trip' />,
  userName: <IntlMessages id='per_journey_log.user_name' />
})

export const DEFAULT_APP_VERSIONS_FILTER = Object.freeze({
  platformTechId: '',
  isActive: '',
  version: '',
  os: ''
})

export const DEFAULT_MESSAGE_AFTER_CONNECTION_FILTER = Object.freeze({
  portalId: '',
  title: '',
  dtBegin: '',
  dtEnd: ''
})

export const ACTIVITY_TYPES = Object.freeze({
  systemLog: 'system_log',
  manualComment: 'manual_comment'
})

export const MODEL_TYPES = Object.freeze({
  company: 'company',
  vehicle: 'vehicle',
  electronic_key: 'electronic_key',
  user_app: 'user_app',
  subscription: 'subscription',
  pers: 'pers'
})

export const ACTIVITY_TYPE_VALUES = Object.freeze({
  manual_comment: <IntlMessages id='activity.manual' />
})

export const MEMBER_STATUSES = Object.freeze({
  requested: 1,
  accepted: 2,
  refused: 3,
  canceled: 4
})

export const MEMBER_STATUS_VALUES = Object.freeze({
  1: <IntlMessages id='status.requested' />,
  2: <IntlMessages id='status.accepted' />,
  3: <IntlMessages id='status.refused' />,
  4: <IntlMessages id='status.canceled' />
})

export const CANCEL_MEMBER_SUCCESS_MESSAGE = (
  <SystemMessages id='CANCEL_MEMBER_SUCCESS_MESSAGE' />
)
export const CANCEL_MEMBER_FAILED_MESSAGE = (
  <SystemMessages id='CANCEL_MEMBER_FAILED_MESSAGE' />
)

export const REQUEST_CONFIRM_MESSAGE =
  'Are you sure you want to request {name}?'
export const REFUSE_CONFIRM_MESSAGE = 'Are you sure you want to refuse {name}?'
export const APPROVE_CONFIRM_MESSAGE =
  'Are you sure you want to approve {name}?'
export const DELETE_CONFIRM_MESSAGE = 'Are you sure you want to delete {name}?'
export const CANCEL_CONFIRM_MESSAGE = 'Are you sure you want to cancel {name}?'
export const DELETE_MEMBER_CONFIRM_MESSAGE =
  'Are you sure you want to delete this member ?{name}'

export const CONVERT_TO_MOTHER_COMPANY_CONFIRM_MESSAGE =
  'After the conversion, this Company can not come back to the Independent Company. Do you confirm ?{name}'
export const REMOVE_CHILDREN_COMPANY_CONFIRM_MESSAGE =
  'This child company will become Individual Company. Do you confirm ?{name}'

export const DELETE_TRANSLATION_MESSAGE =
  'Are you sure you want to delete Translation value for {name} ?'

export const MAPPING_CHILDREN_COMPANY_FILTER = Object.freeze({
  companyName: <IntlMessages id='filter.name' />,
  zipCode: <IntlMessages id='filter.zipCode' />,
  selectedCountry: <IntlMessages id='filter.country' />
})

export const DEFAULT_PAGE_LIMIT = {
  page: PAGINATION.firstPage,
  limit: PAGINATION.limit
}

export const DEFAULT_SCROLL_LOAD_PAGE_LIMIT = {
  page: PAGINATION.firstPage,
  limit: 36
}

export const DEFAULT_APP_USER_FILTER = {
  selectedCountry: null,
  odooId: '',
  email: '',
  name: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_PLATFORM_PAGE_TECH_FILTER = {
  selectedPlatformTechs: null,
  keyPlatformPageTechs: '',
  labelPlatformPage: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_NOTIFICATION_REQUEST_FILTER = {
  keyNotificationTemplates: '',
  keyPlatformTech: '',
  emailUserDestList: '',
  startDate: '',
  endDate: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_NOTIFICATION_SENT_FILTER = {
  keyPlatformTech: '',
  emailUserDestList: '',
  notificationType: '',
  limit: PAGINATION.unlimited
}

export const DEFAULT_APP_USER_SUBSCRIPTIONS_FILTER = {
  subscriptionRef: '',
  status: ''
}

export const DEFAULT_COMPANY_FILTER = {
  selectedCompanyType: null,
  selectedSubscriberType: null,
  selectedStatus: null,
  selectedCountry: null,
  selectedOrderBy: null,
  zipCode: '',
  companyName: '',
  companyCategoryId: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_PILOTS_FILTER = {
  name: '',
  email: '',
  status: '',
  ...DEFAULT_SCROLL_LOAD_PAGE_LIMIT
}

export const DEFAULT_COMPANY_VEHICLES_FILTER = {
  registrationNumber: '',
  vehicleName: '',
  serialNumber: '',
  type: '',
  brandId: '',
  status: ''
}

export const DEFAULT_COMPANY_SUBSCRIPTIONS_FILTER = {
  subscriber: '',
  subscriptionRef: '',
  status: ''
}

export const DEFAULT_SUBSCRIPTION_FILTER = {
  vehicleName: '',
  vehicleRegistrationNumber: '',
  status: '',
  vehicleType: '',
  referenceSubscription: '',
  subscriber: '',
  company: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_COUNTRY_FILTER = {
  name: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_DATE_TIME_FORMAT = {
  selectedLanguage: ''
}

export const DEFAULT_CIXI_REFERENT_FILTER = {
  name: '',
  email: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_TOPIC_FILTER = {
  topicType: null,
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_DOCUMENTATION_FILTER = {
  name: '',
  topicId: null,
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_LABEL_FILTER = {
  name: '',
  keyword: '',
  selectedModule: null,
  selectedLanguage: null,
  selectedSearchScope: SEARCH_SCOPE,
  selectedLabelInterfacePage: null,
  limit: PAGINATION.limit,
  offset: PAGINATION.offset
}

export const DEFAULT_TEXT_FILTER = {
  selectedLanguage: null,
  selectedSearchScope: SEARCH_SCOPE,
  selectedModule: null,
  keyword: '',
  originalText: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_ELECTRONIC_KEYS_TAB_VEHICLE_FILTER = Object.freeze({
  nameVehicle: '',
  vehicleType: '',
  registrationNumber: '',
  status: '',
  isCurrent: true,
  ...DEFAULT_PAGE_LIMIT
})

/**
 * Returns a date that is `month` months ago from the current date.
 *
 * @param {number} month - The number of months to subtract from the current date.
 * @returns {Date} - The resulting date.
 */
const getDurationMonth = (month) => {
  let duration = moment(new Date()).subtract(month, 'month')

  // Set the time of the duration to 00:00:00.000
  duration = duration.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0
  })

  return duration.toDate()
}

const dateToString = (date) => moment(date).format('MM/DD/YYYY')

export const DEFAULT_LOG_MAIL_FILTER = {
  selectedModule: null,
  templateCode: '',
  sentFrom: dateToString(getDurationMonth(3)),
  sentTo: dateToString(new Date()),
  recipient: '',
  sender: '',
  subject: '',
  sortBy: 'dateSent:desc',
  status: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_LOG_ROUTINE_FILTER = {
  selectedModule: null,
  selectedCodeRoutine: null,
  sentFrom: dateToString(getDurationMonth(3)),
  sentTo: dateToString(new Date()),
  sortBy: 'date:desc',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_PERS_PACKAGE_FILTER = {
  statusReceptionPackage: '',
  statusAnalyzePackage: '',
  endDate: '',
  startDate: '',
  persSerialNumber: '',
  packageId: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_ROLE_FILTER = {
  name: '',
  selectedGroupCode: null,
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_USER_FILTER = {
  name: '',
  email: '',
  selectedStatus: DEFAULT_USER_STATUS_LIST,
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_SHARING_FILTER = {
  sortBy:
    'c.sharingType:asc,c.globalDTBegin:desc,c.createdAt:desc,c.referenceSharingId:asc',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_ELECTRONIC_KEY_MODEL_FILTER = {
  nameElectronicKeyModel: '',
  vehicleTypeId: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_ELECTRONIC_KEY_FILTER = {
  nuSerialElectronicKeyNumber: '',
  selectElectronicKeyModel: null,
  vehicleName: '',
  vehicleRegisterNumber: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_MODEL_PER_FILTER = {
  label: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_SOFTWARE_PER_FILTER = {
  label: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_PER_FILTER = {
  nuSerialPersNumber: '',
  nameVehicle: '',
  vehicleSerialNumber: '',
  vehicleRegistrationNumber: '',
  odooPersId: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_VEHICLE_MODEL_FILTER = {
  nameVehicleModel: '',
  vehicleTypeId: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_VEHICLE_FILTER = {
  selectedVehicleType: null,
  serialNumber: '',
  registrationNumber: '',
  nameVehicle: '',
  selectedSortBy: SORT_BY_VEHICLE_OPTIONS[1],
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_VEHICLE_TYPE_FILTER = {
  label: '',
  ...DEFAULT_PAGE_LIMIT
}
export const DEFAULT_VEHICLE_SUBSCRIPTION_FILTER = {
  companyId: '',
  subscriber: '',
  status: '',
  subscriptionRef: ''
}

export const DEFAULT_VEHICLE_USERS_FILTER = {
  pilot: '',
  dtBegin: null,
  dtEnd: null
}

export const DEFAULT_LANGUAGE_FILTER = {
  name: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_CURRENCY_FILTER = {
  name: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_CONDITIONAL_FEATURE_FILTER = {
  module: null,
  keyword: '',
  title: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_TIMEZONE_FILTER = {
  name: '',
  sortBy: `name:${SORT_ASC}`,
  ...DEFAULT_PAGE_LIMIT
}

export const MAPPING_ROLE_FILTER = Object.freeze({
  name: <IntlMessages id='roles.title' />,
  selectedGroupCode: <IntlMessages id='roles.type' />
})

export const VEHICLE_ELECTRONIC_KEY_STATUS_VALUES = Object.freeze({
  1: <IntlMessages id='status.new' />,
  2: <IntlMessages id='status.in_progress' />,
  3: <IntlMessages id='status.closed' />
})

export const VEHICLE_ELECTRONIC_KEY_STATUSES = [
  { value: '1', label: <IntlMessages id='status.new' /> },
  { value: '2', label: <IntlMessages id='status.in_progress' /> },
  { value: '3', label: <IntlMessages id='status.closed' /> }
]

export const MAPPING_LOGS = Object.freeze({
  companyName: <IntlMessages id='companies.name' />,
  cityAddress: <IntlMessages id='companies.address' />,
  countryAddressId: <IntlMessages id='companies.country' />,
  vehicleType: <IntlMessages id='type' />,
  serialNumber: <IntlMessages id='serial_number' />,
  registrationNumber: <IntlMessages id='vehicles.registration_number' />,
  nameVehicle: <IntlMessages id='vehicles.name' />,
  referenceSubscription: (
    <IntlMessages id='subscription.reference_subscription' />
  ),
  nuSerialElectronicKeyNumber: <IntlMessages id='serial_number' />,
  dtOptinProCenter: <IntlMessages id='gdpr.date' />,
  optinProCenter: <IntlMessages id='gdpr.opt_in_pro_center' />,
  platformTechKey: <IntlMessages id='gdpr.platform_tech' />,
  name: <IntlMessages id='app_users.name' />
})

export const MODULE_TYPES = {
  SHOWCASE_WEB: 'showcaseweb',
  MOBILE_APP: 'mobileapp',
  CIXI_CENTER: 'cixicenter',
  FLEET_MANAGER: 'fleetmanager',
  PRO_CENTER: 'procenter',
  PERS: 'pers'
}

export const MODULES = {
  [MODULE_TYPES.SHOWCASE_WEB]: <IntlMessages id='module.showcase_web' />,
  [MODULE_TYPES.MOBILE_APP]: <IntlMessages id='module.pilot_mobile_app' />,
  [MODULE_TYPES.CIXI_CENTER]: <IntlMessages id='module.cixi_center' />,
  [MODULE_TYPES.PERS]: <IntlMessages id='module.pers' />,
  [MODULE_TYPES.FLEET_MANAGER]: <IntlMessages id='module.fleet_manager' />,
  [MODULE_TYPES.PRO_CENTER]: <IntlMessages id='module.pro_center' />
}

export const TYPE_KEY_USAGE_SYSTEM_LOG_NUM = {
  info: 1,
  success: 2,
  warning: 3,
  error: 4
}

export const TYPE_KEY_USAGE_SYSTEM_LOG_LABEL = {
  [TYPE_KEY_USAGE_SYSTEM_LOG_NUM.info]: (
    <IntlMessages id='key_usage_system_log.info' />
  ),
  [TYPE_KEY_USAGE_SYSTEM_LOG_NUM.success]: (
    <IntlMessages id='key_usage_system_log.success' />
  ),
  [TYPE_KEY_USAGE_SYSTEM_LOG_NUM.warning]: (
    <IntlMessages id='key_usage_system_log.warning' />
  ),
  [TYPE_KEY_USAGE_SYSTEM_LOG_NUM.error]: (
    <IntlMessages id='key_usage_system_log.error' />
  )
}

export const TYPE_KEY_USAGE_SYSTEM_LOG_LIST = Object.keys(
  TYPE_KEY_USAGE_SYSTEM_LOG_LABEL
).map((value) => ({ label: TYPE_KEY_USAGE_SYSTEM_LOG_LABEL[value], value }))

export const MAP_MODULE_TO_FILTER = {
  [MODULE_TYPES.CIXI_CENTER]: 'filter',
  [MODULE_TYPES.SHOWCASE_WEB]: 'scwFilter',
  [MODULE_TYPES.MOBILE_APP]: 'mobileFilter',
  [MODULE_TYPES.FLEET_MANAGER]: 'fleetFilter'
}

export const TOPIC_TYPES = {
  0: <IntlMessages id='topic.faqTopic' />,
  1: <IntlMessages id='topic.documentationTopic' />
}

export const SUBSCRIPTION_SUBSCRIBER_TYPE = [
  {
    value: 'company',
    label: <IntlMessages id='subscription.subscriber_type_company' />
  },
  {
    value: 'individual',
    label: <IntlMessages id='subscription.subscriber_type_individual' />
  }
]

export const SUBSCRIPTION_STATUSES = [
  {
    label: <IntlMessages id='subscription.requested_by_customer' />,
    value: 'requested_by_customer'
  },
  {
    label: <IntlMessages id='subscription.proposed_by_cixi' />,
    value: 'proposed_by_cixi'
  },
  {
    label: <IntlMessages id='subscription.accepted_by_customer' />,
    value: 'accepted_by_customer'
  },
  {
    label: <IntlMessages id='subscription.accepted_by_cixi' />,
    value: 'accepted_by_cixi'
  },
  { label: <IntlMessages id='subscription.delivered' />, value: 'delivered' },
  {
    label: <IntlMessages id='subscription.request_refused_by_cixi' />,
    value: 'request_refused_by_cixi'
  },
  {
    label: <IntlMessages id='subscription.request_canceled_by_customer' />,
    value: 'request_canceled_by_customer'
  },
  {
    label: <IntlMessages id='subscription.proposal_refused_by_customer' />,
    value: 'proposal_refused_by_customer'
  },
  {
    label: <IntlMessages id='subscription.proposal_canceled_by_cixi' />,
    value: 'proposal_canceled_by_cixi'
  },
  { label: <IntlMessages id='subscription.in_closure' />, value: 'in_closure' },
  { label: <IntlMessages id='subscription.closed' />, value: 'closed' },
  {
    label: <IntlMessages id='subscription.canceled_by_cixi' />,
    value: 'canceled_by_cixi'
  }
]

export const SUBSCRIPTION_STATUS_OBJECT = SUBSCRIPTION_STATUSES.reduce(
  (acc, t) => ({ ...acc, [t.value]: t }),
  {}
)

export const SUBSCRIPTION_VEHICLE_STATUSES = [
  {
    label: <IntlMessages id='subscription.coming' />,
    value: 'coming'
  },
  {
    label: <IntlMessages id='subscription.delivery_in_progress' />,
    value: 'delivery_in_progress'
  },
  {
    label: <IntlMessages id='subscription.in_progress' />,
    value: 'in_progress'
  },
  {
    label: <IntlMessages id='subscription.returned' />,
    value: 'returned'
  }
]

export const SUBSCRIPTION_VEHICLE_STATUS_VALUES = {
  coming: <IntlMessages id='subscription.coming' />,
  delivery_in_progress: <IntlMessages id='subscription.delivery_in_progress' />,
  in_progress: <IntlMessages id='subscription.in_progress' />,
  returned: <IntlMessages id='subscription.returned' />
}

export const SUBSCRIPTION_MAPPING_FILTER = Object.freeze({
  vehicleName: <IntlMessages id='subscription.vehicle_name' />,
  vehicleRegistrationNumber: (
    <IntlMessages id='subscription.vehicle_registration_number' />
  ),
  status: <IntlMessages id='subscription.status' />,
  vehicleType: <IntlMessages id='subscription.vehicle_type' />,
  referenceSubscription: <IntlMessages id='subscription.subscription' />,
  subscriber: <IntlMessages id='subscription.subscriber' />,
  company: <IntlMessages id='subscription.company' />
})

export const DEFAULT_SHOWCASE_SECTION_FILTER = {
  keyword: '',
  label: '',
  technicalDescription: '',
  sectionPage: null,
  limit: PAGINATION.limit,
  offset: PAGINATION.offset
}

export const MAPPING_SHOWCASE_SECTION_FILTER = Object.freeze({
  keyword: <IntlMessages id='keyword' />,
  label: <IntlMessages id='label' />,
  technicalDescription: <IntlMessages id='technical_description' />,
  sectionPage: <IntlMessages id='page' />
})

export const TYPE_VALUES = Object.freeze({
  1: <IntlMessages id='type.string' />,
  2: <IntlMessages id='type.text' />,
  3: <IntlMessages id='type.rich_text' />,
  4: <IntlMessages id='type.icon' />,
  5: <IntlMessages id='type.static_picture' />,
  6: <IntlMessages id='type.animated_picture' />,
  7: <IntlMessages id='type.video' />,
  // 8: <IntlMessages id="type.internal_url" />,
  9: <IntlMessages id='type.external_url' />,
  10: <IntlMessages id='type.file' />
})

export const TYPE_LIST = [
  { label: <IntlMessages id='type.string' />, value: '1' },
  { label: <IntlMessages id='type.text' />, value: '2' },
  { label: <IntlMessages id='type.rich_text' />, value: '3' },
  { label: <IntlMessages id='type.icon' />, value: '4' },
  { label: <IntlMessages id='type.static_picture' />, value: '5' },
  { label: <IntlMessages id='type.animated_picture' />, value: '6' },
  { label: <IntlMessages id='type.video' />, value: '7' },
  // { label: <IntlMessages id="type.internal_url" />, value: '8' },
  { label: <IntlMessages id='type.external_url' />, value: '9' },
  { label: <IntlMessages id='type.file' />, value: '10' }
]

export const DOCUMENT_CONTENT_TYPES = Object.freeze({
  RICH_TEXT: 1,
  PDF: 2,
  EXTERNAL_URL: 3
})

export const DOCUMENT_CONTENT_TYPE_VALUES = Object.freeze({
  [DOCUMENT_CONTENT_TYPES.RICH_TEXT]: <IntlMessages id='type.rich_text' />,
  [DOCUMENT_CONTENT_TYPES.PDF]: <IntlMessages id='type.pdf' />,
  [DOCUMENT_CONTENT_TYPES.EXTERNAL_URL]: <IntlMessages id='type.extenal_url' />
})

export const DEFAULT_SUBSCRIPTION_TYPE = 'company'

export const DELETE_TRANSLATION_CONFIRM_MESSAGE =
  'Are you sure you want to delete Translation value for {name} ?'

export const DEFAULT_MAIL_TEMPLATE_FILTER = Object.freeze({
  name: '',
  keyword: '',
  moduleId: '',
  ...DEFAULT_PAGE_LIMIT
})

export const MAPPING_MAIL_TEMPLATE_FILTER = Object.freeze({
  name: <IntlMessages id='name' />,
  keyword: <IntlMessages id='keyword' />,
  moduleId: <IntlMessages id='module' />,
  typeMailTemplate: <IntlMessages id='type' />
})

export const TYPE_USE_EDITOR = ['3']
export const TYPE_USE_UPLOAD_S3 = ['4', '5', '6', '7']
export const TYPE_NOT_USING_PREVIEW = ['3']
export const TYPE_IMAGE = ['4', '5', '6']
export const TYPE_VIDEO = ['7']

export const DEFAULT_SHOWCASE_STORY_FILTER = {
  name: '',
  status: '',
  author: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_FAQ_FILTER = {
  name: '',
  topic: '',
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_SHOWCASE_CONTACT_LOG_FILTER = {
  email: '',
  content: '',
  ...DEFAULT_PAGE_LIMIT
}

export const STORY_STATUS_VALUE = Object.freeze({
  PUBLISH: 'published',
  UNPUBLISH: 'un_published'
})

export const STORY_STATUS_LABEL = Object.freeze({
  [STORY_STATUS_VALUE.PUBLISH]: <IntlMessages id='status.published' />,
  [STORY_STATUS_VALUE.UNPUBLISH]: <IntlMessages id='status.un_published' />
})

export const STORY_STATUS_LIST = toArrayOptions(STORY_STATUS_LABEL)

export const CAROUSEL_TAG_STORY_VALUE = Object.freeze({
  client: 'CLIENT',
  vision: 'VISION'
})

export const CAROUSEL_TAG_STORY_LABEL = Object.freeze({
  [CAROUSEL_TAG_STORY_VALUE.client]: (
    <IntlMessages id='story.carousel_tag_client' />
  ),
  [CAROUSEL_TAG_STORY_VALUE.vision]: (
    <IntlMessages id='story.carousel_tag_vision' />
  )
})

export const CAROUSEL_TAG_STORY_LIST = toArrayOptions(CAROUSEL_TAG_STORY_LABEL)

export const FAQ_TOPICS = Object.freeze({
  1: <IntlMessages id='faq.pers_technology' />,
  2: <IntlMessages id='faq.vigoz' />,
  3: <IntlMessages id='faq.eBike' />
})

export const FAQ_STATUS = Object.freeze({
  published: 'published',
  unPublished: 'un_published'
})

export const MAPPING_SHOWCASE_STORY_FILTER = Object.freeze({
  name: <IntlMessages id='name' />,
  author: <IntlMessages id='author' />,
  status: <IntlMessages id='status' />,
  carouselTag: <IntlMessages id='carousel_tag' />
})

export const MAPPING_MAIN_PICTURE_FILE_TYPES = Object.freeze({
  ANIMATED_PICTURE: ['gif'],
  STATIC_PICTURE: ['png', 'jpg', 'jpeg', 'webp'],
  VIDEO: ['mp4', 'avi', 'wmv']
})

export const AVATAR_ACCEPT = 'image/png, image/jpg, image/jpeg, image/webp'
export const LIST_MEDIA_ACCEPT =
  'image/gif, image/png, image/jpg, image/jpeg, image/webp, video/mp4, video/avi, video/wmv'

export const PUBLISH_STORY_CONFIRM_MESSAGE =
  'Are you sure you want to publish the story: {name}?'

export const PUBLISH_STORY_UN_CONFIRM_MESSAGE =
  'Are you sure you want to unpublish the story: {name}?'

export const PUBLISH_STORY_SUCCESS = (
  <SystemMessages id='PUBLISH_STORY_SUCCESS' />
)
export const PUBLISH_STORY_FAILED = <SystemMessages id='PUBLISH_STORY_FAILED' />

export const UN_PUBLISH_STORY_SUCCESS = (
  <SystemMessages id='UN_PUBLISH_STORY_SUCCESS' />
)
export const UN_PUBLISH_STORY_FAILED = (
  <SystemMessages id='UN_PUBLISH_STORY_FAILED' />
)

export const SECTION_PAGE_VALUES = Object.freeze({
  1: 'HOMEPAGE',
  2: 'ACTIVE_VEHICLE',
  3: 'ACTIVE_VEHICLE_TECHNOLOGY',
  4: 'ABOUT_US',
  5: 'ABOUT_US_STORIES',
  6: 'VIBES_STORIES_DETAIL',
  7: 'CONTACT_US',
  8: 'STORIES',
  9: 'STORY_DETAIL',
  10: 'PRIVACY_POLICY'
})

export const MOBILE_PAGE_VALUES = Object.freeze({
  11: 'LANDING_VEHICLE',
  12: 'DETAILED_VEHICLE',
  13: 'SHARING',
  14: 'KEYCHAIN',
  15: 'CHECKIN',
  16: 'CHECKOUT',
  17: 'PROFILE',
  18: 'LOGIN',
  19: 'RECOVERY_PASSWORD'
})

export const ALL_PAGE_VALUES = Object.freeze({
  ...SECTION_PAGE_VALUES,
  ...MOBILE_PAGE_VALUES
})

export const SECTION_PAGE_LIST = Object.keys(SECTION_PAGE_VALUES).map(
  (value) => ({ label: SECTION_PAGE_VALUES[value], value })
)

export const MOBILE_PAGE_LIST = Object.keys(MOBILE_PAGE_VALUES).map(
  (value) => ({ label: MOBILE_PAGE_VALUES[value], value })
)

export const MAPPING_SHOWCASE_CONTACT_LOGS_FILTER = Object.freeze({
  email: <IntlMessages id='email' />,
  content: <IntlMessages id='content' />,
  startDate: <IntlMessages id='startDate' />,
  endDate: <IntlMessages id='endDate' />,
  type: <IntlMessages id='type' />
})

export const CONTACT_LOG_TYPES = Object.freeze({
  notify_me: 'notify_me',
  request_brochure: 'request_brochure',
  contact_us: 'contact_us'
})

export const CONTACT_LOG_LIST = [
  { label: <IntlMessages id='notify_me' />, value: 'notify_me' },
  { label: <IntlMessages id='request_brochure' />, value: 'request_brochure' },
  { label: <IntlMessages id='contact_us' />, value: 'contact_us' }
]

export const CONTACT_LOG_TYPE_VALUES = Object.freeze({
  notify_me: <IntlMessages id='notify_me' />,
  request_brochure: <IntlMessages id='request_brochure' />,
  contact_us: <IntlMessages id='contact_us' />
})

export const NOTIFY_ME_REQUEST_TYPE_VALUES = Object.freeze({
  private_individual: <IntlMessages id='private_individual' />,
  company: <IntlMessages id='company' />
})

// USED IN BRAND MESSAGES
export const DEVICE_TYPES = Object.freeze({
  1: <IntlMessages id='brandMessage.smartPhone' />,
  2: <IntlMessages id='brandMessage.tablet' />,
  3: <IntlMessages id='brandMessage.desktop' />
})

export const SUBSCRIPTION_TYPES = Object.freeze({
  COMPANY: 'company',
  INDIVIDUAL: 'individual'
})

export const DEFAULT_MESSAGE_FILTER = {
  message: '',
  keyword: '',
  selectedModule: null,
  selectedLanguage: null,
  selectedSearchScope: SEARCH_SCOPE,
  ...DEFAULT_PAGE_LIMIT
}

export const DEFAULT_KEY_USAGE_SYSTEM_LOG_FILTER = {
  originalName: '',
  keyword: '',
  type: '',
  moduleId: '',
  languageId: '',
  searchIn: SEARCH_SCOPE.value,
  limit: PAGINATION.limit
}

export const DEFAULT_USAGE_SYSTEM_LOG_FILTER = {
  logLabel: '',
  sender: '',
  type: '',
  startDate: '',
  endDate: '',
  persId: '',
  limit: PAGINATION.limit
}

export const CURRENCY_FORMAT_DISPLAY = {
  afterAmount: 1,
  beforeAmount: 2
}

export const DROPDOWN_STYLES = {
  // For the select it self, not the options of the select
  control: (styles, { isDisabled }) => {
    return {
      ...styles,
      cursor: isDisabled ? 'not-allowed' : 'default'
    }
  },
  // For the options
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? '#E0E0E0 !important' : ''
    }
  }
}

export const DEFAULT_NOTIFICATION_TEMPLATE_FILTER = {
  keyNotificationTemplate: '',
  labelNotificationTemplate: '',
  platformTechs: '',
  isPublic: '',
  ...DEFAULT_PAGE_LIMIT
}

export const MAPPING_NOTIFICATION_TEMPLATE_FILTER = Object.freeze({
  labelNotificationTemplate: <IntlMessages id='notification_templates.label' />,
  keyNotificationTemplate: <IntlMessages id='notification_templates.key' />,
  platformTechs: <IntlMessages id='notification_templates.platform_tech' />
})

export const SUPER_ADMIN = 'SuperAdmin'

export const MAPPING_NOTIFICATION_REQUEST_KEY_FILTER = Object.freeze({
  keyPlatformTech: <IntlMessages id='notification_request.platform_tech' />,
  keyNotificationTemplates: (
    <IntlMessages id='notification_request.notification_template' />
  ),
  emailUserDestList: (
    <IntlMessages id='notification_request.destination_user_email' />
  )
})

export const MAPPING_NOTIFICATION_SENT_KEY_FILTER = Object.freeze({
  keyPlatformTech: <IntlMessages id='notification_request.platform_tech' />,
  notificationType: (
    <IntlMessages id='notification_request.notification_type' />
  ),
  emailUserDestList: (
    <IntlMessages id='notification_request.destination_user_email' />
  )
})

export const STATUS_PER_ALERT_LABEL = {
  1: <IntlMessages id='per_alert.status_received' />
}

export const TYPE_MAIL_TEMPLATES = {
  BO_USER: 1,
  PORTAL_USER: 2,
  COMPANY_MEMBER: 3
}

export const TYPE_MAIL_VALUES = {
  1: 'BO User',
  2: 'Portal User',
  3: 'Company Member'
}

export const TYPE_MAIL_LIST = [
  { label: TYPE_MAIL_VALUES[1], value: 1 },
  { label: TYPE_MAIL_VALUES[2], value: 2 },
  { label: TYPE_MAIL_VALUES[3], value: 3 }
]

export const DEFAULT_COMPANY_MAIL_TEMPLATES_FILTER = {
  selectedModule: '',
  keyword: '',
  name: '',
  typeMailTemplate: 3
}

export const MAPPING_COMPANY_MAIL_TEMPLATE_FILTER = Object.freeze({
  name: <IntlMessages id='name' />,
  keyword: <IntlMessages id='keyword' />,
  selectedModule: <IntlMessages id='module' />
})
export const COMPANY_ADMIN_STATUS = {
  REQUESTED: 1,
  ACCEPTED: 2,
  REFUSED: 3,
  CANCELED: 4
}

export const COMPANY_ADMIN_STATUS_VALUES = {
  1: <IntlMessages id='status.requested' />,
  2: <IntlMessages id='status.approved' />,
  3: <IntlMessages id='status.refused' />,
  4: <IntlMessages id='status.canceled' />
}

export const COMPANY_ADMIN_STATUS_LIST = [
  { label: COMPANY_ADMIN_STATUS_VALUES[1], value: 1 },
  { label: COMPANY_ADMIN_STATUS_VALUES[2], value: 2 },
  { label: COMPANY_ADMIN_STATUS_VALUES[3], value: 3 }
]

export const MAIN_ADMIN_ROLE = 'main_admin_role'

export const COMPANY_ROLE_CONFIGURATION_VALUES = {
  main_admin_role: <IntlMessages id='main_admin_role' />
}

export const DEFAULT_PRO_CENTER_ADMIN_FILTER = {
  name: '',
  email: '',
  company: '',
  status: '',
  ...DEFAULT_PAGE_LIMIT
}

export const MAPPING_PRO_CENTER_ADMIN_FILTER = Object.freeze({
  name: 'Name',
  email: 'Email',
  company: 'Company',
  status: 'Status'
})

export const LINK_TYPE_LIST = [
  { label: <IntlMessages id='vehicle_brands.owner' />, value: 1 },
  { label: <IntlMessages id='vehicle_brands.contractor' />, value: 8 }
]

export const LINK_TYPE_VALUES = Object.freeze({
  1: <IntlMessages id='vehicle_brands.owner' />,
  8: <IntlMessages id='vehicle_brands.contractor' />
})

export const LINK_TYPES = [
  { value: 1, label: <IntlMessages id='vehicle_brands.owner' /> },
  { value: 8, label: <IntlMessages id='vehicle_brands.contractor' /> }
]

export const COMPANY_LINK_TYPE_LIST = [
  { label: <IntlMessages id='vehicles.manufacturer' />, value: 1 },
  { label: <IntlMessages id='vehicles.no_manufacturer' />, value: 0 }
]

export const COMPANY_LINK_TYPE_VALUES = Object.freeze({
  1: <IntlMessages id='vehicles.manufacturer' />,
  0: <IntlMessages id='vehicles.no_manufacturer' />
})

export const COMPANY_FILTER_TYPE = Object.freeze({
  EQUAL: 1,
  NOT_EQUAL: 2
})

export const DEFAULT_ALERT_FILTER = {
  groupAlert: '',
  topicAlert: '',
  alertLabel: '',
  ...DEFAULT_PAGE_LIMIT
}

export const ALERT_FILTER = Object.freeze({
  groupAlert: <IntlMessages id='alert.group_alert' />,
  alertLabel: <IntlMessages id='alert.alert_label' />,
  topicAlert: <IntlMessages id='alert.topic_alert' />
})

export const LIST_AVATAR_CODES = [
  'default_avatar_company',
  'default_avatar_vehicle',
  'default_avatar_user_BO',
  'default_avatar_electronic_key',
  'default_avatar_bike',
  'default_avatar_user_app',
  'default_image_company_selection'
]

export const DATA_MAPPING_INIT_VALUES = {
  keyFeature: '',
  keyword: '',
  sourceValue: '',
  destinationValue: '',
  comment: '',
  emptyDestinationValue: false
}

export const DEFAULT_DATA_MAPPING_FILTER = {
  ...DATA_MAPPING_INIT_VALUES,
  ...DEFAULT_PAGE_LIMIT
}

export const KEY_FEATURE = Object.freeze({
  ODOO_TO_SERVER: 'OdooToServer',
  SERVER_TO_ODOO: 'ServerToOdoo'
})

export const KEY_FEATURE_LIST = [
  { label: KEY_FEATURE.ODOO_TO_SERVER, value: KEY_FEATURE.ODOO_TO_SERVER },
  { label: KEY_FEATURE.SERVER_TO_ODOO, value: KEY_FEATURE.SERVER_TO_ODOO }
]

export const MAPPING_DATA_MAPPING_FILTER = Object.freeze({
  keyFeature: <IntlMessages id='data_mapping.key_feature' />,
  keyword: <IntlMessages id='data_mapping.keyword' />,
  sourceValue: <IntlMessages id='data_mapping.source_value' />,
  destinationValue: <IntlMessages id='data_mapping.destination_value' />,
  emptyDestinationValue: (
    <IntlMessages id='data_mapping.empty_destination_value' />
  )
})

export const DEFAULT_PLATFORM_TECH_KEY = {
  cixiCenter: 'CIXI_CENTER',
  pilotAppIOS: 'PILOT_APP_IOS',
  pilotAppAndroid: 'PILOT_APP_ANDROID',
  proCenterWeb: 'PRO_CENTER_WEB',
  fleetManagerWeb: 'FLEET_MANAGER_WEB'
}
