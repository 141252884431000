import Types from '../actionTypes/alertTopic'
import { alertTopicAPI } from '../api'
import { mapAlertTopicDetail } from '../mapping/alertTopic'
import { bindRequestLogic } from './utils'

const getAlertTopics = bindRequestLogic({
  clientFunc: alertTopicAPI.getAlertTopics,
  type: Types.GET_ALERT_TOPICS_REQUEST,
  successType: Types.GET_ALERT_TOPICS_SUCCESS,
  failType: Types.GET_ALERT_TOPICS_FAILED,
  isQueryParam: true
})

const addAlertTopic = bindRequestLogic({
  clientFunc: alertTopicAPI.addAlertTopic,
  type: Types.ADD_ALERT_TOPIC_REQUEST,
  successType: Types.ADD_ALERT_TOPIC_SUCCESS,
  failType: Types.ADD_ALERT_TOPIC_FAILED
})

const getAlertTopicDetail = bindRequestLogic({
  clientFunc: alertTopicAPI.getAlertTopic,
  type: Types.GET_ALERT_TOPIC_DETAIL_REQUEST,
  successType: Types.GET_ALERT_TOPIC_DETAIL_SUCCESS,
  failType: Types.GET_ALERT_TOPIC_DETAIL_FAILED,
  modelName: 'topicAlert',
  mapResponse: mapAlertTopicDetail
})

const updateAlertTopic = bindRequestLogic({
  clientFunc: alertTopicAPI.updateAlertTopic,
  type: Types.UPDATE_ALERT_TOPIC_REQUEST,
  successType: Types.UPDATE_ALERT_TOPIC_SUCCESS,
  failType: Types.UPDATE_ALERT_TOPIC_FAILED
})

const deleteAlertTopic = bindRequestLogic({
  clientFunc: alertTopicAPI.deleteAlertTopic,
  type: Types.DELETE_ALERT_TOPIC_REQUEST,
  successType: Types.DELETE_ALERT_TOPIC_SUCCESS,
  failType: Types.DELETE_ALERT_TOPIC_FAILED
})

const upsertAlertTopicTranslation = bindRequestLogic({
  clientFunc: alertTopicAPI.upsertAlertTopicTranslation,
  type: Types.UPSERT_ALERT_TOPIC_TRANSLATION_REQUEST,
  successType: Types.UPSERT_ALERT_TOPIC_TRANSLATION_SUCCESS,
  failType: Types.UPSERT_ALERT_TOPIC_TRANSLATION_FAILED
})

const deleteAlertTopicTranslation = bindRequestLogic({
  clientFunc: alertTopicAPI.deleteAlertTopicTranslation,
  type: Types.DELETE_ALERT_TOPIC_TRANSLATION_REQUEST,
  successType: Types.DELETE_ALERT_TOPIC_TRANSLATION_SUCCESS,
  failType: Types.DELETE_ALERT_TOPIC_TRANSLATION_FAILED
})

const getSystemAlertTopics = bindRequestLogic({
  clientFunc: alertTopicAPI.getSystemAlertTopics,
  type: Types.GET_SYSTEM_ALERT_TOPICS_REQUEST,
  successType: Types.GET_SYSTEM_ALERT_TOPICS_SUCCESS,
  failType: Types.GET_SYSTEM_ALERT_TOPICS_FAILED,
  isQueryParam: true
})

export default [
  getAlertTopics,
  addAlertTopic,
  getAlertTopicDetail,
  updateAlertTopic,
  deleteAlertTopic,
  upsertAlertTopicTranslation,
  deleteAlertTopicTranslation,
  getSystemAlertTopics
]
