import keyMirror from 'keymirror'

export default keyMirror({
  GET_PERS_REQUEST: null,
  GET_PERS_SUCCESS: null,
  GET_PERS_FAILED: null,

  ADD_PER_REQUEST: null,
  ADD_PER_SUCCESS: null,
  ADD_PER_FAILED: null,

  GET_PER_DETAIL_REQUEST: null,
  GET_PER_DETAIL_SUCCESS: null,
  GET_PER_DETAIL_FAILED: null,

  UPDATE_PER_REQUEST: null,
  UPDATE_PER_SUCCESS: null,
  UPDATE_PER_FAILED: null,

  DELETE_PER_REQUEST: null,
  DELETE_PER_SUCCESS: null,
  DELETE_PER_FAILED: null,

  UPDATE_FILTER_PER_SUCCESS: null,
  CLEAR_FILTER_PER_SUCCESS: null,

  GET_SYSTEM_PERS_REQUEST: null,
  GET_SYSTEM_PERS_SUCCESS: null,
  GET_SYSTEM_PERS_FAILED: null,

  GET_PER_ALERTS_REQUEST: null,
  GET_PER_ALERTS_SUCCESS: null,
  GET_PER_ALERTS_FAILED: null,

  GET_VEHICLE_TRIPS_REQUEST: null,
  GET_VEHICLE_TRIPS_SUCCESS: null,
  GET_VEHICLE_TRIPS_FAILED: null,

  GET_VEHICLE_TRIP_DETAIL_REQUEST: null,
  GET_VEHICLE_TRIP_DETAIL_SUCCESS: null,
  GET_VEHICLE_TRIP_DETAIL_FAILED: null,

  PUBLISH_IOT_SERVICE_REQUEST: null,
  PUBLISH_IOT_SERVICE_SUCCESS: null,
  PUBLISH_IOT_SERVICE_FAILED: null,

  GET_PER_PACKAGE_LOGS_REQUEST: null,
  GET_PER_PACKAGE_LOGS_SUCCESS: null,
  GET_PER_PACKAGE_LOGS_FAILED: null,

  EXPORT_VEHICLE_TRIP_DETAIL_REQUEST: null,
  EXPORT_VEHICLE_TRIP_DETAIL_SUCCESS: null,
  EXPORT_VEHICLE_TRIP_DETAIL_FAILED: null
})
