import { DEFAULT_DATE_TIME_FORMAT } from 'constants/common'
import DateTimeFormatTypes from 'store/actionTypes/dateTimeFormat'
import UserTypes from 'store/actionTypes/user'

const initDateTimeFormat = {
  list: [],
  pagination: {},
  filter: DEFAULT_DATE_TIME_FORMAT
}

const dateTimeFormat = (state = initDateTimeFormat, action) => {
  switch (action.type) {
    case UserTypes.LOGOUT_SUCCESS:
      return initDateTimeFormat
    case DateTimeFormatTypes.GET_DATETIME_FORMATS_BY_LANGUAGE_SUCCESS:
      return {
        ...state,
        list: action.payload?.items
      }
    case DateTimeFormatTypes.UPDATE_FILTER_DATETIME_FORMAT_SUCCESS:
      return { ...state, filter: action.payload }
    case DateTimeFormatTypes.CLEAR_FILTER_DATETIME_FORMAT_SUCCESS:
      return { ...state, filter: initDateTimeFormat.filter }
    default:
      return state
  }
}

export default dateTimeFormat
