import Types from '../actionTypes/alertGroup'
import { alertGroupAPI } from '../api'
import { mapAlertGroupDetail } from '../mapping/alertGroup'
import { bindRequestLogic } from './utils'

const getAlertGroups = bindRequestLogic({
  clientFunc: alertGroupAPI.getAlertGroups,
  type: Types.GET_ALERT_GROUPS_REQUEST,
  successType: Types.GET_ALERT_GROUPS_SUCCESS,
  failType: Types.GET_ALERT_GROUPS_FAILED,
  isQueryParam: true
})

const addAlertGroup = bindRequestLogic({
  clientFunc: alertGroupAPI.addAlertGroup,
  type: Types.ADD_ALERT_GROUP_REQUEST,
  successType: Types.ADD_ALERT_GROUP_SUCCESS,
  failType: Types.ADD_ALERT_GROUP_FAILED
})

const getAlertGroupDetail = bindRequestLogic({
  clientFunc: alertGroupAPI.getAlertGroup,
  type: Types.GET_ALERT_GROUP_DETAIL_REQUEST,
  successType: Types.GET_ALERT_GROUP_DETAIL_SUCCESS,
  failType: Types.GET_ALERT_GROUP_DETAIL_FAILED,
  modelName: 'groupAlerts',
  mapResponse: mapAlertGroupDetail
})

const updateAlertGroup = bindRequestLogic({
  clientFunc: alertGroupAPI.updateAlertGroup,
  type: Types.UPDATE_ALERT_GROUP_REQUEST,
  successType: Types.UPDATE_ALERT_GROUP_SUCCESS,
  failType: Types.UPDATE_ALERT_GROUP_FAILED
})

const deleteAlertGroup = bindRequestLogic({
  clientFunc: alertGroupAPI.deleteAlertGroup,
  type: Types.DELETE_ALERT_GROUP_REQUEST,
  successType: Types.DELETE_ALERT_GROUP_SUCCESS,
  failType: Types.DELETE_ALERT_GROUP_FAILED
})

const addAlertGroupTranslation = bindRequestLogic({
  clientFunc: alertGroupAPI.addAlertGroupTranslation,
  type: Types.ADD_ALERT_GROUP_TRANSLATION_REQUEST,
  successType: Types.ADD_ALERT_GROUP_TRANSLATION_SUCCESS,
  failType: Types.ADD_ALERT_GROUP_TRANSLATION_FAILED
})

const updateAlertGroupTranslation = bindRequestLogic({
  clientFunc: alertGroupAPI.updateAlertGroupTranslation,
  type: Types.UPDATE_ALERT_GROUP_TRANSLATION_REQUEST,
  successType: Types.UPDATE_ALERT_GROUP_TRANSLATION_SUCCESS,
  failType: Types.UPDATE_ALERT_GROUP_TRANSLATION_FAILED
})

const deleteAlertGroupTranslation = bindRequestLogic({
  clientFunc: alertGroupAPI.deleteAlertGroupTranslation,
  type: Types.DELETE_ALERT_GROUP_TRANSLATION_REQUEST,
  successType: Types.DELETE_ALERT_GROUP_TRANSLATION_SUCCESS,
  failType: Types.DELETE_ALERT_GROUP_TRANSLATION_FAILED
})

const getSystemAlertGroups = bindRequestLogic({
  clientFunc: alertGroupAPI.getSystemAlertGroups,
  type: Types.GET_SYSTEM_ALERT_GROUPS_REQUEST,
  successType: Types.GET_SYSTEM_ALERT_GROUPS_SUCCESS,
  failType: Types.GET_SYSTEM_ALERT_GROUPS_FAILED,
  isQueryParam: true
})

export default [
  getAlertGroups,
  addAlertGroup,
  getAlertGroupDetail,
  updateAlertGroup,
  deleteAlertGroup,
  addAlertGroupTranslation,
  updateAlertGroupTranslation,
  deleteAlertGroupTranslation,
  getSystemAlertGroups
]
