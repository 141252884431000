import BaseAPI from './base'

class AlertLevelAPI extends BaseAPI {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.baseURL}/admin`
  }

  getAlertLevels = (query) => this.client.get(`level-alerts?${query}`)

  getAlertLevel = (levelAlertId) =>
    this.client.get(`level-alerts/${levelAlertId}`)

  addAlertLevel = (data) => this.client.post(`level-alerts`, data)

  updateAlertLevel = ({ levelAlertId, payload }) =>
    this.client.patch(`level-alerts/${levelAlertId}`, payload)

  deleteAlertLevel = (levelAlertId) =>
    this.client.delete(`level-alerts/${levelAlertId}`)

  upsertAlertLevelTranslation = ({ levelAlertId, payload }) =>
    this.client.post(`level-alerts/${levelAlertId}/translation`, payload)

  deleteAlertLevelTranslation = ({ translationId }) =>
    this.client.delete(`level-alerts/translation/${translationId}`)

  getSystemAlertLevels = (query) =>
    this.client.get(`backoffice/systems/level-alert?${query}`, {
      baseURL: this.fleetURL
    })
}

export default new AlertLevelAPI()
