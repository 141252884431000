import ShowcaseContactLogTypes from '../actionTypes/showcaseContactLogs'
import { showcaseContactLog as showcaseContactLogApi } from '../api'
import { bindRequestLogic } from './utils'

const getContactLogs = bindRequestLogic({
  clientFunc: showcaseContactLogApi.getShowcaseContactLogs,
  type: ShowcaseContactLogTypes.GET_SHOWCASE_LOGS_REQUEST,
  successType: ShowcaseContactLogTypes.GET_SHOWCASE_LOGS_SUCCESS,
  failType: ShowcaseContactLogTypes.GET_SHOWCASE_LOGS_FAILED,
  isQueryParam: true
})

const exportShowcaseContactLogs = bindRequestLogic({
  clientFunc: showcaseContactLogApi.exportShowcaseContactLogs,
  type: ShowcaseContactLogTypes.EXPORT_SHOWCASE_CONTACT_LOG_REQUEST,
  successType: ShowcaseContactLogTypes.EXPORT_SHOWCASE_CONTACT_LOG_SUCCESS,
  failType: ShowcaseContactLogTypes.EXPORT_SHOWCASE_CONTACT_LOG_FAILED,
  isQueryParam: true
})

const updateAnonymizeVisitor = bindRequestLogic({
  clientFunc: showcaseContactLogApi.updateAnonymizeVisitor,
  type: ShowcaseContactLogTypes.UPDATE_ANONYMIZE_VISITOR_REQUEST,
  successType: ShowcaseContactLogTypes.UPDATE_ANONYMIZE_VISITOR_SUCCESS,
  failType: ShowcaseContactLogTypes.UPDATE_ANONYMIZE_VISITOR_FAILED,
  isQueryParam: false
})

export default [
  getContactLogs,
  exportShowcaseContactLogs,
  updateAnonymizeVisitor
]
