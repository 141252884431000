import { combineReducers } from 'redux'
import VehicleModelTypes from 'store/actionTypes/vehicleModel'
import { createHandleRequest } from './helpers'

const getVehicleModels = createHandleRequest(
  VehicleModelTypes.GET_VEHICLE_MODELS_REQUEST,
  VehicleModelTypes.GET_VEHICLE_MODELS_SUCCESS,
  VehicleModelTypes.GET_VEHICLE_MODELS_FAILED
)

export default combineReducers({
  getVehicleModels
})
