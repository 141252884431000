import { combineReducers } from 'redux'
import Types from 'store/actionTypes/companyRole'
import { createHandleRequest } from './helpers'

const getCompanyRoles = createHandleRequest(
  Types.GET_COMPANY_ROLES_REQUEST,
  Types.GET_COMPANY_ROLES_SUCCESS,
  Types.GET_COMPANY_ROLES_FAILED
)

export default combineReducers({
  getCompanyRoles
})
