import keyMirror from 'keymirror'

export default keyMirror({
  GET_LOG_MAILS_REQUEST: null,
  GET_LOG_MAILS_SUCCESS: null,
  GET_LOG_MAILS_FAILED: null,

  UPDATE_LOG_MAIL_FILTER: null,
  CLEAR_LOG_MAIL_FILTER: null,

  UPDATE_FILTER_LOG_MAIL_SUCCESS: null,
  CLEAR_FILTER_LOG_MAIL_SUCCESS: null
})
