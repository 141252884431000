import NotificationTemplateTypes from '../actionTypes/notificationTemplate'
import {
  notificationTemplate as notificationTemplateApi,
  platformPageTech as platformPageTechApi
} from '../api'

import { bindRequestLogic } from './utils'

const getNotificationTemplates = bindRequestLogic({
  clientFunc: notificationTemplateApi.getNotificationTemplates,
  type: NotificationTemplateTypes.GET_NOTIFICATION_TEMPLATES_REQUEST,
  successType: NotificationTemplateTypes.GET_NOTIFICATION_TEMPLATES_SUCCESS,
  failType: NotificationTemplateTypes.GET_NOTIFICATION_TEMPLATES_FAILED,
  isQueryParam: true
})

const getNotificationTemplate = bindRequestLogic({
  clientFunc: notificationTemplateApi.getNotificationTemplateDetail,
  type: NotificationTemplateTypes.GET_NOTIFICATION_TEMPLATE_DETAIL_REQUEST,
  successType:
    NotificationTemplateTypes.GET_NOTIFICATION_TEMPLATE_DETAIL_SUCCESS,
  failType: NotificationTemplateTypes.GET_NOTIFICATION_TEMPLATE_DETAIL_FAILED
})

const addNotificationTemplate = bindRequestLogic({
  clientFunc: notificationTemplateApi.addNotificationTemplate,
  type: NotificationTemplateTypes.ADD_NOTIFICATION_TEMPLATE_REQUEST,
  successType: NotificationTemplateTypes.ADD_NOTIFICATION_TEMPLATE_SUCCESS,
  failType: NotificationTemplateTypes.ADD_NOTIFICATION_TEMPLATE_FAILED
})

const updateNotificationTemplate = bindRequestLogic({
  clientFunc: notificationTemplateApi.updateNotificationTemplate,
  type: NotificationTemplateTypes.UPDATE_NOTIFICATION_TEMPLATE_REQUEST,
  successType: NotificationTemplateTypes.UPDATE_NOTIFICATION_TEMPLATE_SUCCESS,
  failType: NotificationTemplateTypes.UPDATE_NOTIFICATION_TEMPLATE_FAILED
})

const deleteNotificationTemplate = bindRequestLogic({
  clientFunc: notificationTemplateApi.deleteNotificationTemplate,
  type: NotificationTemplateTypes.DELETE_NOTIFICATION_TEMPLATE_REQUEST,
  successType: NotificationTemplateTypes.DELETE_NOTIFICATION_TEMPLATE_SUCCESS,
  failType: NotificationTemplateTypes.DELETE_NOTIFICATION_TEMPLATE_FAILED
})

export const getPageTechInNotificationTemplate = bindRequestLogic({
  clientFunc: platformPageTechApi.getPlatformPageTechs,
  type: NotificationTemplateTypes.GET_PLATFORM_PAGE_TECH_IN_NOTIFICATION_TEMPLATE_REQUEST,
  successType:
    NotificationTemplateTypes.GET_PLATFORM_PAGE_TECH_IN_NOTIFICATION_TEMPLATE_SUCCESS,
  failType:
    NotificationTemplateTypes.GET_PLATFORM_PAGE_TECH_IN_NOTIFICATION_TEMPLATE_FAILED,
  isQueryParam: true
})

export const getSystemNotificationTemplates = bindRequestLogic({
  clientFunc: notificationTemplateApi.getSystemNotificationTemplates,
  type: NotificationTemplateTypes.GET_SYSTEM_NOTIFICATION_TEMPLATES_REQUEST,
  successType:
    NotificationTemplateTypes.GET_SYSTEM_NOTIFICATION_TEMPLATES_SUCCESS,
  failType: NotificationTemplateTypes.GET_SYSTEM_NOTIFICATION_TEMPLATES_FAILED,
  isQueryParam: true
})

export const getSystemNotificationTemplateById = bindRequestLogic({
  clientFunc: notificationTemplateApi.getSystemNotificationTemplateById,
  type: NotificationTemplateTypes.GET_SYSTEM_NOTIFICATION_TEMPLATE_BY_ID_REQUEST,
  successType:
    NotificationTemplateTypes.GET_SYSTEM_NOTIFICATION_TEMPLATE_BY_ID_SUCCESS,
  failType:
    NotificationTemplateTypes.GET_SYSTEM_NOTIFICATION_TEMPLATE_BY_ID_FAILED
})

export default [
  getNotificationTemplates,
  getNotificationTemplate,
  addNotificationTemplate,
  updateNotificationTemplate,
  deleteNotificationTemplate,
  getPageTechInNotificationTemplate,
  getSystemNotificationTemplates,
  getSystemNotificationTemplateById
]
