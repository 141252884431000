import { combineReducers } from 'redux'
import TopicTypes from 'store/actionTypes/topic'
import { createHandleRequest } from './helpers'

const getTopics = createHandleRequest(
  TopicTypes.GET_TOPIC_REQUEST,
  TopicTypes.GET_TOPIC_SUCCESS,
  TopicTypes.GET_TOPIC_FAILED
)

const getTopicDetails = createHandleRequest(
  TopicTypes.GET_TOPIC_DETAIL_REQUEST,
  TopicTypes.GET_TOPIC_DETAIL_SUCCESS,
  TopicTypes.GET_TOPIC_DETAIL_FAILED
)

export default combineReducers({
  getTopics,
  getTopicDetails
})
