import VehicleTypeTypes from '../actionTypes/vehicleType'

export const getVehicleTypes = (data) => ({
  type: VehicleTypeTypes.GET_VEHICLE_TYPES_REQUEST,
  payload: data
})

export const addVehicleType = (data, { onSuccess, onError }) => ({
  type: VehicleTypeTypes.ADD_VEHICLE_TYPE_REQUEST,
  payload: data,
  meta: { onSuccess, onError }
})

export const updateVehicleType = (id, data, { onSuccess, onError }) => ({
  type: VehicleTypeTypes.UPDATE_VEHICLE_TYPE_REQUEST,
  payload: { id, data },
  meta: { onSuccess, onError }
})

export const deleteVehicleType = (id, { onSuccess, onError }) => ({
  type: VehicleTypeTypes.DELETE_VEHICLE_TYPE_REQUEST,
  payload: id,
  meta: { onSuccess, onError }
})

export const getVehicleTypeDetail = (id) => ({
  type: VehicleTypeTypes.GET_VEHICLE_TYPE_DETAIL_REQUEST,
  payload: id
})

export const getSystemVehicleTypes = (data) => ({
  type: VehicleTypeTypes.GET_SYSTEM_VEHICLE_TYPES_REQUEST,
  payload: data
})

export const updateFilter = (data) => ({
  type: VehicleTypeTypes.UPDATE_FILTER_VEHICLE_TYPE_SUCCESS,
  payload: data
})

export const clearFilter = () => ({
  type: VehicleTypeTypes.CLEAR_FILTER_VEHICLE_TYPE_SUCCESS,
  payload: {}
})
