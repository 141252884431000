import BaseApi from './base'

class ProCenterAdmin extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  getProCenterAdmins = (query) =>
    this.client.get(`admin/companies/company-admin?${query}`)

  getProCenterAdminDetail = (id) =>
    this.client.get(`admin/companies/company-admin/${id}`)

  addProCenterAdmin = (id, data) =>
    this.client.post(`admin/companies/${id}/company-admin`, data)

  deleteProCenterAdmin = (id) =>
    this.client.delete(`admin/companies/company-admin/${id}`)
}

export default new ProCenterAdmin()
