import BaseApi from './base'

class VehicleFleet extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  getVehicleUsers = (id, query = '') =>
    this.client.get(`vehicle-users/${id}/users?${query}`)
}

export default new VehicleFleet()
