import MessageAfterConnection from 'store/actionTypes/messageAfterConnection'
import { DEFAULT_MESSAGE_AFTER_CONNECTION_FILTER } from 'constants/common'
import { replaceValueAtPath } from 'helpers/utils'
import UserTypes from '../actionTypes/user'

const initAppVersions = {
  list: [],
  current: {},
  filter: DEFAULT_MESSAGE_AFTER_CONNECTION_FILTER,
  pagination: {}
}

const messageAfterConnection = (state = initAppVersions, action) => {
  switch (action.type) {
    case MessageAfterConnection.GET_MESSAGE_AFTER_CONNECTIONS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case MessageAfterConnection.GET_MESSAGE_AFTER_CONNECTION_DETAIL_SUCCESS:
      return replaceValueAtPath(state, 'current', action.payload)
    case MessageAfterConnection.UPDATE_MESSAGE_AFTER_CONNECTION_FILTER_SUCCESS:
      return replaceValueAtPath(state, 'filter', action.payload)
    case UserTypes.LOGOUT_SUCCESS:
      return initAppVersions
    default:
      return state
  }
}

export default messageAfterConnection
