import keyMirror from 'keymirror'

export default keyMirror({
  UPDATE_DATETIME_FORMAT_REQUEST: null,
  UPDATE_DATETIME_FORMAT_SUCCESS: null,
  UPDATE_DATETIME_FORMAT_FAILED: null,

  GET_DATETIME_FORMATS_BY_LANGUAGE_REQUEST: null,
  GET_DATETIME_FORMATS_BY_LANGUAGE_SUCCESS: null,
  GET_DATETIME_FORMATS_BY_LANGUAGE_FAILED: null,

  UPDATE_FILTER_DATETIME_FORMAT_SUCCESS: null,
  CLEAR_FILTER_DATETIME_FORMAT_SUCCESS: null
})
