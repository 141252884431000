import BaseAPI from './base'

class AlertGroupAPI extends BaseAPI {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  getAlertGroups = (query) => this.client.get(`group-alerts?${query}`)

  getAlertGroup = (groupAlertId) =>
    this.client.get(`group-alerts/${groupAlertId}`)

  addAlertGroup = (data) => this.client.post(`group-alerts`, data)

  updateAlertGroup = ({ groupAlertId, payload }) =>
    this.client.patch(`group-alerts/${groupAlertId}`, payload)

  deleteAlertGroup = (groupAlertId) =>
    this.client.delete(`group-alerts/${groupAlertId}`)

  addAlertGroupTranslation = ({ groupAlertId, payload }) =>
    this.client.post(`group-alerts/${groupAlertId}/translation`, payload)

  updateAlertGroupTranslation = ({ groupAlertId, translationId, payload }) =>
    this.client.patch(
      `group-alerts/${groupAlertId}/translation/${translationId}`,
      payload
    )

  deleteAlertGroupTranslation = ({ groupAlertId, translationId }) =>
    this.client.delete(
      `group-alerts/${groupAlertId}/translation/${translationId}`
    )

  getSystemAlertGroups = (query) =>
    this.client.get(`systems/group-alert?${query}`)
}

export default new AlertGroupAPI()
