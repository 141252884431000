import BaseAPI from './base'

class AlertTopicAPI extends BaseAPI {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.baseURL}/admin`
  }

  getAlertTopics = (query) => this.client.get(`topic-alerts?${query}`)

  getAlertTopic = (topicAlertId) =>
    this.client.get(`topic-alerts/${topicAlertId}`)

  addAlertTopic = (data) => this.client.post(`topic-alerts`, data)

  updateAlertTopic = ({ topicAlertId, payload }) =>
    this.client.patch(`topic-alerts/${topicAlertId}`, payload)

  deleteAlertTopic = (topicAlertId) =>
    this.client.delete(`topic-alerts/${topicAlertId}`)

  upsertAlertTopicTranslation = ({ topicAlertId, payload }) =>
    this.client.post(`topic-alerts/${topicAlertId}/translation`, payload)

  deleteAlertTopicTranslation = ({ translationId }) =>
    this.client.delete(`topic-alerts/translation/${translationId}`)

  getSystemAlertTopics = (query) =>
    this.client.get(`backoffice/systems/topic-alert?${query}`, {
      baseURL: this.fleetURL
    })
}

export default new AlertTopicAPI()
