import { combineReducers } from 'redux'
import MessageTypes from 'store/actionTypes/message'
import { createHandleRequest } from './helpers'

const getMessages = createHandleRequest(
  MessageTypes.GET_MESSAGES_REQUEST,
  MessageTypes.GET_MESSAGES_SUCCESS,
  MessageTypes.GET_MESSAGES_FAILED
)

export default combineReducers({
  getMessages
})
