import keyMirror from 'keymirror'

export default keyMirror({
  GET_ROLES_CONFIGURATION_REQUEST: null,
  GET_ROLES_CONFIGURATION_SUCCESS: null,
  GET_ROLES_CONFIGURATION_FAILED: null,

  UPDATE_ROLES_CONFIGURATION_REQUEST: null,
  UPDATE_ROLES_CONFIGURATION_SUCCESS: null,
  UPDATE_ROLES_CONFIGURATION_FAILED: null
})
