import { DEFAULT_LABEL_FILTER } from 'constants/common'
import LabelTypes from 'store/actionTypes/label'
import UserTypes from 'store/actionTypes/user'
// import { clearModuleFilter, updateModuleFilter } from 'helpers/filter'
import { getTranslationLabels } from '../mapping/shared'

const initialData = {
  list: [],
  systems: {},
  current: {},
  pagination: {},
  filter: DEFAULT_LABEL_FILTER,
  scwFilter: { ...DEFAULT_LABEL_FILTER },
  mobileFilter: { ...DEFAULT_LABEL_FILTER },
  fleetFilter: { ...DEFAULT_LABEL_FILTER }
}

const label = (state = initialData, action) => {
  const data = action?.payload
  switch (action.type) {
    case LabelTypes.GET_LABELS_SUCCESS:
      return {
        ...state,
        list: data?.items,
        pagination: data?.meta
      }
    case LabelTypes.GET_SYSTEM_LABELS_SUCCESS:
      return { ...state, systems: getTranslationLabels(data?.items) }
    case LabelTypes.UPDATE_FILTER_LABEL_SUCCESS:
      return { ...state, filter: data?.value }
    case LabelTypes.CLEAR_FILTER_LABEL_SUCCESS:
      return { ...state, filter: DEFAULT_LABEL_FILTER }
    case LabelTypes.UPDATE_LABEL_FROM_PAGE_TITLE_SUCCESS:
      return { ...state, systems: { ...state.systems, ...data } }
    case UserTypes.LOGOUT_SUCCESS:
      return {
        ...initialData,
        systems: state.systems
      }
    default:
      return state
  }
}

export default label
