import keyMirror from 'keymirror'

export default keyMirror({
  GET_FAQS_REQUEST: null,
  GET_FAQS_SUCCESS: null,
  GET_FAQS_FAILED: null,

  GET_FAQ_DETAIL_REQUEST: null,
  GET_FAQ_DETAIL_SUCCESS: null,
  GET_FAQ_DETAIL_FAILED: null,

  DELETE_FAQ_REQUEST: null,
  DELETE_FAQ_SUCCESS: null,
  DELETE_FAQ_FAILED: null,

  ADD_FAQ_REQUEST: null,
  ADD_FAQ_SUCCESS: null,
  ADD_FAQ_FAILED: null,

  UPDATE_FAQ_REQUEST: null,
  UPDATE_FAQ_SUCCESS: null,
  UPDATE_FAQ_FAILED: null,

  UPDATE_FAQ_TRANSLATION_REQUEST: null,
  UPDATE_FAQ_TRANSLATION_SUCCESS: null,
  UPDATE_FAQ_TRANSLATION_FAILED: null,

  UPDATE_FILTER_FAQ_SUCCESS: null,
  UPDATE_FAQ_LANGUAGE_FILTER_SUCCESS: null,

  ADD_FAQ_TRANSLATION_REQUEST: null,
  ADD_FAQ_TRANSLATION_SUCCESS: null,
  ADD_FAQ_TRANSLATION_FAILED: null,

  GET_FAQ_TRANSLATIONS_REQUEST: null,
  GET_FAQ_TRANSLATIONS_SUCCESS: null,
  GET_FAQ_TRANSLATIONS_FAILED: null
})
