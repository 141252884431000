import BaseApi from './base'

class ModelPer extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  getModelPers = (query) => this.client.get(`model-pers?${query}`)

  getModelPerDetail = (id) => this.client.get(`model-pers/${id}`)

  addModelPer = (data) => this.client.post('model-pers', data)

  updateModelPer = (id, data) => this.client.patch(`model-pers/${id}`, data)

  deleteModelPer = (id) => this.client.delete(`model-pers/${id}`)

  getSystemModelPers = (query) => this.client.get(`systems/model-pers?${query}`)
}

export default new ModelPer()
