import ShowcaseComponentTypes from '../actionTypes/showcaseComponent'
import { showcaseComponent as showcaseComponentApi } from '../api'
import { bindRequestLogic } from './utils'

const getShowcaseComponents = bindRequestLogic({
  clientFunc: showcaseComponentApi.getShowcaseComponents,
  type: ShowcaseComponentTypes.GET_SHOWCASE_COMPONENTS_REQUEST,
  successType: ShowcaseComponentTypes.GET_SHOWCASE_COMPONENTS_SUCCESS,
  failType: ShowcaseComponentTypes.GET_SHOWCASE_COMPONENTS_FAILED,
  isQueryParam: true
})

const addShowcaseComponent = bindRequestLogic({
  clientFunc: showcaseComponentApi.addShowcaseComponent,
  type: ShowcaseComponentTypes.ADD_SHOWCASE_COMPONENT_REQUEST,
  successType: ShowcaseComponentTypes.ADD_SHOWCASE_COMPONENT_SUCCESS,
  failType: ShowcaseComponentTypes.ADD_SHOWCASE_COMPONENT_FAILED
})

const updateShowcaseComponent = bindRequestLogic({
  clientFunc: showcaseComponentApi.updateShowcaseComponent,
  type: ShowcaseComponentTypes.UPDATE_SHOWCASE_COMPONENT_REQUEST,
  successType: ShowcaseComponentTypes.UPDATE_SHOWCASE_COMPONENT_SUCCESS,
  failType: ShowcaseComponentTypes.UPDATE_SHOWCASE_COMPONENT_FAILED
})

const getShowcaseComponent = bindRequestLogic({
  clientFunc: showcaseComponentApi.getShowcaseComponent,
  type: ShowcaseComponentTypes.GET_SHOWCASE_COMPONENT_DETAIL_REQUEST,
  successType: ShowcaseComponentTypes.GET_SHOWCASE_COMPONENT_DETAIL_SUCCESS,
  failType: ShowcaseComponentTypes.GET_SHOWCASE_COMPONENT_DETAIL_FAILED
})

const deleteShowcaseComponent = bindRequestLogic({
  clientFunc: showcaseComponentApi.deleteShowcaseComponent,
  type: ShowcaseComponentTypes.DELETE_SHOWCASE_COMPONENT_REQUEST,
  successType: ShowcaseComponentTypes.DELETE_SHOWCASE_COMPONENT_SUCCESS,
  failType: ShowcaseComponentTypes.DELETE_SHOWCASE_COMPONENT_FAILED
})

const updateTranslationComponent = bindRequestLogic({
  clientFunc: showcaseComponentApi.updateTranslation,
  type: ShowcaseComponentTypes.UPDATE_TRANSLATION_COMPONENT_REQUEST,
  successType: ShowcaseComponentTypes.UPDATE_TRANSLATION_COMPONENT_SUCCESS,
  failType: ShowcaseComponentTypes.UPDATE_TRANSLATION_COMPONENT_FAILED
})

export default [
  getShowcaseComponents,
  addShowcaseComponent,
  deleteShowcaseComponent,
  updateShowcaseComponent,
  getShowcaseComponent,
  updateTranslationComponent
]
