import { DEFAULT_MESSAGE_FILTER, DEFAULT_TEXT_FILTER } from 'constants/common'
import TextTypes from 'store/actionTypes/text'
import UserTypes from 'store/actionTypes/user'
import { clearModuleFilter, updateModuleFilter } from 'helpers/filter'

const initText = {
  list: [],
  systems: [],
  current: {},
  pagination: {},
  filter: DEFAULT_TEXT_FILTER,
  mobileFilter: { ...DEFAULT_MESSAGE_FILTER },
  fleetFilter: { ...DEFAULT_MESSAGE_FILTER }
}

const text = (state = initText, action) => {
  switch (action.type) {
    case TextTypes.GET_TEXTS_SUCCESS:
      return {
        ...state,
        list: action?.payload?.items,
        pagination: action.payload?.meta
      }
    case TextTypes.GET_SYSTEM_TEXTS_SUCCESS:
      return { ...state, systems: action.payload }
    case TextTypes.UPDATE_FILTER_TEXT_SUCCESS:
      return updateModuleFilter(state, action.payload)
    case TextTypes.CLEAR_FILTER_TEXT_SUCCESS:
      return clearModuleFilter(state, action.payload, initText)
    case UserTypes.LOGOUT_SUCCESS:
      return initText
    default:
      return state
  }
}

export default text
