import keyMirror from 'keymirror'

export default keyMirror({
  GET_DATA_MAPPINGS_REQUEST: null,
  GET_DATA_MAPPINGS_SUCCESS: null,
  GET_DATA_MAPPINGS_FAILED: null,

  ADD_DATA_MAPPING_REQUEST: null,
  ADD_DATA_MAPPING_SUCCESS: null,
  ADD_DATA_MAPPING_FAILED: null,

  UPDATE_DATA_MAPPING_REQUEST: null,
  UPDATE_DATA_MAPPING_SUCCESS: null,
  UPDATE_DATA_MAPPING_FAILED: null,

  DELETE_DATA_MAPPING_REQUEST: null,
  DELETE_DATA_MAPPING_SUCCESS: null,
  DELETE_DATA_MAPPING_FAILED: null,

  UPDATE_FILTER_DATA_MAPPING_SUCCESS: null,
  CLEAR_FILTER_DATA_MAPPING_SUCCESS: null
})
