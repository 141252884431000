import keyMirror from 'keymirror'

export default keyMirror({
  GET_USAGE_SYSTEM_LOGS_REQUEST: null,
  GET_USAGE_SYSTEM_LOGS_SUCCESS: null,
  GET_USAGE_SYSTEM_LOGS_FAILED: null,

  GET_DETAIL_USAGE_SYSTEM_LOG_REQUEST: null,
  GET_DETAIL_USAGE_SYSTEM_LOG_SUCCESS: null,
  GET_DETAIL_USAGE_SYSTEM_LOG_FAILED: null,

  DELETE_USAGE_SYSTEM_LOG_REQUEST: null,
  DELETE_USAGE_SYSTEM_LOG_SUCCESS: null,
  DELETE_USAGE_SYSTEM_LOG_FAILED: null,

  UPDATE_FILTER_USAGE_SYSTEM_LOG_REQUEST: null,
  UPDATE_FILTER_USAGE_SYSTEM_LOG_SUCCESS: null,

  CLEAR_FILTER_USAGE_SYSTEM_LOG_REQUEST: null,
  CLEAR_FILTER_USAGE_SYSTEM_LOG_SUCCESS: null
})
