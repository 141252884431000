import Types from '../actionTypes/usageSystemLog'
import { usageSystemLog as api } from '../api'
import { bindRequestLogic } from './utils'
import { bindFilterLogic } from './filter'

const getUsageSystemLogs = bindRequestLogic({
  clientFunc: api.getUsageSystemLogs,
  type: Types.GET_USAGE_SYSTEM_LOGS_REQUEST,
  successType: Types.GET_USAGE_SYSTEM_LOGS_SUCCESS,
  failType: Types.GET_USAGE_SYSTEM_LOGS_FAILED,
  isQueryParam: true
})

const getDetailUsageSystemLog = bindRequestLogic({
  clientFunc: api.getUsageSystemLogById,
  type: Types.GET_DETAIL_USAGE_SYSTEM_LOG_REQUEST,
  successType: Types.GET_DETAIL_USAGE_SYSTEM_LOG_SUCCESS,
  failType: Types.GET_DETAIL_USAGE_SYSTEM_LOG_FAILED
})

const updateFilter = bindFilterLogic({
  type: Types.UPDATE_FILTER_USAGE_SYSTEM_LOG_REQUEST,
  successType: Types.UPDATE_FILTER_USAGE_SYSTEM_LOG_SUCCESS
})

const clearFilter = bindFilterLogic({
  type: Types.CLEAR_FILTER_USAGE_SYSTEM_LOG_REQUEST,
  successType: Types.CLEAR_FILTER_USAGE_SYSTEM_LOG_SUCCESS
})

export default [
  getUsageSystemLogs,
  getDetailUsageSystemLog,
  updateFilter,
  clearFilter
]
