import { DEFAULT_SHOWCASE_SECTION_FILTER } from 'constants/common'
import ShowcaseSectionTypes from 'store/actionTypes/showcaseSection'
import ShowcaseComponentTypes from 'store/actionTypes/showcaseComponent'
import { getTranslationSections } from '../mapping/shared'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  current: {},
  systems: [],
  pagination: {},
  filter: DEFAULT_SHOWCASE_SECTION_FILTER,
  components: {
    list: [],
    pagination: {}
  }
}

const showcaseSection = (state = initialData, action) => {
  const data = action?.payload || []
  switch (action.type) {
    case ShowcaseSectionTypes.GET_SHOWCASE_SECTIONS_SUCCESS:
      return {
        ...state,
        list: data?.items,
        pagination: data?.meta
      }
    case ShowcaseSectionTypes.GET_SHOWCASE_SECTION_DETAIL_SUCCESS:
      return {
        ...state,
        current: data
      }
    case ShowcaseSectionTypes.UPDATE_FILTER_SHOWCASE_SECTION_SUCCESS:
      return { ...state, filter: data }
    case ShowcaseSectionTypes.CLEAR_FILTER_SHOWCASE_SECTION_SUCCESS:
      return { ...state, filter: initialData.filter }
    case ShowcaseComponentTypes.GET_SHOWCASE_COMPONENTS_SUCCESS:
      return {
        ...state,
        components: {
          list: action.payload?.items,
          pagination: action.payload?.meta
        }
      }
    case ShowcaseSectionTypes.GET_SYSTEM_SECTIONS_SUCCESS:
      return {
        ...state,
        systems: getTranslationSections(data?.sections)
      }
    case UserTypes.LOGOUT_SUCCESS:
      return { ...initialData, systems: state.systems }
    default:
      return state
  }
}

export default showcaseSection
