import TimezoneTypes from '../actionTypes/timezone'
import { timezone as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const getTimezones = bindRequestLogic({
  clientFunc: clientApi.getTimezones,
  type: TimezoneTypes.GET_TIMEZONES_REQUEST,
  successType: TimezoneTypes.GET_TIMEZONES_SUCCESS,
  failType: TimezoneTypes.GET_TIMEZONES_FAILED,
  isQueryParam: true,
  modelName: 'timezones'
})

const addTimezone = bindRequestLogic({
  clientFunc: clientApi.addTimezone,
  type: TimezoneTypes.ADD_TIMEZONE_REQUEST,
  successType: TimezoneTypes.ADD_TIMEZONE_SUCCESS,
  failType: TimezoneTypes.ADD_TIMEZONE_FAILED
})

const getTimezoneDetail = bindRequestLogic({
  clientFunc: clientApi.getTimezoneDetail,
  type: TimezoneTypes.GET_TIMEZONE_DETAIL_REQUEST,
  successType: TimezoneTypes.GET_TIMEZONE_DETAIL_SUCCESS,
  failType: TimezoneTypes.GET_TIMEZONE_DETAIL_FAILED,
  modelName: 'timezone'
})

const deleteTimezone = bindRequestLogic({
  clientFunc: clientApi.deleteTimezone,
  type: TimezoneTypes.DELETE_TIMEZONE_REQUEST,
  successType: TimezoneTypes.DELETE_TIMEZONE_SUCCESS,
  failType: TimezoneTypes.DELETE_TIMEZONE_FAILED
})

const updateTimezone = bindRequestLogic({
  clientFunc: clientApi.updateTimezone,
  type: TimezoneTypes.UPDATE_TIMEZONE_REQUEST,
  successType: TimezoneTypes.UPDATE_TIMEZONE_SUCCESS,
  failType: TimezoneTypes.UPDATE_TIMEZONE_FAILED
})

const getSystemTimezones = bindRequestLogic({
  clientFunc: clientApi.getSystemTimezones,
  type: TimezoneTypes.GET_SYSTEM_TIMEZONES_REQUEST,
  successType: TimezoneTypes.GET_SYSTEM_TIMEZONES_SUCCESS,
  failType: TimezoneTypes.GET_SYSTEM_TIMEZONES_FAILED,
  isQueryParam: true
})

export default [
  getTimezones,
  addTimezone,
  getTimezoneDetail,
  deleteTimezone,
  updateTimezone,
  getSystemTimezones
]
