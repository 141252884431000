import { DEFAULT_LANGUAGE_FILTER } from 'constants/common'
import LanguageTypes from 'store/actionTypes/language'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  current: {},
  systems: [],
  defaultLang: {},
  pagination: {},
  filter: DEFAULT_LANGUAGE_FILTER
}

const language = (state = initialData, action) => {
  switch (action.type) {
    case LanguageTypes.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case LanguageTypes.GET_LANGUAGE_DETAIL_SUCCESS:
      return { ...state, current: action.payload }

    case LanguageTypes.UPDATE_FILTER_LANGUAGE_SUCCESS:
      return { ...state, filter: action.payload }
    case LanguageTypes.CLEAR_FILTER_LANGUAGE_SUCCESS:
      return { ...state, filter: initialData.filter }
    case LanguageTypes.GET_SYSTEM_LANGUAGES_SUCCESS:
      return { ...state, systems: action.payload?.languages?.items }
    case UserTypes.LOGOUT_SUCCESS:
      return { ...initialData, systems: state.systems }
    default:
      return state
  }
}

export default language
