import BaseApi from './base'

class Timezone extends BaseApi {
  getTimezones = (query) => this.client.get(`admin/timezones?${query}`)

  getTimezoneDetail = (id) => this.client.get(`admin/timezones/${id}`)

  addTimezone = (data) => this.client.post('admin/timezones', data)

  updateTimezone = (id, data) =>
    this.client.patch(`admin/timezones/${id}`, data)

  deleteTimezone = (id) => this.client.delete(`admin/timezones/${id}`)

  getSystemTimezones = (query) =>
    this.client.get(`admin/system/timezones?${query}`)
}

export default new Timezone()
