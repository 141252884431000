import BaseApi from './base'

class AppVersion extends BaseApi {
  getAppVersions = (query) => this.client.get(`admin/app-versions?${query}`)

  getAppVersionDetail = (id) => this.client.get(`admin/app-versions/${id}`)

  addAppVersion = (data) => this.client.post('admin/app-versions', data)

  updateAppVersion = (id, data) =>
    this.client.patch(`admin/app-versions/${id}`, data)

  deleteAppVersion = (id) => this.client.delete(`admin/app-versions/${id}`)

  appVersionTranslation = (id, data) => {
    const uri = `admin/app-versions/${id}/translation/${data?.languageId}`
    return this.client.post(uri, data)
  }

  deleteAppVersionTranslation = (id) =>
    this.client?.delete(`admin/app-versions/translation/${id}`)

  getSystemAppVersions = (query) =>
    this.client.get(`admin/system/app-versions?${query}`)
}

export default new AppVersion()
