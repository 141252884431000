import BaseApi from './base'

class AppUser extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  getAppUsers = (query) => this.client.get(`users?${query}`)

  addUser = (data) => this.client.post(`users`, data)

  updateUser = (id, data) => this.client.patch(`users/${id}`, data)

  deleteUser = (id) => this.client.delete(`users/${id}`)

  getAppUserDetail = (id) => this.client.get(`users/${id}`)

  updateRoleUser = (id, data) =>
    this.client.post(`admin/user-app/${id}/roles`, data, {
      baseURL: this.baseURL
    })

  getRoleUser = (id) =>
    this.client.get(`admin/user-app/${id}/roles`, { baseURL: this.baseURL })

  getUserSubscription = (id, query) =>
    this.client.get(`admin/subscriptions/subscriber/${id}?${query}`)

  getSubscriptionAllowedPilots = (id) =>
    this.client.get(`/users/${id}/allowed-pilots`)

  getUserVehicles = (id, query) =>
    this.client.get(`/users/${id}/vehicles?${query}`)

  getListVehicleSharingExtension = (params) =>
    this.client.get(`/vehicle-sharing/extensions`, { params })

  getDetailVehicleSharing = (params) => {
    return this.client.get(`/vehicle-sharing/extensions/${params.sharingId}`, {
      params
    })
  }

  forceStatusPermanentExtension = (id, data) =>
    this.client.patch(
      `/vehicle-sharing/force-update-permanent-extension/${id}/status`,
      data
    )

  forceStatusTemporalExtension = (id, data) =>
    this.client.patch(
      `/vehicle-sharing/admin-update-temporal-extension/${id}/status`,
      data
    )

  forceStatusCheckIn = (data) =>
    this.client.post('/vehicle-sharing/force-update-check-in-status', data)

  forceStatusCheckOut = (data) =>
    this.client.post('/vehicle-sharing/force-update-check-out-status', data)

  getStatusUsers = (query) =>
    this.client.get(`/users/status?${query}`, {
      baseURL: this.notificationURL
    })

  getDirectVehiclesByUserId = (id, query) =>
    this.client.get(`/admin/direct-vehicles/${id}?${query}`, {
      baseURL: this.baseURL
    })

  cancelDirectVehicle = (id) =>
    this.client.post(
      `/admin/direct-vehicles/cancel/${id}`,
      {},
      {
        baseURL: this.baseURL
      }
    )

  getCommSystemLogs = (id, query) =>
    this.client.get(`/admin/user-comm-system-log/${id}?${query}`, {
      baseURL: this.baseURL
    })

  upsertGDPR = ({ userId, payload }) =>
    this.client.put(`/users/${userId}/optin`, payload)

  getGDPR = ({ userId, platformTechKey }) =>
    this.client.get(`/users/${userId}/optin/${platformTechKey}`)
}

export default new AppUser()
