import BaseApi from './base'

class VehicleBrand extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  getVehicleBrands = (query) => this.client.get(`vehicle-brand?${query}`)

  getVehicleBrandDetail = (id) => this.client.get(`vehicle-brand/${id}`)

  addVehicleBrand = (data) => this.client.post('vehicle-brand', data)

  updateVehicleBrand = (id, data) =>
    this.client.patch(`vehicle-brand/${id}`, data)

  deleteVehicleBrand = (id) => this.client.delete(`vehicle-brand/${id}`)

  /* =============   VEHICLE BRAND MESSAGE ============ */
  getVehicleBrandMessages = (query) =>
    this.client.get(`vehicle-brand-message?${query}`)

  addVehicleBrandMessage = (data) =>
    this.client.post('vehicle-brand-message', data)

  updateVehicleBrandMessage = (id, data) =>
    this.client.patch(`vehicle-brand-message/${id}`, data)

  deleteVehicleBrandMessage = (id) =>
    this.client.delete(`vehicle-brand-message/${id}`)

  getSystemVehicleBrands = (query) =>
    this.client.get(`systems/vehicle-brand?${query}`)
}

export default new VehicleBrand()
