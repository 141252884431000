import BaseApi from './base'

class User extends BaseApi {
  loginUser = (data) => this.client.post('/login', data)

  fetchProfile = () => this.client.get('me')

  getUsers = (query) => this.client.get(`admin/users?${query}`)

  addUser = (data) => this.client.post('admin/users', data)

  updateUser = (id, data) => this.client.patch(`admin/users/${id}`, data)

  deleteUser = (id) => this.client.delete(`admin/users/${id}`)

  getUserDetail = (id) => this.client.get(`admin/users/${id}`)

  updateStatusUser = (data) =>
    this.client.put(`admin/users/change-status`, data)

  updatePasswordUser = (id, data) =>
    this.client.put(`admin/users/change-password/${id}`, data)
}

export default new User()
