import ElectronicKeyType from '../actionTypes/electronicKey'
import { electronicKey as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const getElectronicKeys = bindRequestLogic({
  clientFunc: clientApi.getElectronicKeys,
  type: ElectronicKeyType.GET_ELECTRONIC_KEYS_REQUEST,
  successType: ElectronicKeyType.GET_ELECTRONIC_KEYS_SUCCESS,
  failType: ElectronicKeyType.GET_ELECTRONIC_KEYS_FAILED,
  isQueryParam: true,
  modelName: 'electronicKey'
})

const addElectronicKey = bindRequestLogic({
  clientFunc: clientApi.addElectronicKey,
  type: ElectronicKeyType.ADD_ELECTRONIC_KEY_REQUEST,
  successType: ElectronicKeyType.ADD_ELECTRONIC_KEY_SUCCESS,
  failType: ElectronicKeyType.ADD_ELECTRONIC_KEY_FAILED
})

const getElectronicKeyDetail = bindRequestLogic({
  clientFunc: clientApi.getElectronicKeyDetail,
  type: ElectronicKeyType.GET_ELECTRONIC_KEY_DETAIL_REQUEST,
  successType: ElectronicKeyType.GET_ELECTRONIC_KEY_DETAIL_SUCCESS,
  failType: ElectronicKeyType.GET_ELECTRONIC_KEY_DETAIL_FAILED,
  modelName: 'electronicKey'
})

const deleteElectronicKey = bindRequestLogic({
  clientFunc: clientApi.deleteElectronicKey,
  type: ElectronicKeyType.DELETE_ELECTRONIC_KEY_REQUEST,
  successType: ElectronicKeyType.DELETE_ELECTRONIC_KEY_SUCCESS,
  failType: ElectronicKeyType.DELETE_ELECTRONIC_KEY_FAILED
})

const updateElectronicKey = bindRequestLogic({
  clientFunc: clientApi.updateElectronicKey,
  type: ElectronicKeyType.UPDATE_ELECTRONIC_KEY_REQUEST,
  successType: ElectronicKeyType.UPDATE_ELECTRONIC_KEY_SUCCESS,
  failType: ElectronicKeyType.UPDATE_ELECTRONIC_KEY_FAILED
})

const getSheetVehicles = bindRequestLogic({
  clientFunc: clientApi.getSheetVehicle,
  type: ElectronicKeyType.GET_VEHICLE_ELECTRONIC_KEYS_REQUEST,
  successType: ElectronicKeyType.GET_VEHICLE_ELECTRONIC_KEYS_REQUEST_SUCCESS,
  failType: ElectronicKeyType.GET_VEHICLE_ELECTRONIC_KEYS_REQUEST_FAILED,
  isQueryParam: true
})

const getSystemElectronicKeys = bindRequestLogic({
  clientFunc: clientApi.getSystemElectronicKeys,
  type: ElectronicKeyType.GET_SYSTEM_ELECTRONIC_KEYS_REQUEST,
  successType: ElectronicKeyType.GET_SYSTEM_ELECTRONIC_KEYS_SUCCESS,
  failType: ElectronicKeyType.GET_SYSTEM_ELECTRONIC_KEYS_FAILED,
  isQueryParam: true
})

export default [
  getElectronicKeys,
  addElectronicKey,
  getElectronicKeyDetail,
  deleteElectronicKey,
  updateElectronicKey,
  getSheetVehicles,
  getSystemElectronicKeys
]
