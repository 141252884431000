// eslint-disable-next-line import/prefer-default-export
export const mapAlertTopicDetail = (item = {}) => {
  const translations = item.translations || []
  return {
    ...item,
    translations: translations.map((t) => ({
      ...t,
      languageId: t.language?.id,
      translationValue: t.translationLabelField
    }))
  }
}
