import { OS_TYPES } from 'constants/platformTech'

const overwriteOs = (platformTech) => {
  // eslint-disable-next-line no-param-reassign
  if (platformTech.isWebApp) platformTech.os = 3 // Web;
}

// eslint-disable-next-line import/prefer-default-export
export const mapAppVersionDetail = (appVersion) => {
  const { platformTech } = appVersion
  overwriteOs(platformTech)

  const platformOS = OS_TYPES[platformTech.os] || ''

  const title = `${platformOS} - ${appVersion.version}`

  return { ...appVersion, title, platformOS }
}

export const mapAppVersions = (appVersions) => {
  appVersions.items.map((version) => {
    return overwriteOs(version.platformTech)
  })
  return { ...appVersions }
}
