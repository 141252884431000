import { DEFAULT_ELECTRONIC_KEY_MODEL_FILTER } from 'constants/common'
import ElectronicKeyModelTypes from 'store/actionTypes/electronicKeyModel'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  systems: [],
  current: {},
  pagination: {},
  filter: DEFAULT_ELECTRONIC_KEY_MODEL_FILTER
}

const electronicKeyModel = (state = initialData, action) => {
  switch (action.type) {
    case ElectronicKeyModelTypes.GET_ELECTRONIC_KEY_MODELS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case ElectronicKeyModelTypes.GET_ELECTRONIC_KEY_MODEL_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case ElectronicKeyModelTypes.UPDATE_FILTER_ELECTRONIC_KEY_MODEL_SUCCESS:
      return { ...state, filter: action.payload }
    case ElectronicKeyModelTypes.CLEAR_FILTER_ELECTRONIC_KEY_MODEL_SUCCESS:
      return { ...state, filter: initialData.filter }
    case ElectronicKeyModelTypes.GET_SYSTEM_ELECTRONIC_KEY_MODELS_SUCCESS:
      return { ...state, systems: action.payload?.electronicKeyModel?.items }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default electronicKeyModel
