import BaseApi from './base'

class NotificationTemplateTech extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.notificationURL}/admin/`
  }

  getNotificationTemplateTechs = (query) =>
    this.client.get(`notification-template-tech?${query}`)

  addNotificationTemplateTech = (data) =>
    this.client.post(`notification-template-tech`, data)

  updateNotificationTemplateTech = (id, data) =>
    this.client.patch(`notification-template-tech/${id}`, data)

  deleteNotificationTemplateTech = (id) =>
    this.client.delete(`notification-template-tech/${id}`)

  getNotificationTemplateTechsByNotificationId = (id) =>
    this.client.get(`notification-template-tech?noticationTemplateId=${id}`)
}

export default new NotificationTemplateTech()
