import keyMirror from 'keymirror'

export default keyMirror({
  GET_SYSTEM_CONFIG_LIST_REQUEST: null,
  GET_SYSTEM_CONFIG_LIST_SUCCESS: null,
  GET_SYSTEM_CONFIG_LIST_FAILED: null,

  UPDATE_SYSTEMS_CONFIG_REQUEST: null,
  UPDATE_SYSTEMS_CONFIG_SUCCESS: null,
  UPDATE_SYSTEMS_CONFIG_FAILED: null,

  GET_MODULES_REQUEST: null,
  GET_MODULES_SUCCESS: null,
  GET_MODULES_FAILED: null,

  UPDATE_CURRENT_MODULES_SUCCESS: null,

  GET_ALL_SYSTEM_CONFIGS_REQUEST: null,
  GET_ALL_SYSTEM_CONFIGS_SUCCESS: null,
  GET_ALL_SYSTEM_CONFIGS_FAILED: null,

  GET_ADMIN_PORTALS_REQUEST: null,
  GET_ADMIN_PORTALS_SUCCESS: null,
  GET_ADMIN_PORTALS_FAILED: null,

  GET_MODULE_BY_NAME_REQUEST: null,
  GET_MODULE_BY_NAME_SUCCESS: null,
  GET_MODULE_BY_NAME_FAILED: null
})
