import BaseApi from './base'

class ShowcaseContactLog extends BaseApi {
  constructor() {
    super()
    this.client.defaults.headers.Accept = 'multipart/form-data'
  }

  getAnonymizedVisitors = (query) =>
    this.client.get(`/admin/platform-contact-logs/anonymized?${query}`)

  cancelAnonymizeVisitor = (id) =>
    this.client.post(`/admin/platform-contact-logs/${id}/un-anonymized`)
}

export default new ShowcaseContactLog()
