import SharingTypes from '../actionTypes/sharing'
import { sharing as sharingApi } from '../api'
import { bindRequestLogic } from './utils'

const getSharings = bindRequestLogic({
  clientFunc: sharingApi.getSharings,
  type: SharingTypes.GET_LIST_OF_SHARING_REQUEST,
  successType: SharingTypes.GET_LIST_OF_SHARING_SUCCESS,
  failType: SharingTypes.GET_LIST_OF_SHARING_FAILED,
  isQueryParam: true
})

export default [getSharings]
