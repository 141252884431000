import KeyMirror from 'keymirror'

export default KeyMirror({
  GET_CONDITIONAL_FEATURE_REQUEST: null,
  GET_CONDITIONAL_FEATURE_SUCCESS: null,
  GET_CONDITIONAL_FEATURE_FAILED: null,

  ADD_CONDITIONAL_FEATURE_REQUEST: null,
  ADD_CONDITIONAL_FEATURE_SUCCESS: null,
  ADD_CONDITIONAL_FEATURE_FAILED: null,

  DELETE_CONDITIONAL_FEATURE_REQUEST: null,
  DELETE_CONDITIONAL_FEATURE_SUCCESS: null,
  DELETE_CONDITIONAL_FEATURE_FAILED: null,

  UPDATE_CONDITIONAL_FEATURE_FAILED: null,
  UPDATE_CONDITIONAL_FEATURE_REQUEST: null,
  UPDATE_CONDITIONAL_FEATURE_SUCCESS: null,

  UPDATE_FILTER_CONDITIONAL_FEATURE_SUCCESS: null,
  CLEAR_FILTER_CONDITIONAL_FEATURE_SUCCESS: null
})
