import { createLogic } from 'redux-logic'
import { MAP_MODULE_TO_FILTER } from 'constants/common'
import { currentModule } from '../selector/systemConfig'

/**
 * Based on the current module, set the filter key to the correct filter
 * @param type
 * @param successType
 * return {Logic}
 */
// eslint-disable-next-line import/prefer-default-export
export const bindFilterLogic = ({ type, successType }) =>
  createLogic({
    type,
    latest: true,
    process: ({ action, getState }, dispatch, done) => {
      const module = currentModule(getState())

      const data = action.payload

      const key = MAP_MODULE_TO_FILTER[module]

      dispatch({
        type: successType,
        payload: { key, value: data }
      })

      done()
    }
  })
