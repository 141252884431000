import BaseApi from './base'

class ElectricKey extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  getElectronicKeys = (query) => this.client.get(`electronic-key?${query}`)

  getElectronicKeyDetail = (id) => this.client.get(`electronic-key/${id}`)

  addElectronicKey = (data) => this.client.post('electronic-key', data)

  updateElectronicKey = (id, data) =>
    this.client.patch(`electronic-key/${id}`, data)

  deleteElectronicKey = (id) => this.client.delete(`electronic-key/${id}`)

  getSheetVehicle = (id, query) =>
    this.client.get(`electronic-key/${id}/sheet-vehicles?${query}`)

  getSystemElectronicKeys = (query) =>
    this.client.get(`systems/electronic-key?${query}`)
}

export default new ElectricKey()
