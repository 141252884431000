import AlertTopicTypes from 'store/actionTypes/alertTopic'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  current: {},
  system: [],
  filter: {}
}

const AlertTopic = (state = initialData, action = {}) => {
  switch (action.type) {
    case AlertTopicTypes.GET_ALERT_TOPICS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items || []
      }
    case AlertTopicTypes.GET_ALERT_TOPIC_DETAIL_SUCCESS:
      return {
        ...state,
        current: action.payload || []
      }
    case AlertTopicTypes.GET_SYSTEM_ALERT_TOPICS_SUCCESS:
      return {
        ...state,
        system: action.payload?.items || []
      }
    case AlertTopicTypes.UPDATE_FILTER_ALERT_TOPIC_SUCCESS:
      return {
        ...state,
        filter: action.payload
      }
    case AlertTopicTypes.CLEAR_ALERT_TOPIC_FILTER_SUCCESS:
      return {
        ...state,
        filter: {}
      }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default AlertTopic
