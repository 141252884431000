import keyMirror from 'keymirror'

export default keyMirror({
  GET_NOTIFICATION_TEMPLATES_REQUEST: null,
  GET_NOTIFICATION_TEMPLATES_SUCCESS: null,
  GET_NOTIFICATION_TEMPLATES_FAILED: null,

  ADD_NOTIFICATION_TEMPLATE_REQUEST: null,
  ADD_NOTIFICATION_TEMPLATE_SUCCESS: null,
  ADD_NOTIFICATION_TEMPLATE_FAILED: null,

  GET_NOTIFICATION_TEMPLATE_DETAIL_REQUEST: null,
  GET_NOTIFICATION_TEMPLATE_DETAIL_SUCCESS: null,
  GET_NOTIFICATION_TEMPLATE_DETAIL_FAILED: null,

  UPDATE_NOTIFICATION_TEMPLATE_REQUEST: null,
  UPDATE_NOTIFICATION_TEMPLATE_SUCCESS: null,
  UPDATE_NOTIFICATION_TEMPLATE_FAILED: null,

  DELETE_NOTIFICATION_TEMPLATE_REQUEST: null,
  DELETE_NOTIFICATION_TEMPLATE_SUCCESS: null,
  DELETE_NOTIFICATION_TEMPLATE_FAILED: null,

  UPDATE_FILTER_NOTIFICATION_TEMPLATE_SUCCESS: null,
  CLEAR_FILTER_NOTIFICATION_TEMPLATE_SUCCESS: null,

  GET_PLATFORM_PAGE_TECH_IN_NOTIFICATION_TEMPLATE_REQUEST: null,
  GET_PLATFORM_PAGE_TECH_IN_NOTIFICATION_TEMPLATE_SUCCESS: null,
  GET_PLATFORM_PAGE_TECH_IN_NOTIFICATION_TEMPLATE_FAILED: null,

  GET_SYSTEM_NOTIFICATION_TEMPLATES_REQUEST: null,
  GET_SYSTEM_NOTIFICATION_TEMPLATES_SUCCESS: null,
  GET_SYSTEM_NOTIFICATION_TEMPLATES_FAILED: null,

  GET_SYSTEM_NOTIFICATION_TEMPLATE_BY_ID_REQUEST: null,
  GET_SYSTEM_NOTIFICATION_TEMPLATE_BY_ID_SUCCESS: null,
  GET_SYSTEM_NOTIFICATION_TEMPLATE_BY_ID_FAILED: null
})
