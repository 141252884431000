import DateTimeFormatType from '../actionTypes/dateTimeFormat'
import { dateTimeFormat as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const updateDateTimeFormat = bindRequestLogic({
  clientFunc: clientApi.updateDateTimeFormat,
  type: DateTimeFormatType.UPDATE_DATETIME_FORMAT_REQUEST,
  successType: DateTimeFormatType.UPDATE_DATETIME_FORMAT_SUCCESS,
  failType: DateTimeFormatType.UPDATE_DATETIME_FORMAT_FAILED
})

const getDateTimeFormatsByLanguage = bindRequestLogic({
  clientFunc: clientApi.getDateTimeFormatsByLanguage,
  type: DateTimeFormatType.GET_DATETIME_FORMATS_BY_LANGUAGE_REQUEST,
  successType: DateTimeFormatType.GET_DATETIME_FORMATS_BY_LANGUAGE_SUCCESS,
  failType: DateTimeFormatType.GET_DATETIME_FORMATS_BY_LANGUAGE_FAILED,
  isQueryParam: true,
  modelName: 'dateTimeConfigs'
})

export default [getDateTimeFormatsByLanguage, updateDateTimeFormat]
