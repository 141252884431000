import BaseApi from './base'

class Alert extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  getSystemAlerts = (query) => this.client.get(`systems/alert?${query}`)

  getAlerts = (query) => this.client.get(`alerts?${query}`)

  addAlert = (data) => this.client.post(`alerts`, data)

  getAlertDetail = (id) => this.client.get(`alerts/${id}`)

  updateAlert = ({ alertId, payload }) =>
    this.client.patch(`alerts/${alertId}`, payload)

  deleteAlert = (alertId) => this.client.delete(`alerts/${alertId}`)

  addAlertTranslation = ({ alertId, payload }) =>
    this.client.post(`alerts/${alertId}/translation`, payload)

  updateAlertTranslation = ({ alertId, translationId, payload }) =>
    this.client.patch(`alerts/${alertId}/translation/${translationId}`, payload)

  deleteAlertlation = ({ alertId, translationId }) =>
    this.client.delete(`alerts/${alertId}/translation/${translationId}`)
}

export default new Alert()
