import PlatformTechTypes from 'store/actionTypes/platformTech'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  pagination: {},
  systems: [],
  current: {}
}

const platformTech = (state = initialData, action) => {
  switch (action.type) {
    case PlatformTechTypes.GET_PLATFORM_TECH_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case PlatformTechTypes.GET_PLATFORM_TECH_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case PlatformTechTypes.GET_SYSTEM_PLATFORM_TECHS_SUCCESS:
      return { ...state, systems: action?.payload?.items }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default platformTech
