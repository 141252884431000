import keyMirror from 'keymirror'

export default keyMirror({
  GET_ACTIVITIES_REQUEST: null,
  GET_ACTIVITIES_SUCCESS: null,
  GET_ACTIVITIES_FAILED: null,

  ADD_ACTIVITY_REQUEST: null,
  ADD_ACTIVITY_SUCCESS: null,
  ADD_ACTIVITY_FAILED: null,

  UPDATE_ACTIVITY_REQUEST: null,
  UPDATE_ACTIVITY_SUCCESS: null,
  UPDATE_ACTIVITY_FAILED: null,

  DELETE_ACTIVITY_REQUEST: null,
  DELETE_ACTIVITY_SUCCESS: null,
  DELETE_ACTIVITY_FAILED: null
})
