import keyMirror from 'keymirror'

export default keyMirror({
  GET_SHOWCASE_COMPONENTS_REQUEST: null,
  GET_SHOWCASE_COMPONENTS_SUCCESS: null,
  GET_SHOWCASE_COMPONENTS_FAILED: null,

  GET_SHOWCASE_COMPONENT_DETAIL_REQUEST: null,
  GET_SHOWCASE_COMPONENT_DETAIL_SUCCESS: null,
  GET_SHOWCASE_COMPONENT_DETAIL_FAILED: null,

  DELETE_SHOWCASE_COMPONENT_REQUEST: null,
  DELETE_SHOWCASE_COMPONENT_SUCCESS: null,
  DELETE_SHOWCASE_COMPONENT_FAILED: null,

  ADD_SHOWCASE_COMPONENT_REQUEST: null,
  ADD_SHOWCASE_COMPONENT_SUCCESS: null,
  ADD_SHOWCASE_COMPONENT_FAILED: null,

  UPDATE_SHOWCASE_COMPONENT_REQUEST: null,
  UPDATE_SHOWCASE_COMPONENT_SUCCESS: null,
  UPDATE_SHOWCASE_COMPONENT_FAILED: null,

  UPDATE_TRANSLATION_COMPONENT_REQUEST: null,
  UPDATE_TRANSLATION_COMPONENT_SUCCESS: null,
  UPDATE_TRANSLATION_COMPONENT_FAILED: null
})
