import ShowcaseStoryTypes from '../actionTypes/showcaseStory'
import { showcaseStory as showcaseStoryApi } from '../api'
import { bindRequestLogic } from './utils'

const getShowcaseStories = bindRequestLogic({
  clientFunc: showcaseStoryApi.getShowcaseStories,
  type: ShowcaseStoryTypes.GET_SHOWCASE_STORIES_REQUEST,
  successType: ShowcaseStoryTypes.GET_SHOWCASE_STORIES_SUCCESS,
  failType: ShowcaseStoryTypes.GET_SHOWCASE_STORIES_FAILED,
  isQueryParam: true
})

const addShowcaseStory = bindRequestLogic({
  clientFunc: showcaseStoryApi.addShowcaseStory,
  type: ShowcaseStoryTypes.ADD_SHOWCASE_STORY_REQUEST,
  successType: ShowcaseStoryTypes.ADD_SHOWCASE_STORY_SUCCESS,
  failType: ShowcaseStoryTypes.ADD_SHOWCASE_STORY_FAILED
})

const updateShowcaseStory = bindRequestLogic({
  clientFunc: showcaseStoryApi.updateShowcaseStory,
  type: ShowcaseStoryTypes.UPDATE_SHOWCASE_STORY_REQUEST,
  successType: ShowcaseStoryTypes.UPDATE_SHOWCASE_STORY_SUCCESS,
  failType: ShowcaseStoryTypes.UPDATE_SHOWCASE_STORY_FAILED
})

const getShowcaseStory = bindRequestLogic({
  clientFunc: showcaseStoryApi.getShowcaseStory,
  type: ShowcaseStoryTypes.GET_SHOWCASE_STORY_DETAIL_REQUEST,
  successType: ShowcaseStoryTypes.GET_SHOWCASE_STORY_DETAIL_SUCCESS,
  failType: ShowcaseStoryTypes.GET_SHOWCASE_STORY_DETAIL_FAILED
})

const deleteShowcaseStory = bindRequestLogic({
  clientFunc: showcaseStoryApi.deleteShowcaseStory,
  type: ShowcaseStoryTypes.DELETE_SHOWCASE_STORY_REQUEST,
  successType: ShowcaseStoryTypes.DELETE_SHOWCASE_STORY_SUCCESS,
  failType: ShowcaseStoryTypes.DELETE_SHOWCASE_STORY_FAILED
})

const updateTranslationComponent = bindRequestLogic({
  clientFunc: showcaseStoryApi.updateShowcaseStory,
  type: ShowcaseStoryTypes.UPDATE_SHOWCASE_STORY_CONTENT_REQUEST,
  successType: ShowcaseStoryTypes.UPDATE_SHOWCASE_STORY_CONTENT_SUCCESS,
  failType: ShowcaseStoryTypes.UPDATE_SHOWCASE_STORY_CONTENT_FAILED
})

const addShowcaseStoryContent = bindRequestLogic({
  clientFunc: showcaseStoryApi.addShowcaseStoryContent,
  type: ShowcaseStoryTypes.ADD_SHOWCASE_STORY_CONTENT_REQUEST,
  successType: ShowcaseStoryTypes.ADD_SHOWCASE_STORY_CONTENT_SUCCESS,
  failType: ShowcaseStoryTypes.ADD_SHOWCASE_STORY_CONTENT_FAILED
})

const getShowcaseStoryContents = bindRequestLogic({
  clientFunc: showcaseStoryApi.getShowcaseContents,
  type: ShowcaseStoryTypes.GET_SHOWCASE_STORY_CONTENTS_REQUEST,
  successType: ShowcaseStoryTypes.GET_SHOWCASE_STORY_CONTENTS_SUCCESS,
  failType: ShowcaseStoryTypes.GET_SHOWCASE_STORY_CONTENTS_FAILED
})

const publishStory = bindRequestLogic({
  clientFunc: showcaseStoryApi.updateShowcaseStory,
  type: ShowcaseStoryTypes.PUBLISH_SHOWCASE_STORY_REQUEST,
  successType: ShowcaseStoryTypes.PUBLISH_SHOWCASE_STORY_SUCCESS,
  failType: ShowcaseStoryTypes.PUBLISH_SHOWCASE_STORY_FAILED
})

export default [
  getShowcaseStories,
  addShowcaseStory,
  deleteShowcaseStory,
  updateShowcaseStory,
  getShowcaseStory,
  updateTranslationComponent,
  addShowcaseStoryContent,
  getShowcaseStoryContents,
  publishStory
]
