import BaseApi from './base'

class CompanyVehicleBrandLink extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  getCompanyVehicleBrandLinks = (query) =>
    this.client.get(`admin/company-vehicle-brand-link?${query}`)

  getCompanyVehicleBrandLinkDetail = (id) =>
    this.client.get(`admin/company-vehicle-brand-link/${id}`)

  addCompanyVehicleBrandLink = (data) =>
    this.client.post('admin/company-vehicle-brand-link', data)

  updateCompanyVehicleBrandLink = (id, data) =>
    this.client.patch(`admin/company-vehicle-brand-link/${id}`, data)

  deleteCompanyVehicleBrandLink = (id) =>
    this.client.delete(`admin/company-vehicle-brand-link/${id}`)
}

export default new CompanyVehicleBrandLink()
