import BaseApi from './base'

class PlatformTech extends BaseApi {
  getPlatformTechs = (query) => this.client.get(`admin/platform-techs?${query}`)

  getPlatformTech = (id) => this.client.get(`admin/platform-techs/${id}`)

  addPlatformTech = (data) => this.client.post('admin/platform-techs', data)

  updatePlatformTech = (id, data) =>
    this.client.patch(`admin/platform-techs/${id}`, data)

  getSystemPlatformTechs = (query) =>
    this.client.get(`admin/system/platform-techs?${query}`)
}

export default new PlatformTech()
