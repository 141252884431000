import { combineReducers } from 'redux'
import NotificationRequest from 'store/actionTypes/notificationRequest'
import { createHandleRequest } from './helpers'

const getNotificationRequests = createHandleRequest(
  NotificationRequest.GET_LIST_NOTIFICATION_REQUEST_REQUEST,
  NotificationRequest.GET_LIST_NOTIFICATION_REQUEST_SUCCESS,
  NotificationRequest.GET_LIST_NOTIFICATION_REQUEST_FAILED
)

export default combineReducers({
  getNotificationRequests
})
