import PlatformTech from '../actionTypes/platformTech'
import { platformTech as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const getPlatformTechs = bindRequestLogic({
  clientFunc: clientApi.getPlatformTechs,
  type: PlatformTech.GET_PLATFORM_TECH_REQUEST,
  successType: PlatformTech.GET_PLATFORM_TECH_SUCCESS,
  failType: PlatformTech.GET_PLATFORM_TECH_FAILED,
  isQueryParam: true
})

const getPlatformTech = bindRequestLogic({
  clientFunc: clientApi.getPlatformTech,
  type: PlatformTech.GET_PLATFORM_TECH_DETAIL_REQUEST,
  successType: PlatformTech.GET_PLATFORM_TECH_DETAIL_SUCCESS,
  failType: PlatformTech.GET_PLATFORM_TECH_DETAIL_FAILED
})

const addPlatformTech = bindRequestLogic({
  clientFunc: clientApi.addPlatformTech,
  type: PlatformTech.ADD_PLATFORM_TECH_REQUEST,
  successType: PlatformTech.ADD_PLATFORM_TECH_SUCCESS,
  failType: PlatformTech.ADD_PLATFORM_TECH_FAILED
})

const updatePlatformTech = bindRequestLogic({
  clientFunc: clientApi.updatePlatformTech,
  type: PlatformTech.UPDATE_PLATFORM_TECH_REQUEST,
  successType: PlatformTech.UPDATE_PLATFORM_TECH_SUCCESS,
  failType: PlatformTech.UPDATE_PLATFORM_TECH_FAILED
})

const getSystemPlatformTechs = bindRequestLogic({
  clientFunc: clientApi.getSystemPlatformTechs,
  type: PlatformTech.GET_SYSTEM_PLATFORM_TECHS_REQUEST,
  successType: PlatformTech.GET_SYSTEM_PLATFORM_TECHS_SUCCESS,
  failType: PlatformTech.GET_SYSTEM_PLATFORM_TECHS_FAILED,
  isQueryParam: true
})

export default [
  getPlatformTechs,
  addPlatformTech,
  updatePlatformTech,
  getPlatformTech,
  getSystemPlatformTechs
]
