import BaseApi from './base'

class UsageSystemLog extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = this.perURL
  }

  getUsageSystemLogs = (query) =>
    this.client.get(`pers/backoffice/usage-system-log?${query}`)

  getUsageSystemLogById = (id) =>
    this.client.get(`pers/backoffice/usage-system-log/${id}`)
}

export default new UsageSystemLog()
