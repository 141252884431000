import { Fragment } from 'react'

const SystemMessages = ({ id, systemMessages, params = {} }) => {
  const translationValue = systemMessages[id]

  if (translationValue) {
    if (Object.keys(params).length) {
      const values = translationValue.split(/({[\w\\.\\-]+})/)

      return values.map((value, index) => {
        const isMatched = /{[\w\\.\\-]+}/g.test(value)
        const keyId = `${id}-${index}`
        if (isMatched) {
          const key = value.replace(/{|}/g, '')
          return <Fragment key={keyId}>{params[key]}</Fragment>
        }
        return <Fragment key={keyId}>{value}</Fragment>
      })
    }

    return translationValue
  }
  return id
}

export default SystemMessages
