import BaseApi from './base'

class ShowcaseStory extends BaseApi {
  getShowcaseStories = (query) => this.client.get(`admin/stories?${query}`)

  getShowcaseStory = (id) => this.client.get(`admin/stories/${id}`)

  addShowcaseStory = (data) => this.client.post('admin/stories', data)

  updateShowcaseStory = (id, data) =>
    this.client.patch(`admin/stories/${id}`, data)

  deleteShowcaseStory = (id) => this.client.delete(`admin/stories/${id}`)

  addShowcaseStoryContent = (id, data) =>
    this.client.post(`admin/stories/content/${id}/translation`, data)

  getShowcaseContents = (id) => this.client.get(`admin/stories/${id}/contents`)
}

export default new ShowcaseStory()
