import BaseApi from './base'

class KeyUsageSystemLog extends BaseApi {
  getKeyUsageSystemLogs = (query) =>
    this.client.get(`admin/key-usage-system-log?${query}`)

  createKeyUsageSystemLog = (data) =>
    this.client.post('admin/key-usage-system-log', data)

  deleteKeyUsageSystemLog = (id) =>
    this.client.delete(`admin/key-usage-system-log/${id}`)

  updateKeyUsageSystemLogs = (languageId, data) =>
    this.client.post(
      `admin/key-usage-system-log/translation/bulk-upsert/${languageId}`,
      data
    )
}

export default new KeyUsageSystemLog()
