import PerTypes from '../actionTypes/per'
import { per as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const getPers = bindRequestLogic({
  clientFunc: clientApi.getPers,
  type: PerTypes.GET_PERS_REQUEST,
  successType: PerTypes.GET_PERS_SUCCESS,
  failType: PerTypes.GET_PERS_FAILED,
  isQueryParam: true
})

const addPer = bindRequestLogic({
  clientFunc: clientApi.addPer,
  type: PerTypes.ADD_PER_REQUEST,
  successType: PerTypes.ADD_PER_SUCCESS,
  failType: PerTypes.ADD_PER_FAILED
})

const getPerBy = bindRequestLogic({
  clientFunc: clientApi.getPerBy,
  type: PerTypes.GET_PER_DETAIL_REQUEST,
  successType: PerTypes.GET_PER_DETAIL_SUCCESS,
  failType: PerTypes.GET_PER_DETAIL_FAILED
})

const updatePer = bindRequestLogic({
  clientFunc: clientApi.updatePer,
  type: PerTypes.UPDATE_PER_REQUEST,
  successType: PerTypes.UPDATE_PER_SUCCESS,
  failType: PerTypes.UPDATE_PER_FAILED
})

const deletePer = bindRequestLogic({
  clientFunc: clientApi.deletePer,
  type: PerTypes.DELETE_PER_REQUEST,
  successType: PerTypes.DELETE_PER_SUCCESS,
  failType: PerTypes.DELETE_PER_FAILED
})

const getSystemPers = bindRequestLogic({
  clientFunc: clientApi.getSystemPers,
  type: PerTypes.GET_SYSTEM_PERS_REQUEST,
  successType: PerTypes.GET_SYSTEM_PERS_SUCCESS,
  failType: PerTypes.GET_SYSTEM_PERS_FAILED,
  isQueryParam: true
})

const getPerAlerts = bindRequestLogic({
  clientFunc: clientApi.getPerAlerts,
  type: PerTypes.GET_PER_ALERTS_REQUEST,
  successType: PerTypes.GET_PER_ALERTS_SUCCESS,
  failType: PerTypes.GET_PER_ALERTS_FAILED,
  isQueryParam: true
})

const publishIOTService = bindRequestLogic({
  clientFunc: clientApi.publishIOTService,
  type: PerTypes.PUBLISH_IOT_SERVICE_REQUEST,
  successType: PerTypes.PUBLISH_IOT_SERVICE_SUCCESS,
  failType: PerTypes.PUBLISH_IOT_SERVICE_FAILED
})

const getPerPackageLogs = bindRequestLogic({
  clientFunc: clientApi.getPerPackageLogs,
  type: PerTypes.GET_PER_PACKAGE_LOGS_REQUEST,
  successType: PerTypes.GET_PER_PACKAGE_LOGS_SUCCESS,
  failType: PerTypes.GET_PER_PACKAGE_LOGS_FAILED,
  isQueryParam: true
})

const getVehicleTrips = bindRequestLogic({
  clientFunc: clientApi.getVehicleTrips,
  type: PerTypes.GET_VEHICLE_TRIPS_REQUEST,
  successType: PerTypes.GET_VEHICLE_TRIPS_SUCCESS,
  failType: PerTypes.GET_VEHICLE_TRIPS_FAILED,
  isQueryParam: true
})

const getVehicleTripBy = bindRequestLogic({
  clientFunc: clientApi.getVehicleTripBy,
  type: PerTypes.GET_VEHICLE_TRIP_DETAIL_REQUEST,
  successType: PerTypes.GET_VEHICLE_TRIP_DETAIL_SUCCESS,
  failType: PerTypes.GET_VEHICLE_TRIP_DETAIL_FAILED
})

const exportVehicleTripBy = bindRequestLogic({
  clientFunc: clientApi.exportVehicleTripBy,
  type: PerTypes.EXPORT_VEHICLE_TRIP_DETAIL_REQUEST,
  successType: PerTypes.EXPORT_VEHICLE_TRIP_DETAIL_SUCCESS,
  failType: PerTypes.EXPORT_VEHICLE_TRIP_DETAIL_FAILED
})

export default [
  getPers,
  addPer,
  updatePer,
  deletePer,
  getPerBy,
  getSystemPers,
  getPerAlerts,
  publishIOTService,
  getPerPackageLogs,
  getVehicleTrips,
  getVehicleTripBy,
  exportVehicleTripBy
]
