import PerTypes from '../actionTypes/vehiclePer'
import { vehiclePer as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const addVehiclePer = bindRequestLogic({
  clientFunc: clientApi.addVehiclePer,
  type: PerTypes.ADD_VEHICLE_PER_REQUEST,
  successType: PerTypes.ADD_VEHICLE_PER_SUCCESS,
  failType: PerTypes.ADD_VEHICLE_PER_FAILED
})

const deleteVehiclePer = bindRequestLogic({
  clientFunc: clientApi.deleteVehiclePer,
  type: PerTypes.DELETE_VEHICLE_PER_REQUEST,
  successType: PerTypes.DELETE_VEHICLE_PER_SUCCESS,
  failType: PerTypes.DELETE_VEHICLE_PER_FAILED
})

export default [addVehiclePer, deleteVehiclePer]
