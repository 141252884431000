/* eslint-disable no-bitwise */
import React, { useMemo, useState } from 'react'
import { injectIntl } from 'react-intl'
import { Tooltip } from 'reactstrap'
import symbolTooltip from 'assets/img/symbol-tooltip.png'

/* Generate a random UUID (Universally Unique Identifier).
 * @returns {string} A random UUID.
 */
const generateUUID = () => {
  const uuidFormat = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'

  const uuid = uuidFormat.replace(/[xy]/g, (char) => {
    const randomNumber = (Math.random() * 16) | 0
    const randomDigit = char === 'x' ? randomNumber : (randomNumber & 0x3) | 0x8
    return randomDigit.toString(16)
  })

  return uuid
}

function InjectMassage({ id, systemLabels, hiddenHelpText }) {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const tooltipId = useMemo(() => {
    return `tooltip-${generateUUID()}`
  }, [])

  const isEnabledHelpText = useMemo(() => {
    return systemLabels[id]?.helpText && !hiddenHelpText
  }, [systemLabels, id, hiddenHelpText])

  return (
    <span className='tooltip-help-text'>
      <span
        color='secondary'
        className={isEnabledHelpText ? 'inline-block' : ''}
      >
        {systemLabels[id]?.originalName ?? id}
      </span>
      {isEnabledHelpText && (
        <>
          &nbsp;
          <img
            className='align-middle -mt-1'
            src={symbolTooltip}
            width={16}
            height={16}
            alt=''
            id={tooltipId}
          />
          <Tooltip
            container='body'
            trigger='hover'
            placement='top'
            isOpen={tooltipOpen}
            target={tooltipId}
            toggle={() => setTooltipOpen(!tooltipOpen)}
          >
            {systemLabels[id]?.helpText}
          </Tooltip>
        </>
      )}
    </span>
  )
}

export default injectIntl(InjectMassage, { forwardRef: true })
