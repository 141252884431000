import { combineReducers } from 'redux'
import SystemConfigTypes from 'store/actionTypes/systemConfig'
import { createHandleRequest } from './helpers'

const getSystemConfigs = createHandleRequest(
  SystemConfigTypes.GET_SYSTEM_CONFIG_LIST_REQUEST,
  SystemConfigTypes.GET_SYSTEM_CONFIG_LIST_SUCCESS,
  SystemConfigTypes.GET_SYSTEM_CONFIG_LIST_FAILED
)

const getModules = createHandleRequest(
  SystemConfigTypes.GET_MODULES_REQUEST,
  SystemConfigTypes.GET_MODULES_SUCCESS,
  SystemConfigTypes.GET_MODULES_FAILED
)

export default combineReducers({
  getSystemConfigs,
  getModules
})
