import keyMirror from 'keymirror'

export default keyMirror({
  GET_SUBSCRIPTIONS_REQUEST: null,
  GET_SUBSCRIPTIONS_SUCCESS: null,
  GET_SUBSCRIPTIONS_FAILED: null,

  ADD_SUBSCRIPTION_REQUEST: null,
  ADD_SUBSCRIPTION_SUCCESS: null,
  ADD_SUBSCRIPTION_FAILED: null,

  GET_SUBSCRIPTION_DETAIL_REQUEST: null,
  GET_SUBSCRIPTION_DETAIL_SUCCESS: null,
  GET_SUBSCRIPTION_DETAIL_FAILED: null,

  UPDATE_SUBSCRIPTION_REQUEST: null,
  UPDATE_SUBSCRIPTION_SUCCESS: null,
  UPDATE_SUBSCRIPTION_FAILED: null,

  DELETE_SUBSCRIPTION_REQUEST: null,
  DELETE_SUBSCRIPTION_SUCCESS: null,
  DELETE_SUBSCRIPTION_FAILED: null,

  GET_SUBSCRIPTIONS_VEHICLE_REQUEST: null,
  GET_SUBSCRIPTIONS_VEHICLE_SUCCESS: null,
  GET_SUBSCRIPTIONS_VEHICLE_FAILED: null,

  ADD_SUBSCRIPTION_VEHICLE_REQUEST: null,
  ADD_SUBSCRIPTION_VEHICLE_SUCCESS: null,
  ADD_SUBSCRIPTION_VEHICLE_FAILED: null,

  GET_SUBSCRIPTION_VEHICLE_DETAIL_REQUEST: null,
  GET_SUBSCRIPTION_VEHICLE_DETAIL_SUCCESS: null,
  GET_SUBSCRIPTION_VEHICLE_DETAIL_FAILED: null,

  UPDATE_SUBSCRIPTION_VEHICLE_REQUEST: null,
  UPDATE_SUBSCRIPTION_VEHICLE_SUCCESS: null,
  UPDATE_SUBSCRIPTION_VEHICLE_FAILED: null,

  DELETE_SUBSCRIPTION_VEHICLE_REQUEST: null,
  DELETE_SUBSCRIPTION_VEHICLE_SUCCESS: null,
  DELETE_SUBSCRIPTION_VEHICLE_FAILED: null,

  UPDATE_FILTER_SUBSCRIPTION_SUCCESS: null,
  CLEAR_FILTER_SUBSCRIPTION_SUCCESS: null,

  GET_ALLOWED_PILOTS_REQUEST: null,
  GET_ALLOWED_PILOTS_SUCCESS: null,
  GET_ALLOWED_PILOTS_FAILED: null,

  ADD_ALLOWED_PILOT_REQUEST: null,
  ADD_ALLOWED_PILOT_SUCCESS: null,
  ADD_ALLOWED_PILOT_FAILED: null,

  UPDATE_ALLOWED_PILOT_REQUEST: null,
  UPDATE_ALLOWED_PILOT_SUCCESS: null,
  UPDATE_ALLOWED_PILOT_FAILED: null,

  DELETE_ALLOWED_PILOT_REQUEST: null,
  DELETE_ALLOWED_PILOT_SUCCESS: null,
  DELETE_ALLOWED_PILOT_FAILED: null
})
