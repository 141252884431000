import keyMirror from 'keymirror'

export default keyMirror({
  GET_SUBSCRIPTION_ELECTRONIC_KEYS_REQUEST: null,
  GET_SUBSCRIPTION_ELECTRONIC_KEYS_SUCCESS: null,
  GET_SUBSCRIPTION_ELECTRONIC_KEYS_FAILED: null,

  ADD_SUBSCRIPTION_ELECTRONIC_KEY_REQUEST: null,
  ADD_SUBSCRIPTION_ELECTRONIC_KEY_SUCCESS: null,
  ADD_SUBSCRIPTION_ELECTRONIC_KEY_FAILED: null,

  GET_SUBSCRIPTION_ELECTRONIC_KEY_DETAIL_REQUEST: null,
  GET_SUBSCRIPTION_ELECTRONIC_KEY_DETAIL_SUCCESS: null,
  GET_SUBSCRIPTION_ELECTRONIC_KEY_DETAIL_FAILED: null,

  UPDATE_SUBSCRIPTION_ELECTRONIC_KEY_REQUEST: null,
  UPDATE_SUBSCRIPTION_ELECTRONIC_KEY_SUCCESS: null,
  UPDATE_SUBSCRIPTION_ELECTRONIC_KEY_FAILED: null,

  DELETE_SUBSCRIPTION_ELECTRONIC_KEY_REQUEST: null,
  DELETE_SUBSCRIPTION_ELECTRONIC_KEY_SUCCESS: null,
  DELETE_SUBSCRIPTION_ELECTRONIC_KEY_FAILED: null
})
