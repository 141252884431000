import UploadMediaTypes from '../actionTypes/uploadMedia'
import { uploadMedia as uploadMediaApi } from '../api'
import { bindRequestLogic } from './utils'

const uploadMedia = bindRequestLogic({
  clientFunc: uploadMediaApi.upload,
  type: UploadMediaTypes.UPLOAD_MEDIA_REQUEST,
  successType: UploadMediaTypes.UPLOAD_MEDIA_SUCCESS,
  failType: UploadMediaTypes.UPLOAD_MEDIA_FAILED
})

export default [uploadMedia]
