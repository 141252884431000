import { mapCurrencies, mapCurrencyDetail } from 'store/mapping'
import CurrencyType from '../actionTypes/currency'
import { currency as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const getCurrencies = bindRequestLogic({
  clientFunc: clientApi.getCurrencies,
  type: CurrencyType.GET_CURRENCIES_REQUEST,
  successType: CurrencyType.GET_CURRENCIES_SUCCESS,
  failType: CurrencyType.GET_CURRENCIES_FAILED,
  isQueryParam: true,
  mapResponse: mapCurrencies
})

const addCurrency = bindRequestLogic({
  clientFunc: clientApi.addCurrency,
  type: CurrencyType.ADD_CURRENCY_REQUEST,
  successType: CurrencyType.ADD_CURRENCY_SUCCESS,
  failType: CurrencyType.ADD_CURRENCY_FAILED
})

const getCurrencyDetail = bindRequestLogic({
  clientFunc: clientApi.getCurrencyDetail,
  type: CurrencyType.GET_CURRENCY_DETAIL_REQUEST,
  successType: CurrencyType.GET_CURRENCY_DETAIL_SUCCESS,
  failType: CurrencyType.GET_CURRENCY_DETAIL_FAILED,
  mapResponse: mapCurrencyDetail
})

const updateCurrency = bindRequestLogic({
  clientFunc: clientApi.updateCurrency,
  type: CurrencyType.UPDATE_CURRENCY_REQUEST,
  successType: CurrencyType.UPDATE_CURRENCY_SUCCESS,
  failType: CurrencyType.UPDATE_CURRENCY_FAILED
})

const deleteCurrency = bindRequestLogic({
  clientFunc: clientApi.deleteCurrency,
  type: CurrencyType.DELETE_CURRENCY_REQUEST,
  successType: CurrencyType.DELETE_CURRENCY_SUCCESS,
  failType: CurrencyType.DELETE_CURRENCY_FAILED
})

const upsertCurrencyTranslation = bindRequestLogic({
  clientFunc: clientApi.upsertCurrencyTranslation,
  type: CurrencyType.ADD_CURRENCY_TRANSLATION_REQUEST,
  successType: CurrencyType.ADD_CURRENCY_TRANSLATION_SUCCESS,
  failType: CurrencyType.ADD_CURRENCY_TRANSLATION_FAILED
})

const deleteCurrencyTranslation = bindRequestLogic({
  clientFunc: clientApi.deleteCurrencyTranslation,
  type: CurrencyType.DELETE_CURRENCY_TRANSLATION_REQUEST,
  successType: CurrencyType.DELETE_CURRENCY_TRANSLATION_SUCCESS,
  failType: CurrencyType.DELETE_CURRENCY_TRANSLATION_FAILED
})

const getSystemCurrencies = bindRequestLogic({
  clientFunc: clientApi.getSystemCurrencies,
  type: CurrencyType.GET_SYSTEM_CURRENCIES_REQUEST,
  successType: CurrencyType.GET_SYSTEM_CURRENCIES_SUCCESS,
  failType: CurrencyType.GET_SYSTEM_CURRENCIES_FAILED,
  isQueryParam: true
})

export default [
  getCurrencies,
  addCurrency,
  getCurrencyDetail,
  updateCurrency,
  deleteCurrency,
  upsertCurrencyTranslation,
  deleteCurrencyTranslation,
  getSystemCurrencies
]
