import CompanyPerTypes from 'store/actionTypes/companyPerLink'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  current: {},
  pagination: {}
}

const companyPer = (state = initialData, action) => {
  switch (action.type) {
    case CompanyPerTypes.GET_COMPANY_PERS_LINK_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default companyPer
