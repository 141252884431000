import BaseApi from './base'

class Language extends BaseApi {
  getLanguages = (query) => this.client.get(`admin/languages?${query}`)

  addLanguage = (data) => this.client.post('admin/languages', data)

  updateLanguage = (id, data) =>
    this.client.patch(`admin/languages/${id}`, data)

  deleteLanguage = (id) => this.client.delete(`admin/languages/${id}`)

  getLanguageDetail = (id) => this.client.get(`admin/languages/${id}`)

  addLanguageTranslation = (id, data) =>
    this.client.post(`admin/languages/${id}/translation`, data)

  updateLanguageTranslation = (id, data) =>
    this.client.patch(
      `admin/languages/${id}/translation/${data?.translationId}`,
      {
        name: data?.name
      }
    )

  deleteLanguageTranslation = (id, data) =>
    this.client.delete(
      `admin/languages/${id}/translation/${data?.translationId}`
    )

  getSystemLanguages = (query) =>
    this.client.get(`admin/system/languages?${query}`)
}

export default new Language()
