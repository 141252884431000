import { createLogic } from 'redux-logic'
import UserTypes from 'store/actionTypes/user'
import { refreshUser as refreshUserAPI, user as userAPI } from 'store/api'
import { bindRequestLogic } from './utils'

const login = createLogic({
  type: UserTypes.LOGIN_REQUEST,
  latest: true,

  async process({ action = {} }, dispatch, done) {
    const { payload = {}, meta = {} } = action
    const { onSuccess, onError } = meta
    try {
      const userResponse = await userAPI.loginUser(payload)
      const { accessToken, refreshToken, success } = userResponse?.data
      if (success && accessToken) {
        userAPI.setAccessToken(accessToken)
        userAPI.setRefreshToken(refreshToken)
      }
      dispatch({
        type: UserTypes.LOGIN_SUCCESS,
        payload: { accessToken, refreshToken }
      })
      onSuccess()
    } catch (error) {
      dispatch({
        type: UserTypes.LOGIN_FAILURE,
        payload: error.response,
        meta: {}
      })
      onError(error)
    }
    done()
  }
})

const logout = createLogic({
  type: UserTypes.LOGOUT_REQUEST,
  latest: true,

  async process({ action = {} }, dispatch, done) {
    const { meta = {} } = action
    const { onSuccess, onError } = meta
    try {
      const response = await refreshUserAPI.logoutUser()
      dispatch({
        type: UserTypes.LOGOUT_SUCCESS,
        payload: response
      })
      onSuccess()
    } catch (error) {
      dispatch({
        type: UserTypes.LOGOUT_FAILURE,
        payload: error.message,
        meta: {}
      })
      onError(error)
    }
    done()
  }
})

const getUser = bindRequestLogic({
  clientFunc: userAPI.getUsers,
  type: UserTypes.GET_USERS_REQUEST,
  successType: UserTypes.GET_USERS_SUCCESS,
  failType: UserTypes.GET_USERS_FAILED,
  isQueryParam: true,
  modelName: 'users'
})

const deleteUser = bindRequestLogic({
  clientFunc: userAPI.deleteUser,
  type: UserTypes.DELETE_USER_REQUEST,
  successType: UserTypes.DELETE_USER_SUCCESS,
  failType: UserTypes.DELETE_USER_FAILED
})

const getUserDetail = bindRequestLogic({
  clientFunc: userAPI.getUserDetail,
  type: UserTypes.GET_USER_DETAIL_REQUEST,
  successType: UserTypes.GET_USER_DETAIL_SUCCESS,
  failType: UserTypes.GET_USER_DETAIL_FAILED,
  modelName: 'user'
})

const addUser = bindRequestLogic({
  clientFunc: userAPI.addUser,
  type: UserTypes.ADD_USER_REQUEST,
  successType: UserTypes.ADD_USER_SUCCESS,
  failType: UserTypes.ADD_USER_FAILED
})

const updateUser = bindRequestLogic({
  clientFunc: userAPI.updateUser,
  type: UserTypes.UPDATE_USER_REQUEST,
  successType: UserTypes.UPDATE_USER_SUCCESS,
  failType: UserTypes.UPDATE_USER_FAILED
})

const updateStatusUser = bindRequestLogic({
  clientFunc: userAPI.updateStatusUser,
  type: UserTypes.UPDATE_STATUS_USER_REQUEST,
  successType: UserTypes.UPDATE_STATUS_USER_SUCCESS,
  failType: UserTypes.UPDATE_STATUS_USER_FAILED
})

const updatePasswordUser = bindRequestLogic({
  clientFunc: userAPI.updatePasswordUser,
  type: UserTypes.UPDATE_PASSWORD_USER_REQUEST,
  successType: UserTypes.UPDATE_PASSWORD_USER_SUCCESS,
  failType: UserTypes.UPDATE_PASSWORD_USER_FAILED
})

export default [
  login,
  getUser,
  deleteUser,
  getUserDetail,
  addUser,
  updateUser,
  logout,
  updateStatusUser,
  updatePasswordUser
]
