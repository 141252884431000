import ActivityTypes from '../actionTypes/activity'
import { activity as activityApi } from '../api'
import { bindRequestLogic } from './utils'

const getActivities = bindRequestLogic({
  clientFunc: activityApi.getActivities,
  type: ActivityTypes.GET_ACTIVITIES_REQUEST,
  successType: ActivityTypes.GET_ACTIVITIES_SUCCESS,
  failType: ActivityTypes.GET_ACTIVITIES_FAILED,
  isQueryParam: true,
  modelName: 'sheetActivities'
})

const addActivity = bindRequestLogic({
  clientFunc: activityApi.addActivity,
  type: ActivityTypes.ADD_ACTIVITY_REQUEST,
  successType: ActivityTypes.ADD_ACTIVITY_SUCCESS,
  failType: ActivityTypes.ADD_ACTIVITY_FAILED
})

const deleteActivity = bindRequestLogic({
  clientFunc: activityApi.deleteActivity,
  type: ActivityTypes.DELETE_ACTIVITY_REQUEST,
  successType: ActivityTypes.DELETE_ACTIVITY_SUCCESS,
  failType: ActivityTypes.DELETE_ACTIVITY_FAILED
})

const updateActivity = bindRequestLogic({
  clientFunc: activityApi.updateActivity,
  type: ActivityTypes.UPDATE_ACTIVITY_REQUEST,
  successType: ActivityTypes.UPDATE_ACTIVITY_SUCCESS,
  failType: ActivityTypes.UPDATE_ACTIVITY_FAILED
})

export default [getActivities, addActivity, updateActivity, deleteActivity]
