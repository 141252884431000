import keyMirror from 'keymirror'

export default keyMirror({
  ADD_VEHICLE_PER_REQUEST: null,
  ADD_VEHICLE_PER_SUCCESS: null,
  ADD_VEHICLE_PER_FAILED: null,

  GET_VEHICLE_PER_DETAIL_REQUEST: null,
  GET_VEHICLE_PER_DETAIL_SUCCESS: null,
  GET_VEHICLE_PER_DETAIL_FAILED: null,

  UPDATE_VEHICLE_PER_REQUEST: null,
  UPDATE_VEHICLE_PER_SUCCESS: null,
  UPDATE_VEHICLE_PER_FAILED: null,

  DELETE_VEHICLE_PER_REQUEST: null,
  DELETE_VEHICLE_PER_SUCCESS: null,
  DELETE_VEHICLE_PER_FAILED: null,

  GET_SYSTEM_VEHICLE_PERS_REQUEST: null,
  GET_SYSTEM_VEHICLE_PERS_SUCCESS: null,
  GET_SYSTEM_VEHICLE_PERS_FAILED: null
})
