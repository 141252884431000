import {
  DEFAULT_ELECTRONIC_KEYS_TAB_VEHICLE_FILTER,
  DEFAULT_ELECTRONIC_KEY_FILTER
} from 'constants/common'
import ElectronicKeyTypes from 'store/actionTypes/electronicKey'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  systems: [],
  current: {},
  pagination: {},
  filter: DEFAULT_ELECTRONIC_KEY_FILTER,
  vehicleList: [],
  vehiclePagination: [],
  vehicleFilter: DEFAULT_ELECTRONIC_KEYS_TAB_VEHICLE_FILTER
}

const electronicKey = (state = initialData, action) => {
  switch (action.type) {
    case ElectronicKeyTypes.GET_ELECTRONIC_KEYS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case ElectronicKeyTypes.GET_ELECTRONIC_KEY_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case ElectronicKeyTypes.UPDATE_FILTER_ELECTRONIC_KEY_SUCCESS:
      return { ...state, filter: action.payload }
    case ElectronicKeyTypes.CLEAR_FILTER_ELECTRONIC_KEY_SUCCESS:
      return { ...state, filter: initialData.filter }
    case ElectronicKeyTypes.GET_VEHICLE_ELECTRONIC_KEYS_REQUEST_SUCCESS:
      return {
        ...state,
        vehicleList: action.payload?.items,
        vehiclePagination: action.payload?.meta
      }
    case ElectronicKeyTypes.UPDATE_FILTER_VEHICLE_ELECTRONIC_KEY_SUCCESS:
      return { ...state, vehicleFilter: action.payload }
    case ElectronicKeyTypes.CLEAR_FILTER_VEHICLE_ELECTRONIC_KEY_SUCCESS:
      return { ...state, vehicleFilter: initialData.filter }
    case ElectronicKeyTypes.GET_SYSTEM_ELECTRONIC_KEYS_SUCCESS:
      return { ...state, systems: action.payload?.electronicKey?.items }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default electronicKey
