import VehicleElectronicKeyTypes from 'store/actionTypes/vehicleElectronicKey'
import { vehicle as clientApi } from 'store/api'
import { bindRequestLogic } from './utils'

const getVehicleElectronicKeys = bindRequestLogic({
  clientFunc: clientApi.getVehicleElectronicKeys,
  type: VehicleElectronicKeyTypes.GET_VEHICLE_ELECTRONIC_KEYS_REQUEST,
  successType: VehicleElectronicKeyTypes.GET_VEHICLE_ELECTRONIC_KEYS_SUCCESS,
  failType: VehicleElectronicKeyTypes.GET_VEHICLE_ELECTRONIC_KEYS_FAILED,
  isQueryParam: true,
  modelName: 'vehicleElectronicKeys'
})

const addVehicleElectronicKey = bindRequestLogic({
  clientFunc: clientApi.addVehicleElectronicKey,
  type: VehicleElectronicKeyTypes.ADD_VEHICLE_ELECTRONIC_KEY_REQUEST,
  successType: VehicleElectronicKeyTypes.ADD_VEHICLE_ELECTRONIC_KEY_SUCCESS,
  failType: VehicleElectronicKeyTypes.ADD_VEHICLE_ELECTRONIC_KEY_FAILED
})

const updateVehicleElectronicKey = bindRequestLogic({
  clientFunc: clientApi.updateVehicleElectronicKey,
  type: VehicleElectronicKeyTypes.UPDATE_VEHICLE_ELECTRONIC_KEY_REQUEST,
  successType: VehicleElectronicKeyTypes.UPDATE_VEHICLE_ELECTRONIC_KEY_SUCCESS,
  failType: VehicleElectronicKeyTypes.UPDATE_VEHICLE_ELECTRONIC_KEY_FAILED
})

const deleteVehicleElectronicKey = bindRequestLogic({
  clientFunc: clientApi.deleteVehicleElectronicKey,
  type: VehicleElectronicKeyTypes.DELETE_VEHICLE_ELECTRONIC_KEY_REQUEST,
  successType: VehicleElectronicKeyTypes.DELETE_VEHICLE_ELECTRONIC_KEY_SUCCESS,
  failType: VehicleElectronicKeyTypes.DELETE_VEHICLE_ELECTRONIC_KEY_FAILED
})

export default [
  getVehicleElectronicKeys,
  addVehicleElectronicKey,
  updateVehicleElectronicKey,
  deleteVehicleElectronicKey
]
