import MessageAfterConnectionTypes from '../actionTypes/messageAfterConnection'
import { messageAfterConnection as messageAfterConnectionApi } from '../api'
import { bindRequestLogic } from './utils'

const getMessageAfterConnection = bindRequestLogic({
  clientFunc: messageAfterConnectionApi.getMessageAfterConnections,
  type: MessageAfterConnectionTypes.GET_MESSAGE_AFTER_CONNECTIONS_REQUEST,
  successType:
    MessageAfterConnectionTypes.GET_MESSAGE_AFTER_CONNECTIONS_SUCCESS,
  failType: MessageAfterConnectionTypes.GET_MESSAGE_AFTER_CONNECTIONS_FAILED,
  isQueryParam: true,
  modelName: 'messageAfterConnection'
})

const getMessageAfterConnectionDetail = bindRequestLogic({
  clientFunc: messageAfterConnectionApi.getMessageAfterConnection,
  type: MessageAfterConnectionTypes.GET_MESSAGE_AFTER_CONNECTION_DETAIL_REQUEST,
  successType:
    MessageAfterConnectionTypes.GET_MESSAGE_AFTER_CONNECTION_DETAIL_SUCCESS,
  failType:
    MessageAfterConnectionTypes.GET_MESSAGE_AFTER_CONNECTION_DETAIL_FAILED
})

const addMessageAfterConnection = bindRequestLogic({
  clientFunc: messageAfterConnectionApi.addMessageAfterConnection,
  type: MessageAfterConnectionTypes.ADD_MESSAGE_AFTER_CONNECTION_REQUEST,
  successType: MessageAfterConnectionTypes.ADD_MESSAGE_AFTER_CONNECTION_SUCCESS,
  failType: MessageAfterConnectionTypes.ADD_MESSAGE_AFTER_CONNECTION_FAILED
})

const deleteMessageAfterConnection = bindRequestLogic({
  clientFunc: messageAfterConnectionApi.deleteMessageAfterConnection,
  type: MessageAfterConnectionTypes.DELETE_MESSAGE_AFTER_CONNECTION_REQUEST,
  successType:
    MessageAfterConnectionTypes.DELETE_MESSAGE_AFTER_CONNECTION_SUCCESS,
  failType: MessageAfterConnectionTypes.DELETE_MESSAGE_AFTER_CONNECTION_FAILED
})

const updateMessageAfterConnection = bindRequestLogic({
  clientFunc: messageAfterConnectionApi.updateMessageAfterConnection,
  type: MessageAfterConnectionTypes.UPDATE_MESSAGE_AFTER_CONNECTION_REQUEST,
  successType:
    MessageAfterConnectionTypes.UPDATE_MESSAGE_AFTER_CONNECTION_SUCCESS,
  failType: MessageAfterConnectionTypes.UPDATE_MESSAGE_AFTER_CONNECTION_FAILED
})

const translateMessageAfterConnection = bindRequestLogic({
  clientFunc: messageAfterConnectionApi.msgAfterConnectionTranslation,
  type: MessageAfterConnectionTypes.TRANSLATE_MESSAGE_AFTER_CONNECTION_REQUEST,
  successType:
    MessageAfterConnectionTypes.TRANSLATE_MESSAGE_AFTER_CONNECTION_SUCCESS,
  failType:
    MessageAfterConnectionTypes.TRANSLATE_MESSAGE_AFTER_CONNECTION_FAILED
})

const deleteMessageAfterConnectionTranslation = bindRequestLogic({
  clientFunc: messageAfterConnectionApi.deleteMsgAfterConnectionTranslation,
  type: MessageAfterConnectionTypes.DELETE_MESSAGE_AFTER_CONNECTION_TRANSLATION_REQUEST,
  successType:
    MessageAfterConnectionTypes.DELETE_MESSAGE_AFTER_CONNECTION_TRANSLATION_SUCCESS,
  failType:
    MessageAfterConnectionTypes.DELETE_MESSAGE_AFTER_CONNECTION_TRANSLATION_FAILED
})

export default [
  getMessageAfterConnection,
  getMessageAfterConnectionDetail,
  translateMessageAfterConnection,
  addMessageAfterConnection,
  updateMessageAfterConnection,
  deleteMessageAfterConnection,
  deleteMessageAfterConnectionTranslation
]
