import keyMirror from 'keymirror'

export default keyMirror({
  GET_COMPANY_CATEGORIES_REQUEST: null,
  GET_COMPANY_CATEGORIES_SUCCESS: null,
  GET_COMPANY_CATEGORIES_FAILED: null,

  GET_COMPANY_CATEGORY_REQUEST: null,
  GET_COMPANY_CATEGORY_SUCCESS: null,
  GET_COMPANY_CATEGORY_FAILED: null,

  UPDATE_COMPANY_CATEGORY_REQUEST: null,
  UPDATE_COMPANY_CATEGORY_SUCCESS: null,
  UPDATE_COMPANY_CATEGORY_FAILED: null,

  ADD_COMPANY_CATEGORY_TRANSLATION_REQUEST: null,
  ADD_COMPANY_CATEGORY_TRANSLATION_SUCCESS: null,
  ADD_COMPANY_CATEGORY_TRANSLATION_FAILED: null,

  DELETE_COMPANY_CATEGORY_TRANSLATION_REQUEST: null,
  DELETE_COMPANY_CATEGORY_TRANSLATION_SUCCESS: null,
  DELETE_COMPANY_CATEGORY_TRANSLATION_FAILED: null,

  UPDATE_COMPANY_CATEGORY_TRANSLATION_REQUEST: null,
  UPDATE_COMPANY_CATEGORY_TRANSLATION_SUCCESS: null,
  UPDATE_COMPANY_CATEGORY_TRANSLATION_FAILED: null,

  GET_SYSTEM_COMPANY_CATEGORIES_REQUEST: null,
  GET_SYSTEM_COMPANY_CATEGORIES_SUCCESS: null,
  GET_SYSTEM_COMPANY_CATEGORIES_FAILED: null
})
