import BaseApi from './base'

class Sharing extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  getSharings = (query) =>
    this.client.get(`vehicle-sharing/extensions?${query}`)
}

export default new Sharing()
