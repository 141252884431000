import { DEFAULT_KEY_USAGE_SYSTEM_LOG_FILTER } from 'constants/common'
import KeyUsageSystemLogTypes from 'store/actionTypes/keyUsageSystemLog'
import UserTypes from 'store/actionTypes/user'

const initialData = Object.freeze({
  list: [],
  current: {},
  pagination: {},
  filter: DEFAULT_KEY_USAGE_SYSTEM_LOG_FILTER
})

const keyUsageSystemLog = (state = initialData, action) => {
  switch (action.type) {
    case KeyUsageSystemLogTypes.GET_KEY_USAGE_SYSTEM_LOGS_SUCCESS:
      return {
        ...state,
        list: action?.payload?.keyUsageSystemLogs?.items,
        pagination: action.payload?.keyUsageSystemLogs?.meta
      }
    case KeyUsageSystemLogTypes.UPDATE_FILTER_KEY_USAGE_SYSTEM_LOG_SUCCESS:
      return { ...state, filter: action?.payload?.value }
    case KeyUsageSystemLogTypes.CLEAR_FILTER_KEY_USAGE_SYSTEM_LOG_SUCCESS:
      return { ...state, filter: initialData.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default keyUsageSystemLog
