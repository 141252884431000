import { combineReducers } from 'redux'
import Types from 'store/actionTypes/companyCategory'
import { createHandleRequest } from './helpers'

const getCompanyCategories = createHandleRequest(
  Types.GET_COMPANY_CATEGORIES_REQUEST,
  Types.GET_COMPANY_CATEGORIES_SUCCESS,
  Types.GET_COMPANY_CATEGORIES_FAILED
)

export default combineReducers({
  getCompanyCategories
})
