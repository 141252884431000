import RequestStatus from 'constants/requestStatus'

export const initialRequestState = () => ({
  status: RequestStatus.NOT_STARTED,
  error: null
})

export const createHandleRequest =
  (REQUEST, SUCCESS, FAILURE) =>
  (state = initialRequestState(), action) => {
    // eslint-disable-next-line react/destructuring-assignment
    switch (action.type) {
      case REQUEST:
        return {
          ...state,
          status: RequestStatus.STARTED
        }
      case SUCCESS:
        return {
          ...state,
          status: RequestStatus.SUCCESS,
          error: null
        }
      case FAILURE: {
        return {
          ...state,
          status: RequestStatus.FAILURE,
          // eslint-disable-next-line react/destructuring-assignment
          error: action.payload
        }
      }
      default:
        return state
    }
  }
