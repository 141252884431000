import MessageTypes from '../actionTypes/message'
import {
  message as messageApi,
  messageImport as messageImportApi
} from '../api'
import { bindRequestLogic } from './utils'
import { bindFilterLogic } from './filter'

const getMessages = bindRequestLogic({
  clientFunc: messageApi.getMessages,
  type: MessageTypes.GET_MESSAGES_REQUEST,
  successType: MessageTypes.GET_MESSAGES_SUCCESS,
  failType: MessageTypes.GET_MESSAGES_FAILED,
  isQueryParam: true,
  modelName: 'messages'
})

const createMessage = bindRequestLogic({
  clientFunc: messageApi.createMessage,
  type: MessageTypes.CREATE_MESSAGE_REQUEST,
  successType: MessageTypes.CREATE_MESSAGE_SUCCESS,
  failType: MessageTypes.CREATE_MESSAGE_FAILED
})

const deleteMessage = bindRequestLogic({
  clientFunc: messageApi.deleteMessage,
  type: MessageTypes.DELETE_MESSAGE_REQUEST,
  successType: MessageTypes.DELETE_MESSAGE_SUCCESS,
  failType: MessageTypes.DELETE_MESSAGE_FAILED
})

const updateMessages = bindRequestLogic({
  clientFunc: messageApi.updateMessages,
  type: MessageTypes.UPDATE_MESSAGES_REQUEST,
  successType: MessageTypes.UPDATE_MESSAGES_SUCCESS,
  failType: MessageTypes.UPDATE_MESSAGES_FAILED
})

const importMessages = bindRequestLogic({
  clientFunc: messageImportApi.importMessages,
  type: MessageTypes.IMPORT_MESSAGES_REQUEST,
  successType: MessageTypes.IMPORT_MESSAGES_SUCCESS,
  failType: MessageTypes.IMPORT_MESSAGES_FAILED
})

const updateFilterMessage = bindFilterLogic({
  type: MessageTypes.UPDATE_FILTER_MESSAGE_REQUEST,
  successType: MessageTypes.UPDATE_FILTER_MESSAGE_SUCCESS
})

const clearFilterMessage = bindFilterLogic({
  type: MessageTypes.CLEAR_FILTER_MESSAGE_REQUEST,
  successType: MessageTypes.CLEAR_FILTER_MESSAGE_SUCCESS
})

const getSystemMessages = bindRequestLogic({
  clientFunc: messageApi.getSystemMessages,
  type: MessageTypes.GET_SYSTEM_MESSAGES_REQUEST,
  successType: MessageTypes.GET_SYSTEM_MESSAGES_SUCCESS,
  failType: MessageTypes.GET_SYSTEM_MESSAGES_FAILED,
  isQueryParam: true
})

export default [
  getMessages,
  createMessage,
  deleteMessage,
  updateMessages,
  importMessages,
  updateFilterMessage,
  clearFilterMessage,
  getSystemMessages
]
