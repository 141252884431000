import BaseApi from './base'

class SubscriptionElectronicKey extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  getSubscriptionElectronicKeys = (id, query = '') =>
    this.client.get(`admin/subscriptions/${id}/electronic-keys?${query}`)

  addSubscriptionElectronicKey = (id, data) =>
    this.client.post(`admin/subscriptions/${id}/electronic-keys`, data)

  updateSubscriptionElectronicKey = (id, data) =>
    this.client.patch(`admin/subscriptions/electronic-keys/${id}`, data)

  deleteSubscriptionElectronicKey = (id) =>
    this.client.delete(`admin/subscriptions/electronic-keys/${id}`)
}

export default new SubscriptionElectronicKey()
