import NotificationTemplateTechTypes from '../actionTypes/notificationTemplateTech'
import { notificationTemplateTech as notificationTemplateTechApi } from '../api'
import { bindRequestLogic } from './utils'

const getNotificationTemplateTechs = bindRequestLogic({
  clientFunc: notificationTemplateTechApi.getNotificationTemplateTechs,
  type: NotificationTemplateTechTypes.GET_NOTIFICATION_TEMPLATE_TECHS_REQUEST,
  successType:
    NotificationTemplateTechTypes.GET_NOTIFICATION_TEMPLATE_TECHS_SUCCESS,
  failType:
    NotificationTemplateTechTypes.GET_NOTIFICATION_TEMPLATE_TECHS_FAILED,
  isQueryParam: true
})

const getNotificationTemplateTechsByNotificationTemplateId = bindRequestLogic({
  clientFunc:
    notificationTemplateTechApi.getNotificationTemplateTechsByNotificationId,
  type: NotificationTemplateTechTypes.GET_NOTIFICATION_TEMPLATE_TECH_BY_NOTIFICATION_TEMPLATE_ID_REQUEST,
  successType:
    NotificationTemplateTechTypes.GET_NOTIFICATION_TEMPLATE_TECH_BY_NOTIFICATION_TEMPLATE_ID_SUCCESS,
  failType:
    NotificationTemplateTechTypes.GET_NOTIFICATION_TEMPLATE_TECH_BY_NOTIFICATION_TEMPLATE_ID_FAILED,
  isQueryParam: true
})

const addNotificationTemplateTech = bindRequestLogic({
  clientFunc: notificationTemplateTechApi.addNotificationTemplateTech,
  type: NotificationTemplateTechTypes.ADD_NOTIFICATION_TEMPLATE_TECH_REQUEST,
  successType:
    NotificationTemplateTechTypes.ADD_NOTIFICATION_TEMPLATE_TECH_SUCCESS,
  failType: NotificationTemplateTechTypes.ADD_NOTIFICATION_TEMPLATE_TECH_FAILED
})

const updateNotificationTemplateTech = bindRequestLogic({
  clientFunc: notificationTemplateTechApi.updateNotificationTemplateTech,
  type: NotificationTemplateTechTypes.UPDATE_NOTIFICATION_TEMPLATE_TECH_REQUEST,
  successType:
    NotificationTemplateTechTypes.UPDATE_NOTIFICATION_TEMPLATE_TECH_SUCCESS,
  failType:
    NotificationTemplateTechTypes.UPDATE_NOTIFICATION_TEMPLATE_TECH_FAILED
})

const deleteNotificationTemplateTech = bindRequestLogic({
  clientFunc: notificationTemplateTechApi.deleteNotificationTemplateTech,
  type: NotificationTemplateTechTypes.DELETE_NOTIFICATION_TEMPLATE_TECH_REQUEST,
  successType:
    NotificationTemplateTechTypes.DELETE_NOTIFICATION_TEMPLATE_TECH_SUCCESS,
  failType:
    NotificationTemplateTechTypes.DELETE_NOTIFICATION_TEMPLATE_TECH_FAILED
})

export default [
  getNotificationTemplateTechs,
  addNotificationTemplateTech,
  updateNotificationTemplateTech,
  deleteNotificationTemplateTech,
  getNotificationTemplateTechsByNotificationTemplateId
]
