import { DEFAULT_SHOWCASE_STORY_FILTER } from 'constants/common'
import ShowcaseContactLogsTypes from 'store/actionTypes/showcaseContactLogs'
import UserTypes from 'store/actionTypes/user'

const initShowcaseContactLog = {
  list: [],
  current: {},
  pagination: {},
  filter: DEFAULT_SHOWCASE_STORY_FILTER,
  components: {
    list: [],
    pagination: {}
  },
  exportData: {}
}

const showcaseContactLog = (state = initShowcaseContactLog, action) => {
  const data = action?.payload || []
  switch (action.type) {
    case ShowcaseContactLogsTypes.GET_SHOWCASE_LOGS_SUCCESS:
      return {
        ...state,
        list: data?.items,
        pagination: data?.meta
      }
    case ShowcaseContactLogsTypes.UPDATE_FILTER_SHOWCASE_LOG_SUCCESS:
      return { ...state, filter: data }
    case ShowcaseContactLogsTypes.CLEAR_FILTER_SHOWCASE_LOG_SUCCESS:
      return { ...state, filter: initShowcaseContactLog.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initShowcaseContactLog
    case ShowcaseContactLogsTypes.EXPORT_SHOWCASE_CONTACT_LOG_SUCCESS:
      return {
        ...state,
        exportData: { data, responseHeaders: action.responseHeaders }
      }
    default:
      return state
  }
}

export default showcaseContactLog
