import keyMirror from 'keymirror'

export default keyMirror({
  GET_ROLES_REQUEST: null,
  GET_ROLES_SUCCESS: null,
  GET_ROLES_FAILED: null,

  ADD_ROLE_REQUEST: null,
  ADD_ROLE_SUCCESS: null,
  ADD_ROLE_FAILED: null,

  GET_ROLE_DETAIL_REQUEST: null,
  GET_ROLE_DETAIL_SUCCESS: null,
  GET_ROLE_DETAIL_FAILED: null,

  UPDATE_ROLE_REQUEST: null,
  UPDATE_ROLE_SUCCESS: null,
  UPDATE_ROLE_FAILED: null,

  DELETE_ROLE_REQUEST: null,
  DELETE_ROLE_SUCCESS: null,
  DELETE_ROLE_FAILED: null,

  DELETE_ROLE_TRANSLATION_REQUEST: null,
  DELETE_ROLE_TRANSLATION_SUCCESS: null,
  DELETE_ROLE_TRANSLATION_FAILED: null,

  GET_GROUPS_REQUEST: null,
  GET_GROUPS_SUCCESS: null,
  GET_GROUPS_FAILED: null,

  GET_PERMISSIONS_REQUEST: null,
  GET_PERMISSIONS_SUCCESS: null,
  GET_PERMISSIONS_FAILED: null,

  DELETE_MULTIPLE_ROLE_REQUEST: null,
  DELETE_MULTIPLE_ROLE_SUCCESS: null,
  DELETE_MULTIPLE_ROLE_FAILED: null,

  ADD_ROLE_TRANSLATION_REQUEST: null,
  ADD_ROLE_TRANSLATION_SUCCESS: null,
  ADD_ROLE_TRANSLATION_FAILED: null,

  UPDATE_ROLE_TRANSLATION_REQUEST: null,
  UPDATE_ROLE_TRANSLATION_SUCCESS: null,
  UPDATE_ROLE_TRANSLATION_FAILED: null,

  UPDATE_FILTER_ROLE_SUCCESS: null,
  CLEAR_FILTER_ROLE_SUCCESS: null,

  GET_SYSTEM_ROLES_REQUEST: null,
  GET_SYSTEM_ROLES_SUCCESS: null,
  GET_SYSTEM_ROLES_FAILED: null,

  GET_ROLES_BY_ADMIN_PORTAL_REQUEST: null,
  GET_ROLES_BY_ADMIN_PORTAL_SUCCESS: null,
  GET_ROLES_BY_ADMIN_PORTAL_FAILED: null,

  ADD_PORTAL_ROLE_REQUEST: null,
  ADD_PORTAL_ROLE_SUCCESS: null,
  ADD_PORTAL_ROLE_FAILED: null
})
