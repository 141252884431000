import IntlMessages from 'helpers/IntlMessages'

export const CIXI_MODULES = {
  backOffice: 'cixi_center',
  fleetManagerPortal: 'fleet_manager_portal',
  mobileApp: 'pilot_mobile_app',
  proCenter: 'pro_center',
  proCenterPortal: 'pro_center_portal',
  proCenterMember: 'pro_center_member'
}

const PARENT_CATEGORY_PERMISSION_CODE = {
  database: 'database',
  dataLogs: 'data_logs',
  activePilotApp: 'active_pilot_app',
  apis: 'apis',
  fleetManagerPlatform: 'fleet_manager_platform',
  adminAndSetting: 'admin_and_setting',
  showcaseWebsite: 'showcase_website',
  simulator: 'simulator',
  cixiCenter: 'cixi_center',
  logs: 'logs',
  additionalPermission: 'additional_permission',
  proCenter: 'pro_center'
}

export const PARENT_CATEGORY_PERMISSION_ORDER = [
  [PARENT_CATEGORY_PERMISSION_CODE.database],
  [PARENT_CATEGORY_PERMISSION_CODE.dataLogs],
  [PARENT_CATEGORY_PERMISSION_CODE.activePilotApp],
  [PARENT_CATEGORY_PERMISSION_CODE.apis],
  [PARENT_CATEGORY_PERMISSION_CODE.fleetManagerPlatform],
  [PARENT_CATEGORY_PERMISSION_CODE.showcaseWebsite],
  [PARENT_CATEGORY_PERMISSION_CODE.simulator],
  [PARENT_CATEGORY_PERMISSION_CODE.cixiCenter],
  [PARENT_CATEGORY_PERMISSION_CODE.logs],
  [PARENT_CATEGORY_PERMISSION_CODE.additionalPermission],
  [PARENT_CATEGORY_PERMISSION_CODE.proCenter]
]

export const PARENT_CATEGORY_PERMISSION_LABEL = Object.freeze({
  [PARENT_CATEGORY_PERMISSION_CODE.database]: (
    <IntlMessages id='roles.parent_category_permission_database' />
  ),
  [PARENT_CATEGORY_PERMISSION_CODE.dataLogs]: (
    <IntlMessages id='roles.parent_category_permission_data_logs' />
  ),
  [PARENT_CATEGORY_PERMISSION_CODE.activePilotApp]: (
    <IntlMessages id='roles.parent_category_permission_active_pilot_app' />
  ),
  [PARENT_CATEGORY_PERMISSION_CODE.apis]: (
    <IntlMessages id='roles.parent_category_permission_data_apis' />
  ),
  [PARENT_CATEGORY_PERMISSION_CODE.fleetManagerPlatform]: (
    <IntlMessages id='roles.parent_category_permission_fleet_management_platform' />
  ),
  [PARENT_CATEGORY_PERMISSION_CODE.showcaseWebsite]: (
    <IntlMessages id='roles.parent_category_permission_showcase_website' />
  ),
  [PARENT_CATEGORY_PERMISSION_CODE.simulator]: (
    <IntlMessages id='roles.parent_category_permission_simulator' />
  ),
  [PARENT_CATEGORY_PERMISSION_CODE.cixiCenter]: (
    <IntlMessages id='roles.parent_category_permission_cixi_center' />
  ),
  [PARENT_CATEGORY_PERMISSION_CODE.logs]: (
    <IntlMessages id='roles.parent_category_permission_logs' />
  ),
  [PARENT_CATEGORY_PERMISSION_CODE.additionalPermission]: (
    <IntlMessages id='roles.parent_category_permission_additional_permission' />
  ),
  [PARENT_CATEGORY_PERMISSION_CODE.proCenter]: (
    <IntlMessages id='roles.parent_category_permission_pro_center' />
  )
})

/**
 * The permission code prefix is the role for each category
 * For example, example we have a permission code:
 * ViewList_PERSVehicle -> role prefix is 'ViewList'
 * Manage_Dashboard ->  role prefix is 'Manage'
 */
export const ROLE_PERMISSION_CODE_PREFIX = {
  manage: 'Manage',
  viewList: 'ViewList',
  viewDetail: 'View',
  viewSheet: 'Sheet',
  use: 'Use',
  send: 'Send',
  menu: 'Menu',
  cancel: 'Cancel',
  module: 'Module',
  anonymize: 'Anonymize',
  run: 'Run'
}

/**
 * The permission code suffix is the category
 * For example, we have a permissions code:
 * ViewList_PERSVehicle -> category is 'PERSVehicle'
 * Manage_PERS ->  category is 'PERS'
 * Sheet_PERSStatus ->  category is 'PERSStatus'
 */
export const CATEGORY_PERMISSION_CODE_SUFFIX = {
  // Dashboard
  dashboard: 'Dashboard',

  // Data Business/PERS
  pers: 'PERS',
  persStatus: 'PERSStatus',
  persPublicKeys: 'PERSPublicKeys',
  persVehicle: 'PERSVehicle',
  persHistoryVehicle: 'PERSHistoryVehicle',
  usageSystemLogs: 'UsageSystemLogs',
  persCurrentData: 'PERSCurrentData',
  whatTodoToPers: 'WhattodoToPers',
  persJourneyLogs: 'PERSJourneyLogs',
  persActivity: 'PERSActivity',
  softwarePers: 'SoftwarePERS',
  modelPers: 'ModelPERS',
  persAlert: 'PERSAlert',
  persVehicleTrips: 'PERSVehicleTrips',
  persCommSystemLogs: 'PERSCommSystemLogs',

  // Data Business/PERS Clients
  usersPortal: 'UsersPortal',
  userPortalStatus: 'UserPortalStatus',
  userPortalGDPR: 'UserPortalGDPR',
  userPortalAdditionalData: 'UserPortalAdditionalData',
  userPortalSubscriptions: 'UserPortalSubscriptions',
  userAppSubscriptionsAllowedPilot: 'UsersAppSubscriptionsAllowedPilot',
  userPortalVehicles: 'UserPortalVehicles',
  usersPortalVehiclesSharingAS: 'UserPortalVehiclesSharingAS',
  userPortalElectronicKeysSharingAS: 'UserPortalElectronicKeysSharingAS',
  userPortalVehiclesSharingAB: 'UserPortalVehiclesSharingAB',
  userPortalElectronicKeysSharingAB: 'UserPortalElectronicKeysSharingAB',
  userPortalPersCalibration: 'UserPortalPERSCalibration',
  userAppPortalRoles: 'UserAppPortalRoles',
  userAppNotifications: 'UserAppNotifications',
  userPortalCommSystemLogs: 'UserPortalCommSystemLogs',
  userPortalDirectVehicles: 'UserPortalDirectVehicles',
  userPortalDirectVehicle: 'UserPortalDirectVehicle',
  userPortalActivity: 'UserPortalActivity',
  companies: 'Companies',
  companyStatus: 'CompanyStatus',
  childCompanies: 'ChildCompanies',
  companyAdditionalData: 'CompanyAdditionalData',
  companyMembers: 'CompanyMembers',
  companyMembersBo: 'CompanyMembers_BO',
  companyPilots: 'CompanyPilots',
  companySubscriptions: 'CompanySubscriptions',
  companyVehicles: 'CompanyVehicles',
  companyVehiclesSharing: 'CompanyVehiclesSharing',
  companyElectronicKeys: 'CompanyElectronicKeys',
  companyElectronicKeysSharing: 'CompanyElectronicKeysSharing',
  companyRoles: 'CompanyRoles',
  companyRolesConfiguration: 'CompanyRolesConfiguration',
  companyPersLinks: 'CompanyPersLinks',
  companyActivity: 'CompanyActivity',
  subscriptions: 'Subscriptions',
  subscriptionStatus: 'SubscriptionStatus',
  subscriptionAllowedPilots: 'SubscriptionAllowedPilots',
  subscriptionVehicle: 'SubscriptionVehicle',
  subscriptionActivity: 'SubscriptionActivity',
  companyMailTemplates: 'CompanyMailTemplates',

  // Data Business/Vehicles
  vehicles: 'Vehicles',
  vehicleStatus: 'VehicleStatus',
  vehicleElectronicKeys: 'VehicleElectronicKeys',
  vehicleUsers: 'VehicleUsers',
  vehiclePers: 'VehiclePERS',
  vehicleSharings: 'VehicleSharings',
  vehicleSubscriptions: 'VehicleSubscriptions',
  vehicleCurrentData: 'VehicleCurrentData',
  vehicleDirectVehicles: 'VehicleDirectVehicles',
  vehicleActivity: 'VehicleActivity',
  electronicKeys: 'ElectronicKeys',
  electronicKeyStatus: 'ElectronicKeyStatus',
  electronicKeyVehicles: 'ElectronicKeyVehicles',
  electronicKeysSharing: 'ElectronicKeySharing',
  electronicKeyActivity: 'electronicKeyActivity',
  electronicKeyModels: 'ElectronicKeyModels',
  vehicleModels: 'VehicleModels',
  vehicleBrands: 'VehicleBrands',
  vehicleBrandMessage: 'VehicleBrandMessage',
  vehicleBrandCompanyLinks: 'VehicleBrandCompanyLinks',
  vehicleTypes: 'VehicleTypes',

  // Data Business/Sharings
  sharings: 'Sharings',

  // Data LOGS/Connectivity
  connectivity: 'Connectivity',

  // Active Pilot App/Content Management
  apaInterfaceLabels: 'APAInterfaceLabels',
  apaInterfaceTexts: 'APAInterfaceTexts',
  apaInterfaceFormattedTexts: 'APAInterfaceFormattedTexts',
  apaMessages: 'APAMessages',

  // Active Pilot App/Configuration & Settings
  apaMailTemplates: 'APAMailTemplates',
  appVersioning: 'AppVersioning',
  messagesAfterConnection: 'MessagesAfterConnection',

  // Active Pilot App/Documentation
  apaDocumentation: 'APADocumentation',

  // APIs/CIXI
  apisCIXICenter: 'APIsCIXICenter',
  apisCIXIBusiness: 'APIsCIXIBusiness',
  apisCIXIVehicleLogs: 'APIsCIXIVehicleLogs',
  apisCIXIDocumentation: 'APIsCIXIDocumentation',

  // APIs/Partners
  apisPartnersDocumentation: 'APIsPartnersDocumentation',

  // Fleet Management Platform/Content Management
  fmpInterfaceLabels: 'FMPInterfaceLabels',
  fmpInterfaceTexts: 'FMPInterfaceTexts',
  fmpInterfaceFormattedTexts: 'FMPInterfaceFormattedTexts',
  fmpMessages: 'FMPMessages',

  // Fleet Management Platform/Configuration & Settings
  fmpMailTemplates: 'FMPMailTemplates',

  // Fleet Management Platform/Documentation
  fmpDocumentation: 'FMPDocumentation',

  // Pro center/Content Management
  proInterfaceLabels: 'PROInterfaceLabels',
  proInterfaceTexts: 'PROInterfaceTexts',
  proInterfaceFormattedTexts: 'PROInterfaceFormattedTexts',
  proMessages: 'PROMessages',

  // Pro center/Configuration & Settings
  proMailTemplates: 'PROMailTemplates',
  proCenterAdmin: 'PROCenterAdmin',

  // Pro center/Documentation
  proDocumentation: 'PRODocumentation',

  // Showcase Website/Visitor's Contacts
  visitorContacts: 'VisitorContacts',
  archivedVisitorContacts: 'Archived_VisitorContacts',

  // Showcase Website/Content Management
  scwSections: 'SCWSections',
  scwSectionComponent: 'SCWSectionComponent',
  scwStories: 'SCWStories',
  scwPrivacyPolicy: 'SCWPrivacyPolicy',
  faq: 'FAQ',
  scwMessages: 'SCWMessages',

  // Showcase Website/Configuration & Settings
  scwMailTemplates: 'SCWMailTemplates',

  // Showcase Website/Documentation
  scwDocumentation: 'SCWDocumentation',

  // Simulator/Simulator Vehicle APIs
  simulatorVehicleAPIs: 'SimulatorVehicleAPIs',

  // CIXI Center/Content Management
  interfaceLabels: 'InterfaceLabels',
  interfaceTexts: 'InterfaceTexts',
  interfaceFormattedTexts: 'InterfaceFormattedTexts',
  messages: 'Messages',
  adminDocumentation: 'AdminDocumentation',
  keyUsageSystemLogs: 'KeyUsageSystemLogs',

  // CIXI Center/General Referentials
  languages: 'Languages',
  countries: 'Countries',
  timezones: 'Timezones',
  currencies: 'Currencies',

  // CIXI Center/Business Referentials
  tagContactTypes: 'TagContactTypes',
  pilotTypes: 'PilotTypes',
  cixiReferents: 'CIXIReferents',
  companyCategories: 'CompanyCategories',

  // CIXI Center/Notifications
  platformPageTech: 'PlatformPageTech',
  notificationTemplates: 'NotificationTemplates',
  notificationRequests: 'NotificationRequests',
  notificationSimulator: 'NotificationSimulator',
  configNotificationSystem: 'ConfigNotificationSystem',

  // CIXI Center/Alerts
  groupAlerts: 'GroupAlerts',
  topicAlerts: 'TopicAlerts',
  levelAlerts: 'LevelAlerts',
  alerts: 'Alerts',
  alertConfigurations: 'AlertConfigurations',

  // CIXI Center/Configuration & Settings
  usersBO: 'UsersBO',
  roles: 'Roles',
  rolesConfiguration: 'RolesConfiguration',
  platformTech: 'PlatformTech',
  businessSettings: 'BusinessSettings',
  generalSettings: 'GeneralSettings',
  regionalSettings: 'RegionalSettings',
  datetimeFormats: 'DatetimeFormats',
  conditionalFeatures: 'ConditionalFeatures',
  mailTemplates: 'MailTemplates',
  dataMapping: 'DataMapping',

  // CIXI Center/Documentation
  documentations: 'Documentations',

  // CIXI Center/Topics
  topics: 'Topics',

  // Logs
  logMails: 'LogMails',
  logRoutines: 'LogRoutines',
  persPackage: 'PersPackage',
  odooSynchronization: 'OdooSynchronization',

  // Additional Permissions
  cixiCenterDescription: 'CixiCenter_Description',
  odooSettings: 'OdooSettings'
}

export const OTHER_PLATFORM_PERMISSIONS = {
  // Pilot Platform
  switchToPilotApp: 'SwitchTo_PilotApp',
  anotherUserPilotApp: 'AnotherUser_PilotApp',
  connectPilotPlatform: 'ConnectPilotPlatform',

  // Fleet Manager Portal
  switchToCFM: 'SwitchTo_CFM',
  anotherUserCFM: 'AnotherUser_CFM',
  connectCFMPlatform: 'ConnectCFMPlatform',

  // Fleet Manager Member/Company Profile
  companyProfile: 'CompanyProfile',
  companyProfileInformation: 'CompanyProfile_Information',
  companyProfileMembers: 'CompanyProfile_Members',
  companyMembers: 'CompanyMembers',
  companyProfileChildCompanies: 'CompanyProfile_ChildCompanies',
  companyProfileGeneralSettings: 'CompanyProfile_GeneralSettings',

  // Fleet Manager Member/Fleets & Subscriptions
  companyGeneralSettings: 'Company_GeneralSettings',
  fleetsAndSubscriptions: 'FleetsAndSubscriptions',
  fleetsAndSubscriptionsFleets: 'FleetsAndSubscriptions_Fleets',
  fleetsAndSubscriptionsOrders: 'FleetsAndSubscriptions_Orders',

  // Fleet Manager Member/Fleet Management
  fleetManagement: 'FleetManagement',
  fleetManagementTeams: 'FleetManagement_Teams',
  fleetManagementVehicles: 'FleetManagement_Vehicles',

  // Fleet Manager Member/Sharing Management
  sharingManagement: 'SharingManagement',
  sharingManagementCurrentVehiclesUse: 'SharingManagement_CurrentVehiclesUse',
  sharingManagementUpcomingVehiclesUse: 'SharingManagement_UpcomingVehiclesUse',
  sharingManagementPastVehiclesUsed: 'SharingManagement_PastVehiclesUsed',

  // Fleet Manager Member/Statistics
  statistics: 'Statistics',
  statisticsUsage: 'Statistics_Usage',
  statisticsFinancial: 'Statistics_Financial',
  statisticsUsers: 'Statistics_Users',
  statisticsVehicles: 'Statistics_Vehicles',

  // Fleet Manager Member/Help
  help: 'Help',
  helpFAQ: 'Help_FAQ',
  helpGlossary: 'Help_Glossary',
  helpDocuments: 'Help_Documents',

  // Fleet Manager Member/Vehicle User
  allVehicles: 'AllVehicles'
}

export const CATEGORY_PERMISSION_LABEL_MAPPING = {
  [CATEGORY_PERMISSION_CODE_SUFFIX.dashboard]: 'Dashboard',

  // Data Business/PERS
  [CATEGORY_PERMISSION_CODE_SUFFIX.pers]: 'PERS',
  [CATEGORY_PERMISSION_CODE_SUFFIX.persStatus]: 'PERS',
  [CATEGORY_PERMISSION_CODE_SUFFIX.persHistoryVehicle]: 'PERS',
  [CATEGORY_PERMISSION_CODE_SUFFIX.persPublicKeys]: 'PERS',
  [CATEGORY_PERMISSION_CODE_SUFFIX.persVehicle]: 'PERS',
  [CATEGORY_PERMISSION_CODE_SUFFIX.usageSystemLogs]: 'PERS',
  [CATEGORY_PERMISSION_CODE_SUFFIX.persJourneyLogs]: 'PERS',
  [CATEGORY_PERMISSION_CODE_SUFFIX.persCurrentData]: 'PERS',
  [CATEGORY_PERMISSION_CODE_SUFFIX.whatTodoToPers]: 'PERS',
  [CATEGORY_PERMISSION_CODE_SUFFIX.persActivity]: 'PERS',
  [CATEGORY_PERMISSION_CODE_SUFFIX.softwarePers]: 'PERS',
  [CATEGORY_PERMISSION_CODE_SUFFIX.modelPers]: 'PERS',
  [CATEGORY_PERMISSION_CODE_SUFFIX.persAlert]: 'PERS',
  [CATEGORY_PERMISSION_CODE_SUFFIX.persVehicleTrips]: 'PERS',
  [CATEGORY_PERMISSION_CODE_SUFFIX.persCommSystemLogs]: 'PERS',

  // Data Business/PERS Clients
  [CATEGORY_PERMISSION_CODE_SUFFIX.usersPortal]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.userPortalStatus]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.userPortalGDPR]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.userPortalAdditionalData]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.userPortalSubscriptions]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.userPortalVehicles]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.usersPortalVehiclesSharingAS]:
    'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.userPortalElectronicKeysSharingAS]:
    'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.userPortalVehiclesSharingAB]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.userPortalElectronicKeysSharingAB]:
    'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.userPortalPersCalibration]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.userAppPortalRoles]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.userAppNotifications]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.userPortalCommSystemLogs]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.userPortalDirectVehicles]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.userPortalDirectVehicle]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.userPortalActivity]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.companies]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.companyStatus]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.childCompanies]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.companyAdditionalData]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.companyMembers]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.companyMembersBo]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.companyPilots]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.companySubscriptions]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.companyVehicles]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.companyVehiclesSharing]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.companyElectronicKeys]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.companyElectronicKeysSharing]:
    'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.companyRoles]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.companyRolesConfiguration]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.companyPersLinks]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.companyActivity]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.subscriptions]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.subscriptionStatus]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.subscriptionAllowedPilots]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.subscriptionVehicle]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.subscriptionActivity]: 'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.userAppSubscriptionsAllowedPilot]:
    'PERS Clients',
  [CATEGORY_PERMISSION_CODE_SUFFIX.companyMailTemplates]: 'PERS Clients',

  // Data Business/Vehicles
  [CATEGORY_PERMISSION_CODE_SUFFIX.vehicles]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.vehicleStatus]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.vehiclePublicKeys]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.vehicleElectronicKeys]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.vehicleUsers]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.vehicleSharings]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.vehicleSubscriptions]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.vehicleCurrentData]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.vehicleDirectVehicles]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.vehicleActivity]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.electronicKeys]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.electronicKeyStatus]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.electronicKeyVehicles]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.electronicKeysSharing]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.electronicKeyActivity]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.electronicKeyModels]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.vehiclePers]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.vehicleBrands]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.vehicleBrandMessage]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.vehicleModels]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.vehicleBrandCompanyLinks]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.vehicleBrandCompanyRights]: 'Vehicles',
  [CATEGORY_PERMISSION_CODE_SUFFIX.vehicleTypes]: 'Vehicles',

  // Data Business/Sharings
  [CATEGORY_PERMISSION_CODE_SUFFIX.sharings]: 'Sharings',

  // Data LOGS/Connectivity
  [CATEGORY_PERMISSION_CODE_SUFFIX.connectivity]: 'Connectivity',

  // Active Pilot App/Content Management
  [CATEGORY_PERMISSION_CODE_SUFFIX.apaInterfaceLabels]: 'Content Management',
  [CATEGORY_PERMISSION_CODE_SUFFIX.apaInterfaceTexts]: 'Content Management',
  [CATEGORY_PERMISSION_CODE_SUFFIX.apaInterfaceFormattedTexts]:
    'Content Management',
  [CATEGORY_PERMISSION_CODE_SUFFIX.apaMessages]: 'Content Management',

  // Active Pilot App/Configuration & Settings
  [CATEGORY_PERMISSION_CODE_SUFFIX.apaMailTemplates]:
    'Configuration & Settings',
  [CATEGORY_PERMISSION_CODE_SUFFIX.appVersioning]: 'Configuration & Settings',
  [CATEGORY_PERMISSION_CODE_SUFFIX.messagesAfterConnection]:
    'Configuration & Settings',

  // Active Pilot App/Documentation
  [CATEGORY_PERMISSION_CODE_SUFFIX.apaDocumentation]: 'Documentation',

  // APIs/CIXI
  [CATEGORY_PERMISSION_CODE_SUFFIX.apisCIXICenter]: 'CIXI',
  [CATEGORY_PERMISSION_CODE_SUFFIX.apisCIXIBusiness]: 'CIXI',
  [CATEGORY_PERMISSION_CODE_SUFFIX.apisCIXIVehicleLogs]: 'CIXI',
  [CATEGORY_PERMISSION_CODE_SUFFIX.apisCIXIDocumentation]: 'CIXI',

  // APIs/Partners
  [CATEGORY_PERMISSION_CODE_SUFFIX.apisPartnersDocumentation]: 'Partners',

  // Fleet Management Platform/Content Management
  [CATEGORY_PERMISSION_CODE_SUFFIX.fmpInterfaceLabels]: 'Content Management',
  [CATEGORY_PERMISSION_CODE_SUFFIX.fmpInterfaceTexts]: 'Content Management',
  [CATEGORY_PERMISSION_CODE_SUFFIX.fmpInterfaceFormattedTexts]:
    'Content Management',
  [CATEGORY_PERMISSION_CODE_SUFFIX.fmpMessages]: 'Content Management',

  // Fleet Management Platform/Configuration & Settings
  [CATEGORY_PERMISSION_CODE_SUFFIX.fmpMailTemplates]:
    'Configuration & Settings',

  // Fleet Management Platform/Documentation
  [CATEGORY_PERMISSION_CODE_SUFFIX.fmpDocumentation]: 'Documentation',

  // Showcase Website/Visitor's Contacts
  [CATEGORY_PERMISSION_CODE_SUFFIX.visitorContacts]: "Visitor's Contacts",
  [CATEGORY_PERMISSION_CODE_SUFFIX.archivedVisitorContacts]:
    "Visitor's Contacts",

  // Showcase Website/Content Management
  [CATEGORY_PERMISSION_CODE_SUFFIX.scwSections]: 'Content Management',
  [CATEGORY_PERMISSION_CODE_SUFFIX.scwSectionComponent]: 'Content Management',
  [CATEGORY_PERMISSION_CODE_SUFFIX.scwStories]: 'Content Management',
  [CATEGORY_PERMISSION_CODE_SUFFIX.scwPrivacyPolicy]: 'Content Management',
  [CATEGORY_PERMISSION_CODE_SUFFIX.faq]: 'Content Management',
  [CATEGORY_PERMISSION_CODE_SUFFIX.scwMessages]: 'Content Management',

  // Showcase Website/Configuration & Settings
  [CATEGORY_PERMISSION_CODE_SUFFIX.scwMailTemplates]:
    'Configuration & Settings',

  // Showcase Website/Documentation
  [CATEGORY_PERMISSION_CODE_SUFFIX.scwDocumentation]: 'Documentation',

  // Simulator/Simulator Vehicle APIs
  [CATEGORY_PERMISSION_CODE_SUFFIX.simulatorVehicleAPIs]: 'Simulator PERS APIs',

  // CIXI Center/Content Management
  [CATEGORY_PERMISSION_CODE_SUFFIX.interfaceLabels]: 'Content Management',
  [CATEGORY_PERMISSION_CODE_SUFFIX.interfaceTexts]: 'Content Management',
  [CATEGORY_PERMISSION_CODE_SUFFIX.interfaceFormattedTexts]:
    'Content Management',
  [CATEGORY_PERMISSION_CODE_SUFFIX.messages]: 'Content Management',
  [CATEGORY_PERMISSION_CODE_SUFFIX.adminDocumentation]: 'Content Management',
  [CATEGORY_PERMISSION_CODE_SUFFIX.keyUsageSystemLogs]: 'Content Management',

  // CIXI Center/General Referentials
  [CATEGORY_PERMISSION_CODE_SUFFIX.languages]: 'General Referentials',
  [CATEGORY_PERMISSION_CODE_SUFFIX.countries]: 'General Referentials',
  [CATEGORY_PERMISSION_CODE_SUFFIX.timezones]: 'General Referentials',
  [CATEGORY_PERMISSION_CODE_SUFFIX.currencies]: 'General Referentials',

  // CIXI Center/Business Referentials
  [CATEGORY_PERMISSION_CODE_SUFFIX.tagContactTypes]: 'Business Referentials',
  [CATEGORY_PERMISSION_CODE_SUFFIX.pilotTypes]: 'Business Referentials',
  [CATEGORY_PERMISSION_CODE_SUFFIX.cixiReferents]: 'Business Referentials',
  [CATEGORY_PERMISSION_CODE_SUFFIX.companyCategories]: 'Business Referentials',

  // CIXI Center/Notification
  [CATEGORY_PERMISSION_CODE_SUFFIX.platformPageTech]: 'Notifications',
  [CATEGORY_PERMISSION_CODE_SUFFIX.notificationTemplates]: 'Notifications',
  [CATEGORY_PERMISSION_CODE_SUFFIX.notificationRequests]: 'Notifications',
  [CATEGORY_PERMISSION_CODE_SUFFIX.notificationSimulator]: 'Notifications',
  [CATEGORY_PERMISSION_CODE_SUFFIX.configNotificationSystem]: 'Notifications',

  // CIXI Center/Alerts
  [CATEGORY_PERMISSION_CODE_SUFFIX.groupAlerts]: 'Alerts',
  [CATEGORY_PERMISSION_CODE_SUFFIX.topicAlerts]: 'Alerts',
  [CATEGORY_PERMISSION_CODE_SUFFIX.levelAlerts]: 'Alerts',
  [CATEGORY_PERMISSION_CODE_SUFFIX.alerts]: 'Alerts',
  [CATEGORY_PERMISSION_CODE_SUFFIX.alertConfigurations]: 'Alerts',

  // CIXI Center/Configuration & Settings
  [CATEGORY_PERMISSION_CODE_SUFFIX.usersBO]: 'Configuration & Settings',
  [CATEGORY_PERMISSION_CODE_SUFFIX.roles]: 'Configuration & Settings',
  [CATEGORY_PERMISSION_CODE_SUFFIX.rolesConfiguration]:
    'Configuration & Settings',
  [CATEGORY_PERMISSION_CODE_SUFFIX.platformTech]: 'Configuration & Settings',
  [CATEGORY_PERMISSION_CODE_SUFFIX.businessSettings]:
    'Configuration & Settings',
  [CATEGORY_PERMISSION_CODE_SUFFIX.generalSettings]: 'Configuration & Settings',
  [CATEGORY_PERMISSION_CODE_SUFFIX.regionalSettings]:
    'Configuration & Settings',
  [CATEGORY_PERMISSION_CODE_SUFFIX.datetimeFormats]: 'Configuration & Settings',
  [CATEGORY_PERMISSION_CODE_SUFFIX.conditionalFeatures]:
    'Configuration & Settings',
  [CATEGORY_PERMISSION_CODE_SUFFIX.mailTemplates]: 'Configuration & Settings',

  [CATEGORY_PERMISSION_CODE_SUFFIX.odooSettings]: 'Configuration & Settings',

  // CIXI Center/Documentation
  [CATEGORY_PERMISSION_CODE_SUFFIX.dataMapping]: 'Data Mapping',

  // CIXI Center/Documentation
  [CATEGORY_PERMISSION_CODE_SUFFIX.documentations]: 'Documentation',

  // CIXI Center/Topics
  [CATEGORY_PERMISSION_CODE_SUFFIX.topics]: 'Topics',

  // Logs/logs
  [CATEGORY_PERMISSION_CODE_SUFFIX.logMails]: 'Logs',
  [CATEGORY_PERMISSION_CODE_SUFFIX.logRoutines]: 'Logs',
  [CATEGORY_PERMISSION_CODE_SUFFIX.persPackage]: 'Logs',
  [CATEGORY_PERMISSION_CODE_SUFFIX.odooSynchronization]: 'Logs',

  // Additional Permissions
  [CATEGORY_PERMISSION_CODE_SUFFIX.cixiCenterDescription]:
    'Additional Permission',

  // Procenter
  [CATEGORY_PERMISSION_CODE_SUFFIX.proInterfaceLabels]: 'Content Management',
  [CATEGORY_PERMISSION_CODE_SUFFIX.proMessages]: 'Messages',
  [CATEGORY_PERMISSION_CODE_SUFFIX.proMailTemplates]: 'Mail Templates',
  [CATEGORY_PERMISSION_CODE_SUFFIX.proCenterAdmin]: 'PRO CENTER Admins'
}

export const MAP_OTHER_PLATFORM_PERMISSIONS_TO_CATEGORY = Object.freeze({
  [OTHER_PLATFORM_PERMISSIONS.companyProfile]: 'Company Profile',
  [OTHER_PLATFORM_PERMISSIONS.companyProfileInformation]: 'Company Profile',
  [OTHER_PLATFORM_PERMISSIONS.companyProfileMembers]: 'Company Profile',
  [OTHER_PLATFORM_PERMISSIONS.companyMembers]: 'Company Profile',
  [OTHER_PLATFORM_PERMISSIONS.companyProfileChildCompanies]: 'Company Profile',
  [OTHER_PLATFORM_PERMISSIONS.companyProfileGeneralSettings]: 'Company Profile',
  [OTHER_PLATFORM_PERMISSIONS.companyGeneralSettings]: 'Company Profile',
  [OTHER_PLATFORM_PERMISSIONS.fleetsAndSubscriptions]: 'Fleet & Subscriptions',
  [OTHER_PLATFORM_PERMISSIONS.fleetsAndSubscriptionsFleets]:
    'Fleet & Subscriptions',
  [OTHER_PLATFORM_PERMISSIONS.fleetsAndSubscriptionsOrders]:
    'Fleet & Subscriptions',
  [OTHER_PLATFORM_PERMISSIONS.fleetManagement]: 'Fleet Management',
  [OTHER_PLATFORM_PERMISSIONS.fleetManagementTeams]: 'Fleet Management',
  [OTHER_PLATFORM_PERMISSIONS.fleetManagementVehicles]: 'Fleet Management',
  [OTHER_PLATFORM_PERMISSIONS.sharingManagement]: 'Sharing Management',
  [OTHER_PLATFORM_PERMISSIONS.sharingManagementCurrentVehiclesUse]:
    'Sharing Management',
  [OTHER_PLATFORM_PERMISSIONS.sharingManagementUpcomingVehiclesUse]:
    'Sharing Management',
  [OTHER_PLATFORM_PERMISSIONS.sharingManagementPastVehiclesUsed]:
    'Sharing Management',
  [OTHER_PLATFORM_PERMISSIONS.statistics]: 'Statistics',
  [OTHER_PLATFORM_PERMISSIONS.statisticsUsage]: 'Statistics',
  [OTHER_PLATFORM_PERMISSIONS.statisticsFinancial]: 'Statistics',
  [OTHER_PLATFORM_PERMISSIONS.statisticsUsers]: 'Statistics',
  [OTHER_PLATFORM_PERMISSIONS.statisticsVehicles]: 'Statistics',
  [OTHER_PLATFORM_PERMISSIONS.help]: 'Help',
  [OTHER_PLATFORM_PERMISSIONS.helpFAQ]: 'Help',
  [OTHER_PLATFORM_PERMISSIONS.helpGlossary]: 'Help',
  [OTHER_PLATFORM_PERMISSIONS.helpDocuments]: 'Help',
  [OTHER_PLATFORM_PERMISSIONS.allVehicles]: 'Vehicle User'
})

export const ROLE_PERMISSION_CODE_PREFIX_MAPPING = {
  [ROLE_PERMISSION_CODE_PREFIX.manage]: 'isManage',
  [ROLE_PERMISSION_CODE_PREFIX.viewList]: 'isViewList',
  [ROLE_PERMISSION_CODE_PREFIX.viewDetail]: 'isViewDetail',
  [ROLE_PERMISSION_CODE_PREFIX.viewSheet]: 'isViewSheet',
  [ROLE_PERMISSION_CODE_PREFIX.use]: 'isUse',
  [ROLE_PERMISSION_CODE_PREFIX.send]: 'isSend',
  [ROLE_PERMISSION_CODE_PREFIX.menu]: 'isMenu',
  [ROLE_PERMISSION_CODE_PREFIX.cancel]: 'isCancel',
  [ROLE_PERMISSION_CODE_PREFIX.module]: 'isModule',
  [ROLE_PERMISSION_CODE_PREFIX.anonymize]: 'isAnonymize',
  [ROLE_PERMISSION_CODE_PREFIX.run]: 'isRun'
}
