import CompanyCategoryTypes from 'store/actionTypes/companyCategory'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  current: {},
  pagination: {},
  system: []
}

const companyCategory = (state = initialData, action) => {
  switch (action.type) {
    case CompanyCategoryTypes.GET_COMPANY_CATEGORIES_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case CompanyCategoryTypes.GET_SYSTEM_COMPANY_CATEGORIES_SUCCESS:
      return {
        ...state,
        system: action.payload?.items
      }
    case CompanyCategoryTypes.GET_COMPANY_CATEGORY_SUCCESS:
      return { ...state, current: action.payload }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default companyCategory
