import VehicleModelTypes from 'store/actionTypes/vehicleType'
import UserTypes from 'store/actionTypes/user'
import { DEFAULT_VEHICLE_TYPE_FILTER } from 'constants/common'

const initialData = {
  list: [],
  current: {},
  pagination: {},
  filter: DEFAULT_VEHICLE_TYPE_FILTER,
  systems: []
}

const vehicleType = (state = initialData, action) => {
  switch (action.type) {
    case VehicleModelTypes.GET_VEHICLE_TYPES_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case VehicleModelTypes.GET_VEHICLE_TYPE_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case VehicleModelTypes.UPDATE_FILTER_VEHICLE_TYPE_SUCCESS:
      return { ...state, filter: action.payload }
    case VehicleModelTypes.CLEAR_FILTER_VEHICLE_TYPE_SUCCESS:
      return { ...state, filter: initialData.filter }
    case VehicleModelTypes.GET_SYSTEM_VEHICLE_TYPES_SUCCESS:
      return { ...state, systems: action.payload?.items }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}
export default vehicleType
