import { DEFAULT_SHARING_FILTER } from 'constants/common'
import SharingTypes from 'store/actionTypes/sharing'
import UserTypes from 'store/actionTypes/user'

const initData = {
  list: [],
  pagination: {},
  filter: DEFAULT_SHARING_FILTER
}

const sharing = (state = initData, action) => {
  switch (action.type) {
    case SharingTypes.GET_LIST_OF_SHARING_SUCCESS:
      return {
        ...state,
        list: action.payload?.vehicleSharings?.items,
        pagination: action.payload?.vehicleSharings?.meta
      }
    case SharingTypes.CLEAR_FILTER_LIST_OF_SHARING_SUCCESS:
      return { ...state, filter: initData.filter }
    case SharingTypes.UPDATE_FILTER_LIST_OF_SHARING_SUCCESS:
      return { ...state, filter: action.payload }
    case UserTypes.LOGOUT_SUCCESS:
      return initData
    default:
      return state
  }
}

export default sharing
