import BaseApi from './base'

class PersPackage extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = this.perLogURL
  }

  getPersPackages = (query) =>
    this.client.get(`backoffice/pers-package?${query}`)

  getPersPackageById = (id) => this.client.get(`backoffice/pers-package/${id}`)
}

export default new PersPackage()
