import Types from '../actionTypes/alertLevel'
import { alertLevelAPI } from '../api'
import { mapAlertLevelDetail } from '../mapping/alertLevel'
import { bindRequestLogic } from './utils'

const getAlertLevels = bindRequestLogic({
  clientFunc: alertLevelAPI.getAlertLevels,
  type: Types.GET_ALERT_LEVELS_REQUEST,
  successType: Types.GET_ALERT_LEVELS_SUCCESS,
  failType: Types.GET_ALERT_LEVELS_FAILED,
  isQueryParam: true
})

const addAlertLevel = bindRequestLogic({
  clientFunc: alertLevelAPI.addAlertLevel,
  type: Types.ADD_ALERT_LEVEL_REQUEST,
  successType: Types.ADD_ALERT_LEVEL_SUCCESS,
  failType: Types.ADD_ALERT_LEVEL_FAILED
})

const getAlertLevelDetail = bindRequestLogic({
  clientFunc: alertLevelAPI.getAlertLevel,
  type: Types.GET_ALERT_LEVEL_DETAIL_REQUEST,
  successType: Types.GET_ALERT_LEVEL_DETAIL_SUCCESS,
  failType: Types.GET_ALERT_LEVEL_DETAIL_FAILED,
  modelName: 'levelAlert',
  mapResponse: mapAlertLevelDetail
})

const updateAlertLevel = bindRequestLogic({
  clientFunc: alertLevelAPI.updateAlertLevel,
  type: Types.UPDATE_ALERT_LEVEL_REQUEST,
  successType: Types.UPDATE_ALERT_LEVEL_SUCCESS,
  failType: Types.UPDATE_ALERT_LEVEL_FAILED
})

const deleteAlertLevel = bindRequestLogic({
  clientFunc: alertLevelAPI.deleteAlertLevel,
  type: Types.DELETE_ALERT_LEVEL_REQUEST,
  successType: Types.DELETE_ALERT_LEVEL_SUCCESS,
  failType: Types.DELETE_ALERT_LEVEL_FAILED
})

const upsertAlertLevelTranslation = bindRequestLogic({
  clientFunc: alertLevelAPI.upsertAlertLevelTranslation,
  type: Types.UPSERT_ALERT_LEVEL_TRANSLATION_REQUEST,
  successType: Types.UPSERT_ALERT_LEVEL_TRANSLATION_SUCCESS,
  failType: Types.UPSERT_ALERT_LEVEL_TRANSLATION_FAILED
})

const deleteAlertLevelTranslation = bindRequestLogic({
  clientFunc: alertLevelAPI.deleteAlertLevelTranslation,
  type: Types.DELETE_ALERT_LEVEL_TRANSLATION_REQUEST,
  successType: Types.DELETE_ALERT_LEVEL_TRANSLATION_SUCCESS,
  failType: Types.DELETE_ALERT_LEVEL_TRANSLATION_FAILED
})

const getSystemAlertLevels = bindRequestLogic({
  clientFunc: alertLevelAPI.getSystemAlertLevels,
  type: Types.GET_SYSTEM_ALERT_LEVELS_REQUEST,
  successType: Types.GET_SYSTEM_ALERT_LEVELS_SUCCESS,
  failType: Types.GET_SYSTEM_ALERT_LEVELS_FAILED,
  isQueryParam: true
})

export default [
  getAlertLevels,
  addAlertLevel,
  getAlertLevelDetail,
  updateAlertLevel,
  deleteAlertLevel,
  upsertAlertLevelTranslation,
  deleteAlertLevelTranslation,
  getSystemAlertLevels
]
