import keyMirror from 'keymirror'

export default keyMirror({
  GET_LIST_OF_SHARING_REQUEST: null,
  GET_LIST_OF_SHARING_SUCCESS: null,
  GET_LIST_OF_SHARING_FAILED: null,

  UPDATE_FILTER_LIST_OF_SHARING_SUCCESS: null,
  CLEAR_FILTER_LIST_OF_SHARING_SUCCESS: null
})
