import keyMirror from 'keymirror'

export default keyMirror({
  GET_SHOWCASE_LOGS_REQUEST: null,
  GET_SHOWCASE_LOGS_SUCCESS: null,
  GET_SHOWCASE_LOGS_FAILED: null,

  UPDATE_FILTER_SHOWCASE_LOG_SUCCESS: null,
  CLEAR_FILTER_SHOWCASE_LOG_SUCCESS: null,

  EXPORT_SHOWCASE_CONTACT_LOG_REQUEST: null,
  EXPORT_SHOWCASE_CONTACT_LOG_SUCCESS: null,
  EXPORT_SHOWCASE_CONTACT_LOG_FAILED: null,

  UPDATE_ANONYMIZE_VISITOR_REQUEST: null,
  UPDATE_ANONYMIZE_VISITOR_SUCCESS: null,
  UPDATE_ANONYMIZE_VISITOR_FAILED: null
})
