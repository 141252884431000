import { createLogic } from 'redux-logic'
import { memoizedSystemMessages } from 'store/selector/message'
import qs from 'qs'

// eslint-disable-next-line import/prefer-default-export
export const bindRequestLogic = ({
  clientFunc,
  type,
  successType,
  failType,
  validate,
  isQueryParam,
  modelName,
  mapResponse
}) =>
  createLogic({
    type,
    latest: true,
    validate,

    async process({ action, getState }, dispatch, done) {
      const { payload = {}, meta = {} } = action
      const { onSuccess, onError } = meta
      try {
        let res
        const { id } = payload
        let payloadReq = payload.data || payload
        if (isQueryParam) {
          payloadReq = qs.stringify(payloadReq, { arrayFormat: 'brackets' })
        }
        if (id) {
          res = await clientFunc(id, payloadReq, meta)
        } else {
          res = await clientFunc(payloadReq, meta)
        }
        const payloadRes = modelName ? res?.data[modelName] : res?.data

        dispatch({
          type: successType,
          payload: mapResponse ? mapResponse(payloadRes, payload) : payloadRes,
          meta: { params: payload, ...meta },
          responseHeaders: res?.headers
        })

        if (onSuccess) {
          onSuccess({ response: res?.data })
        }
      } catch (error) {
        const originalMsg = error.response?.data?.message
        const systemMsg = memoizedSystemMessages(getState())
        const errorMsgStr = systemMsg[originalMsg?.code] || originalMsg?.text
        dispatch({ type: failType, payload: errorMsgStr, error: true })

        if (onError) {
          onError(errorMsgStr, originalMsg)
        }
      }
      done()
    }
  })
