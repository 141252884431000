import { getUniqueItem } from 'helpers/utils'
import ActivityTypes from 'store/actionTypes/activity'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  pagination: {}
}

const activity = (state = initialData, action) => {
  switch (action.type) {
    case ActivityTypes.GET_ACTIVITIES_SUCCESS:
      if (action.payload.meta.currentPage === 0) {
        return {
          ...state,
          list: action.payload?.items,
          pagination: action.payload?.meta
        }
      }
      return {
        ...state,
        list: getUniqueItem([...action.payload?.items, ...state.list]),
        pagination: action.payload?.meta
      }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default activity
