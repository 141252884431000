import keyMirror from 'keymirror'

export default keyMirror({
  GET_ELECTRONIC_KEY_MODELS_REQUEST: null,
  GET_ELECTRONIC_KEY_MODELS_SUCCESS: null,
  GET_ELECTRONIC_KEY_MODELS_FAILED: null,

  ADD_ELECTRONIC_KEY_MODEL_REQUEST: null,
  ADD_ELECTRONIC_KEY_MODEL_SUCCESS: null,
  ADD_ELECTRONIC_KEY_MODEL_FAILED: null,

  GET_ELECTRONIC_KEY_MODEL_DETAIL_REQUEST: null,
  GET_ELECTRONIC_KEY_MODEL_DETAIL_SUCCESS: null,
  GET_ELECTRONIC_KEY_MODEL_DETAIL_FAILED: null,

  UPDATE_ELECTRONIC_KEY_MODEL_REQUEST: null,
  UPDATE_ELECTRONIC_KEY_MODEL_SUCCESS: null,
  UPDATE_ELECTRONIC_KEY_MODEL_FAILED: null,

  DELETE_ELECTRONIC_KEY_MODEL_REQUEST: null,
  DELETE_ELECTRONIC_KEY_MODEL_SUCCESS: null,
  DELETE_ELECTRONIC_KEY_MODEL_FAILED: null,

  UPDATE_FILTER_ELECTRONIC_KEY_MODEL_SUCCESS: null,
  CLEAR_FILTER_ELECTRONIC_KEY_MODEL_SUCCESS: null,

  GET_SYSTEM_ELECTRONIC_KEY_MODELS_REQUEST: null,
  GET_SYSTEM_ELECTRONIC_KEY_MODELS_SUCCESS: null,
  GET_SYSTEM_ELECTRONIC_KEY_MODELS_FAILED: null
})
