import { DEFAULT_LOG_ROUTINE_FILTER } from 'constants/common'
import LogRoutineTypes from 'store/actionTypes/logRoutine'
import UserTypes from 'store/actionTypes/user'

const initLogRoutine = {
  list: [],
  pagination: {},
  filter: DEFAULT_LOG_ROUTINE_FILTER,
  codeRoutines: [],
  modules: []
}

const logRoutine = (state = initLogRoutine, action) => {
  switch (action.type) {
    case LogRoutineTypes.GET_LOG_ROUTINES_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case LogRoutineTypes.GET_CODE_ROUTINES_SUCCESS:
      return { ...state, codeRoutines: action.payload?.items }
    case LogRoutineTypes.UPDATE_FILTER_LOG_ROUTINE_SUCCESS:
      return { ...state, filter: action.payload }
    case LogRoutineTypes.CLEAR_FILTER_LOG_ROUTINE_SUCCESS:
      return { ...state, filter: initLogRoutine.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initLogRoutine
    default:
      return state
  }
}

export default logRoutine
