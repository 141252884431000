import keyMirror from 'keymirror'

export default keyMirror({
  GET_COMPANY_VEHICLE_BRAND_LINKS_REQUEST: null,
  GET_COMPANY_VEHICLE_BRAND_LINKS_SUCCESS: null,
  GET_COMPANY_VEHICLE_BRAND_LINKS_FAILED: null,

  ADD_COMPANY_VEHICLE_BRAND_LINK_REQUEST: null,
  ADD_COMPANY_VEHICLE_BRAND_LINK_SUCCESS: null,
  ADD_COMPANY_VEHICLE_BRAND_LINK_FAILED: null,

  GET_COMPANY_VEHICLE_BRAND_LINK_DETAIL_REQUEST: null,
  GET_COMPANY_VEHICLE_BRAND_LINK_DETAIL_SUCCESS: null,
  GET_COMPANY_VEHICLE_BRAND_LINK_DETAIL_FAILED: null,

  UPDATE_COMPANY_VEHICLE_BRAND_LINK_FILTER_SUCCESS: null,

  UPDATE_COMPANY_VEHICLE_BRAND_LINK_REQUEST: null,
  UPDATE_COMPANY_VEHICLE_BRAND_LINK_SUCCESS: null,
  UPDATE_COMPANY_VEHICLE_BRAND_LINK_FAILED: null,

  DELETE_COMPANY_VEHICLE_BRAND_LINK_REQUEST: null,
  DELETE_COMPANY_VEHICLE_BRAND_LINK_SUCCESS: null,
  DELETE_COMPANY_VEHICLE_BRAND_LINK_FAILED: null
})
