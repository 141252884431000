import KeyMirror from 'keymirror'

export default KeyMirror({
  GET_TOPIC_REQUEST: null,
  GET_TOPIC_SUCCESS: null,
  GET_TOPIC_FAILED: null,

  GET_TOPIC_DETAIL_REQUEST: null,
  GET_TOPIC_DETAIL_SUCCESS: null,
  GET_TOPIC_DETAIL_FAILED: null,

  ADD_TOPIC_REQUEST: null,
  ADD_TOPIC_SUCCESS: null,
  ADD_TOPIC_FAILED: null,

  DELETE_TOPIC_REQUEST: null,
  DELETE_TOPIC_SUCCESS: null,
  DELETE_TOPIC_FAILED: null,

  UPDATE_TOPIC_FAILED: null,
  UPDATE_TOPIC_REQUEST: null,
  UPDATE_TOPIC_SUCCESS: null,

  DELETE_TOPIC_TRANSLATION_REQUEST: null,
  DELETE_TOPIC_TRANSLATION_FAILED: null,
  DELETE_TOPIC_TRANSLATION_SUCCESS: null,

  UPDATE_TOPIC_TRANSLATION_REQUEST: null,
  UPDATE_TOPIC_TRANSLATION_FAILED: null,
  UPDATE_TOPIC_TRANSLATION_SUCCESS: null,

  ADD_TOPIC_TRANSLATION_REQUEST: null,
  ADD_TOPIC_TRANSLATION_FAILED: null,
  ADD_TOPIC_TRANSLATION_SUCCESS: null
})
