import { combineReducers } from 'redux'
import RoleTypes from 'store/actionTypes/role'
import { createHandleRequest } from './helpers'

const getRoles = createHandleRequest(
  RoleTypes.GET_ROLES_REQUEST,
  RoleTypes.GET_ROLES_SUCCESS,
  RoleTypes.GET_ROLES_FAILED
)

export default combineReducers({
  getRoles
})
