import BaseApi from './base'

class Per extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  getPers = (query) => this.client.get(`pers?${query}`)

  getPerBy = (id) => this.client.get(`pers/${id}`)

  addPer = (data) => this.client.post('pers', data)

  updatePer = (id, data) => this.client.patch(`pers/${id}`, data)

  deletePer = (id) => this.client.delete(`pers/${id}`)

  getPerAlerts = (id, query) =>
    this.client.get(`pers/${id}/pers-alerts?${query}`)

  publishIOTService = (data) =>
    this.client.post('pers/iot-service/publish', data)

  getSystemPers = (query) => this.client.get(`systems/pers?${query}`)

  getPerPackageLogs = (query) =>
    this.client.get(`backoffice/pers-package-log?${query}`, {
      baseURL: this.perLogURL
    })

  getVehicleTrips = (query) =>
    this.client.get(`backoffice/vehicle-trips?${query}`, {
      baseURL: this.perLogURL
    })

  getVehicleTripBy = (id) =>
    this.client.get(`backoffice/vehicle-trips/${id}`, {
      baseURL: this.perLogURL
    })

  exportVehicleTripBy = (id) =>
    this.client.get(`backoffice/vehicle-trips/${id}/export`, {
      baseURL: this.perLogURL,
      responseType: 'blob'
    })
}

export default new Per()
