import { combineReducers } from 'redux'
import ProCenterAdminsTypes from 'store/actionTypes/proCenterAdmin'
import { createHandleRequest } from './helpers'

const getProCenterAdminss = createHandleRequest(
  ProCenterAdminsTypes.GET_PRO_CENTER_ADMINS_REQUEST,
  ProCenterAdminsTypes.GET_PRO_CENTER_ADMINS_SUCCESS,
  ProCenterAdminsTypes.GET_PRO_CENTER_ADMINS_FAILED
)

export default combineReducers({
  getProCenterAdminss
})
