import BaseApi from './base'

class OdooSynchronization extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  getOdooSynchronizations = () => this.client.get(`odoo-sync`)
}

export default new OdooSynchronization()
