// eslint-disable-next-line import/named
import { MAIN_ADMIN_ROLE } from 'constants/common'
import BaseApi from './base'

class SystemConfig extends BaseApi {
  getSystemConfigs = (query) => this.client.get(`admin/system-config?${query}`)

  updateSystemConfig = (id, data) =>
    this.client.patch(`admin/system-config/${id}`, data)

  updateSystemConfigs = (data) =>
    this.client.post(`admin/system-config/bulk-update`, data)

  getAllSystemConfigs = () =>
    this.client.get(`admin/system/system-config?limit=0`)

  getModules = () => this.client.get(`module`)

  getAdminPortals = () => this.client.get(`admin/portals`)

  getCompanyRoleConfigurationById = (id) =>
    this.client.get(
      `company/${id}/company-role-configuration/${MAIN_ADMIN_ROLE}`
    )

  getModuleByName = (name) => this.client.get(`internals/module/name/${name}`)

  getSystemGroupAlerts = () => this.client.get(`systems/group-alert?limit=0`)

  getSystemLevelAlerts = () => this.client.get(`systems/level-alert?limit=0`)

  getSystemTopicAlerts = () => this.client.get(`systems/topic-alert?limit=0`)
}

export default new SystemConfig()
