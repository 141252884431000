import BaseApi from './base'

class FormattedText extends BaseApi {
  getFormattedTexts = (query) =>
    this.client.get(`admin/formatted-text?${query}`)

  addFormattedText = (data) => this.client.post('admin/formatted-text', data)

  updateFormattedText = (id, data) =>
    this.client.post(`admin/formatted-text/translation/bulk-upsert/${id}`, data)

  deleteFormattedText = (id) => this.client.delete(`admin/formatted-text/${id}`)
}

export default new FormattedText()
