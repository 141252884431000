/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import axios from 'axios'

const STATUS_CODE = {
  UNAUTHORIZE: 401
}

const STORAGE_KEYS = {
  accessToken: '@accessToken',
  refreshToken: '@refreshToken'
}

export default class BaseAPI {
  client = null

  token = null

  baseURL = `${process.env.REACT_APP_API_URL}/api/v1/backoffice`

  fleetURL = `${process.env.REACT_APP_FLEET_API_URL}/api/v1`

  notificationURL = `${process.env.REACT_APP_NOTIFICATION_API_URL}/api`

  perLogURL = `${process.env.REACT_APP_PER_LOG_API_URL}/api/v1`

  perURL = `${process.env.REACT_APP_PER_API_URL}/api/v1`

  constructor() {
    const instance = axios.create({
      baseURL: this.baseURL,
      headers: {
        Accept: 'application/json'
      },
      timeout: process.env.REACT_APP_AXIOS_REQUEST_TIMEOUT || 3000,
      autoSetToken: true
    })

    instance.interceptors.request.use(
      (config) => {
        const urlUsingRefreshToken = ['refresh-token', 'logout']
        const refreshToken = localStorage.getItem(STORAGE_KEYS.refreshToken)
        const token = localStorage.getItem(STORAGE_KEYS.accessToken)

        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
        if (
          refreshToken &&
          urlUsingRefreshToken.some((url) => config.url.includes(url))
        ) {
          config.headers.refreshToken = `Bearer ${refreshToken}`
        }

        return config
      },
      (error) => Promise.reject(error)
    )

    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const urlBypassRefreshToken = ['login', 'refresh-token', 'logout']
        const { config, response } = error

        if (!response) {
          console.error('Network error:', error)
        } else if (
          response.status === STATUS_CODE.UNAUTHORIZE &&
          !urlBypassRefreshToken.join(',').includes(config.url) &&
          !config._retry
        ) {
          config._retry = true

          try {
            const refreshToken = localStorage.getItem(STORAGE_KEYS.refreshToken)
            const res = await axios.post(`${this.baseURL}/refresh-token`, {
              refreshToken
            })
            const { accessToken } = res.data

            localStorage.setItem(STORAGE_KEYS.accessToken, accessToken)

            config.headers.Authorization = `Bearer ${accessToken}`
            return axios(config)
          } catch (err) {
            console.error('Refresh token error:', err)
          }
        }

        return Promise.reject(error)
      }
    )

    this.client = instance
  }

  setAccessToken = (token) => {
    localStorage.setItem(STORAGE_KEYS.accessToken, token)
  }

  setRefreshToken = (token) => {
    localStorage.setItem(STORAGE_KEYS.refreshToken, token)
  }
}
