import AlertLevelTypes from 'store/actionTypes/alertLevel'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  current: {},
  system: []
}

const AlertLevel = (state = initialData, action = {}) => {
  switch (action.type) {
    case AlertLevelTypes.GET_ALERT_LEVELS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items || []
      }
    case AlertLevelTypes.GET_ALERT_LEVEL_DETAIL_SUCCESS:
      return {
        ...state,
        current: action.payload || []
      }
    case AlertLevelTypes.GET_SYSTEM_ALERT_LEVELS_SUCCESS:
      return {
        ...state,
        system: action.payload?.items || []
      }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default AlertLevel
