import { combineReducers } from 'redux'
import VehicleTypeTypes from 'store/actionTypes/vehicleType'
import { createHandleRequest } from './helpers'

const getVehicleTypes = createHandleRequest(
  VehicleTypeTypes.GET_VEHICLE_TypeS_REQUEST,
  VehicleTypeTypes.GET_VEHICLE_TypeS_SUCCESS,
  VehicleTypeTypes.GET_VEHICLE_TypeS_FAILED
)

export default combineReducers({
  getVehicleTypes
})
