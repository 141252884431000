import { combineReducers } from 'redux'
import PerTypes from 'store/actionTypes/per'
import { createHandleRequest } from './helpers'

const pers = createHandleRequest(
  PerTypes.GET_PERS_REQUEST,
  PerTypes.GET_PERS_SUCCESS,
  PerTypes.GET_PERS_FAILED
)

const getPerAlerts = createHandleRequest(
  PerTypes.GET_PER_ALERTS_REQUEST,
  PerTypes.GET_PER_ALERTS_SUCCESS,
  PerTypes.GET_PER_ALERTS_FAILED
)

const getPerPackageLogs = createHandleRequest(
  PerTypes.GET_PER_PACKAGE_LOGS_REQUEST,
  PerTypes.GET_PER_PACKAGE_LOGS_SUCCESS,
  PerTypes.GET_PER_PACKAGE_LOGS_FAILED
)

const publishIOTService = createHandleRequest(
  PerTypes.PUBLISH_IOT_SERVICE_REQUEST,
  PerTypes.PUBLISH_IOT_SERVICE_SUCCESS,
  PerTypes.PUBLISH_IOT_SERVICE_FAILED
)

const getVehicleTripBy = createHandleRequest(
  PerTypes.GET_VEHICLE_TRIP_DETAIL_REQUEST,
  PerTypes.GET_VEHICLE_TRIP_DETAIL_SUCCESS,
  PerTypes.GET_VEHICLE_TRIP_DETAIL_FAILED
)

const getVehicleTrips = createHandleRequest(
  PerTypes.GET_VEHICLE_TRIPS_REQUEST,
  PerTypes.GET_VEHICLE_TRIPS_SUCCESS,
  PerTypes.GET_VEHICLE_TRIPS_FAILED
)

const exportVehicleTripBy = createHandleRequest(
  PerTypes.EXPORT_VEHICLE_TRIP_DETAIL_REQUEST,
  PerTypes.EXPORT_VEHICLE_TRIP_DETAIL_SUCCESS,
  PerTypes.EXPORT_VEHICLE_TRIP_DETAIL_FAILED
)

export default combineReducers({
  pers,
  getPerAlerts,
  getPerPackageLogs,
  publishIOTService,
  getVehicleTripBy,
  getVehicleTrips,
  exportVehicleTripBy
})
