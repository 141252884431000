import UserTypes from 'store/actionTypes/appUser'
import { appUser as clientApi } from 'store/api'
import { bindRequestLogic } from './utils'
import { mapUserSubscriptions, mapUserVehicles } from '../mapping'

const getAppUserList = bindRequestLogic({
  clientFunc: clientApi.getAppUsers,
  type: UserTypes.GET_APP_USERS_REQUEST,
  successType: UserTypes.GET_APP_USERS_SUCCESS,
  failType: UserTypes.GET_APP_USERS_FAILED,
  isQueryParam: true,
  modelName: 'users'
})

const deleteAppUser = bindRequestLogic({
  clientFunc: clientApi.deleteUser,
  type: UserTypes.DELETE_APP_USER_REQUEST,
  successType: UserTypes.DELETE_APP_USER_SUCCESS,
  failType: UserTypes.DELETE_APP_USER_FAILED
})

const getAppUserDetail = bindRequestLogic({
  clientFunc: clientApi.getAppUserDetail,
  type: UserTypes.GET_APP_USER_DETAIL_REQUEST,
  successType: UserTypes.GET_APP_USER_DETAIL_SUCCESS,
  failType: UserTypes.GET_APP_USER_DETAIL_FAILED,
  modelName: 'user'
})

const addUser = bindRequestLogic({
  clientFunc: clientApi.addUser,
  type: UserTypes.ADD_APP_USER_REQUEST,
  successType: UserTypes.ADD_APP_USER_SUCCESS,
  failType: UserTypes.ADD_APP_USER_FAILED
})

const updateAppUser = bindRequestLogic({
  clientFunc: clientApi.updateUser,
  type: UserTypes.UPDATE_APP_USER_REQUEST,
  successType: UserTypes.UPDATE_APP_USER_SUCCESS,
  failType: UserTypes.UPDATE_APP_USER_FAILED
})

const updateRoleUser = bindRequestLogic({
  clientFunc: clientApi.updateRoleUser,
  type: UserTypes.UPDATE_ROLE_USER_REQUEST,
  successType: UserTypes.UPDATE_ROLE_USER_SUCCESS,
  failType: UserTypes.UPDATE_ROLE_USER_FAILED
})

const getRoleUser = bindRequestLogic({
  clientFunc: clientApi.getRoleUser,
  type: UserTypes.GET_ROLE_USERS_REQUEST,
  successType: UserTypes.GET_ROLE_USERS_SUCCESS,
  failType: UserTypes.GET_ROLE_USERS_FAILED
})

const getUserSubscription = bindRequestLogic({
  clientFunc: clientApi.getUserSubscription,
  type: UserTypes.GET_USER_SUBSCRIPTION_REQUEST,
  successType: UserTypes.GET_USER_SUBSCRIPTION_SUCCESS,
  failType: UserTypes.GET_USER_SUBSCRIPTION_FAILED,
  isQueryParam: true,
  mapResponse: mapUserSubscriptions
})

const getSubscriptionAllowedPilots = bindRequestLogic({
  clientFunc: clientApi.getSubscriptionAllowedPilots,
  type: UserTypes.GET_SUBSCRIPTION_ALLOWED_PILOTS_REQUEST,
  successType: UserTypes.GET_SUBSCRIPTION_ALLOWED_PILOTS_SUCCESS,
  failType: UserTypes.GET_SUBSCRIPTION_ALLOWED_PILOTS_FAILED,
  isQueryParam: false
})

const getUserVehicles = bindRequestLogic({
  clientFunc: clientApi.getUserVehicles,
  type: UserTypes.GET_USER_VEHICLES_REQUEST,
  successType: UserTypes.GET_USER_VEHICLES_SUCCESS,
  failType: UserTypes.GET_USER_VEHICLES_FAILED,
  isQueryParam: true,
  mapResponse: mapUserVehicles
})

const getListIndividualVehicleSharingExtension = bindRequestLogic({
  clientFunc: clientApi.getListVehicleSharingExtension,
  type: UserTypes.GET_LIST_VEHICLES_SHARING_EXTENSION_REQUEST,
  successType: UserTypes.GET_LIST_VEHICLES_SHARING_EXTENSION_SUCCESS,
  failType: UserTypes.GET_LIST_VEHICLES_SHARING_EXTENSION_FAILED,
  isQueryParam: false
})

const getDetailIndividualVehicleSharing = bindRequestLogic({
  clientFunc: clientApi.getDetailVehicleSharing,
  type: UserTypes.GET_DETAIL_VEHICLES_SHARING_REQUEST,
  successType: UserTypes.GET_DETAIL_VEHICLES_SHARING_SUCCESS,
  failType: UserTypes.GET_DETAIL_VEHICLES_SHARING_FAILED,
  isQueryParam: false
})

const forceStatusPermanentExtension = bindRequestLogic({
  clientFunc: clientApi.forceStatusPermanentExtension,
  type: UserTypes.FORCE_STATUS_PERMANENT_EXTENSION_REQUEST,
  successType: UserTypes.FORCE_STATUS_PERMANENT_EXTENSION_SUCCESS,
  failType: UserTypes.FORCE_STATUS_PERMANENT_EXTENSION_FAILED
})

const forceStatusTemporalExtension = bindRequestLogic({
  clientFunc: clientApi.forceStatusTemporalExtension,
  type: UserTypes.FORCE_STATUS_TEMPORAL_EXTENSION_REQUEST,
  successType: UserTypes.FORCE_STATUS_TEMPORAL_EXTENSION_SUCCESS,
  failType: UserTypes.FORCE_STATUS_TEMPORAL_EXTENSION_FAILED
})

const forceStatusCheckIn = bindRequestLogic({
  clientFunc: clientApi.forceStatusCheckIn,
  type: UserTypes.FORCE_STATUS_CHECK_IN_REQUEST,
  successType: UserTypes.FORCE_STATUS_CHECK_IN_SUCCESS,
  failType: UserTypes.FORCE_STATUS_CHECK_IN_FAILED
})

const forceStatusCheckOut = bindRequestLogic({
  clientFunc: clientApi.forceStatusCheckOut,
  type: UserTypes.FORCE_STATUS_CHECK_OUT_REQUEST,
  successType: UserTypes.FORCE_STATUS_CHECK_OUT_SUCCESS,
  failType: UserTypes.FORCE_STATUS_CHECK_OUT_FAILED
})

const getStatusUsers = bindRequestLogic({
  clientFunc: clientApi.getStatusUsers,
  type: UserTypes.GET_STATUS_USERS_REQUEST,
  successType: UserTypes.GET_STATUS_USERS_SUCCESS,
  failType: UserTypes.GET_STATUS_USERS_FAILED,
  isQueryParam: true
})

const getDirectVehiclesByUserId = bindRequestLogic({
  clientFunc: clientApi.getDirectVehiclesByUserId,
  type: UserTypes.GET_DIRECT_VEHICLES_BY_USER_ID_REQUEST,
  successType: UserTypes.GET_DIRECT_VEHICLES_BY_USER_ID_SUCCESS,
  failType: UserTypes.GET_DIRECT_VEHICLES_BY_USER_ID_FAILED,
  isQueryParam: true
})

const cancelDirectVehicle = bindRequestLogic({
  clientFunc: clientApi.cancelDirectVehicle,
  type: UserTypes.CANCEL_DIRECT_VEHICLE_REQUEST,
  successType: UserTypes.CANCEL_DIRECT_VEHICLE_SUCCESS,
  failType: UserTypes.CANCEL_DIRECT_VEHICLE_FAILED
})

const getCommSystemLogs = bindRequestLogic({
  clientFunc: clientApi.getCommSystemLogs,
  type: UserTypes.GET_COMM_SYSTEM_LOGS_REQUEST,
  successType: UserTypes.GET_COMM_SYSTEM_LOGS_SUCCESS,
  failType: UserTypes.GET_COMM_SYSTEM_LOGS_FAILED,
  isQueryParam: true
})

const upsertGDPR = bindRequestLogic({
  clientFunc: clientApi.upsertGDPR,
  type: UserTypes.UPSERT_GDPR_REQUEST,
  successType: UserTypes.UPSERT_GDPR_SUCCESS,
  failType: UserTypes.UPSERT_GDPR_FAILED
})

const getGDPR = bindRequestLogic({
  clientFunc: clientApi.getGDPR,
  type: UserTypes.GET_GDPR_REQUEST,
  successType: UserTypes.GET_GDPR_SUCCESS,
  failType: UserTypes.GET_GDPR_FAILED
})

export default [
  getAppUserList,
  deleteAppUser,
  getAppUserDetail,
  getRoleUser,
  addUser,
  updateAppUser,
  updateRoleUser,
  getUserSubscription,
  getUserVehicles,
  getListIndividualVehicleSharingExtension,
  getDetailIndividualVehicleSharing,
  getSubscriptionAllowedPilots,
  forceStatusPermanentExtension,
  forceStatusTemporalExtension,
  forceStatusCheckIn,
  forceStatusCheckOut,
  getStatusUsers,
  getDirectVehiclesByUserId,
  cancelDirectVehicle,
  getCommSystemLogs,
  upsertGDPR,
  getGDPR
]
