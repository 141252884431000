import keyMirror from 'keymirror'

export default keyMirror({
  GET_LIST_NOTIFICATION_REQUEST_REQUEST: null,
  GET_LIST_NOTIFICATION_REQUEST_SUCCESS: null,
  GET_LIST_NOTIFICATION_REQUEST_FAILED: null,

  GET_LIST_NOTIFICATION_SENT_REQUEST: null,
  GET_LIST_NOTIFICATION_SENT_SUCCESS: null,
  GET_LIST_NOTIFICATION_SENT_FAILED: null,

  SEND_NOTIFICATION_REQUEST_REQUEST: null,
  SEND_NOTIFICATION_REQUEST_SUCCESS: null,
  SEND_NOTIFICATION_REQUEST_FAILED: null,

  UPDATE_FILTER_NOTIFICATION_REQUEST_SUCCESS: null,
  CLEAR_FILTER_NOTIFICATION_REQUEST_SUCCESS: null,

  UPDATE_FILTER_NOTIFICATION_SENT_SUCCESS: null,
  CLEAR_FILTER_NOTIFICATION_SENT_SUCCESS: null
})
