import BaseApi from './base'

class ShowcaseSection extends BaseApi {
  getShowcaseSections = (query) => this.client.get(`admin/sections?${query}`)

  getShowcaseSection = (id) => this.client.get(`admin/sections/${id}`)

  addShowcaseSection = (data) => this.client.post('admin/sections', data)

  updateShowcaseSection = (id, data) =>
    this.client.patch(`admin/sections/${id}`, data)

  deleteShowcaseSection = (id) => this.client.delete(`admin/sections/${id}`)

  getSystemSections = (query) =>
    this.client.get(`show-case-website/sections?${query}`)
}

export default new ShowcaseSection()
