import Types from '../actionTypes/companyCategory'
import { companyCategory as clientApi } from '../api'
import { bindRequestLogic } from './utils'
import { mapCompanyCategoryDetail } from '../mapping/roles'

const getCompanyCategories = bindRequestLogic({
  clientFunc: clientApi.getCompanyCategories,
  type: Types.GET_COMPANY_CATEGORIES_REQUEST,
  successType: Types.GET_COMPANY_CATEGORIES_SUCCESS,
  failType: Types.GET_COMPANY_CATEGORIES_FAILED,
  isQueryParam: true
})

const getCompanyCategory = bindRequestLogic({
  clientFunc: clientApi.getCompanyCategory,
  type: Types.GET_COMPANY_CATEGORY_REQUEST,
  successType: Types.GET_COMPANY_CATEGORY_SUCCESS,
  failType: Types.GET_COMPANY_CATEGORY_FAILED,
  modelName: 'companyCategory',
  mapResponse: mapCompanyCategoryDetail
})

const updateCompanyCategory = bindRequestLogic({
  clientFunc: clientApi.updateCompanyCategory,
  type: Types.UPDATE_COMPANY_CATEGORY_REQUEST,
  successType: Types.UPDATE_COMPANY_CATEGORY_SUCCESS,
  failType: Types.UPDATE_COMPANY_CATEGORY_FAILED
})

const addCompanyCategoryTranslation = bindRequestLogic({
  clientFunc: clientApi.addCompanyCategoryTranslation,
  type: Types.ADD_COMPANY_CATEGORY_TRANSLATION_REQUEST,
  successType: Types.ADD_COMPANY_CATEGORY_TRANSLATION_SUCCESS,
  failType: Types.ADD_COMPANY_CATEGORY_TRANSLATION_FAILED
})

const updateCompanyCategoryTranslation = bindRequestLogic({
  clientFunc: clientApi.updateCompanyCategoryTranslation,
  type: Types.UPDATE_COMPANY_CATEGORY_TRANSLATION_REQUEST,
  successType: Types.UPDATE_COMPANY_CATEGORY_TRANSLATION_SUCCESS,
  failType: Types.UPDATE_COMPANY_CATEGORY_TRANSLATION_FAILED
})

const deleteCompanyCategoryTranslation = bindRequestLogic({
  clientFunc: clientApi.deleteCompanyCategoryTranslation,
  type: Types.DELETE_COMPANY_CATEGORY_TRANSLATION_REQUEST,
  successType: Types.DELETE_COMPANY_CATEGORY_TRANSLATION_SUCCESS,
  failType: Types.DELETE_COMPANY_CATEGORY_TRANSLATION_FAILED
})

const getSystemCompanyCategories = bindRequestLogic({
  clientFunc: clientApi.getSystemCompanyCategories,
  type: Types.GET_SYSTEM_COMPANY_CATEGORIES_REQUEST,
  successType: Types.GET_SYSTEM_COMPANY_CATEGORIES_SUCCESS,
  failType: Types.GET_SYSTEM_COMPANY_CATEGORIES_FAILED,
  isQueryParam: true
})

export default [
  getCompanyCategories,
  getCompanyCategory,
  updateCompanyCategory,
  addCompanyCategoryTranslation,
  updateCompanyCategoryTranslation,
  deleteCompanyCategoryTranslation,
  getSystemCompanyCategories
]
