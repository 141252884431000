import keyMirror from 'keymirror'

export default keyMirror({
  GET_SYSTEM_ALERTS_REQUEST: null,
  GET_SYSTEM_ALERTS_SUCCESS: null,
  GET_SYSTEM_ALERTS_FAILED: null,

  GET_SYSTEM_ALERT_REQUEST: null,
  GET_SYSTEM_ALERT_SUCCESS: null,
  GET_SYSTEM_ALERT_FAILED: null,

  GET_ALERTS_REQUEST: null,
  GET_ALERTS_SUCCESS: null,
  GET_ALERTS_FAILED: null,

  GET_ALERT_DETAIL_REQUEST: null,
  GET_ALERT_DETAIL_SUCCESS: null,
  GET_ALERT_DETAIL_FAILED: null,

  UPDATE_FILTER_ALERT_SUCCESS: null,
  CLEAR_FILTER_ALERT_SUCCESS: null,

  ADD_ALERT_REQUEST: null,
  ADD_ALERT_SUCCESS: null,
  ADD_ALERT_FAILED: null,

  UPDATE_ALERT_REQUEST: null,
  UPDATE_ALERT_SUCCESS: null,
  UPDATE_ALERT_FAILED: null,

  DELETE_ALERT_REQUEST: null,
  DELETE_ALERT_SUCCESS: null,
  DELETE_ALERT_FAILED: null,

  ADD_ALERT_TRANSLATION_REQUEST: null,
  ADD_ALERT_TRANSLATION_SUCCESS: null,
  ADD_ALERT_TRANSLATION_FAILED: null,

  UPDATE_ALERT_TRANSLATION_REQUEST: null,
  UPDATE_ALERT_TRANSLATION_SUCCESS: null,
  UPDATE_ALERT_TRANSLATION_FAILED: null,

  DELETE_ALERT_TRANSLATION_REQUEST: null,
  DELETE_ALERT_TRANSLATION_SUCCESS: null,
  DELETE_ALERT_TRANSLATION_FAILED: null
})
