import VehicleModels from 'store/actionTypes/vehicleModel'
import { vehicleModel as clientApi } from 'store/api'
import { bindRequestLogic } from './utils'

const getVehicleModels = bindRequestLogic({
  clientFunc: clientApi.getVehicleModels,
  type: VehicleModels.GET_VEHICLE_MODELS_REQUEST,
  successType: VehicleModels.GET_VEHICLE_MODELS_SUCCESS,
  failType: VehicleModels.GET_VEHICLE_MODELS_FAILED,
  isQueryParam: true
})

const addVehicleModel = bindRequestLogic({
  clientFunc: clientApi.addVehicleModel,
  type: VehicleModels.ADD_VEHICLE_MODEL_REQUEST,
  successType: VehicleModels.ADD_VEHICLE_MODEL_SUCCESS,
  failType: VehicleModels.ADD_VEHICLE_MODEL_FAILED
})

const getVehicleModelDetail = bindRequestLogic({
  clientFunc: clientApi.getVehicleModelDetail,
  type: VehicleModels.GET_VEHICLE_MODEL_DETAIL_REQUEST,
  successType: VehicleModels.GET_VEHICLE_MODEL_DETAIL_SUCCESS,
  failType: VehicleModels.GET_VEHICLE_MODEL_DETAIL_FAILED,
  modelName: 'vehicleModel'
})

const updateVehicleModel = bindRequestLogic({
  clientFunc: clientApi.updateVehicleModel,
  type: VehicleModels.UPDATE_VEHICLE_MODEL_REQUEST,
  successType: VehicleModels.UPDATE_VEHICLE_MODEL_SUCCESS,
  failType: VehicleModels.UPDATE_VEHICLE_MODEL_FAILED
})

const deleteVehicleModel = bindRequestLogic({
  clientFunc: clientApi.deleteVehicleModel,
  type: VehicleModels.DELETE_VEHICLE_MODEL_REQUEST,
  successType: VehicleModels.DELETE_VEHICLE_MODEL_SUCCESS,
  failType: VehicleModels.DELETE_VEHICLE_MODEL_FAILED
})

const getSystemVehicleModels = bindRequestLogic({
  clientFunc: clientApi.getSystemVehicleModels,
  type: VehicleModels.GET_SYSTEM_VEHICLE_MODELS_REQUEST,
  successType: VehicleModels.GET_SYSTEM_VEHICLE_MODELS_SUCCESS,
  failType: VehicleModels.GET_SYSTEM_VEHICLE_MODELS_FAILED,
  isQueryParam: true
})

const getVehicleModelsByVehicleBrand = bindRequestLogic({
  clientFunc: clientApi.getVehicleModelsByVehicleBrand,
  type: VehicleModels.GET_VEHICLE_MODELS_BY_VEHICLE_BRAND_REQUEST,
  successType: VehicleModels.GET_VEHICLE_MODELS_BY_VEHICLE_BRAND_SUCCESS,
  failType: VehicleModels.GET_VEHICLE_MODELS_BY_VEHICLE_BRAND_FAILED
})

export default [
  getVehicleModels,
  addVehicleModel,
  getVehicleModelDetail,
  deleteVehicleModel,
  updateVehicleModel,
  getSystemVehicleModels,
  getVehicleModelsByVehicleBrand
]
