import Types from '../actionTypes/odooSynchronization'
import { odooSynchronizationAPI } from '../api'
import { bindRequestLogic } from './utils'

const getOdooSynchronizations = bindRequestLogic({
  clientFunc: odooSynchronizationAPI.getOdooSynchronizations,
  type: Types.GET_ODOO_SYNCHRONIZATIONS_REQUEST,
  successType: Types.GET_ODOO_SYNCHRONIZATIONS_SUCCESS,
  failType: Types.GET_ODOO_SYNCHRONIZATIONS_FAILED
})

export default [getOdooSynchronizations]
