import keyMirror from 'keymirror'

export default keyMirror({
  GET_COMPANY_PERS_LINK_REQUEST: null,
  GET_COMPANY_PERS_LINK_SUCCESS: null,
  GET_COMPANY_PERS_LINK_FAILED: null,

  ADD_COMPANY_PER_LINK_REQUEST: null,
  ADD_COMPANY_PER_LINK_SUCCESS: null,
  ADD_COMPANY_PER_LINK_FAILED: null,

  UPDATE_COMPANY_PER_LINK_REQUEST: null,
  UPDATE_COMPANY_PER_LINK_SUCCESS: null,
  UPDATE_COMPANY_PER_LINK_FAILED: null,

  DELETE_COMPANY_PER_LINK_REQUEST: null,
  DELETE_COMPANY_PER_LINK_SUCCESS: null,
  DELETE_COMPANY_PER_LINK_FAILED: null,

  UPDATE_FILTER_COMPANY_PER_LINK_SUCCESS: null,
  CLEAR_FILTER_COMPANY_PER_LINK_SUCCESS: null
})
