import { combineReducers } from 'redux'
import ModelPerTypes from 'store/actionTypes/modelPer'
import { createHandleRequest } from './helpers'

const getModelPers = createHandleRequest(
  ModelPerTypes.GET_MODEL_PERS_REQUEST,
  ModelPerTypes.GET_MODEL_PERS_SUCCESS,
  ModelPerTypes.GET_MODEL_PERS_FAILED
)

export default combineReducers({
  getModelPers
})
