import { combineReducers } from 'redux'
import Types from 'store/actionTypes/companyPerLink'
import { createHandleRequest } from './helpers'

const getCompanyPersLink = createHandleRequest(
  Types.GET_COMPANY_PERS_LINK_REQUEST,
  Types.GET_COMPANY_PERS_LINK_SUCCESS,
  Types.GET_COMPANY_PERS_LINK_FAILED
)

export default combineReducers({
  getCompanyPersLink
})
