import RequestStatus from 'constants/requestStatus'
import { createSelector } from 'reselect'

export const getSystemConfigList = (state) =>
  state?.store?.systemConfig?.list || []

export const getPagination = (state) =>
  state.store?.systemConfig?.pagination || {}

export const moduleList = createSelector(
  [(state) => state?.store?.systemConfig?.modules],
  (modules = []) => modules.filter((module) => module.name !== 'portal')
)

// export const moduleList = (state) => {
//   const modules = state?.store?.systemConfig?.modules || []
//   return modules.filter((module) => module.name !== 'portal')
// }

export const defaultTimezone = (state) => {
  const configs = state?.store?.systemConfig?.systems || []
  return configs.find((conf) => conf.code === 'default_timezone')?.value
}

export const systemConfigList = (state) =>
  state?.store?.systemConfig?.systems || []

export const getVehicleModelList = (state) =>
  state?.store?.systemConfig?.vehicleModels || []

export const isLoading = (state) =>
  state.requests?.systemConfig?.getSystemConfigs.status ===
  RequestStatus.STARTED

export const currentModule = (state) => state?.store.systemConfig.currentModule
