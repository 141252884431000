import BaseApi from './base'

class MessageAfterConnection extends BaseApi {
  getMessageAfterConnections = (query) =>
    this.client.get(`admin/message-after-connection?${query}`)

  getMessageAfterConnection = (id) =>
    this.client.get(`admin/message-after-connection/${id}`)

  addMessageAfterConnection = (data) =>
    this.client.post('admin/message-after-connection', data)

  updateMessageAfterConnection = (id, data) =>
    this.client.patch(`admin/message-after-connection/${id}`, data)

  deleteMessageAfterConnection = (id) =>
    this.client.delete(`admin/message-after-connection/${id}`)

  msgAfterConnectionTranslation = (langId, data) =>
    this.client.post(
      `admin/message-after-connection/language/${langId}/translation`,
      data
    )

  deleteMsgAfterConnectionTranslation = (id) =>
    this.client?.delete(`admin/message-after-connection/translation/${id}`)

  getSystemMessagesAfterConnections = (query) =>
    this.client.get(`admin/system/message-after-connection?${query}`)
}

export default new MessageAfterConnection()
