import { DEFAULT_FAQ_FILTER } from 'constants/common'
import FAQTypes from 'store/actionTypes/faq'
import UserTypes from 'store/actionTypes/user'

const initFAQ = {
  list: [],
  current: {},
  pagination: {},
  filter: DEFAULT_FAQ_FILTER,
  filterLanguage: '',
  components: {
    list: [],
    pagination: {}
  }
}

const faq = (state = initFAQ, action) => {
  const data = action?.payload || []
  switch (action.type) {
    case FAQTypes.GET_FAQS_SUCCESS:
      return {
        ...state,
        list: data?.items,
        pagination: data?.meta
      }
    case FAQTypes.GET_FAQ_DETAIL_SUCCESS:
      return {
        ...state,
        current: data
      }
    case FAQTypes.UPDATE_FILTER_FAQ_SUCCESS:
      return { ...state, filter: data }
    case FAQTypes.UPDATE_FAQ_LANGUAGE_FILTER_SUCCESS:
      return { ...state, filterLanguage: data }
    case UserTypes.LOGOUT_SUCCESS:
      return initFAQ
    default:
      return state
  }
}

export default faq
