import keyMirror from 'keymirror'

export default keyMirror({
  GET_VEHICLE_MODELS_REQUEST: null,
  GET_VEHICLE_MODELS_SUCCESS: null,
  GET_VEHICLE_MODELS_FAILED: null,

  ADD_VEHICLE_MODEL_REQUEST: null,
  ADD_VEHICLE_MODEL_SUCCESS: null,
  ADD_VEHICLE_MODEL_FAILED: null,

  GET_VEHICLE_MODEL_DETAIL_REQUEST: null,
  GET_VEHICLE_MODEL_DETAIL_SUCCESS: null,
  GET_VEHICLE_MODEL_DETAIL_FAILED: null,

  UPDATE_VEHICLE_MODEL_REQUEST: null,
  UPDATE_VEHICLE_MODEL_SUCCESS: null,
  UPDATE_VEHICLE_MODEL_FAILED: null,

  DELETE_VEHICLE_MODEL_REQUEST: null,
  DELETE_VEHICLE_MODEL_SUCCESS: null,
  DELETE_VEHICLE_MODEL_FAILED: null,

  UPDATE_FILTER_VEHICLE_MODEL_SUCCESS: null,
  CLEAR_FILTER_VEHICLE_MODEL_SUCCESS: null,

  GET_VEHICLE_MODELS_BY_VEHICLE_BRAND_REQUEST: null,
  GET_VEHICLE_MODELS_BY_VEHICLE_BRAND_SUCCESS: null,
  GET_VEHICLE_MODELS_BY_VEHICLE_BRAND_FAILED: null,

  GET_SYSTEM_VEHICLE_MODELS_REQUEST: null,
  GET_SYSTEM_VEHICLE_MODELS_SUCCESS: null,
  GET_SYSTEM_VEHICLE_MODELS_FAILED: null
})
