import keyMirror from 'keymirror'

export default keyMirror({
  GET_SHOWCASE_STORIES_REQUEST: null,
  GET_SHOWCASE_STORIES_SUCCESS: null,
  GET_SHOWCASE_STORIES_FAILED: null,

  GET_SHOWCASE_STORY_DETAIL_REQUEST: null,
  GET_SHOWCASE_STORY_DETAIL_SUCCESS: null,
  GET_SHOWCASE_STORY_DETAIL_FAILED: null,

  DELETE_SHOWCASE_STORY_REQUEST: null,
  DELETE_SHOWCASE_STORY_SUCCESS: null,
  DELETE_SHOWCASE_STORY_FAILED: null,

  ADD_SHOWCASE_STORY_REQUEST: null,
  ADD_SHOWCASE_STORY_SUCCESS: null,
  ADD_SHOWCASE_STORY_FAILED: null,

  UPDATE_SHOWCASE_STORY_REQUEST: null,
  UPDATE_SHOWCASE_STORY_SUCCESS: null,
  UPDATE_SHOWCASE_STORY_FAILED: null,

  UPDATE_SHOWCASE_STORY_CONTENT_REQUEST: null,
  UPDATE_SHOWCASE_STORY_CONTENT_SUCCESS: null,
  UPDATE_SHOWCASE_STORY_CONTENT_FAILED: null,

  UPDATE_FILTER_SHOWCASE_STORY_SUCCESS: null,
  CLEAR_FILTER_SHOWCASE_STORY_SUCCESS: null,

  ADD_SHOWCASE_STORY_CONTENT_REQUEST: null,
  ADD_SHOWCASE_STORY_CONTENT_SUCCESS: null,
  ADD_SHOWCASE_STORY_CONTENT_FAILED: null,

  GET_SHOWCASE_STORY_CONTENTS_REQUEST: null,
  GET_SHOWCASE_STORY_CONTENTS_SUCCESS: null,
  GET_SHOWCASE_STORY_CONTENTS_FAILED: null,

  PUBLISH_SHOWCASE_STORY_REQUEST: null,
  PUBLISH_SHOWCASE_STORY_SUCCESS: null,
  PUBLISH_SHOWCASE_STORY_FAILED: null
})
