import keyMirror from 'keymirror'

export default keyMirror({
  GET_CURRENCIES_REQUEST: null,
  GET_CURRENCIES_SUCCESS: null,
  GET_CURRENCIES_FAILED: null,

  ADD_CURRENCY_REQUEST: null,
  ADD_CURRENCY_SUCCESS: null,
  ADD_CURRENCY_FAILED: null,

  GET_CURRENCY_DETAIL_REQUEST: null,
  GET_CURRENCY_DETAIL_SUCCESS: null,
  GET_CURRENCY_DETAIL_FAILED: null,

  UPDATE_CURRENCY_REQUEST: null,
  UPDATE_CURRENCY_SUCCESS: null,
  UPDATE_CURRENCY_FAILED: null,

  DELETE_CURRENCY_REQUEST: null,
  DELETE_CURRENCY_SUCCESS: null,
  DELETE_CURRENCY_FAILED: null,

  ADD_CURRENCY_TRANSLATION_REQUEST: null,
  ADD_CURRENCY_TRANSLATION_SUCCESS: null,
  ADD_CURRENCY_TRANSLATION_FAILED: null,

  UPDATE_CURRENCY_TRANSLATION_REQUEST: null,
  UPDATE_CURRENCY_TRANSLATION_SUCCESS: null,
  UPDATE_CURRENCY_TRANSLATION_FAILED: null,

  DELETE_CURRENCY_TRANSLATION_REQUEST: null,
  DELETE_CURRENCY_TRANSLATION_SUCCESS: null,
  DELETE_CURRENCY_TRANSLATION_FAILED: null,

  UPDATE_FILTER_CURRENCY_SUCCESS: null,
  CLEAR_FILTER_CURRENCY_SUCCESS: null,

  GET_SYSTEM_CURRENCIES_REQUEST: null,
  GET_SYSTEM_CURRENCIES_SUCCESS: null,
  GET_SYSTEM_CURRENCIES_FAILED: null
})
