import { combineReducers } from 'redux'
import Types from 'store/actionTypes/keyUsageSystemLog'
import { createHandleRequest } from './helpers'

const getKeyUsageSystemLogs = createHandleRequest(
  Types.GET_KEY_USAGE_SYSTEM_LOGS_REQUEST,
  Types.GET_KEY_USAGE_SYSTEM_LOGS_SUCCESS,
  Types.GET_KEY_USAGE_SYSTEM_LOGS_FAILED
)

export default combineReducers({
  getKeyUsageSystemLogs
})
