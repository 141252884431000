import SoftwarePerTypes from '../actionTypes/softwarePer'
import { softwarePer as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const getSoftwarePers = bindRequestLogic({
  clientFunc: clientApi.getSoftwarePers,
  type: SoftwarePerTypes.GET_SOFTWARE_PERS_REQUEST,
  successType: SoftwarePerTypes.GET_SOFTWARE_PERS_SUCCESS,
  failType: SoftwarePerTypes.GET_SOFTWARE_PERS_FAILED,
  isQueryParam: true
})

const addSoftwarePer = bindRequestLogic({
  clientFunc: clientApi.addSoftwarePer,
  type: SoftwarePerTypes.ADD_SOFTWARE_PER_REQUEST,
  successType: SoftwarePerTypes.ADD_SOFTWARE_PER_SUCCESS,
  failType: SoftwarePerTypes.ADD_SOFTWARE_PER_FAILED
})

const getSoftwarePerBy = bindRequestLogic({
  clientFunc: clientApi.getSoftwarePerBy,
  type: SoftwarePerTypes.GET_SOFTWARE_PER_DETAIL_REQUEST,
  successType: SoftwarePerTypes.GET_SOFTWARE_PER_DETAIL_SUCCESS,
  failType: SoftwarePerTypes.GET_SOFTWARE_PER_DETAIL_FAILED
})

const updateSoftwarePer = bindRequestLogic({
  clientFunc: clientApi.updateSoftwarePer,
  type: SoftwarePerTypes.UPDATE_SOFTWARE_PER_REQUEST,
  successType: SoftwarePerTypes.UPDATE_SOFTWARE_PER_SUCCESS,
  failType: SoftwarePerTypes.UPDATE_SOFTWARE_PER_FAILED
})

const deleteSoftwarePer = bindRequestLogic({
  clientFunc: clientApi.deleteSoftwarePer,
  type: SoftwarePerTypes.DELETE_SOFTWARE_PER_REQUEST,
  successType: SoftwarePerTypes.DELETE_SOFTWARE_PER_SUCCESS,
  failType: SoftwarePerTypes.DELETE_SOFTWARE_PER_FAILED
})

const getSystemSoftwarePers = bindRequestLogic({
  clientFunc: clientApi.getSystemSoftwarePers,
  type: SoftwarePerTypes.GET_SYSTEM_SOFTWARE_PERS_REQUEST,
  successType: SoftwarePerTypes.GET_SYSTEM_SOFTWARE_PERS_SUCCESS,
  failType: SoftwarePerTypes.GET_SYSTEM_SOFTWARE_PERS_FAILED,
  isQueryParam: true
})

export default [
  getSoftwarePers,
  addSoftwarePer,
  updateSoftwarePer,
  deleteSoftwarePer,
  getSoftwarePerBy,
  getSystemSoftwarePers
]
