import NotificationTemplateContentTypes from '../actionTypes/notificationTemplateContent'
import { notificationTemplateContent as notificationTemplateContentApi } from '../api'
import { bindRequestLogic } from './utils'

const getNotificationTemplateContentByNotificationTemplateId = bindRequestLogic(
  {
    clientFunc:
      notificationTemplateContentApi.getNotificationTemplateContentByNotificationId,
    type: NotificationTemplateContentTypes.GET_NOTIFICATION_TEMPLATE_CONTENT_BY_NOTIFICATION_TEMPLATE_ID_REQUEST,
    successType:
      NotificationTemplateContentTypes.GET_NOTIFICATION_TEMPLATE_CONTENT_BY_NOTIFICATION_TEMPLATE_ID_SUCCESS,
    failType:
      NotificationTemplateContentTypes.GET_NOTIFICATION_TEMPLATE_CONTENT_BY_NOTIFICATION_TEMPLATE_ID_FAILED,
    isQueryParam: true
  }
)

const addNotificationTemplateContent = bindRequestLogic({
  clientFunc: notificationTemplateContentApi.addNotificationTemplateContent,
  type: NotificationTemplateContentTypes.ADD_NOTIFICATION_TEMPLATE_CONTENT_REQUEST,
  successType:
    NotificationTemplateContentTypes.ADD_NOTIFICATION_TEMPLATE_CONTENT_SUCCESS,
  failType:
    NotificationTemplateContentTypes.ADD_NOTIFICATION_TEMPLATE_CONTENT_FAILED
})

const updateNotificationTemplateContent = bindRequestLogic({
  clientFunc: notificationTemplateContentApi.updateNotificationTemplateContent,
  type: NotificationTemplateContentTypes.UPDATE_NOTIFICATION_TEMPLATE_CONTENT_REQUEST,
  successType:
    NotificationTemplateContentTypes.UPDATE_NOTIFICATION_TEMPLATE_CONTENT_SUCCESS,
  failType:
    NotificationTemplateContentTypes.UPDATE_NOTIFICATION_TEMPLATE_CONTENT_FAILED
})

const deleteNotificationTemplateContent = bindRequestLogic({
  clientFunc: notificationTemplateContentApi.deleteNotificationTemplateContent,
  type: NotificationTemplateContentTypes.DELETE_NOTIFICATION_TEMPLATE_CONTENT_REQUEST,
  successType:
    NotificationTemplateContentTypes.DELETE_NOTIFICATION_TEMPLATE_CONTENT_SUCCESS,
  failType:
    NotificationTemplateContentTypes.DELETE_NOTIFICATION_TEMPLATE_CONTENT_FAILED
})

const getSystemNotificationTemplateContentById = bindRequestLogic({
  clientFunc:
    notificationTemplateContentApi.getSystemNotificationTemplateContentById,
  type: NotificationTemplateContentTypes.GET_SYSTEM_NOTIFICATION_TEMPLATE_CONTENT_BY_ID_REQUEST,
  successType:
    NotificationTemplateContentTypes.GET_SYSTEM_NOTIFICATION_TEMPLATE_CONTENT_BY_ID_SUCCESS,
  failType:
    NotificationTemplateContentTypes.GET_SYSTEM_NOTIFICATION_TEMPLATE_CONTENT_BY_ID_FAILED
})

export default [
  addNotificationTemplateContent,
  updateNotificationTemplateContent,
  deleteNotificationTemplateContent,
  getNotificationTemplateContentByNotificationTemplateId,
  getSystemNotificationTemplateContentById
]
