import BaseApi from './base'

class ShowcaseContactLog extends BaseApi {
  constructor() {
    super()
    this.client.defaults.headers.Accept = 'multipart/form-data'
  }

  getShowcaseContactLogs = (query) =>
    this.client.get(`/admin/platform-contact-logs?${query}`)

  exportShowcaseContactLogs = (query) =>
    this.client.get(`/admin/platform-contact-logs/export?${query}`, {
      responseType: 'blob'
    })

  updateAnonymizeVisitor = (id) =>
    this.client.post(`/admin/platform-contact-logs/${id}/anonymized`)
}

export default new ShowcaseContactLog()
