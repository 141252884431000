import CompanyVehicleBrandLinks from '../actionTypes/companyVehicleBrandLink'
import { companyVehicleBrandLink as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const getCompanyVehicleBrandLinks = bindRequestLogic({
  clientFunc: clientApi.getCompanyVehicleBrandLinks,
  type: CompanyVehicleBrandLinks.GET_COMPANY_VEHICLE_BRAND_LINKS_REQUEST,
  successType: CompanyVehicleBrandLinks.GET_COMPANY_VEHICLE_BRAND_LINKS_SUCCESS,
  failType: CompanyVehicleBrandLinks.GET_COMPANY_VEHICLE_BRAND_LINKS_FAILED,
  isQueryParam: true
})

const getCompanyVehicleBrandLinkDetail = bindRequestLogic({
  clientFunc: clientApi.getCompanyVehicleBrandLinkDetail,
  type: CompanyVehicleBrandLinks.GET_COMPANY_VEHICLE_BRAND_LINK_DETAIL_REQUEST,
  successType:
    CompanyVehicleBrandLinks.GET_COMPANY_VEHICLE_BRAND_LINK_DETAIL_SUCCESS,
  failType:
    CompanyVehicleBrandLinks.GET_COMPANY_VEHICLE_BRAND_LINK_DETAIL_FAILED,
  modelName: 'CompanyVehicleBrandLink'
})

const addCompanyVehicleBrandLink = bindRequestLogic({
  clientFunc: clientApi.addCompanyVehicleBrandLink,
  type: CompanyVehicleBrandLinks.ADD_COMPANY_VEHICLE_BRAND_LINK_REQUEST,
  successType: CompanyVehicleBrandLinks.ADD_COMPANY_VEHICLE_BRAND_LINK_SUCCESS,
  failType: CompanyVehicleBrandLinks.ADD_COMPANY_VEHICLE_BRAND_LINK_FAILED
})

const deleteCompanyVehicleBrandLink = bindRequestLogic({
  clientFunc: clientApi.deleteCompanyVehicleBrandLink,
  type: CompanyVehicleBrandLinks.DELETE_COMPANY_VEHICLE_BRAND_LINK_REQUEST,
  successType:
    CompanyVehicleBrandLinks.DELETE_COMPANY_VEHICLE_BRAND_LINK_SUCCESS,
  failType: CompanyVehicleBrandLinks.DELETE_COMPANY_VEHICLE_BRAND_LINK_FAILED
})

const updateCompanyVehicleBrandLink = bindRequestLogic({
  clientFunc: clientApi.updateCompanyVehicleBrandLink,
  type: CompanyVehicleBrandLinks.UPDATE_COMPANY_VEHICLE_BRAND_LINK_REQUEST,
  successType:
    CompanyVehicleBrandLinks.UPDATE_COMPANY_VEHICLE_BRAND_LINK_SUCCESS,
  failType: CompanyVehicleBrandLinks.UPDATE_COMPANY_VEHICLE_BRAND_LINK_FAILED
})

export default [
  getCompanyVehicleBrandLinks,
  getCompanyVehicleBrandLinkDetail,
  addCompanyVehicleBrandLink,
  updateCompanyVehicleBrandLink,
  deleteCompanyVehicleBrandLink
]
