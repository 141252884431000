import FAQTypes from '../actionTypes/faq'
import { faq as FAQApi } from '../api'
import { bindRequestLogic } from './utils'
import { mapFAQDetail } from '../mapping/faq'

const getFAQs = bindRequestLogic({
  clientFunc: FAQApi.getFAQs,
  type: FAQTypes.GET_FAQS_REQUEST,
  successType: FAQTypes.GET_FAQS_SUCCESS,
  failType: FAQTypes.GET_FAQS_FAILED,
  isQueryParam: true
})

const addFAQ = bindRequestLogic({
  clientFunc: FAQApi.addFAQ,
  type: FAQTypes.ADD_FAQ_REQUEST,
  successType: FAQTypes.ADD_FAQ_SUCCESS,
  failType: FAQTypes.ADD_FAQ_FAILED
})

const updateFAQ = bindRequestLogic({
  clientFunc: FAQApi.updateFAQ,
  type: FAQTypes.UPDATE_FAQ_REQUEST,
  successType: FAQTypes.UPDATE_FAQ_SUCCESS,
  failType: FAQTypes.UPDATE_FAQ_FAILED
})

const getFAQ = bindRequestLogic({
  clientFunc: FAQApi.getFAQ,
  type: FAQTypes.GET_FAQ_DETAIL_REQUEST,
  successType: FAQTypes.GET_FAQ_DETAIL_SUCCESS,
  failType: FAQTypes.GET_FAQ_DETAIL_FAILED,
  mapResponse: mapFAQDetail
})

const deleteFAQ = bindRequestLogic({
  clientFunc: FAQApi.deleteFAQ,
  type: FAQTypes.DELETE_FAQ_REQUEST,
  successType: FAQTypes.DELETE_FAQ_SUCCESS,
  failType: FAQTypes.DELETE_FAQ_FAILED
})

const upsertContentTranslation = bindRequestLogic({
  clientFunc: FAQApi.addFAQContentTranslation,
  type: FAQTypes.UPDATE_FAQ_TRANSLATION_REQUEST,
  successType: FAQTypes.UPDATE_FAQ_TRANSLATION_SUCCESS,
  failType: FAQTypes.UPDATE_FAQ_TRANSLATION_FAILED
})

const addFAQContent = bindRequestLogic({
  clientFunc: FAQApi.addFAQContent,
  type: FAQTypes.ADD_FAQ_TRANSLATION_REQUEST,
  successType: FAQTypes.ADD_FAQ_TRANSLATION_SUCCESS,
  failType: FAQTypes.ADD_FAQ_TRANSLATION_FAILED
})

const getFAQContents = bindRequestLogic({
  clientFunc: FAQApi.getFAQContent,
  type: FAQTypes.GET_FAQ_TRANSLATIONS_REQUEST,
  successType: FAQTypes.GET_FAQ_TRANSLATIONS_SUCCESS,
  failType: FAQTypes.GET_FAQ_TRANSLATIONS_FAILED
})

export default [
  getFAQs,
  addFAQ,
  deleteFAQ,
  updateFAQ,
  getFAQ,
  upsertContentTranslation,
  addFAQContent,
  getFAQContents
]
