import qs from 'qs'
import { MAP_MODULE_TO_FILTER } from 'constants/common'

/**
 * Get the module filter for a specific feature.
 *
 * @param {Object} state - The current state object.
 * @param {string} featureName - The name of the feature.
 * @param {Object} defaultFilter - The default filter object.
 * @returns {Object} - The module filter for the feature.
 */
export const getModuleFilter = (
  state = {},
  featureName,
  defaultFilter = {}
) => {
  const currentModule = state.store?.systemConfig?.currentModule

  return (
    state.store[featureName][MAP_MODULE_TO_FILTER[currentModule]] ||
    defaultFilter
  )
}

export const stringifyFilterToQuery = (filter) => {
  return qs.stringify(filter, {
    arrayFormat: 'brackets'
  })
}

/** UPDATE STATE */
/**
 * Update module filter
 * Used for feature that has different filter for each module
 * such as: message, label, mail template, etc
 * @param state
 * @param payload
 */
export const updateModuleFilter = (state, payload) => ({
  ...state,
  [payload.key]: payload.value
})

/**
 * Clear module filter
 * Used for feature that has different filter for each module
 * such as: message, label, mail template, etc
 * @param state
 * @param payload
 * @param initData
 */
export const clearModuleFilter = (state, payload, initData) => ({
  ...state,
  [payload.key]: initData[payload.key]
})
