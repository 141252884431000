import keyMirror from 'keymirror'

export default keyMirror({
  GET_COMPANY_ROLES_REQUEST: null,
  GET_COMPANY_ROLES_SUCCESS: null,
  GET_COMPANY_ROLES_FAILED: null,

  GET_COMPANY_ROLE_REQUEST: null,
  GET_COMPANY_ROLE_SUCCESS: null,
  GET_COMPANY_ROLE_FAILED: null,

  ADD_COMPANY_ROLE_REQUEST: null,
  ADD_COMPANY_ROLE_SUCCESS: null,
  ADD_COMPANY_ROLE_FAILED: null,

  UPDATE_COMPANY_ROLE_REQUEST: null,
  UPDATE_COMPANY_ROLE_SUCCESS: null,
  UPDATE_COMPANY_ROLE_FAILED: null,

  DELETE_COMPANY_ROLE_REQUEST: null,
  DELETE_COMPANY_ROLE_SUCCESS: null,
  DELETE_COMPANY_ROLE_FAILED: null,

  UPSERT_COMPANY_ROLE_TRANSLATION_REQUEST: null,
  UPSERT_COMPANY_ROLE_TRANSLATION_SUCCESS: null,
  UPSERT_COMPANY_ROLE_TRANSLATION_FAILED: null,

  DELETE_COMPANY_ROLE_TRANSLATION_REQUEST: null,
  DELETE_COMPANY_ROLE_TRANSLATION_SUCCESS: null,
  DELETE_COMPANY_ROLE_TRANSLATION_FAILED: null,

  GET_COMPANY_ROLE_CONFIGURATION_REQUEST: null,
  GET_COMPANY_ROLE_CONFIGURATION_SUCCESS: null,
  GET_COMPANY_ROLE_CONFIGURATION_FAILED: null,

  UPDATE_COMPANY_ROLE_CONFIGURATION_REQUEST: null,
  UPDATE_COMPANY_ROLE_CONFIGURATION_SUCCESS: null,
  UPDATE_COMPANY_ROLE_CONFIGURATION_FAILED: null,

  GET_SYSTEM_COMPANY_ROLES_REQUEST: null,
  GET_SYSTEM_COMPANY_ROLES_SUCCESS: null,
  GET_SYSTEM_COMPANY_ROLES_FAILED: null,

  GET_COMPANY_ALLOW_TO_MANAGE_REQUEST: null,
  GET_COMPANY_ALLOW_TO_MANAGE_SUCCESS: null,
  GET_COMPANY_ALLOW_TO_MANAGE_FAILED: null,

  UPDATE_COMPANY_ALLOW_TO_MANAGE_REQUEST: null,
  UPDATE_COMPANY_ALLOW_TO_MANAGE_SUCCESS: null,
  UPDATE_COMPANY_ALLOW_TO_MANAGE_FAILED: null,

  GET_PERMISSIONS_BY_COMPANY_ID_REQUEST: null,
  GET_PERMISSIONS_BY_COMPANY_ID_SUCCESS: null,
  GET_PERMISSIONS_BY_COMPANY_ID_FAILED: null
})
