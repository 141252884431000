import ConditionalFeatureTypes from '../actionTypes/conditionalFeature'
import { conditionalFeature as conditionalFeatureApi } from '../api'
import { bindRequestLogic } from './utils'

const getConditionalFeatures = bindRequestLogic({
  clientFunc: conditionalFeatureApi.getConditionalFeatures,
  type: ConditionalFeatureTypes.GET_CONDITIONAL_FEATURE_REQUEST,
  successType: ConditionalFeatureTypes.GET_CONDITIONAL_FEATURE_SUCCESS,
  failType: ConditionalFeatureTypes.GET_CONDITIONAL_FAILED,
  isQueryParam: true
})

const addConditionalFeature = bindRequestLogic({
  clientFunc: conditionalFeatureApi.addConditionalFeature,
  type: ConditionalFeatureTypes.ADD_CONDITIONAL_FEATURE_REQUEST,
  successType: ConditionalFeatureTypes.ADD_CONDITIONAL_FEATURE_SUCCESS,
  failType: ConditionalFeatureTypes.ADD_CONDITIONAL_FEATURE_FAILED
})

const updateConditionalFeature = bindRequestLogic({
  clientFunc: conditionalFeatureApi.updateConditionalFeature,
  type: ConditionalFeatureTypes.UPDATE_CONDITIONAL_FEATURE_REQUEST,
  successType: ConditionalFeatureTypes.UPDATE_CONDITIONAL_FEATURE_SUCCESS,
  failType: ConditionalFeatureTypes.UPDATE_CONDITIONAL_FEATURE_FAILED
})

const deleteConditionalFeature = bindRequestLogic({
  clientFunc: conditionalFeatureApi.deleteConditionalFeature,
  type: ConditionalFeatureTypes.DELETE_CONDITIONAL_FEATURE_REQUEST,
  successType: ConditionalFeatureTypes.DELETE_CONDITIONAL_FEATURE_SUCCESS,
  failType: ConditionalFeatureTypes.DELETE_CONDITIONAL_FEATURE_FAILED
})

export default [
  getConditionalFeatures,
  deleteConditionalFeature,
  updateConditionalFeature,
  addConditionalFeature
]
