import BaseApi from './base'

class ShowcaseComponent extends BaseApi {
  getShowcaseComponents = (id, query) =>
    this.client.get(`admin/sections/${id}/component?${query}`)

  addShowcaseComponent = (id, data) =>
    this.client.post(`admin/sections/${id}/component`, data)

  updateShowcaseComponent = (id, data) =>
    this.client.patch(`admin/sections/component/${id}`, data)

  deleteShowcaseComponent = (id) =>
    this.client.delete(`admin/sections/component/${id}`)

  updateTranslation = (id, data) =>
    this.client.post(`admin/sections/component/${id}/translation`, data)
}

export default new ShowcaseComponent()
