import keyMirror from 'keymirror'

export default keyMirror({
  GET_ALERT_TOPICS_REQUEST: null,
  GET_ALERT_TOPICS_SUCCESS: null,
  GET_ALERT_TOPICS_FAILED: null,

  ADD_ALERT_TOPIC_REQUEST: null,
  ADD_ALERT_TOPIC_SUCCESS: null,
  ADD_ALERT_TOPIC_FAILED: null,

  GET_ALERT_TOPIC_DETAIL_REQUEST: null,
  GET_ALERT_TOPIC_DETAIL_SUCCESS: null,
  GET_ALERT_TOPIC_DETAIL_FAILED: null,

  UPDATE_ALERT_TOPIC_REQUEST: null,
  UPDATE_ALERT_TOPIC_SUCCESS: null,
  UPDATE_ALERT_TOPIC_FAILED: null,

  DELETE_ALERT_TOPIC_REQUEST: null,
  DELETE_ALERT_TOPIC_SUCCESS: null,
  DELETE_ALERT_TOPIC_FAILED: null,

  UPSERT_ALERT_TOPIC_TRANSLATION_REQUEST: null,
  UPSERT_ALERT_TOPIC_TRANSLATION_SUCCESS: null,
  UPSERT_ALERT_TOPIC_TRANSLATION_FAILED: null,

  DELETE_ALERT_TOPIC_TRANSLATION_REQUEST: null,
  DELETE_ALERT_TOPIC_TRANSLATION_SUCCESS: null,
  DELETE_ALERT_TOPIC_TRANSLATION_FAILED: null,

  UPDATE_FILTER_ALERT_TOPIC_REQUEST: null,
  UPDATE_FILTER_ALERT_TOPIC_SUCCESS: null,
  CLEAR_ALERT_TOPIC_FILTER_REQUEST: null,
  CLEAR_ALERT_TOPIC_FILTER_SUCCESS: null,

  GET_SYSTEM_ALERT_TOPICS_REQUEST: null,
  GET_SYSTEM_ALERT_TOPICS_SUCCESS: null,
  GET_SYSTEM_ALERT_TOPICS_FAILED: null
})
