import { DEFAULT_MESSAGE_FILTER } from 'constants/common'
import MessageTypes from 'store/actionTypes/message'
import UserTypes from 'store/actionTypes/user'
// import { clearModuleFilter, updateModuleFilter } from 'helpers/filter'
import { getTranslationMessages } from '../mapping/shared'

const initialData = {
  list: [],
  current: {},
  pagination: {},
  systems: {},
  filter: DEFAULT_MESSAGE_FILTER,
  scwFilter: DEFAULT_MESSAGE_FILTER,
  mobileFilter: DEFAULT_MESSAGE_FILTER,
  fleetFilter: DEFAULT_MESSAGE_FILTER
}

const label = (state = initialData, action) => {
  switch (action.type) {
    case MessageTypes.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        list: action?.payload?.items,
        pagination: action.payload?.meta
      }
    case MessageTypes.GET_SYSTEM_MESSAGES_SUCCESS:
      return {
        ...state,
        systems: getTranslationMessages(action?.payload?.messages?.items)
      }
    case MessageTypes.UPDATE_FILTER_MESSAGE_SUCCESS:
      return { ...state, filter: action?.payload?.value }

    // return updateModuleFilter(state, action.payload)
    case MessageTypes.CLEAR_FILTER_MESSAGE_SUCCESS:
      return { ...state, filter: DEFAULT_MESSAGE_FILTER }
    // return clearModuleFilter(state, action.payload, initialData)
    case UserTypes.LOGOUT_SUCCESS:
      return {
        ...initialData,
        systems: state.systems
      }
    default:
      return state
  }
}

export default label
