import keyMirror from 'keymirror'

export default keyMirror({
  GET_SOFTWARE_PERS_REQUEST: null,
  GET_SOFTWARE_PERS_SUCCESS: null,
  GET_SOFTWARE_PERS_FAILED: null,

  ADD_SOFTWARE_PER_REQUEST: null,
  ADD_SOFTWARE_PER_SUCCESS: null,
  ADD_SOFTWARE_PER_FAILED: null,

  GET_SOFTWARE_PER_DETAIL_REQUEST: null,
  GET_SOFTWARE_PER_DETAIL_SUCCESS: null,
  GET_SOFTWARE_PER_DETAIL_FAILED: null,

  UPDATE_SOFTWARE_PER_REQUEST: null,
  UPDATE_SOFTWARE_PER_SUCCESS: null,
  UPDATE_SOFTWARE_PER_FAILED: null,

  DELETE_SOFTWARE_PER_REQUEST: null,
  DELETE_SOFTWARE_PER_SUCCESS: null,
  DELETE_SOFTWARE_PER_FAILED: null,

  UPDATE_FILTER_SOFTWARE_PER_SUCCESS: null,
  CLEAR_FILTER_SOFTWARE_PER_SUCCESS: null,

  GET_SYSTEM_SOFTWARE_PERS_REQUEST: null,
  GET_SYSTEM_SOFTWARE_PERS_SUCCESS: null,
  GET_SYSTEM_SOFTWARE_PERS_FAILED: null
})
