import BaseApi from './base'

class Documentation extends BaseApi {
  getDocumentations = (query) => this.client.get(`admin/documentation?${query}`)

  getDocumentation = (id) => this.client.get(`admin/documentation/${id}`)

  getDocumentationContent = (id) =>
    this.client.get(`admin/documentation/${id}/content`)

  addDocumentation = (data) => this.client.post('admin/documentation', data)

  updateDocumentation = (id, data) =>
    this.client.patch(`admin/documentation/${id}`, data)

  deleteDocumentation = (id) => this.client.delete(`admin/documentation/${id}`)

  addDocumentationContent = (id, data) =>
    this.client.post(`admin/documentation/${id}/content`, data)

  upsertDocumentationContentTranslation = (id, data) =>
    this.client.post(`admin/documentation/${id}/content/translation`, data)
}

export default new Documentation()
