import { DEFAULT_SHOWCASE_STORY_FILTER } from 'constants/common'
import ShowcaseStoryTypes from 'store/actionTypes/showcaseStory'

import UserTypes from 'store/actionTypes/user'

const initShowcaseStory = {
  list: [],
  current: {},
  pagination: {},
  filter: DEFAULT_SHOWCASE_STORY_FILTER,
  components: {
    list: [],
    pagination: {}
  }
}

const showcaseStory = (state = initShowcaseStory, action) => {
  const data = action?.payload || []
  switch (action.type) {
    case ShowcaseStoryTypes.GET_SHOWCASE_STORIES_SUCCESS:
      return {
        ...state,
        list: data?.items,
        pagination: data?.meta
      }
    case ShowcaseStoryTypes.GET_SHOWCASE_STORY_DETAIL_SUCCESS:
      return {
        ...state,
        current: data
      }
    case ShowcaseStoryTypes.UPDATE_FILTER_SHOWCASE_STORY_SUCCESS:
      return { ...state, filter: data }
    case ShowcaseStoryTypes.CLEAR_FILTER_SHOWCASE_STORY_SUCCESS:
      return { ...state, filter: initShowcaseStory.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initShowcaseStory
    default:
      return state
  }
}

export default showcaseStory
