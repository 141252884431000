import TopicTypes from 'store/actionTypes/topic'

const initTopic = {
  list: [],
  current: {},
  pagination: {}
}

const Topic = (state = initTopic, action) => {
  const data = action?.payload || []
  switch (action.type) {
    case TopicTypes.GET_TOPIC_SUCCESS:
      return {
        ...state,
        list: data?.items,
        pagination: data?.meta ?? state.pagination
      }

    case TopicTypes.GET_TOPIC_DETAIL_SUCCESS:
      return {
        ...state,
        current: data
      }

    default:
      return state
  }
}

export default Topic
