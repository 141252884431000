import PlatformPageTechTypes from 'store/actionTypes/platformPageTech'
import UserTypes from 'store/actionTypes/user'
import { DEFAULT_PLATFORM_PAGE_TECH_FILTER } from 'constants/common'

const initialData = {
  list: [],
  systems: [],
  current: {},
  pagination: {},
  filter: DEFAULT_PLATFORM_PAGE_TECH_FILTER
}

const platformPageTech = (state = initialData, action) => {
  switch (action.type) {
    case PlatformPageTechTypes.GET_LIST_PLATFORM_TECH_PAGE_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case PlatformPageTechTypes.GET_DETAIL_PLATFORM_TECH_PAGE_SUCCESS:
      return { ...state, current: action.payload }
    case PlatformPageTechTypes.UPDATE_FILTER_PLATFORM_PAGE_TECH_SUCCESS:
      return { ...state, filter: action.payload }
    case PlatformPageTechTypes.CLEAR_FILTER_PLATFORM_PAGE_TECH_SUCCESS:
      return { ...state, filter: initialData.filter }
    case PlatformPageTechTypes.GET_SYSTEM_PLATFORM_PAGE_TECHS_SUCCESS:
      return { ...state, systems: action.payload?.items }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default platformPageTech
