import LabelTypes from '../actionTypes/label'
import { label as labelApi, labelImport as labelImportApi } from '../api'
import { bindRequestLogic } from './utils'
import { bindFilterLogic } from './filter'
import { createLogic } from 'redux-logic'

const getLabels = bindRequestLogic({
  clientFunc: labelApi.getLabels,
  type: LabelTypes.GET_LABELS_REQUEST,
  successType: LabelTypes.GET_LABELS_SUCCESS,
  failType: LabelTypes.GET_LABELS_FAILED,
  isQueryParam: true
})

const createLabel = bindRequestLogic({
  clientFunc: labelApi.createLabel,
  type: LabelTypes.CREATE_LABEL_REQUEST,
  successType: LabelTypes.CREATE_LABEL_SUCCESS,
  failType: LabelTypes.CREATE_LABEL_FAILED
})

const deleteLabel = bindRequestLogic({
  clientFunc: labelApi.deleteLabel,
  type: LabelTypes.DELETE_LABEL_REQUEST,
  successType: LabelTypes.DELETE_LABEL_SUCCESS,
  failType: LabelTypes.DELETE_LABEL_FAILED
})

const updateLabels = bindRequestLogic({
  clientFunc: labelApi.updateLabels,
  type: LabelTypes.UPDATE_LABELS_REQUEST,
  successType: LabelTypes.UPDATE_LABELS_SUCCESS,
  failType: LabelTypes.UPDATE_LABELS_FAILED
})

const updatePageLabels = bindRequestLogic({
  clientFunc: labelApi.updatePageLabels,
  type: LabelTypes.UPDATE_PAGE_LABELS_REQUEST,
  successType: LabelTypes.UPDATE_PAGE_LABELS_SUCCESS,
  failType: LabelTypes.UPDATE_PAGE_LABELS_FAILED
})

const importLabels = bindRequestLogic({
  clientApi: labelImportApi,
  clientFunc: labelImportApi.importLabels,
  type: LabelTypes.IMPORT_LABELS_REQUEST,
  successType: LabelTypes.IMPORT_LABELS_SUCCESS,
  failType: LabelTypes.IMPORT_LABELS_FAILED
})

const updateFilterLabel = bindFilterLogic({
  type: LabelTypes.UPDATE_FILTER_LABEL_REQUEST,
  successType: LabelTypes.UPDATE_FILTER_LABEL_SUCCESS
})

const clearFilterLabel = bindFilterLogic({
  type: LabelTypes.CLEAR_FILTER_LABEL_REQUEST,
  successType: LabelTypes.CLEAR_FILTER_LABEL_SUCCESS
})

const updateLabelFromPageTitle = createLogic({
  type: LabelTypes.UPDATE_LABEL_FROM_PAGE_TITLE_REQUEST,
  latest: true,
  process: ({ action }, dispatch, done) => {
    dispatch({
      type: LabelTypes.UPDATE_LABEL_FROM_PAGE_TITLE_SUCCESS,
      payload: action.payload
    })

    done()
  }
})

const getSystemLabels = bindRequestLogic({
  clientFunc: labelApi.getSystemLabels,
  type: LabelTypes.GET_SYSTEM_LABELS_REQUEST,
  successType: LabelTypes.GET_SYSTEM_LABELS_SUCCESS,
  failType: LabelTypes.GET_SYSTEM_LABELS_FAILED,
  isQueryParam: true
})

export default [
  getLabels,
  createLabel,
  deleteLabel,
  importLabels,
  updateFilterLabel,
  clearFilterLabel,
  updateLabels,
  updatePageLabels,
  getSystemLabels,
  updateLabelFromPageTitle
]
