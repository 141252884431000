import { DEFAULT_MODEL_PER_FILTER } from 'constants/common'
import ModelPerTypes from 'store/actionTypes/modelPer'
import UserTypes from 'store/actionTypes/user'

const initModelPer = {
  list: [],
  systems: [],
  current: {},
  pagination: {},
  filter: DEFAULT_MODEL_PER_FILTER
}

const modelPer = (state = initModelPer, action) => {
  switch (action.type) {
    case ModelPerTypes.GET_MODEL_PERS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case ModelPerTypes.GET_MODEL_PER_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case ModelPerTypes.UPDATE_FILTER_MODEL_PER_SUCCESS:
      return { ...state, filter: action.payload }
    case ModelPerTypes.CLEAR_FILTER_MODEL_PER_SUCCESS:
      return { ...state, filter: initModelPer.filter }
    case ModelPerTypes.GET_SYSTEM_MODEL_PERS_SUCCESS:
      return {
        ...state,
        systems: action.payload?.modelPers?.items
      }
    case UserTypes.LOGOUT_SUCCESS:
      return initModelPer
    default:
      return state
  }
}

export default modelPer
