import keyMirror from 'keymirror'

export default keyMirror({
  GET_ALERT_LEVELS_REQUEST: null,
  GET_ALERT_LEVELS_SUCCESS: null,
  GET_ALERT_LEVELS_FAILED: null,

  ADD_ALERT_LEVEL_REQUEST: null,
  ADD_ALERT_LEVEL_SUCCESS: null,
  ADD_ALERT_LEVEL_FAILED: null,

  GET_ALERT_LEVEL_DETAIL_REQUEST: null,
  GET_ALERT_LEVEL_DETAIL_SUCCESS: null,
  GET_ALERT_LEVEL_DETAIL_FAILED: null,

  UPDATE_ALERT_LEVEL_REQUEST: null,
  UPDATE_ALERT_LEVEL_SUCCESS: null,
  UPDATE_ALERT_LEVEL_FAILED: null,

  DELETE_ALERT_LEVEL_REQUEST: null,
  DELETE_ALERT_LEVEL_SUCCESS: null,
  DELETE_ALERT_LEVEL_FAILED: null,

  UPSERT_ALERT_LEVEL_TRANSLATION_REQUEST: null,
  UPSERT_ALERT_LEVEL_TRANSLATION_SUCCESS: null,
  UPSERT_ALERT_LEVEL_TRANSLATION_FAILED: null,

  DELETE_ALERT_LEVEL_TRANSLATION_REQUEST: null,
  DELETE_ALERT_LEVEL_TRANSLATION_SUCCESS: null,
  DELETE_ALERT_LEVEL_TRANSLATION_FAILED: null,

  GET_SYSTEM_ALERT_LEVELS_REQUEST: null,
  GET_SYSTEM_ALERT_LEVELS_SUCCESS: null,
  GET_SYSTEM_ALERT_LEVELS_FAILED: null
})
