import { combineReducers } from 'redux'
import user from './user'
import country from './country'
import timezone from './timezone'
import company from './company'
import vehicle from './vehicle'
import appUser from './appUser'
import electronicKey from './electronicKey'
import electronicKeyModel from './electronicKeyModel'
import modelPer from './modelPer'
import vehicleModel from './vehicleModel'
import cixiReferent from './cixiReferent'
import language from './language'
import currency from './currency'
import label from './label'
import text from './text'
import message from './message'
import role from './role'
import rolesConfiguration from './rolesConfiguration'
import systemConfig from './systemConfig'
import dateTimeFormat from './dateTimeFormat'
import logMail from './logMail'
import logRoutine from './logRoutine'
import formattedText from './formattedText'
import subscription from './subscription'
import showcaseSection from './showcaseSection'
import showcaseComponent from './showcaseComponent'
import mailTemplate from './mailTemplate'
import showcaseStory from './showcaseStory'
import vehicleBrand from './vehicleBrand'
import showcasePrivacyPolicy from './showcasePrivacyPolicy'
import topic from './topic'
import showcaseContactLog from './showcaseContactLog'
import conditionalFeature from './conditionalFeature'
import showcaseAnonymizeVisitor from './showcaseAnonymizeVisitor'
import sharing from './sharing'
import softwarePer from './softwarePer'
import per from './per'
import platformPageTech from './platformPageTech'
import notificationTemplate from './notificationTemplate'
import notificationRequest from './notificationRequest'
import keyUsageSystemLog from './keyUsageSystemLog'
import usageSystemLog from './usageSystemLog'
import persPackage from './persPackage'
import companyCategory from './companyCategory'
import companyRole from './companyRole'
import proCenterAdmin from './proCenterAdmin'
import companyPerLink from './companyPerLink'
import vehicleType from './vehicleType'

export default combineReducers({
  company,
  vehicle,
  appUser,
  electronicKey,
  electronicKeyModel,
  modelPer,
  vehicleModel,
  cixiReferent,
  language,
  currency,
  country,
  timezone,
  label,
  text,
  message,
  user,
  role,
  topic,
  rolesConfiguration,
  systemConfig,
  dateTimeFormat,
  logMail,
  logRoutine,
  formattedText,
  subscription,
  showcaseSection,
  showcaseComponent,
  mailTemplate,
  showcaseStory,
  vehicleBrand,
  showcasePrivacyPolicy,
  showcaseContactLog,
  conditionalFeature,
  showcaseAnonymizeVisitor,
  sharing,
  softwarePer,
  per,
  platformPageTech,
  notificationTemplate,
  notificationRequest,
  keyUsageSystemLog,
  usageSystemLog,
  persPackage,
  companyCategory,
  companyRole,
  proCenterAdmin,
  companyPerLink,
  vehicleType
})
