import keyMirror from 'keymirror'

export default keyMirror({
  GET_PRO_CENTER_ADMINS_REQUEST: null,
  GET_PRO_CENTER_ADMINS_SUCCESS: null,
  GET_PRO_CENTER_ADMINS_FAILED: null,

  GET_PRO_CENTER_ADMIN_REQUEST: null,
  GET_PRO_CENTER_ADMIN_SUCCESS: null,
  GET_PRO_CENTER_ADMIN_FAILED: null,

  GET_PRO_CENTER_ADMIN_DETAIL_REQUEST: null,
  GET_PRO_CENTER_ADMIN_DETAIL_SUCCESS: null,
  GET_PRO_CENTER_ADMIN_DETAIL_FAILED: null,

  UPDATE_PRO_CENTER_ADMIN_REQUEST: null,
  UPDATE_PRO_CENTER_ADMIN_SUCCESS: null,
  UPDATE_PRO_CENTER_ADMIN_FAILED: null,

  ADD_PRO_CENTER_ADMIN_REQUEST: null,
  ADD_PRO_CENTER_ADMIN_SUCCESS: null,
  ADD_PRO_CENTER_ADMIN_FAILED: null,

  DELETE_PRO_CENTER_ADMIN_REQUEST: null,
  DELETE_PRO_CENTER_ADMIN_SUCCESS: null,
  DELETE_PRO_CENTER_ADMIN_FAILED: null,

  UPDATE_FILTER_PRO_CENTER_ADMIN_SUCCESS: null,
  CLEAR_FILTER_PRO_CENTER_ADMIN_SUCCESS: null,

  GET_COMPANY_ROLE_CONFIGURATION_BY_COMPANY_ID_REQUEST: null,
  GET_COMPANY_ROLE_CONFIGURATION_BY_COMPANY_ID_SUCCESS: null,
  GET_COMPANY_ROLE_CONFIGURATION_BY_COMPANY_ID_FAILED: null
})
