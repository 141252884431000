import { combineReducers } from 'redux'
import CixiReferentTypes from 'store/actionTypes/cixiReferent'
import { createHandleRequest } from './helpers'

const getCixiReferents = createHandleRequest(
  CixiReferentTypes.GET_CIXI_REFERENTS_REQUEST,
  CixiReferentTypes.GET_CIXI_REFERENTS_SUCCESS,
  CixiReferentTypes.GET_CIXI_REFERENTS_FAILED
)

export default combineReducers({
  getCixiReferents
})
