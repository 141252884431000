import BaseApi from './base'

class CompanyCategory extends BaseApi {
  getCompanyCategories = (query) => this.client.get(`company-category?${query}`)

  getCompanyCategory = (id) => this.client.get(`company-category/${id}`)

  updateCompanyCategory = (id, data) =>
    this.client.patch(`company-category/${id}`, data)

  addCompanyCategoryTranslation = (id, data) =>
    this.client.post(`company-category/${id}/translation`, data)

  updateCompanyCategoryTranslation = (id, data) =>
    this.client.patch(
      `company-category/${id}/translation/${data?.translationId}`,
      {
        translationValue: data?.translationValue
      }
    )

  deleteCompanyCategoryTranslation = (id) =>
    this.client.delete(`company-category/translation/${id}`)

  getSystemCompanyCategories = (query) =>
    this.client.get(
      `backoffice/systems/company-categories-for-dropdown?${query}`,
      {
        baseURL: this.fleetURL
      }
    )
}

export default new CompanyCategory()
