import keyMirror from 'keymirror'

export default keyMirror({
  GET_COMPANIES_REQUEST: null,
  GET_COMPANIES_SUCCESS: null,
  GET_COMPANIES_FAILED: null,

  ADD_COMPANY_REQUEST: null,
  ADD_COMPANY_SUCCESS: null,
  ADD_COMPANY_FAILED: null,

  GET_COMPANY_DETAIL_REQUEST: null,
  GET_COMPANY_DETAIL_SUCCESS: null,
  GET_COMPANY_DETAIL_FAILED: null,

  UPDATE_COMPANY_REQUEST: null,
  UPDATE_COMPANY_SUCCESS: null,
  UPDATE_COMPANY_FAILED: null,

  DELETE_COMPANY_REQUEST: null,
  DELETE_COMPANY_SUCCESS: null,
  DELETE_COMPANY_FAILED: null,

  UPDATE_SHEET_ADDITIONAL_REQUEST: null,
  UPDATE_SHEET_ADDITIONAL_SUCCESS: null,
  UPDATE_SHEET_ADDITIONAL_FAILED: null,

  GET_COMPANY_MEMBERS_REQUEST: null,
  GET_COMPANY_MEMBERS_SUCCESS: null,
  GET_COMPANY_MEMBERS_FAILED: null,

  ADD_COMPANY_MEMBER_REQUEST: null,
  ADD_COMPANY_MEMBER_SUCCESS: null,
  ADD_COMPANY_MEMBER_FAILED: null,

  UPDATE_COMPANY_MEMBER_REQUEST: null,
  UPDATE_COMPANY_MEMBER_SUCCESS: null,
  UPDATE_COMPANY_MEMBER_FAILED: null,

  DELETE_COMPANY_MEMBER_REQUEST: null,
  DELETE_COMPANY_MEMBER_SUCCESS: null,
  DELETE_COMPANY_MEMBER_FAILED: null,

  GET_COMPANY_PILOTS_REQUEST: null,
  GET_COMPANY_PILOTS_SUCCESS: null,
  GET_COMPANY_PILOTS_FAILED: null,

  UPDATE_FILTER_COMPANY_PILOTS_SUCCESS: null,
  CLEAR_FILTER_COMPANY_PILOTS_SUCCESS: null,

  CONVERT_TO_MOTHER_COMPANY_REQUEST: null,
  CONVERT_TO_MOTHER_COMPANY_SUCCESS: null,
  CONVERT_TO_MOTHER_COMPANY_FAILED: null,

  GET_CHILDREN_COMPANIES_REQUEST: null,
  GET_CHILDREN_COMPANIES_SUCCESS: null,
  GET_CHILDREN_COMPANIES_FAILED: null,

  ADD_CHILDREN_COMPANY_REQUEST: null,
  ADD_CHILDREN_COMPANY_SUCCESS: null,
  ADD_CHILDREN_COMPANY_FAILED: null,

  DELETE_CHILDREN_COMPANY_REQUEST: null,
  DELETE_CHILDREN_COMPANY_SUCCESS: null,
  DELETE_CHILDREN_COMPANY_FAILED: null,

  UPDATE_FILTER_COMPANY_SUCCESS: null,
  CLEAR_FILTER_COMPANY_SUCCESS: null,

  GET_COMPANY_VEHICLES_REQUEST: null,
  GET_COMPANY_VEHICLES_SUCCESS: null,
  GET_COMPANY_VEHICLES_FAILED: null,

  UPDATE_FILTER_COMPANY_VEHICLES_SUCCESS: null,
  CLEAR_FILTER_COMPANY_VEHICLES_SUCCESS: null,

  GET_COMPANY_SUBSCRIPTIONS_REQUEST: null,
  GET_COMPANY_SUBSCRIPTIONS_SUCCESS: null,
  GET_COMPANY_SUBSCRIPTIONS_FAILED: null,

  UPDATE_FILTER_COMPANY_SUBSCRIPTIONS_SUCCESS: null,
  CLEAR_FILTER_COMPANY_SUBSCRIPTIONS_SUCCESS: null,

  GET_SYSTEM_COMPANIES_REQUEST: null,
  GET_SYSTEM_COMPANIES_SUCCESS: null,
  GET_SYSTEM_COMPANIES_FAILED: null,

  UPSERT_COMPANY_MAIL_TEMPLATE_REQUEST: null,
  UPSERT_COMPANY_MAIL_TEMPLATE_SUCCESS: null,
  UPSERT_COMPANY_MAIL_TEMPLATE_FAILED: null,

  DELETE_COMPANY_MAIL_TEMPLATE_REQUEST: null,
  DELETE_COMPANY_MAIL_TEMPLATE_SUCCESS: null,
  DELETE_COMPANY_MAIL_TEMPLATE_FAILED: null,

  GET_COMPANY_MAIL_TEMPLATE_DETAIL_REQUEST: null,
  GET_COMPANY_MAIL_TEMPLATE_DETAIL_SUCCESS: null,
  GET_COMPANY_MAIL_TEMPLATE_DETAIL_FAILED: null,

  UPDATE_FILTER_COMPANY_MAIL_TEMPLATES_SUCCESS: null,
  CLEAR_FILTER_COMPANY_MAIL_TEMPLATES_SUCCESS: null,

  GET_COMPANY_MAIL_TEMPLATES_REQUEST: null,
  GET_COMPANY_MAIL_TEMPLATES_SUCCESS: null,
  GET_COMPANY_MAIL_TEMPLATES_FAILED: null
})
