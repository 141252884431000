import { combineReducers } from 'redux'
import PlatformPageTech from 'store/actionTypes/platformPageTech'
import { createHandleRequest } from './helpers'

const getPlatformPageTechs = createHandleRequest(
  PlatformPageTech.GET_LIST_PLATFORM_PAGE_TECH_REQUEST,
  PlatformPageTech.GET_LIST_PLATFORM_TECH_PAGE_SUCCESS,
  PlatformPageTech.GET_LIST_PLATFORM_TECH_PAGE_FAILED
)

export default combineReducers({
  getPlatformPageTechs
})
