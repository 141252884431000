import { combineReducers } from 'redux'
import CurrencyTypes from 'store/actionTypes/currency'
import { createHandleRequest } from './helpers'

const getCurrencies = createHandleRequest(
  CurrencyTypes.GET_CURRENCIES_REQUEST,
  CurrencyTypes.GET_CURRENCIES_SUCCESS,
  CurrencyTypes.GET_CURRENCIES_FAILED
)

const getCurrencyDetail = createHandleRequest(
  CurrencyTypes.GET_CURRENCY_DETAIL_REQUEST,
  CurrencyTypes.GET_CURRENCY_DETAIL_SUCCESS,
  CurrencyTypes.GET_CURRENCY_DETAIL_FAILED
)

export default combineReducers({
  getCurrencies,
  getCurrencyDetail
})
