import keyMirror from 'keymirror'

export default keyMirror({
  GET_LOG_ROUTINES_REQUEST: null,
  GET_LOG_ROUTINES_SUCCESS: null,
  GET_LOG_ROUTINES_FAILED: null,

  UPDATE_LOG_ROUTINE_FILTER: null,
  CLEAR_LOG_ROUTINE_FILTER: null,

  GET_CODE_ROUTINES_REQUEST: null,
  GET_CODE_ROUTINES_SUCCESS: null,
  GET_CODE_ROUTINES_FAILED: null,

  UPDATE_FILTER_LOG_ROUTINE_SUCCESS: null,
  CLEAR_FILTER_LOG_ROUTINE_SUCCESS: null
})
