import BaseApi from './base'

class VehicleTest extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = this.perURL
  }

  getAuthVehicle = (data) => this.client.post(`pers/get-auth-pers`, data)

  encryptData = (data) => this.client.post(`pers/private-encrypt`, data)

  decryptData = (data) => this.client.post(`pers/private-decrypt`, data)

  getWhatToDo = (token) =>
    this.client.post(
      'pers/get-what-to-do',
      {},
      {
        headers: {
          authorization: `bearer ${token}`
        }
      }
    )

  getNewPinBleVehicle = (token, data) =>
    this.client.post('pers/get-new-pin-ble-pers', data, {
      headers: {
        authorization: `bearer ${token}`
      }
    })

  getNewHardToken = (token) =>
    this.client.post(
      'pers/get-new-hard-token-pers',
      {},
      {
        headers: {
          authorization: `bearer ${token}`
        }
      }
    )

  getVehicleListUsers = (token) =>
    this.client.post(
      'pers/get-vehicle-list-users',
      {},
      {
        headers: {
          authorization: `bearer ${token}`
        }
      }
    )

  getNewAuthVehicle = (token) =>
    this.client.post(
      'pers/get-new-auth-pers',
      {},
      {
        headers: {
          authorization: `bearer ${token}`
        }
      }
    )

  downloadPersJourneyLogTemplate = () =>
    this.client.get(`journey-logs/simulator/template`, {
      baseURL: this.perLogURL
    })

  sendPersJourneyLogs = (data) =>
    this.client.post(`journey-logs/simulator/send`, data, {
      baseURL: this.perLogURL
    })
}

export default new VehicleTest()
