import BaseApi from './base'

class NotificationRequest extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.notificationURL}`
  }

  getNotificationRequests = (query) =>
    this.client.get(`notification-request?${query}`)

  sendNotificationRequest = (data) =>
    this.client.post('notification-request', data)

  getNotificationSent = ({ id, ...params }) =>
    this.client.get(`notification-request/${id}`, { params })

  getRichText = (id) => this.client.get(`notification-request/rich-text/${id}`)

  getNotificationTemplates = () =>
    this.client.get(`admin/notification-template?isPublic=true&limit=0`)
}

export default new NotificationRequest()
