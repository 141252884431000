import keyMirror from 'keymirror'

export default keyMirror({
  GET_CIXI_REFERENTS_REQUEST: null,
  GET_CIXI_REFERENTS_SUCCESS: null,
  GET_CIXI_REFERENTS_FAILED: null,

  ADD_CIXI_REFERENT_REQUEST: null,
  ADD_CIXI_REFERENT_SUCCESS: null,
  ADD_CIXI_REFERENT_FAILED: null,

  GET_CIXI_REFERENT_DETAIL_REQUEST: null,
  GET_CIXI_REFERENT_DETAIL_SUCCESS: null,
  GET_CIXI_REFERENT_DETAIL_FAILED: null,

  UPDATE_CIXI_REFERENT_REQUEST: null,
  UPDATE_CIXI_REFERENT_SUCCESS: null,
  UPDATE_CIXI_REFERENT_FAILED: null,

  DELETE_CIXI_REFERENT_REQUEST: null,
  DELETE_CIXI_REFERENT_SUCCESS: null,
  DELETE_CIXI_REFERENT_FAILED: null,

  UPDATE_FILTER_CIXI_REFERENT_SUCCESS: null,
  CLEAR_FILTER_CIXI_REFERENT_SUCCESS: null,

  GET_SYSTEM_COMPANY_CIXI_REFERENTS_REQUEST: null,
  GET_SYSTEM_COMPANY_CIXI_REFERENTS_SUCCESS: null,
  GET_SYSTEM_COMPANY_CIXI_REFERENTS_FAILED: null
})
