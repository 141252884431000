import AlertGroupTypes from 'store/actionTypes/alertGroup'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  current: {},
  system: []
}

const AlertGroup = (state = initialData, action = {}) => {
  switch (action.type) {
    case AlertGroupTypes.GET_ALERT_GROUPS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items || []
      }
    case AlertGroupTypes.GET_ALERT_GROUP_DETAIL_SUCCESS:
      return {
        ...state,
        current: action.payload || []
      }
    case AlertGroupTypes.GET_SYSTEM_ALERT_GROUPS_SUCCESS:
      return {
        ...state,
        system: action.payload?.items || []
      }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default AlertGroup
