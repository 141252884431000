import keyMirror from 'keymirror'

export default keyMirror({
  GET_FORMATTED_TEXTS_REQUEST: null,
  GET_FORMATTED_TEXTS_SUCCESS: null,
  GET_FORMATTED_TEXTS_FAILED: null,

  DELETE_FORMATTED_TEXT_REQUEST: null,
  DELETE_FORMATTED_TEXT_SUCCESS: null,
  DELETE_FORMATTED_TEXT_FAILED: null,

  ADD_FORMATTED_TEXT_REQUEST: null,
  ADD_FORMATTED_TEXT_SUCCESS: null,
  ADD_FORMATTED_TEXT_FAILED: null,

  UPDATE_FORMATTED_TEXT_REQUEST: null,
  UPDATE_FORMATTED_TEXT_SUCCESS: null,
  UPDATE_FORMATTED_TEXT_FAILED: null,

  UPDATE_FILTER_FORMATTED_TEXT_REQUEST: null,
  UPDATE_FILTER_FORMATTED_TEXT_SUCCESS: null,
  CLEAR_FILTER_FORMATTED_TEXT_REQUEST: null,
  CLEAR_FILTER_FORMATTED_TEXT_SUCCESS: null,

  GET_SYSTEM_FORMATTED_TEXTS_REQUEST: null,
  GET_SYSTEM_FORMATTED_TEXTS_SUCCESS: null,
  GET_SYSTEM_FORMATTED_TEXTS_FAILED: null
})
