import CompanyRoleTypes from 'store/actionTypes/companyRole'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  current: {},
  pagination: {},
  configurations: [],
  systems: [],
  allowToManage: {},
  permissions: []
}

const companyRole = (state = initialData, action = {}) => {
  const { payload = {} } = action
  switch (action.type) {
    case CompanyRoleTypes.GET_COMPANY_ROLES_SUCCESS:
      return {
        ...state,
        list: payload.items,
        pagination: payload.meta
      }
    case CompanyRoleTypes.GET_SYSTEM_COMPANY_ROLES_SUCCESS:
      return {
        ...state,
        systems: payload.items
      }
    case CompanyRoleTypes.GET_COMPANY_ROLE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        configurations: payload.items
      }
    case CompanyRoleTypes.GET_COMPANY_ROLE_SUCCESS:
      return { ...state, current: payload }
    case CompanyRoleTypes.GET_COMPANY_ALLOW_TO_MANAGE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { success, ...args } = payload
      return { ...state, allowToManage: args }
    case CompanyRoleTypes.GET_PERMISSIONS_BY_COMPANY_ID_SUCCESS:
      return { ...state, permissions: payload }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default companyRole
