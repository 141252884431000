import RolesConfigurationType from 'store/actionTypes/rolesConfiguration'

const initRole = {
  list: []
}

const rolesConfiguration = (state = initRole, action) => {
  switch (action.type) {
    case RolesConfigurationType.GET_ROLES_CONFIGURATION_SUCCESS:
      return { ...state, list: action.payload?.items }
    default:
      return state
  }
}

export default rolesConfiguration
