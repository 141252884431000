import BaseApi from './base'

class DataMapping extends BaseApi {
  getDataMappings = (query) => this.client.get(`data-mapping?${query}`)

  addDataMapping = (data) => this.client.post(`data-mapping`, data)

  updateDataMapping = ({ dataMappingId, payload }) =>
    this.client.patch(`data-mapping/${dataMappingId}`, payload)

  deleteDataMapping = (dataMappingId) =>
    this.client.delete(`data-mapping/${dataMappingId}`)
}

export default new DataMapping()
