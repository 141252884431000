import { DEFAULT_CIXI_REFERENT_FILTER } from 'constants/common'
import CixiReferentTypes from 'store/actionTypes/cixiReferent'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  systems: [],
  current: {},
  pagination: {},
  filter: DEFAULT_CIXI_REFERENT_FILTER
}

const cixiReferent = (state = initialData, action) => {
  switch (action.type) {
    case CixiReferentTypes.GET_CIXI_REFERENTS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case CixiReferentTypes.GET_SYSTEM_COMPANY_CIXI_REFERENTS_SUCCESS:
      return { ...state, systems: action.payload?.items }
    case CixiReferentTypes.GET_CIXI_REFERENT_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case CixiReferentTypes.UPDATE_FILTER_CIXI_REFERENT_SUCCESS:
      return { ...state, filter: action.payload }
    case CixiReferentTypes.CLEAR_FILTER_CIXI_REFERENT_SUCCESS:
      return { ...state, filter: initialData.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default cixiReferent
