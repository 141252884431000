import { DEFAULT_ROLE_FILTER } from 'constants/common'
import RoleTypes from 'store/actionTypes/role'
import UserTypes from 'store/actionTypes/user'

const initRole = {
  list: [],
  current: {},
  pagination: {},
  permissions: [],
  groups: [],
  systems: [],
  filter: DEFAULT_ROLE_FILTER
}

const role = (state = initRole, action) => {
  switch (action.type) {
    case RoleTypes.GET_ROLES_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case RoleTypes.GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload?.items
      }
    case RoleTypes.GET_PERMISSIONS_SUCCESS:
      return { ...state, permissions: action.payload }
    case RoleTypes.GET_ROLE_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case RoleTypes.UPDATE_FILTER_ROLE_SUCCESS:
      return { ...state, filter: action.payload }
    case RoleTypes.CLEAR_FILTER_ROLE_SUCCESS:
      return { ...state, filter: initRole.filter }
    case RoleTypes.GET_SYSTEM_ROLES_SUCCESS:
      return { ...state, systems: action.payload?.items }
    case UserTypes.LOGOUT_SUCCESS:
      return initRole
    default:
      return state
  }
}

export default role
