import CountryTypes from '../actionTypes/country'
import { country as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const getCountries = bindRequestLogic({
  clientFunc: clientApi.getCountries,
  type: CountryTypes.GET_COUNTRIES_REQUEST,
  successType: CountryTypes.GET_COUNTRIES_SUCCESS,
  failType: CountryTypes.GET_COUNTRIES_FAILED,
  isQueryParam: true,
  modelName: 'countries'
})

const addCountry = bindRequestLogic({
  clientFunc: clientApi.addCountry,
  type: CountryTypes.ADD_COUNTRY_REQUEST,
  successType: CountryTypes.ADD_COUNTRY_SUCCESS,
  failType: CountryTypes.ADD_COUNTRY_FAILED
})

const addCountryTranslation = bindRequestLogic({
  clientFunc: clientApi.addCountryTranslation,
  type: CountryTypes.ADD_COUNTRY_TRANSLATION_REQUEST,
  successType: CountryTypes.ADD_COUNTRY_TRANSLATION_SUCCESS,
  failType: CountryTypes.ADD_COUNTRY_TRANSLATION_FAILED
})

const getCountryDetail = bindRequestLogic({
  clientFunc: clientApi.getCountryDetail,
  type: CountryTypes.GET_COUNTRY_DETAIL_REQUEST,
  successType: CountryTypes.GET_COUNTRY_DETAIL_SUCCESS,
  failType: CountryTypes.GET_COUNTRY_DETAIL_FAILED,
  modelName: 'country'
})

const deleteCountry = bindRequestLogic({
  clientFunc: clientApi.deleteCountry,
  type: CountryTypes.DELETE_COUNTRY_REQUEST,
  successType: CountryTypes.DELETE_COUNTRY_SUCCESS,
  failType: CountryTypes.DELETE_COUNTRY_FAILED
})

const updateCountry = bindRequestLogic({
  clientFunc: clientApi.updateCountry,
  type: CountryTypes.UPDATE_COUNTRY_REQUEST,
  successType: CountryTypes.UPDATE_COUNTRY_SUCCESS,
  failType: CountryTypes.UPDATE_COUNTRY_FAILED
})

const deleteCountryTranslation = bindRequestLogic({
  clientFunc: clientApi.deleteCountryTranslation,
  type: CountryTypes.DELETE_COUNTRY_TRANSLATION_REQUEST,
  successType: CountryTypes.DELETE_COUNTRY_TRANSLATION_SUCCESS,
  failType: CountryTypes.DELETE_COUNTRY_TRANSLATION_FAILED
})

const updateCountryTranslation = bindRequestLogic({
  clientFunc: clientApi.updateCountryTranslation,
  type: CountryTypes.UPDATE_COUNTRY_TRANSLATION_REQUEST,
  successType: CountryTypes.UPDATE_COUNTRY_TRANSLATION_SUCCESS,
  failType: CountryTypes.UPDATE_COUNTRY_TRANSLATION_FAILED
})

const getSystemCountries = bindRequestLogic({
  clientFunc: clientApi.getSystemCountries,
  type: CountryTypes.GET_SYSTEM_COUNTRIES_REQUEST,
  successType: CountryTypes.GET_SYSTEM_COUNTRIES_SUCCESS,
  failType: CountryTypes.GET_SYSTEM_COUNTRIES_FAILED,
  isQueryParam: true
})

export default [
  getCountries,
  addCountry,
  deleteCountry,
  getCountryDetail,
  updateCountry,
  deleteCountryTranslation,
  addCountryTranslation,
  updateCountryTranslation,
  getSystemCountries
]
