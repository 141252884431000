import React from 'react'
import IntlMessages from 'helpers/IntlMessages'
import { toArrayOptions } from 'helpers/utils'

export const VEHICLE_SHARING_TYPE_NUM = {
  TEMPORAL: 1,
  PERMANENT: 2
}

export const VEHICLE_SHARING_TYPE_LABEL = {
  [VEHICLE_SHARING_TYPE_NUM.TEMPORAL]: (
    <IntlMessages id='vehicle_sharing.type_temporal_sharing' />
  ),
  [VEHICLE_SHARING_TYPE_NUM.PERMANENT]: (
    <IntlMessages id='vehicle_sharing.type_permanent_sharing' />
  )
}

export const VEHICLE_SHARING_TYPE_LIST = toArrayOptions(
  VEHICLE_SHARING_TYPE_LABEL
)

export const VEHICLE_SHARING_EXTENSION_STATUS_NUM = {
  // REQUESTED_BY_BENEFICIARY: 1,
  PROPOSED_BY_MAIN_PILOT: 2,
  // ACCEPTED_BY_SHARER: 3,
  PROPOSAL_ACCEPTED_BY_GUEST: 4,
  // FORCE_BY_SHARER: 5,
  // COMPLETED: 6,
  // REQUEST_CANCELED_BY_BENEFICIARY: 7,
  // REQUEST_REFUSED_BY_SHARER: 8,
  PROPOSAL_CANCELED_BY_MAIN_PILOT: 9,
  PROPOSAL_DECLINED_BY_GUEST: 10,
  ACCEPTATION_CANCELED_BY_MAIN_PILOT: 11,
  ACCEPTATION_CANCELED_BY_GUEST: 12,
  // IN_PROGRESS: 13,
  // RETURNED: 14,
  PROPOSAL_EXPIRED: 15
}

export const VEHICLE_SHARING_EXTENSION_STATUS_LABEL = Object.freeze({
  // [VEHICLE_SHARING_EXTENSION_STATUS_NUM.REQUESTED_BY_BENEFICIARY]: (
  //   <IntlMessages id='vehicle_sharing.ext_status_requested_by_beneficiary' />
  // ),
  [VEHICLE_SHARING_EXTENSION_STATUS_NUM.PROPOSED_BY_MAIN_PILOT]: (
    <IntlMessages id='status_sharing_extension_proposed_by_main_pilot_for_cc' />
  ),
  // [VEHICLE_SHARING_EXTENSION_STATUS_NUM.ACCEPTED_BY_SHARER]: (
  //   <IntlMessages id='vehicle_sharing.ext_status_accepted_by_sharer' />
  // ),
  [VEHICLE_SHARING_EXTENSION_STATUS_NUM.PROPOSAL_ACCEPTED_BY_GUEST]: (
    <IntlMessages id='status_sharing_extension_proposal_accepted_by_guest_for_cc' />
  ),
  // [VEHICLE_SHARING_EXTENSION_STATUS_NUM.FORCE_BY_SHARER]: (
  //   <IntlMessages id='vehicle_sharing.ext_status_force_by_sharer' />
  // ),
  // [VEHICLE_SHARING_EXTENSION_STATUS_NUM.COMPLETED]: (
  //   <IntlMessages id='vehicle_sharing.ext_status_completed' />
  // ),
  // [VEHICLE_SHARING_EXTENSION_STATUS_NUM.REQUEST_CANCELED_BY_BENEFICIARY]: (
  //   <IntlMessages id='vehicle_sharing.ext_status_request_canceled_by_beneficiary' />
  // ),
  // [VEHICLE_SHARING_EXTENSION_STATUS_NUM.REQUEST_REFUSED_BY_SHARER]: (
  //   <IntlMessages id='vehicle_sharing.ext_status_request_refused_by_sharer' />
  // ),
  [VEHICLE_SHARING_EXTENSION_STATUS_NUM.PROPOSAL_CANCELED_BY_MAIN_PILOT]: (
    <IntlMessages id='status_sharing_extension_proposal_canceled_by_main_pilot_for_cc' />
  ),
  [VEHICLE_SHARING_EXTENSION_STATUS_NUM.PROPOSAL_DECLINED_BY_GUEST]: (
    <IntlMessages id='status_sharing_extension_proposal_declined_by_guest_for_cc' />
  ),
  [VEHICLE_SHARING_EXTENSION_STATUS_NUM.ACCEPTATION_CANCELED_BY_MAIN_PILOT]: (
    <IntlMessages id='status_sharing_extension_acceptation_canceled_by_main_pilot_for_cc' />
  ),
  [VEHICLE_SHARING_EXTENSION_STATUS_NUM.ACCEPTATION_CANCELED_BY_GUEST]: (
    <IntlMessages id='status_sharing_extension_acceptation_canceled_by_guest_for_cc' />
  ),
  // [VEHICLE_SHARING_EXTENSION_STATUS_NUM.IN_PROGRESS]: (
  //   <IntlMessages id='vehicle_sharing.ext_status_in_progress' />
  // ),
  // [VEHICLE_SHARING_EXTENSION_STATUS_NUM.RETURNED]: (
  //   <IntlMessages id='vehicle_sharing.ext_status_returned' />
  // ),
  [VEHICLE_SHARING_EXTENSION_STATUS_NUM.PROPOSAL_EXPIRED]: (
    <IntlMessages id='status_sharing_extension_proposal_expired_for_cc' />
  )
})

export const VEHICLE_SHARING_EXTENSION_STATUS_LIST = toArrayOptions(
  VEHICLE_SHARING_EXTENSION_STATUS_LABEL
)

export const VEHICLE_USER_TYPE_NUM = Object.freeze({
  SHARER: 1,
  BENEFICIARY: 2
})

export const VEHICLE_SHARING_STATUS_NUM = Object.freeze({
  USAGE_NOT_AVAILABLE: 1,
  USAGE_ACCEPTED_AND_IN_PROGRESS: 2,
  USAGE_CLOSED: 3
})

export const VEHICLE_SHARING_STATUS_LABEL = Object.freeze({
  [VEHICLE_SHARING_STATUS_NUM.USAGE_NOT_AVAILABLE]: (
    <IntlMessages id='status_sharing_usage_not_available_for_cc' />
  ),
  [VEHICLE_SHARING_STATUS_NUM.USAGE_ACCEPTED_AND_IN_PROGRESS]: (
    <IntlMessages id='status_sharing_usage_accepted_and_in_progress_for_cc' />
  ),
  [VEHICLE_SHARING_STATUS_NUM.USAGE_CLOSED]: (
    <IntlMessages id='status_sharing_usage_closed_for_cc' />
  )
})

export const VEHICLE_EXTENSION_PRICING_PERIOD_TYPE = {
  1: <IntlMessages id='vehicle_sharing.period_type_day' />
}

export const VEHICLE_SHARING_CHECK_IN_STATUS_NUM = Object.freeze({
  CHECKIN_NOT_AVAILABLE: 0,
  CHECKIN_AVAILABLE: 1,
  CHECKIN_ACTIVATED_BY_MAIN_PILOT: 2,
  // STARTED_BY_BENEFICIARY: 3,
  CHECKIN_ACCEPTED_BY_GUEST: 4,
  CHECKIN_DECLINED_BY_MAIN_PILOT: 5,
  CHECKIN_DECLINED_BY_GUEST: 6,
  CHECKIN_EXPIRED: 7
})

export const VEHICLE_SHARING_CHECK_IN_STATUS_LABEL = Object.freeze({
  [VEHICLE_SHARING_CHECK_IN_STATUS_NUM.CHECKIN_NOT_AVAILABLE]: (
    <IntlMessages id='status_sharing_checkin_not_available_for_cc' />
  ),
  [VEHICLE_SHARING_CHECK_IN_STATUS_NUM.CHECKIN_AVAILABLE]: (
    <IntlMessages id='status_sharing_checkin_available_for_cc' />
  ),
  [VEHICLE_SHARING_CHECK_IN_STATUS_NUM.CHECKIN_ACTIVATED_BY_MAIN_PILOT]: (
    <IntlMessages id='status_sharing_checkin_activated_by_main_pilot_for_cc' />
  ),
  // [VEHICLE_SHARING_CHECK_IN_STATUS_NUM.STARTED_BY_BENEFICIARY]: (
  //   <IntlMessages id='vehicle_sharing.check_in_status_started_by_beneficiary' />
  // ),
  [VEHICLE_SHARING_CHECK_IN_STATUS_NUM.CHECKIN_ACCEPTED_BY_GUEST]: (
    <IntlMessages id='status_sharing_checkin_accepted_by_guest_for_cc' />
  ),
  [VEHICLE_SHARING_CHECK_IN_STATUS_NUM.CHECKIN_DECLINED_BY_MAIN_PILOT]: (
    <IntlMessages id='status_sharing_checkin_declined_by_main_pilot_for_cc' />
  ),
  [VEHICLE_SHARING_CHECK_IN_STATUS_NUM.CHECKIN_DECLINED_BY_GUEST]: (
    <IntlMessages id='status_sharing_checkin_declined_by_guest_for_cc' />
  ),
  [VEHICLE_SHARING_CHECK_IN_STATUS_NUM.CHECKIN_EXPIRED]: (
    <IntlMessages id='status_sharing_checkin_expired_for_cc' />
  )
})

export const VEHICLE_SHARING_CHECK_OUT_STATUS_NUM = Object.freeze({
  CHECKOUT_NOT_AVAILABLE: 0,
  CHECKOUT_AVAILABLE: 1,
  CHECKOUT_EXCEEDED: 2,
  // STARTED_BY_BENEFICIARY: 2,
  // FORCING_STARTED_BY_SHARER: 3,
  CHECKOUT_ACCEPTED_BY_GUEST: 4,
  CHECKOUT_ACCEPTED_BY_MAIN_PILOT: 5,
  CHECKOUT_FORCED_BY_MAIN_PILOT: 6,
  CHECKOUT_DECLINED_BY_MAIN_PILOT: 7,
  CHECKOUT_DECLINED_BY_GUEST_FOR_MAIN_PILOT: 8
})

export const VEHICLE_SHARING_CHECK_OUT_STATUS_LABEL = Object.freeze({
  [VEHICLE_SHARING_CHECK_OUT_STATUS_NUM.CHECKOUT_NOT_AVAILABLE]: (
    <IntlMessages id='status_sharing_checkout_not_available_for_cc' />
  ),
  [VEHICLE_SHARING_CHECK_OUT_STATUS_NUM.CHECKOUT_AVAILABLE]: (
    <IntlMessages id='status_sharing_checkout_available_for_cc' />
  ),
  [VEHICLE_SHARING_CHECK_OUT_STATUS_NUM.CHECKOUT_EXCEEDED]: (
    <IntlMessages id='status_sharing_checkout_exceeded_for_cc' />
  ),
  // [VEHICLE_SHARING_CHECK_OUT_STATUS_NUM.STARTED_BY_BENEFICIARY]: (
  //   <IntlMessages id='vehicle_sharing.check_out_status_started_by_beneficiary' />
  // ),
  // [VEHICLE_SHARING_CHECK_OUT_STATUS_NUM.FORCING_STARTED_BY_SHARER]: (
  //   <IntlMessages id='vehicle_sharing.check_out_status_forcing_started_by_sharer' />
  // ),
  [VEHICLE_SHARING_CHECK_OUT_STATUS_NUM.CHECKOUT_ACCEPTED_BY_GUEST]: (
    <IntlMessages id='status_sharing_checkout_accepted_by_guest_for_cc' />
  ),
  [VEHICLE_SHARING_CHECK_OUT_STATUS_NUM.CHECKOUT_ACCEPTED_BY_MAIN_PILOT]: (
    <IntlMessages id='status_sharing_checkout_accepted_by_main_pilot_for_cc' />
  ),
  [VEHICLE_SHARING_CHECK_OUT_STATUS_NUM.CHECKOUT_FORCED_BY_MAIN_PILOT]: (
    <IntlMessages id='status_sharing_checkout_forced_by_main_pilot_for_cc' />
  ),
  [VEHICLE_SHARING_CHECK_OUT_STATUS_NUM.CHECKOUT_DECLINED_BY_MAIN_PILOT]: (
    <IntlMessages id='status_sharing_checkout_declined_by_main_pilot_for_cc' />
  ),
  [VEHICLE_SHARING_CHECK_OUT_STATUS_NUM.CHECKOUT_DECLINED_BY_GUEST_FOR_MAIN_PILOT]:
    <IntlMessages id='status_sharing_checkout_declined_by_guest_for_cc' />
})

export const OBJECT_TYPE_COMMENT = Object.freeze({
  SHARING: 1,
  EXTENSION: 2,
  CHECK_IN: 3,
  CHECKOUT: 4
})
