import NotificationRequest from '../actionTypes/notificationRequest'
import { notificationRequest as api } from '../api'
import { bindRequestLogic } from './utils'

const getNotificationRequests = bindRequestLogic({
  clientFunc: api.getNotificationRequests,
  type: NotificationRequest.GET_LIST_NOTIFICATION_REQUEST_REQUEST,
  successType: NotificationRequest.GET_LIST_NOTIFICATION_REQUEST_SUCCESS,
  failType: NotificationRequest.GET_LIST_NOTIFICATION_REQUEST_FAILED,
  isQueryParam: true
})

const sendNotificationRequest = bindRequestLogic({
  clientFunc: api.sendNotificationRequest,
  type: NotificationRequest.SEND_NOTIFICATION_REQUEST_REQUEST,
  successType: NotificationRequest.SEND_NOTIFICATION_REQUEST_SUCCESS,
  failType: NotificationRequest.SEND_NOTIFICATION_REQUEST_FAILED
})

const getNotificationSent = bindRequestLogic({
  clientFunc: api.getNotificationSent,
  type: NotificationRequest.GET_LIST_NOTIFICATION_SENT_REQUEST,
  successType: NotificationRequest.GET_LIST_NOTIFICATION_SENT_SUCCESS,
  failType: NotificationRequest.GET_LIST_NOTIFICATION_SENT_FAILED,
  isQueryParam: false
})

export default [
  getNotificationRequests,
  getNotificationSent,
  sendNotificationRequest
]
