import PersPackageTypes from '../actionTypes/persPackage'
import { persPackage as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const getPersPackages = bindRequestLogic({
  clientFunc: clientApi.getPersPackages,
  type: PersPackageTypes.GET_PERS_PACKAGES_REQUEST,
  successType: PersPackageTypes.GET_PERS_PACKAGES_SUCCESS,
  failType: PersPackageTypes.GET_PERS_PACKAGES_FAILED,
  isQueryParam: true
})

const getPersPackageById = bindRequestLogic({
  clientFunc: clientApi.getPersPackageById,
  type: PersPackageTypes.GET_PERS_PACKAGE_BY_ID_REQUEST,
  successType: PersPackageTypes.GET_PERS_PACKAGE_BY_ID_SUCCESS,
  failType: PersPackageTypes.GET_PERS_PACKAGE_BY_ID_FAILED
})

export default [getPersPackages, getPersPackageById]
