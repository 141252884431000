import RequestStatus from 'constants/requestStatus'
// import { DEFAULT_MESSAGE_FILTER } from 'constants/common'
// import { getModuleFilter } from 'helpers/filter'
import { createSelector } from 'reselect'

export const getMessageList = (state) => state?.store?.message.list || []

export const getPagination = (state) => state?.store?.message.pagination || {}

export const systemMessages = (state) => state?.store?.message.systems || []

export const memoizedSystemMessages = createSelector(
  [systemMessages],
  (messages) => messages || {}
)

// export const getFilter = (state) =>
//   getModuleFilter(state, 'message', DEFAULT_MESSAGE_FILTER)
export const getFilter = (state) => state.store?.message?.filter || {}

export const isLoading = (state) =>
  state.requests?.message?.getMessages.status === RequestStatus.STARTED
