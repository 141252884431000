import AppVersionsTypes from 'store/actionTypes/appVersion'
import UserTypes from '../actionTypes/user'
import { replaceValueAtPath } from 'helpers/utils'
import { DEFAULT_APP_VERSIONS_FILTER } from 'constants/common'

const initialData = {
  list: [],
  current: {},
  systems: [],
  pagination: {},
  filter: DEFAULT_APP_VERSIONS_FILTER
}

const appVersion = (state = initialData, action) => {
  switch (action.type) {
    case AppVersionsTypes.GET_APP_VERSIONS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case AppVersionsTypes.GET_APP_VERSION_DETAIL_SUCCESS:
      return replaceValueAtPath(state, 'current', action.payload)
    case AppVersionsTypes.UPDATE_APP_VERSION_FILTER_SUCCESS:
      return replaceValueAtPath(state, 'filter', action.payload)
    case AppVersionsTypes.GET_SYSTEM_APP_VERSIONS_SUCCESS:
      return { ...state, systems: action.payload?.items?.appVersions }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default appVersion
