import ElectronicKeyModelTypes from '../actionTypes/electronicKeyModel'
import { electronicKeyModel as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const getElectronicKeyModels = bindRequestLogic({
  clientFunc: clientApi.getElectronicKeyModels,
  type: ElectronicKeyModelTypes.GET_ELECTRONIC_KEY_MODELS_REQUEST,
  successType: ElectronicKeyModelTypes.GET_ELECTRONIC_KEY_MODELS_SUCCESS,
  failType: ElectronicKeyModelTypes.GET_ELECTRONIC_KEY_MODELS_FAILED,
  isQueryParam: true,
  modelName: 'electronicKeyModel'
})

const addElectronicKeyModel = bindRequestLogic({
  clientFunc: clientApi.addElectronicKeyModel,
  type: ElectronicKeyModelTypes.ADD_ELECTRONIC_KEY_MODEL_REQUEST,
  successType: ElectronicKeyModelTypes.ADD_ELECTRONIC_KEY_MODEL_SUCCESS,
  failType: ElectronicKeyModelTypes.ADD_ELECTRONIC_KEY_MODEL_FAILED
})

const getElectronicKeyModelDetail = bindRequestLogic({
  clientFunc: clientApi.getElectronicKeyModelDetail,
  type: ElectronicKeyModelTypes.GET_ELECTRONIC_KEY_MODEL_DETAIL_REQUEST,
  successType: ElectronicKeyModelTypes.GET_ELECTRONIC_KEY_MODEL_DETAIL_SUCCESS,
  failType: ElectronicKeyModelTypes.GET_ELECTRONIC_KEY_MODEL_DETAIL_FAILED,
  modelName: 'electronicKeyModel'
})

const deleteElectronicKeyModel = bindRequestLogic({
  clientFunc: clientApi.deleteElectronicKeyModel,
  type: ElectronicKeyModelTypes.DELETE_ELECTRONIC_KEY_MODEL_REQUEST,
  successType: ElectronicKeyModelTypes.DELETE_ELECTRONIC_KEY_MODEL_SUCCESS,
  failType: ElectronicKeyModelTypes.DELETE_ELECTRONIC_KEY_MODEL_FAILED
})

const updateElectronicKeyModel = bindRequestLogic({
  clientFunc: clientApi.updateElectronicKeyModel,
  type: ElectronicKeyModelTypes.UPDATE_ELECTRONIC_KEY_MODEL_REQUEST,
  successType: ElectronicKeyModelTypes.UPDATE_ELECTRONIC_KEY_MODEL_SUCCESS,
  failType: ElectronicKeyModelTypes.UPDATE_ELECTRONIC_KEY_MODEL_FAILED
})

const getSystemElectronicKeyModels = bindRequestLogic({
  clientFunc: clientApi.getSystemElectronicKeyModels,
  type: ElectronicKeyModelTypes.GET_SYSTEM_ELECTRONIC_KEY_MODELS_REQUEST,
  successType: ElectronicKeyModelTypes.GET_SYSTEM_ELECTRONIC_KEY_MODELS_SUCCESS,
  failType: ElectronicKeyModelTypes.GET_SYSTEM_ELECTRONIC_KEY_MODELS_FAILED,
  isQueryParam: true
})

export default [
  getElectronicKeyModels,
  addElectronicKeyModel,
  getElectronicKeyModelDetail,
  deleteElectronicKeyModel,
  updateElectronicKeyModel,
  getSystemElectronicKeyModels
]
