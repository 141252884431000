import keyMirror from 'keymirror'

export default keyMirror({
  GET_TEXTS_REQUEST: null,
  GET_TEXTS_SUCCESS: null,
  GET_TEXTS_FAILED: null,

  UPDATE_TEXTS_REQUEST: null,
  UPDATE_TEXTS_SUCCESS: null,
  UPDATE_TEXTS_FAILED: null,

  CREATE_TEXT_REQUEST: null,
  CREATE_TEXT_SUCCESS: null,
  CREATE_TEXT_FAILED: null,

  DELETE_TEXT_REQUEST: null,
  DELETE_TEXT_SUCCESS: null,
  DELETE_TEXT_FAILED: null,

  SAVE_TEXTS_CONFIGURATION_REQUEST: null,
  SAVE_TEXTS_CONFIGURATION_SUCCESS: null,
  SAVE_TEXTS_CONFIGURATION_FAILED: null,

  UPDATE_FILTER_TEXT_REQUEST: null,
  UPDATE_FILTER_TEXT_SUCCESS: null,
  CLEAR_FILTER_TEXT_REQUEST: null,
  CLEAR_FILTER_TEXT_SUCCESS: null
})
