import BaseApi from './base'

class SoftwarePer extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  getSoftwarePers = (query) => this.client.get(`software-pers?${query}`)

  getSoftwarePerBy = (id) => this.client.get(`software-pers/${id}`)

  addSoftwarePer = (data) => this.client.post('software-pers', data)

  updateSoftwarePer = (id, data) =>
    this.client.patch(`software-pers/${id}`, data)

  deleteSoftwarePer = (id) => this.client.delete(`software-pers/${id}`)

  getSystemSoftwarePers = (query) =>
    this.client.get(`systems/software-pers?${query}`)
}

export default new SoftwarePer()
