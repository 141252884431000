import ModelPerTypes from '../actionTypes/modelPer'
import { modelPer as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const getModelPers = bindRequestLogic({
  clientFunc: clientApi.getModelPers,
  type: ModelPerTypes.GET_MODEL_PERS_REQUEST,
  successType: ModelPerTypes.GET_MODEL_PERS_SUCCESS,
  failType: ModelPerTypes.GET_MODEL_PERS_FAILED,
  isQueryParam: true,
  modelName: 'modelPers'
})

const addModelPer = bindRequestLogic({
  clientFunc: clientApi.addModelPer,
  type: ModelPerTypes.ADD_MODEL_PER_REQUEST,
  successType: ModelPerTypes.ADD_MODEL_PER_SUCCESS,
  failType: ModelPerTypes.ADD_MODEL_PER_FAILED
})

const getModelPerDetail = bindRequestLogic({
  clientFunc: clientApi.getModelPerDetail,
  type: ModelPerTypes.GET_MODEL_PER_DETAIL_REQUEST,
  successType: ModelPerTypes.GET_MODEL_PER_DETAIL_SUCCESS,
  failType: ModelPerTypes.GET_MODEL_PER_DETAIL_FAILED,
  modelName: 'modelPERS'
})

const deleteModelPer = bindRequestLogic({
  clientFunc: clientApi.deleteModelPer,
  type: ModelPerTypes.DELETE_MODEL_PER_REQUEST,
  successType: ModelPerTypes.DELETE_MODEL_PER_SUCCESS,
  failType: ModelPerTypes.DELETE_MODEL_PER_FAILED
})

const updateModelPer = bindRequestLogic({
  clientFunc: clientApi.updateModelPer,
  type: ModelPerTypes.UPDATE_MODEL_PER_REQUEST,
  successType: ModelPerTypes.UPDATE_MODEL_PER_SUCCESS,
  failType: ModelPerTypes.UPDATE_MODEL_PER_FAILED
})

const getSystemModelPers = bindRequestLogic({
  clientFunc: clientApi.getSystemModelPers,
  type: ModelPerTypes.GET_SYSTEM_MODEL_PERS_REQUEST,
  successType: ModelPerTypes.GET_SYSTEM_MODEL_PERS_SUCCESS,
  failType: ModelPerTypes.GET_SYSTEM_MODEL_PERS_FAILED,
  isQueryParam: true
})

export default [
  getModelPers,
  addModelPer,
  getModelPerDetail,
  deleteModelPer,
  updateModelPer,
  getSystemModelPers
]
