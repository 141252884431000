import { DEFAULT_USAGE_SYSTEM_LOG_FILTER } from 'constants/common'
import UsageSystemLogTypes from 'store/actionTypes/usageSystemLog'
import UserTypes from 'store/actionTypes/user'

const initialData = Object.freeze({
  list: [],
  current: {},
  pagination: {},
  filter: DEFAULT_USAGE_SYSTEM_LOG_FILTER
})

const usageSystemLog = (state = initialData, action) => {
  switch (action.type) {
    case UsageSystemLogTypes.GET_USAGE_SYSTEM_LOGS_SUCCESS:
      return {
        ...state,
        list: action?.payload?.items,
        pagination: action.payload?.meta
      }
    case UsageSystemLogTypes.GET_DETAIL_USAGE_SYSTEM_LOG_SUCCESS:
      return {
        ...state,
        current: action?.payload?.keyUsageSystemLogs || {}
      }
    case UsageSystemLogTypes.UPDATE_FILTER_USAGE_SYSTEM_LOG_SUCCESS:
      return { ...state, filter: action?.payload?.value }
    case UsageSystemLogTypes.CLEAR_FILTER_USAGE_SYSTEM_LOG_SUCCESS:
      return { ...state, filter: initialData.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default usageSystemLog
