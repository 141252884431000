import SubscriptionElectronicKeyTypes from '../actionTypes/subscriptionElectronicKey'
import { subscriptionElectronicKey as subscriptionElectronicKeyApi } from '../api'
import { bindRequestLogic } from './utils'

const getSubscriptionElectronicKeys = bindRequestLogic({
  clientFunc: subscriptionElectronicKeyApi.getSubscriptionElectronicKeys,
  type: SubscriptionElectronicKeyTypes.GET_SUBSCRIPTION_ELECTRONIC_KEYS_REQUEST,
  successType:
    SubscriptionElectronicKeyTypes.GET_SUBSCRIPTION_ELECTRONIC_KEYS_SUCCESS,
  failType:
    SubscriptionElectronicKeyTypes.GET_SUBSCRIPTION_ELECTRONIC_KEYS_FAILED,
  isQueryParam: true,
  modelName: 'subscriptionVehicles'
})

const addSubscriptionElectronicKey = bindRequestLogic({
  clientFunc: subscriptionElectronicKeyApi.addSubscriptionElectronicKey,
  type: SubscriptionElectronicKeyTypes.ADD_SUBSCRIPTION_ELECTRONIC_KEY_REQUEST,
  successType:
    SubscriptionElectronicKeyTypes.ADD_SUBSCRIPTION_ELECTRONIC_KEY_SUCCESS,
  failType:
    SubscriptionElectronicKeyTypes.ADD_SUBSCRIPTION_ELECTRONIC_KEY_FAILED
})

const updateSubscriptionElectronicKey = bindRequestLogic({
  clientFunc: subscriptionElectronicKeyApi.updateSubscriptionElectronicKey,
  type: SubscriptionElectronicKeyTypes.UPDATE_SUBSCRIPTION_ELECTRONIC_KEY_REQUEST,
  successType:
    SubscriptionElectronicKeyTypes.UPDATE_SUBSCRIPTION_ELECTRONIC_KEY_SUCCESS,
  failType:
    SubscriptionElectronicKeyTypes.UPDATE_SUBSCRIPTION_ELECTRONIC_KEY_FAILED
})

const getSubscriptionElectronicKeyDetail = bindRequestLogic({
  clientFunc: subscriptionElectronicKeyApi.getSubscriptionElectronicKeyDetail,
  type: SubscriptionElectronicKeyTypes.GET_SUBSCRIPTION_ELECTRONIC_KEY_DETAIL_REQUEST,
  successType:
    SubscriptionElectronicKeyTypes.GET_SUBSCRIPTION_ELECTRONIC_KEY_DETAIL_SUCCESS,
  failType:
    SubscriptionElectronicKeyTypes.GET_SUBSCRIPTION_ELECTRONIC_KEY_DETAIL_FAILED
})

const deleteSubscriptionElectronicKey = bindRequestLogic({
  clientFunc: subscriptionElectronicKeyApi.deleteSubscriptionElectronicKey,
  type: SubscriptionElectronicKeyTypes.DELETE_SUBSCRIPTION_ELECTRONIC_KEY_REQUEST,
  successType:
    SubscriptionElectronicKeyTypes.DELETE_SUBSCRIPTION_ELECTRONIC_KEY_SUCCESS,
  failType:
    SubscriptionElectronicKeyTypes.DELETE_SUBSCRIPTION_ELECTRONIC_KEY_FAILED
})

export default [
  getSubscriptionElectronicKeys,
  addSubscriptionElectronicKey,
  getSubscriptionElectronicKeyDetail,
  deleteSubscriptionElectronicKey,
  updateSubscriptionElectronicKey
]
