import { combineReducers } from 'redux'
import Types from 'store/actionTypes/usageSystemLog'
import { createHandleRequest } from './helpers'

const getUsageSystemLogs = createHandleRequest(
  Types.GET_USAGE_SYSTEM_LOGS_REQUEST,
  Types.GET_USAGE_SYSTEM_LOGS_SUCCESS,
  Types.GET_USAGE_SYSTEM_LOGS_FAILED
)

export default combineReducers({
  getUsageSystemLogs
})
