import { combineReducers } from 'redux'
import SharingTypes from 'store/actionTypes/sharing'
import { createHandleRequest } from './helpers'

const getSharings = createHandleRequest(
  SharingTypes.GET_LIST_OF_SHARING_REQUEST,
  SharingTypes.GET_LIST_OF_SHARING_SUCCESS,
  SharingTypes.GET_LIST_OF_SHARING_FAILED
)

export default combineReducers({
  getSharings
})
