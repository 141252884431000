import ShowcaseSectionTypes from '../actionTypes/showcaseSection'
import { showcaseSection as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const getShowcaseSections = bindRequestLogic({
  clientFunc: clientApi.getShowcaseSections,
  type: ShowcaseSectionTypes.GET_SHOWCASE_SECTIONS_REQUEST,
  successType: ShowcaseSectionTypes.GET_SHOWCASE_SECTIONS_SUCCESS,
  failType: ShowcaseSectionTypes.GET_SHOWCASE_SECTIONS_FAILED,
  isQueryParam: true
})

const addShowcaseSection = bindRequestLogic({
  clientFunc: clientApi.addShowcaseSection,
  type: ShowcaseSectionTypes.ADD_SHOWCASE_SECTION_REQUEST,
  successType: ShowcaseSectionTypes.ADD_SHOWCASE_SECTION_SUCCESS,
  failType: ShowcaseSectionTypes.ADD_SHOWCASE_SECTION_FAILED
})

const deleteShowcaseSection = bindRequestLogic({
  clientFunc: clientApi.deleteShowcaseSection,
  type: ShowcaseSectionTypes.DELETE_SHOWCASE_SECTION_REQUEST,
  successType: ShowcaseSectionTypes.DELETE_SHOWCASE_SECTION_SUCCESS,
  failType: ShowcaseSectionTypes.DELETE_SHOWCASE_SECTION_FAILED
})

const updateShowcaseSection = bindRequestLogic({
  clientFunc: clientApi.updateShowcaseSection,
  type: ShowcaseSectionTypes.UPDATE_SHOWCASE_SECTION_REQUEST,
  successType: ShowcaseSectionTypes.UPDATE_SHOWCASE_SECTION_SUCCESS,
  failType: ShowcaseSectionTypes.UPDATE_SHOWCASE_SECTION_FAILED
})

const getShowcaseSection = bindRequestLogic({
  clientFunc: clientApi.getShowcaseSection,
  type: ShowcaseSectionTypes.GET_SHOWCASE_SECTION_DETAIL_REQUEST,
  successType: ShowcaseSectionTypes.GET_SHOWCASE_SECTION_DETAIL_SUCCESS,
  failType: ShowcaseSectionTypes.GET_SHOWCASE_SECTION_DETAIL_FAILED
})

export const getSystemSections = bindRequestLogic({
  clientFunc: clientApi.getSystemSections,
  type: ShowcaseSectionTypes.GET_SYSTEM_SECTIONS_REQUEST,
  successType: ShowcaseSectionTypes.GET_SYSTEM_SECTIONS_SUCCESS,
  failType: ShowcaseSectionTypes.GET_SYSTEM_SECTIONS_FAILED,
  isQueryParam: true
})

export default [
  getShowcaseSections,
  addShowcaseSection,
  deleteShowcaseSection,
  updateShowcaseSection,
  getShowcaseSection,
  getSystemSections
]
