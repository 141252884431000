import { DEFAULT_NOTIFICATION_TEMPLATE_FILTER } from 'constants/common'
import NotificationTemplateTypes from 'store/actionTypes/notificationTemplate'
import NotificationTemplateTechTypes from 'store/actionTypes/notificationTemplateTech'
import UserTypes from 'store/actionTypes/user'

const initNotificationTemplate = {
  list: [],
  current: {},
  systems: [],
  pagination: {},
  filter: DEFAULT_NOTIFICATION_TEMPLATE_FILTER,
  platformTechs: [],
  platformPageTechs: []
}

const notificationTemplate = (state = initNotificationTemplate, action) => {
  switch (action.type) {
    case NotificationTemplateTypes.GET_NOTIFICATION_TEMPLATES_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case NotificationTemplateTypes.GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload?.items
      }
    case NotificationTemplateTypes.GET_NOTIFICATION_TEMPLATE_DETAIL_SUCCESS:
      return { ...state, current: action.payload }

    case NotificationTemplateTypes.GET_NOTIFICATION_TEMPLATE_DETAIL_FAILED:
      return { ...state, current: {} }
    case NotificationTemplateTypes.UPDATE_FILTER_NOTIFICATION_TEMPLATE_SUCCESS:
      return { ...state, filter: action.payload }
    case NotificationTemplateTypes.CLEAR_FILTER_NOTIFICATION_TEMPLATE_SUCCESS:
      return { ...state, filter: initNotificationTemplate.filter }
    case NotificationTemplateTechTypes.GET_NOTIFICATION_TEMPLATE_TECH_BY_NOTIFICATION_TEMPLATE_ID_SUCCESS:
      return { ...state, platformTechs: action?.payload?.items }
    case NotificationTemplateTypes.GET_PLATFORM_PAGE_TECH_IN_NOTIFICATION_TEMPLATE_SUCCESS:
      return { ...state, platformPageTechs: action?.payload?.items }
    case NotificationTemplateTypes.GET_PLATFORM_PAGE_TECH_IN_NOTIFICATION_TEMPLATE_FAILED:
      return { ...state, platformPageTechs: [] }
    case NotificationTemplateTypes.GET_SYSTEM_NOTIFICATION_TEMPLATES_SUCCESS:
      return { ...state, systems: action.payload?.items }
    case UserTypes.LOGOUT_SUCCESS:
      return initNotificationTemplate
    default:
      return state
  }
}

export default notificationTemplate
