export const SYSTEM_CONFIG_CODE = {
  boSiteName: 'BO_site_name',
  fmpSiteName: 'FMP_site_name',
  mailSender: 'mail_sender',
  mailingList: 'mailing_list',
  durationResendInvitationEmail: 'duration_resend_invitation_email',
  organizationMemberResendInvitationEmailPerDays:
    'organization_member_resend_invitation_email_per_days',
  recipientForContactUs: 'recipient_for_contact_us',
  recipientForRequestBrochure: 'recipient_for_request_brochure',
  maximumFiles: 'maximum_files',
  maximumFileSize: 'maximum_file_size',
  userMaxWrongLoginAttempts: 'user_max_wrong_login_attempts',
  userDurationWrongAttempts: 'user_duration_wrong_attempts',
  userMaxWrongAttemptsToDeactivatedAccount:
    'user_max_wrong_attempts_to_deactivated_account',
  durationOfActivationCode: 'duration_of_activation_code',
  mailingLanguage: 'mailing_language',
  interfaceLanguageForCfm: 'interface_language_for_cfm',
  interfaceLanguageForShowcaseWebsite:
    'interface_language_for_showcase_website',
  interfaceLanguageForMobileApp: 'interface_language_for_mobile_app',
  passwordSwitchPlatform: 'password_switch_platform',
  defaultAvatarUserBO: 'default_avatar_user_BO',
  defaultAvatarUserApp: 'default_avatar_user_app',
  defaultAvatarCompany: 'default_avatar_company',
  defaultAvatarVehicle: 'default_avatar_vehicle',
  defaultAvatarBike: 'default_avatar_bike',
  defaultAvatarElectronicKey: 'default_avatar_electronic_key',
  defaultImageCompanySelection: 'default_image_company_selection',
  defaultCountry: 'default_country',
  defaultTimezone: 'default_timezone',
  removeArchivedVisitorContacts: 'remove_archived_visitor_contacts',
  delayToKeepInUpComingForMainPilot:
    'delay_to_keep_in_up_coming_for_main_pilot',
  delayToKeepInUpComingForGuest: 'delay_to_keep_in_up_coming_for_guest_pilot',
  maximumMonthsToCreateATemporalSharing:
    'maximum_months_to_create_a_temporal_sharing',
  nbDaysForExpiredVehicleSharingInvitation:
    'nb_days_for_expired_vehicle_sharing_invitation',
  referenceSharingForIndividualSharing:
    'reference_sharing_for_individual_sharing',
  nbDaysForExpireVehicleSharingCheckIn:
    'nb_days_for_expire_vehicle_sharing_checkin',
  nbDaysForExceedVehicleSharingCheckout:
    'nb_days_for_exceed_vehicle_sharing_checkout',
  numberOfNotificationsPerPage: 'number_of_notifications_per_page',
  frequencyToUpdateNumberOfNotifications:
    'frequency_to_update_number_of_notifications',
  frequencyToUpdateListOfNotifications:
    'frequency_to_update_list_of_notifications',
  minimumVehicleSpeedToConsider: 'minimum_vehicle_speed_to_consider',
  waitTripCalculation: 'wait_trip_calculation',
  nbMinutesToDetectLatePersPackageLog:
    'nb_minutes_to_detect_late_pers_package_log',
  startingBluetoothName: 'starting_bluetooth_name',
  waitPersResponseSeconds: 'wait_pers_response_seconds',
  interfaceLanguageForProCenter: 'interface_language_for_pro_center',
  proCenterSiteName: 'pro_center_site_name',
  odooModelPersIdentification: 'odoo_model_pers_identification',
  overlapSynchronization: 'overlap_synchronization'
}

export const BUSINESS_CONFIG_PARAMETERS_KEY = [
  'system_parameters.visitor_contact',
  SYSTEM_CONFIG_CODE.removeArchivedVisitorContacts,
  'system_parameters.pilot_app',
  SYSTEM_CONFIG_CODE.delayToKeepInUpComingForMainPilot,
  SYSTEM_CONFIG_CODE.delayToKeepInUpComingForGuest,
  SYSTEM_CONFIG_CODE.nbDaysForExpiredVehicleSharingInvitation,
  SYSTEM_CONFIG_CODE.maximumMonthsToCreateATemporalSharing,
  SYSTEM_CONFIG_CODE.nbDaysForExpireVehicleSharingCheckIn,
  SYSTEM_CONFIG_CODE.nbDaysForExceedVehicleSharingCheckout,
  'system_parameters.sequences',
  SYSTEM_CONFIG_CODE.referenceSharingForIndividualSharing,
  'system_parameters.trip_statistics',
  SYSTEM_CONFIG_CODE.minimumVehicleSpeedToConsider,
  SYSTEM_CONFIG_CODE.waitTripCalculation,
  SYSTEM_CONFIG_CODE.nbMinutesToDetectLatePersPackageLog,
  'system_parameters.bluetooth',
  SYSTEM_CONFIG_CODE.startingBluetoothName,
  'system_parameters.pilot_app_vs_pers',
  SYSTEM_CONFIG_CODE.waitPersResponseSeconds
]

export const NOTIFICATION_CONFIG_PARAMETERS_KEY = [
  SYSTEM_CONFIG_CODE.numberOfNotificationsPerPage,
  SYSTEM_CONFIG_CODE.frequencyToUpdateNumberOfNotifications,
  SYSTEM_CONFIG_CODE.frequencyToUpdateListOfNotifications
]

export const GENERAL_CONFIG_PARAMETERS_KEY = [
  'system_parameters.site_name_of_the_platforms',
  SYSTEM_CONFIG_CODE.boSiteName,
  SYSTEM_CONFIG_CODE.fmpSiteName,
  SYSTEM_CONFIG_CODE.proCenterSiteName,
  'system_parameters.mailing',
  SYSTEM_CONFIG_CODE.mailSender,
  SYSTEM_CONFIG_CODE.mailingList,
  SYSTEM_CONFIG_CODE.durationResendInvitationEmail,
  SYSTEM_CONFIG_CODE.organizationMemberResendInvitationEmailPerDays,
  SYSTEM_CONFIG_CODE.recipientForContactUs,
  SYSTEM_CONFIG_CODE.recipientForRequestBrochure,
  'system_parameters.upload',
  SYSTEM_CONFIG_CODE.maximumFiles,
  SYSTEM_CONFIG_CODE.maximumFileSize,
  'system_parameters.login',
  SYSTEM_CONFIG_CODE.userMaxWrongLoginAttempts,
  SYSTEM_CONFIG_CODE.userDurationWrongAttempts,
  SYSTEM_CONFIG_CODE.userMaxWrongAttemptsToDeactivatedAccount,
  SYSTEM_CONFIG_CODE.durationOfActivationCode,
  'system_parameters.interface_language',
  SYSTEM_CONFIG_CODE.mailingLanguage,
  SYSTEM_CONFIG_CODE.interfaceLanguageForCfm,
  SYSTEM_CONFIG_CODE.interfaceLanguageForProCenter,
  SYSTEM_CONFIG_CODE.interfaceLanguageForMobileApp,
  SYSTEM_CONFIG_CODE.interfaceLanguageForShowcaseWebsite,
  'system_parameters.password_for_switch_user',
  SYSTEM_CONFIG_CODE.passwordSwitchPlatform,
  'system_parameters.avatars',
  SYSTEM_CONFIG_CODE.defaultAvatarUserBO,
  SYSTEM_CONFIG_CODE.defaultAvatarUserApp,
  SYSTEM_CONFIG_CODE.defaultAvatarCompany,
  SYSTEM_CONFIG_CODE.defaultAvatarVehicle,
  SYSTEM_CONFIG_CODE.defaultAvatarBike,
  SYSTEM_CONFIG_CODE.defaultAvatarElectronicKey,
  SYSTEM_CONFIG_CODE.defaultImageCompanySelection
]

export const ODOO_CONFIG_PARAMETERS_KEY = [
  SYSTEM_CONFIG_CODE.odooModelPersIdentification,
  SYSTEM_CONFIG_CODE.overlapSynchronization
]

export const SYSTEM_CONFIG_INPUT_TYPE = {
  imageFile: 'image_file',
  number: 'number',
  integer: 'integer',
  string: 'string'
}
