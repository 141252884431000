/* eslint-disable no-unused-vars */
import { PAGINATION } from 'constants/common'
import { createLogic } from 'redux-logic'
import StorageTypes from '../actionTypes/storage'
import UserTypes from '../actionTypes/user'
import CompanyTypes from '../actionTypes/company'
import SystemConfigTypes from '../actionTypes/systemConfig'
import { user as userAPI } from '../api'
import { getSystemVehicleTypes } from 'store/actions/vehicleType'

const reloadUserInfo = createLogic({
  type: StorageTypes.RELOAD_USER_INFO_LOCAL_STORAGE_REQUEST,
  latest: true,

  async process({ action }, dispatch, done) {
    try {
      const res = await userAPI.fetchProfile()
      const user = res?.data?.user

      if (user?.id) {
        dispatch({
          type: UserTypes.FETCH_PROFILE_SUCCESS,
          payload: user
        })
      }
    } catch (error) {
      // do nothing
    }

    done()
  }
})

const loadLocalData = createLogic({
  type: StorageTypes.LOAD_LOCAL_STORAGE_REQUEST,
  latest: true,

  async process({ action = {} }, dispatch, done) {
    const { onSuccess, onError } = action.meta || {}

    try {
      const res = await userAPI.fetchProfile()
      const user = res?.data?.user
      if (user?.id) {
        dispatch({
          type: UserTypes.FETCH_PROFILE_SUCCESS,
          payload: user
        })

        dispatch({
          type: CompanyTypes.GET_SYSTEM_COMPANIES_REQUEST,
          payload: { limit: PAGINATION.unlimited }
        })

        dispatch({
          type: SystemConfigTypes.GET_SYSTEM_CONFIG_LIST_REQUEST,
          payload: { limit: PAGINATION.unlimited }
        })

        dispatch(getSystemVehicleTypes({ limit: PAGINATION.unlimited }))

        if (onSuccess) {
          onSuccess()
        }
      } else {
        dispatch({
          type: UserTypes.FETCH_PROFILE_FAILURE,
          payload: {}
        })
      }
    } catch (error) {
      if (onError) {
        onError()
      }
    }

    done()
  }
})

export default [loadLocalData, reloadUserInfo]
