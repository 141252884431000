import BaseApi from './base'

class VehiclePer extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  addVehiclePer = (data) => this.client.post(`vehicles/pers/link`, data)

  deleteVehiclePer = (data) => this.client.post(`vehicles/pers/un-link`, data)
}

export default new VehiclePer()
