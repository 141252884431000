import BaseApi from './base'

class Text extends BaseApi {
  getTexts = (query) => this.client.get(`admin/texts?${query}`)

  updateText = (id, data) => this.client.patch(`admin/texts/${id}`, data)

  createText = (data) => this.client.post('admin/texts', data)

  deleteText = (id) => this.client.delete(`admin/texts/${id}`)

  updateTexts = (languageId, data) =>
    this.client.post(`admin/texts/translation/bulk-upsert/${languageId}`, data)

  getSystemTexts = (query) => this.client.get(`admin/system/texts?${query}`)
}

export default new Text()
