import ProCenterAdminType from '../actionTypes/proCenterAdmin'
import {
  proCenterAdmin as proCenterAdminApi,
  systemConfig as systemConfigApi
} from '../api'
import { bindRequestLogic } from './utils'

const getProCenterAdmins = bindRequestLogic({
  clientFunc: proCenterAdminApi.getProCenterAdmins,
  type: ProCenterAdminType.GET_PRO_CENTER_ADMINS_REQUEST,
  successType: ProCenterAdminType.GET_PRO_CENTER_ADMINS_SUCCESS,
  failType: ProCenterAdminType.GET_PRO_CENTER_ADMINS_FAILED,
  isQueryParam: true
})

const addProCenterAdmin = bindRequestLogic({
  clientFunc: proCenterAdminApi.addProCenterAdmin,
  type: ProCenterAdminType.ADD_PRO_CENTER_ADMIN_REQUEST,
  successType: ProCenterAdminType.ADD_PRO_CENTER_ADMIN_SUCCESS,
  failType: ProCenterAdminType.ADD_PRO_CENTER_ADMIN_FAILED
})

const getProCenterAdminDetail = bindRequestLogic({
  clientFunc: proCenterAdminApi.getProCenterAdminDetail,
  type: ProCenterAdminType.GET_PRO_CENTER_ADMIN_DETAIL_REQUEST,
  successType: ProCenterAdminType.GET_PRO_CENTER_ADMIN_DETAIL_SUCCESS,
  failType: ProCenterAdminType.GET_PRO_CENTER_ADMIN_DETAIL_FAILED,
  modelName: 'userCompany'
})

const deleteProCenterAdmin = bindRequestLogic({
  clientFunc: proCenterAdminApi.deleteProCenterAdmin,
  type: ProCenterAdminType.DELETE_PRO_CENTER_ADMIN_REQUEST,
  successType: ProCenterAdminType.DELETE_PRO_CENTER_ADMIN_SUCCESS,
  failType: ProCenterAdminType.DELETE_PRO_CENTER_ADMIN_FAILED
})

const updateProCenterAdmin = bindRequestLogic({
  clientFunc: proCenterAdminApi.updateProCenterAdmin,
  type: ProCenterAdminType.UPDATE_PRO_CENTER_ADMIN_REQUEST,
  successType: ProCenterAdminType.UPDATE_PRO_CENTER_ADMIN_SUCCESS,
  failType: ProCenterAdminType.UPDATE_PRO_CENTER_ADMIN_FAILED
})

const getCompanyRoleConfigurationById = bindRequestLogic({
  clientFunc: systemConfigApi.getCompanyRoleConfigurationById,
  type: ProCenterAdminType.GET_COMPANY_ROLE_CONFIGURATION_BY_COMPANY_ID_REQUEST,
  successType:
    ProCenterAdminType.GET_COMPANY_ROLE_CONFIGURATION_BY_COMPANY_ID_SUCCESS,
  failType:
    ProCenterAdminType.GET_COMPANY_ROLE_CONFIGURATION_BY_COMPANY_ID_FAILED
})

export default [
  getProCenterAdmins,
  addProCenterAdmin,
  getProCenterAdminDetail,
  deleteProCenterAdmin,
  updateProCenterAdmin,
  getCompanyRoleConfigurationById
]
