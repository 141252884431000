import BaseApi from './base'

class NotificationTemplateContent extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = this.notificationURL
  }

  addNotificationTemplateContent = (data) =>
    this.client.post(`admin/notification-template-content`, data)

  updateNotificationTemplateContent = (id, data) =>
    this.client.patch(`admin/notification-template-content/${id}`, data)

  deleteNotificationTemplateContent = (id) =>
    this.client.delete(`admin/notification-template-content/${id}`)

  getNotificationTemplateContentByNotificationId = (query) =>
    this.client.get(`admin/notification-template-content?${query}`)

  getSystemNotificationTemplateContentById = (id) =>
    this.client.get(`notification-template-content/${id}`)
}

export default new NotificationTemplateContent()
