import keyMirror from 'keymirror'

export default keyMirror({
  GET_VEHICLE_TYPES_REQUEST: null,
  GET_VEHICLE_TYPES_SUCCESS: null,
  GET_VEHICLE_TYPES_FAILED: null,

  ADD_VEHICLE_TYPE_REQUEST: null,
  ADD_VEHICLE_TYPE_SUCCESS: null,
  ADD_VEHICLE_TYPE_FAILED: null,

  GET_VEHICLE_TYPE_DETAIL_REQUEST: null,
  GET_VEHICLE_TYPE_DETAIL_SUCCESS: null,
  GET_VEHICLE_TYPE_DETAIL_FAILED: null,

  UPDATE_VEHICLE_TYPE_REQUEST: null,
  UPDATE_VEHICLE_TYPE_SUCCESS: null,
  UPDATE_VEHICLE_TYPE_FAILED: null,

  DELETE_VEHICLE_TYPE_REQUEST: null,
  DELETE_VEHICLE_TYPE_SUCCESS: null,
  DELETE_VEHICLE_TYPE_FAILED: null,

  GET_SYSTEM_VEHICLE_TYPES_REQUEST: null,
  GET_SYSTEM_VEHICLE_TYPES_SUCCESS: null,
  GET_SYSTEM_VEHICLE_TYPES_FAILED: null,

  UPDATE_FILTER_VEHICLE_TYPE_SUCCESS: null,
  CLEAR_FILTER_VEHICLE_TYPE_SUCCESS: null
})
