import { DEFAULT_SOFTWARE_PER_FILTER } from 'constants/common'
import SoftwarePerTypes from 'store/actionTypes/softwarePer'
import UserTypes from 'store/actionTypes/user'

const initData = {
  list: [],
  systems: [],
  current: {},
  pagination: {},
  filter: DEFAULT_SOFTWARE_PER_FILTER
}

const softwarePer = (state = initData, action) => {
  switch (action.type) {
    case SoftwarePerTypes.GET_SOFTWARE_PERS_SUCCESS:
      return {
        ...state,
        list: action.payload?.softwarePers?.items,
        pagination: action.payload?.softwarePers?.meta
      }
    case SoftwarePerTypes.GET_SOFTWARE_PER_DETAIL_SUCCESS:
      return { ...state, current: action.payload?.softwarePERS }
    case SoftwarePerTypes.UPDATE_FILTER_SOFTWARE_PER_SUCCESS:
      return { ...state, filter: action.payload }
    case SoftwarePerTypes.CLEAR_FILTER_SOFTWARE_PER_SUCCESS:
      return { ...state, filter: initData.filter }
    case SoftwarePerTypes.GET_SYSTEM_SOFTWARE_PERS_SUCCESS:
      return {
        ...state,
        systems: action.payload?.softwarePers?.items
      }
    case UserTypes.LOGOUT_SUCCESS:
      return initData
    default:
      return state
  }
}

export default softwarePer
