import SubscriptionTypes from '../actionTypes/subscription'
import { subscriptionAPI } from '../api'
import { bindRequestLogic } from './utils'

const getSubscriptions = bindRequestLogic({
  clientFunc: subscriptionAPI.getSubscriptions,
  type: SubscriptionTypes.GET_SUBSCRIPTIONS_REQUEST,
  successType: SubscriptionTypes.GET_SUBSCRIPTIONS_SUCCESS,
  failType: SubscriptionTypes.GET_SUBSCRIPTIONS_FAILED,
  isQueryParam: true
})

const addSubscription = bindRequestLogic({
  clientFunc: subscriptionAPI.addSubscription,
  type: SubscriptionTypes.ADD_SUBSCRIPTION_REQUEST,
  successType: SubscriptionTypes.ADD_SUBSCRIPTION_SUCCESS,
  failType: SubscriptionTypes.ADD_SUBSCRIPTION_FAILED
})

const updateSubscription = bindRequestLogic({
  clientFunc: subscriptionAPI.updateSubscription,
  type: SubscriptionTypes.UPDATE_SUBSCRIPTION_REQUEST,
  successType: SubscriptionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
  failType: SubscriptionTypes.UPDATE_SUBSCRIPTION_FAILED
})

const getSubscriptionDetail = bindRequestLogic({
  clientFunc: subscriptionAPI.getSubscriptionDetail,
  type: SubscriptionTypes.GET_SUBSCRIPTION_DETAIL_REQUEST,
  successType: SubscriptionTypes.GET_SUBSCRIPTION_DETAIL_SUCCESS,
  failType: SubscriptionTypes.GET_SUBSCRIPTION_DETAIL_FAILED
})

const deleteSubscription = bindRequestLogic({
  clientFunc: subscriptionAPI.deleteSubscription,
  type: SubscriptionTypes.DELETE_SUBSCRIPTION_REQUEST,
  successType: SubscriptionTypes.DELETE_SUBSCRIPTION_SUCCESS,
  failType: SubscriptionTypes.DELETE_SUBSCRIPTION_FAILED
})

const getVehicles = bindRequestLogic({
  clientFunc: subscriptionAPI.getVehicles,
  type: SubscriptionTypes.GET_SUBSCRIPTIONS_VEHICLE_REQUEST,
  successType: SubscriptionTypes.GET_SUBSCRIPTIONS_VEHICLE_SUCCESS,
  failType: SubscriptionTypes.GET_SUBSCRIPTIONS_VEHICLE_FAILED,
  isQueryParam: true
})

const addVehicle = bindRequestLogic({
  clientFunc: subscriptionAPI.addVehicle,
  type: SubscriptionTypes.ADD_SUBSCRIPTION_VEHICLE_REQUEST,
  successType: SubscriptionTypes.ADD_SUBSCRIPTION_VEHICLE_SUCCESS,
  failType: SubscriptionTypes.ADD_SUBSCRIPTION_VEHICLE_FAILED
})

const updateVehicle = bindRequestLogic({
  clientFunc: subscriptionAPI.updateVehicle,
  type: SubscriptionTypes.UPDATE_SUBSCRIPTION_VEHICLE_REQUEST,
  successType: SubscriptionTypes.UPDATE_SUBSCRIPTION_VEHICLE_SUCCESS,
  failType: SubscriptionTypes.UPDATE_SUBSCRIPTION_VEHICLE_FAILED
})

const deleteVehicle = bindRequestLogic({
  clientFunc: subscriptionAPI.deleteVehicle,
  type: SubscriptionTypes.DELETE_SUBSCRIPTION_VEHICLE_REQUEST,
  successType: SubscriptionTypes.DELETE_SUBSCRIPTION_VEHICLE_SUCCESS,
  failType: SubscriptionTypes.DELETE_SUBSCRIPTION_VEHICLE_FAILED
})

// ================ ALLOWED PILOT =============
const mapResponse = (res) => {
  const items = res?.subscriptionAllowPilots?.items

  if (!items) return []

  return items.map((item) => ({
    id: item.id,
    status: item.status,
    profile: item.subscriptionProfilePilot,
    userId: item.userId,
    email: item.emailMember,
    phone: item.user?.phone || '',
    name: item.user?.name || '',
    avatar: item.user?.avatar || ''
  }))
}

const getAllowedPilots = bindRequestLogic({
  clientFunc: subscriptionAPI.getAllowedPilots,
  type: SubscriptionTypes.GET_ALLOWED_PILOTS_REQUEST,
  successType: SubscriptionTypes.GET_ALLOWED_PILOTS_SUCCESS,
  failType: SubscriptionTypes.GET_ALLOWED_PILOTS_FAILED,
  isQueryParam: true,
  mapResponse
})

const addAllowedPilot = bindRequestLogic({
  clientFunc: subscriptionAPI.addAllowedPilot,
  type: SubscriptionTypes.ADD_ALLOWED_PILOT_REQUEST,
  successType: SubscriptionTypes.ADD_ALLOWED_PILOT_SUCCESS,
  failType: SubscriptionTypes.ADD_ALLOWED_PILOT_FAILED
})

const updateAllowedPilot = bindRequestLogic({
  clientFunc: subscriptionAPI.updateAllowedPilot,
  type: SubscriptionTypes.UPDATE_ALLOWED_PILOT_REQUEST,
  successType: SubscriptionTypes.UPDATE_ALLOWED_PILOT_SUCCESS,
  failType: SubscriptionTypes.UPDATE_ALLOWED_PILOT_FAILED
})

const deleteAllowedPilot = bindRequestLogic({
  clientFunc: subscriptionAPI.deleteAllowedPilot,
  type: SubscriptionTypes.DELETE_ALLOWED_PILOT_REQUEST,
  successType: SubscriptionTypes.DELETE_ALLOWED_PILOT_SUCCESS,
  failType: SubscriptionTypes.DELETE_ALLOWED_PILOT_FAILED
})

export default [
  getSubscriptions,
  addSubscription,
  getSubscriptionDetail,
  deleteSubscription,
  updateSubscription,
  getVehicles,
  addVehicle,
  updateVehicle,
  deleteVehicle,
  getAllowedPilots,
  addAllowedPilot,
  updateAllowedPilot,
  deleteAllowedPilot
]
