import { combineReducers } from 'redux'
import SoftwarePerTypes from 'store/actionTypes/softwarePer'
import { createHandleRequest } from './helpers'

const getSoftwarePers = createHandleRequest(
  SoftwarePerTypes.GET_SOFTWARE_PERS_REQUEST,
  SoftwarePerTypes.GET_SOFTWARE_PERS_SUCCESS,
  SoftwarePerTypes.GET_SOFTWARE_PERS_FAILED
)

export default combineReducers({
  getSoftwarePers
})
