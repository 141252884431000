import keyMirror from 'keymirror'

export default keyMirror({
  GET_NOTIFICATION_TEMPLATE_TECHS_REQUEST: null,
  GET_NOTIFICATION_TEMPLATE_TECHS_SUCCESS: null,
  GET_NOTIFICATION_TEMPLATE_TECHS_FAILED: null,

  GET_NOTIFICATION_TEMPLATE_TECH_BY_NOTIFICATION_TEMPLATE_ID_REQUEST: null,
  GET_NOTIFICATION_TEMPLATE_TECH_BY_NOTIFICATION_TEMPLATE_ID_SUCCESS: null,
  GET_NOTIFICATION_TEMPLATE_TECH_BY_NOTIFICATION_TEMPLATE_ID_FAILED: null,

  ADD_NOTIFICATION_TEMPLATE_TECH_REQUEST: null,
  ADD_NOTIFICATION_TEMPLATE_TECH_SUCCESS: null,
  ADD_NOTIFICATION_TEMPLATE_TECH_FAILED: null,

  UPDATE_NOTIFICATION_TEMPLATE_TECH_REQUEST: null,
  UPDATE_NOTIFICATION_TEMPLATE_TECH_SUCCESS: null,
  UPDATE_NOTIFICATION_TEMPLATE_TECH_FAILED: null,

  DELETE_NOTIFICATION_TEMPLATE_TECH_REQUEST: null,
  DELETE_NOTIFICATION_TEMPLATE_TECH_SUCCESS: null,
  DELETE_NOTIFICATION_TEMPLATE_TECH_FAILED: null
})
