import RoleTypes from '../actionTypes/role'
import { role as clientApi } from '../api'
import { bindRequestLogic } from './utils'
import { mapPermissions, mapRoleDetail } from '../mapping'

const getRoles = bindRequestLogic({
  clientFunc: clientApi.getRoles,
  type: RoleTypes.GET_ROLES_REQUEST,
  successType: RoleTypes.GET_ROLES_SUCCESS,
  failType: RoleTypes.GET_ROLES_FAILED,
  isQueryParam: true
})

const addRole = bindRequestLogic({
  clientFunc: clientApi.addRole,
  type: RoleTypes.ADD_ROLE_REQUEST,
  successType: RoleTypes.ADD_ROLE_SUCCESS,
  failType: RoleTypes.ADD_ROLE_FAILED
})

const getRoleDetail = bindRequestLogic({
  clientFunc: clientApi.getRoleDetail,
  type: RoleTypes.GET_ROLE_DETAIL_REQUEST,
  successType: RoleTypes.GET_ROLE_DETAIL_SUCCESS,
  failType: RoleTypes.GET_ROLE_DETAIL_FAILED,
  modelName: 'role',
  mapResponse: mapRoleDetail
})

const deleteRole = bindRequestLogic({
  clientFunc: clientApi.deleteRole,
  type: RoleTypes.DELETE_ROLE_REQUEST,
  successType: RoleTypes.DELETE_ROLE_SUCCESS,
  failType: RoleTypes.DELETE_ROLE_FAILED
})

const updateRole = bindRequestLogic({
  clientFunc: clientApi.updateRole,
  type: RoleTypes.UPDATE_ROLE_REQUEST,
  successType: RoleTypes.UPDATE_ROLE_SUCCESS,
  failType: RoleTypes.UPDATE_ROLE_FAILED
})

const getGroups = bindRequestLogic({
  clientFunc: clientApi.getGroups,
  type: RoleTypes.GET_GROUPS_REQUEST,
  successType: RoleTypes.GET_GROUPS_SUCCESS,
  failType: RoleTypes.GET_GROUPS_FAILED,
  isQueryParam: true
})

const getPermissions = bindRequestLogic({
  clientFunc: clientApi.getPermissions,
  type: RoleTypes.GET_PERMISSIONS_REQUEST,
  successType: RoleTypes.GET_PERMISSIONS_SUCCESS,
  failType: RoleTypes.GET_PERMISSIONS_FAILED,
  isQueryParam: true,
  mapResponse: mapPermissions
})

const deleteMultipleRole = bindRequestLogic({
  clientFunc: clientApi.deleteMultipleRole,
  type: RoleTypes.DELETE_MULTIPLE_ROLE_REQUEST,
  successType: RoleTypes.DELETE_MULTIPLE_ROLE_SUCCESS,
  failType: RoleTypes.DELETE_MULTIPLE_ROLE_FAILED
})

const addRoleTranslation = bindRequestLogic({
  clientFunc: clientApi.addRoleTranslation,
  type: RoleTypes.ADD_ROLE_TRANSLATION_REQUEST,
  successType: RoleTypes.ADD_ROLE_TRANSLATION_SUCCESS,
  failType: RoleTypes.ADD_ROLE_TRANSLATION_FAILED
})

const updateRoleTranslation = bindRequestLogic({
  clientFunc: clientApi.updateRoleTranslation,
  type: RoleTypes.UPDATE_ROLE_TRANSLATION_REQUEST,
  successType: RoleTypes.UPDATE_ROLE_TRANSLATION_SUCCESS,
  failType: RoleTypes.UPDATE_ROLE_TRANSLATION_FAILED
})

const deleteRoleTranslation = bindRequestLogic({
  clientFunc: clientApi.deleteRoleTranslation,
  type: RoleTypes.DELETE_ROLE_TRANSLATION_REQUEST,
  successType: RoleTypes.DELETE_ROLE_TRANSLATION_SUCCESS,
  failType: RoleTypes.DELETE_ROLE_TRANSLATION_FAILED
})

const getSystemRoles = bindRequestLogic({
  clientFunc: clientApi.getSystemRoles,
  type: RoleTypes.GET_SYSTEM_ROLES_REQUEST,
  successType: RoleTypes.GET_SYSTEM_ROLES_SUCCESS,
  failType: RoleTypes.GET_SYSTEM_ROLES_FAILED,
  isQueryParam: true
})

const getRolesByAdminPortal = bindRequestLogic({
  clientFunc: clientApi.getRoles,
  type: RoleTypes.GET_ROLES_BY_ADMIN_PORTAL_REQUEST,
  successType: RoleTypes.GET_ROLES_BY_ADMIN_PORTAL_SUCCESS,
  failType: RoleTypes.GET_ROLES_BY_ADMIN_PORTAL_FAILED,
  isQueryParam: true
})

export default [
  getRoles,
  addRole,
  getRoleDetail,
  updateRole,
  deleteRole,
  getGroups,
  getPermissions,
  deleteMultipleRole,
  addRoleTranslation,
  updateRoleTranslation,
  deleteRoleTranslation,
  getSystemRoles,
  getRolesByAdminPortal
]
