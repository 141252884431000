import { DEFAULT_MESSAGE_FILTER } from 'constants/common'
import FormattedTextTypes from 'store/actionTypes/formattedText'
import UserTypes from 'store/actionTypes/user'
import { clearModuleFilter, updateModuleFilter } from 'helpers/filter'

const initFormattedText = {
  list: [],
  current: {},
  pagination: {},
  filter: DEFAULT_MESSAGE_FILTER,
  scwFilter: { ...DEFAULT_MESSAGE_FILTER },
  mobileFilter: { ...DEFAULT_MESSAGE_FILTER },
  fleetFilter: { ...DEFAULT_MESSAGE_FILTER }
}

const formattedText = (state = initFormattedText, action) => {
  const data = action?.payload || []
  switch (action.type) {
    case FormattedTextTypes.GET_FORMATTED_TEXTS_SUCCESS:
      return {
        ...state,
        list: data?.items,
        pagination: data?.meta
      }
    case FormattedTextTypes.UPDATE_FILTER_ELECTRONIC_KEY_SUCCESS:
      return { ...state, filter: data }
    case FormattedTextTypes.CLEAR_FILTER_FORMATTED_TEXT_SUCCESS:
      return clearModuleFilter(state, action.payload, initFormattedText)
    case FormattedTextTypes.UPDATE_FILTER_FORMATTED_TEXT_SUCCESS:
      return updateModuleFilter(state, action.payload)
    case UserTypes.LOGOUT_SUCCESS:
      return initFormattedText
    default:
      return state
  }
}

export default formattedText
