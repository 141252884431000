import { DEFAULT_COUNTRY_FILTER } from 'constants/common'
import CountryTypes from 'store/actionTypes/country'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  systems: [],
  current: {},
  pagination: {},
  filter: DEFAULT_COUNTRY_FILTER
}

const country = (state = initialData, action) => {
  switch (action.type) {
    case CountryTypes.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case CountryTypes.GET_COUNTRY_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case CountryTypes.GET_SYSTEM_COUNTRIES_SUCCESS:
      return { ...state, systems: action.payload?.countries?.items }
    case CountryTypes.UPDATE_FILTER_COUNTRY_SUCCESS:
      return { ...state, filter: action.payload }
    case CountryTypes.CLEAR_FILTER_COUNTRY_SUCCESS:
      return { ...state, filter: initialData.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default country
