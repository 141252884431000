import SystemConfigTypes from 'store/actionTypes/systemConfig'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  systems: [],
  pagination: {},
  modules: [],
  adminPortals: [],
  vehicleTypes: [],
  currentModule: ''
}

const systemConfig = (state = initialData, action) => {
  switch (action.type) {
    case SystemConfigTypes.GET_SYSTEM_CONFIG_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case SystemConfigTypes.GET_MODULES_SUCCESS:
      return {
        ...state,
        modules: action.payload?.items
      }
    case SystemConfigTypes.UPDATE_CURRENT_MODULES_SUCCESS:
      return { ...state, currentModule: action.payload?.currentModule }
    case SystemConfigTypes.GET_ALL_SYSTEM_CONFIGS_SUCCESS:
      return { ...state, systems: action.payload?.systemConfig?.items }
    case SystemConfigTypes.GET_ADMIN_PORTALS_SUCCESS:
      return { ...state, adminPortals: action.payload }
    // case SystemConfigTypes.GET_SYSTEM_VEHICLE_TYPES_SUCCESS:
    //   return { ...state, vehicleTypes: action.payload }

    case UserTypes.LOGOUT_SUCCESS:
      return { ...initialData, systems: state.systems }
    default:
      return state
  }
}

export default systemConfig
