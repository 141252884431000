import keyMirror from 'keymirror'

export default keyMirror({
  GET_MODEL_PERS_REQUEST: null,
  GET_MODEL_PERS_SUCCESS: null,
  GET_MODEL_PERS_FAILED: null,

  ADD_MODEL_PER_REQUEST: null,
  ADD_MODEL_PER_SUCCESS: null,
  ADD_MODEL_PER_FAILED: null,

  GET_MODEL_PER_DETAIL_REQUEST: null,
  GET_MODEL_PER_DETAIL_SUCCESS: null,
  GET_MODEL_PER_DETAIL_FAILED: null,

  UPDATE_MODEL_PER_REQUEST: null,
  UPDATE_MODEL_PER_SUCCESS: null,
  UPDATE_MODEL_PER_FAILED: null,

  DELETE_MODEL_PER_REQUEST: null,
  DELETE_MODEL_PER_SUCCESS: null,
  DELETE_MODEL_PER_FAILED: null,

  UPDATE_FILTER_MODEL_PER_SUCCESS: null,
  CLEAR_FILTER_MODEL_PER_SUCCESS: null,

  GET_SYSTEM_MODEL_PERS_REQUEST: null,
  GET_SYSTEM_MODEL_PERS_SUCCESS: null,
  GET_SYSTEM_MODEL_PERS_FAILED: null
})
