import keyMirror from 'keymirror'

export default keyMirror({
  GET_ALERT_GROUPS_REQUEST: null,
  GET_ALERT_GROUPS_SUCCESS: null,
  GET_ALERT_GROUPS_FAILED: null,

  ADD_ALERT_GROUP_REQUEST: null,
  ADD_ALERT_GROUP_SUCCESS: null,
  ADD_ALERT_GROUP_FAILED: null,

  GET_ALERT_GROUP_DETAIL_REQUEST: null,
  GET_ALERT_GROUP_DETAIL_SUCCESS: null,
  GET_ALERT_GROUP_DETAIL_FAILED: null,

  UPDATE_ALERT_GROUP_REQUEST: null,
  UPDATE_ALERT_GROUP_SUCCESS: null,
  UPDATE_ALERT_GROUP_FAILED: null,

  DELETE_ALERT_GROUP_REQUEST: null,
  DELETE_ALERT_GROUP_SUCCESS: null,
  DELETE_ALERT_GROUP_FAILED: null,

  ADD_ALERT_GROUP_TRANSLATION_REQUEST: null,
  ADD_ALERT_GROUP_TRANSLATION_SUCCESS: null,
  ADD_ALERT_GROUP_TRANSLATION_FAILED: null,

  UPDATE_ALERT_GROUP_TRANSLATION_REQUEST: null,
  UPDATE_ALERT_GROUP_TRANSLATION_SUCCESS: null,
  UPDATE_ALERT_GROUP_TRANSLATION_FAILED: null,

  DELETE_ALERT_GROUP_TRANSLATION_REQUEST: null,
  DELETE_ALERT_GROUP_TRANSLATION_SUCCESS: null,
  DELETE_ALERT_GROUP_TRANSLATION_FAILED: null,

  GET_SYSTEM_ALERT_GROUPS_REQUEST: null,
  GET_SYSTEM_ALERT_GROUPS_SUCCESS: null,
  GET_SYSTEM_ALERT_GROUPS_FAILED: null
})
