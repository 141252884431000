import Types from '../actionTypes/companyRole'
import { companyRole as clientApi } from '../api'
import { bindRequestLogic } from './utils'
import { mapCompanyRoleDetail } from '../mapping/roles'
import { mapPermissionsByCompanyId } from '../mapping'

const getCompanyRoles = bindRequestLogic({
  clientFunc: clientApi.getCompanyRoles,
  type: Types.GET_COMPANY_ROLES_REQUEST,
  successType: Types.GET_COMPANY_ROLES_SUCCESS,
  failType: Types.GET_COMPANY_ROLES_FAILED,
  isQueryParam: true
})

const getCompanyRole = bindRequestLogic({
  clientFunc: clientApi.getCompanyRole,
  type: Types.GET_COMPANY_ROLE_REQUEST,
  successType: Types.GET_COMPANY_ROLE_SUCCESS,
  failType: Types.GET_COMPANY_ROLE_FAILED,
  mapResponse: mapCompanyRoleDetail
})

const addCompanyRole = bindRequestLogic({
  clientFunc: clientApi.addCompanyRole,
  type: Types.ADD_COMPANY_ROLE_REQUEST,
  successType: Types.ADD_COMPANY_ROLE_SUCCESS,
  failType: Types.ADD_COMPANY_ROLE_FAILED
})

const updateCompanyRole = bindRequestLogic({
  clientFunc: clientApi.updateCompanyRole,
  type: Types.UPDATE_COMPANY_ROLE_REQUEST,
  successType: Types.UPDATE_COMPANY_ROLE_SUCCESS,
  failType: Types.UPDATE_COMPANY_ROLE_FAILED
})

const deleteCompanyRole = bindRequestLogic({
  clientFunc: clientApi.deleteCompanyRole,
  type: Types.DELETE_COMPANY_ROLE_REQUEST,
  successType: Types.DELETE_COMPANY_ROLE_SUCCESS,
  failType: Types.DELETE_COMPANY_ROLE_FAILED
})

const upsertCompanyRoleTranslation = bindRequestLogic({
  clientFunc: clientApi.upsertCompanyRoleTranslation,
  type: Types.UPSERT_COMPANY_ROLE_TRANSLATION_REQUEST,
  successType: Types.UPSERT_COMPANY_ROLE_TRANSLATION_SUCCESS,
  failType: Types.UPSERT_COMPANY_ROLE_TRANSLATION_FAILED
})

const deleteCompanyRoleTranslation = bindRequestLogic({
  clientFunc: clientApi.deleteCompanyRoleTranslation,
  type: Types.DELETE_COMPANY_ROLE_TRANSLATION_REQUEST,
  successType: Types.DELETE_COMPANY_ROLE_TRANSLATION_SUCCESS,
  failType: Types.DELETE_COMPANY_ROLE_TRANSLATION_FAILED
})

const getSystemCompanyRoles = bindRequestLogic({
  clientFunc: clientApi.getSystemCompanyRoles,
  type: Types.GET_SYSTEM_COMPANY_ROLES_REQUEST,
  successType: Types.GET_SYSTEM_COMPANY_ROLES_SUCCESS,
  failType: Types.GET_SYSTEM_COMPANY_ROLES_FAILED,
  isQueryParam: true
})

const getCompanyRoleConfiguration = bindRequestLogic({
  clientFunc: clientApi.getCompanyRoleConfiguration,
  type: Types.GET_COMPANY_ROLE_CONFIGURATION_REQUEST,
  successType: Types.GET_COMPANY_ROLE_CONFIGURATION_SUCCESS,
  failType: Types.GET_COMPANY_ROLE_CONFIGURATION_FAILED,
  isQueryParam: true
})

const updateCompanyRoleConfiguration = bindRequestLogic({
  clientFunc: clientApi.updateCompanyRoleConfiguration,
  type: Types.UPDATE_COMPANY_ROLE_CONFIGURATION_REQUEST,
  successType: Types.UPDATE_COMPANY_ROLE_CONFIGURATION_SUCCESS,
  failType: Types.UPDATE_COMPANY_ROLE_CONFIGURATION_FAILED
})

const getCompanyAllowToManage = bindRequestLogic({
  clientFunc: clientApi.getCompanyAllowToManage,
  type: Types.GET_COMPANY_ALLOW_TO_MANAGE_REQUEST,
  successType: Types.GET_COMPANY_ALLOW_TO_MANAGE_SUCCESS,
  failType: Types.GET_COMPANY_ALLOW_TO_MANAGE_FAILED,
  isQueryParam: true
})

const updateCompanyAllowToManage = bindRequestLogic({
  clientFunc: clientApi.updateCompanyAllowToManage,
  type: Types.UPDATE_COMPANY_ALLOW_TO_MANAGE_REQUEST,
  successType: Types.UPDATE_COMPANY_ALLOW_TO_MANAGE_SUCCESS,
  failType: Types.UPDATE_COMPANY_ALLOW_TO_MANAGE_FAILED
})

const getPermissionsByCompanyId = bindRequestLogic({
  clientFunc: clientApi.getPermissionsByCompanyId,
  type: Types.GET_PERMISSIONS_BY_COMPANY_ID_REQUEST,
  successType: Types.GET_PERMISSIONS_BY_COMPANY_ID_SUCCESS,
  failType: Types.GET_PERMISSIONS_BY_COMPANY_ID_FAILED,
  modelName: 'companyCategoryPermissions',
  mapResponse: mapPermissionsByCompanyId
})

export default [
  getCompanyRoles,
  getCompanyRole,
  addCompanyRole,
  updateCompanyRole,
  deleteCompanyRole,
  upsertCompanyRoleTranslation,
  deleteCompanyRoleTranslation,
  getSystemCompanyRoles,
  getCompanyRoleConfiguration,
  updateCompanyRoleConfiguration,
  getCompanyAllowToManage,
  updateCompanyAllowToManage,
  getPermissionsByCompanyId
]
