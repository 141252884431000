import BaseApi from './base'

class NotificationTemplate extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = this.notificationURL
  }

  getNotificationTemplates = (query) =>
    this.client.get(`admin/notification-template?${query}`)

  getNotificationTemplateDetail = (id) =>
    this.client.get(`admin/notification-template/${id}`)

  addNotificationTemplate = (data) =>
    this.client.post(`admin/notification-template`, data)

  updateNotificationTemplate = (id, data) =>
    this.client.patch(`admin/notification-template/${id}`, data)

  deleteNotificationTemplate = (id) =>
    this.client.delete(`admin/notification-template/${id}`)

  addUserNotificationTemplate = (data) =>
    this.client.post(`admin/notification-template/users`, data)

  getSystemNotificationTemplates = (query) =>
    this.client.get(`notification-template?${query}`)

  getSystemNotificationTemplateById = (id) =>
    this.client.get(`notification-template/${id}`)
}

export default new NotificationTemplate()
