import { combineReducers } from 'redux'
import LabelTypes from 'store/actionTypes/label'
import { createHandleRequest } from './helpers'

const getLabels = createHandleRequest(
  LabelTypes.GET_LABELS_REQUEST,
  LabelTypes.GET_LABELS_SUCCESS,
  LabelTypes.GET_LABELS_FAILED
)

export default combineReducers({
  getLabels
})
