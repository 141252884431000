import BaseApi from './base'

class CompanyPerLink extends BaseApi {
  getCompanyPersLink = (id, query) =>
    this.client.get(`companies/${id}/company-pers-link?${query}`)

  addCompanyPerLink = (id, data) =>
    this.client.post(`companies/${id}/company-pers-link`, data)

  updateCompanyPerLink = (id, { perLinkId, ...data }) =>
    this.client.patch(`companies/${id}/company-pers-link/${perLinkId}`, data)

  deleteCompanyPerLink = (id, { perLinkId }) =>
    this.client.delete(`companies/${id}/company-pers-link/${perLinkId}`)
}

export default new CompanyPerLink()
