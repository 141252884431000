import Types from '../actionTypes/keyUsageSystemLog'
import { keyUsageSystemLog as api } from '../api'
import { bindRequestLogic } from './utils'
import { bindFilterLogic } from './filter'

const getKeyUsageSystemLogs = bindRequestLogic({
  clientFunc: api.getKeyUsageSystemLogs,
  type: Types.GET_KEY_USAGE_SYSTEM_LOGS_REQUEST,
  successType: Types.GET_KEY_USAGE_SYSTEM_LOGS_SUCCESS,
  failType: Types.GET_KEY_USAGE_SYSTEM_LOGS_FAILED,
  isQueryParam: true
})

const createKeyUsageSystemLog = bindRequestLogic({
  clientFunc: api.createKeyUsageSystemLog,
  type: Types.CREATE_KEY_USAGE_SYSTEM_LOG_REQUEST,
  successType: Types.CREATE_KEY_USAGE_SYSTEM_LOG_SUCCESS,
  failType: Types.CREATE_KEY_USAGE_SYSTEM_LOG_FAILED
})

const deleteKeyUsageSystemLog = bindRequestLogic({
  clientFunc: api.deleteKeyUsageSystemLog,
  type: Types.DELETE_KEY_USAGE_SYSTEM_LOG_REQUEST,
  successType: Types.DELETE_KEY_USAGE_SYSTEM_LOG_SUCCESS,
  failType: Types.DELETE_KEY_USAGE_SYSTEM_LOG_FAILED
})

const updateKeyUsageSystemLogs = bindRequestLogic({
  clientFunc: api.updateKeyUsageSystemLogs,
  type: Types.UPDATE_KEY_USAGE_SYSTEM_LOGS_REQUEST,
  successType: Types.UPDATE_KEY_USAGE_SYSTEM_LOGS_SUCCESS,
  failType: Types.UPDATE_KEY_USAGE_SYSTEM_LOGS_FAILED
})

const updateFilter = bindFilterLogic({
  type: Types.UPDATE_FILTER_KEY_USAGE_SYSTEM_LOG_REQUEST,
  successType: Types.UPDATE_FILTER_KEY_USAGE_SYSTEM_LOG_SUCCESS
})

const clearFilter = bindFilterLogic({
  type: Types.CLEAR_FILTER_KEY_USAGE_SYSTEM_LOG_REQUEST,
  successType: Types.CLEAR_FILTER_KEY_USAGE_SYSTEM_LOG_SUCCESS
})

export default [
  getKeyUsageSystemLogs,
  createKeyUsageSystemLog,
  deleteKeyUsageSystemLog,
  updateKeyUsageSystemLogs,
  updateFilter,
  clearFilter
]
