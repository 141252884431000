/* eslint-disable import/prefer-default-export */
const getTranslationSection = (res) => {
  const component = res?.component || []

  return component.reduce((acc, curr) => {
    let value = curr.originalValue
    const translation = curr.translations?.[0]

    if (translation) {
      value = translation?.translationValue || curr.originalValue
    }

    return {
      ...acc,
      [curr.keyword]: { key: curr.keyword, value, id: curr.id }
    }
  }, {})
}

export const getTranslationSections = (sections) =>
  sections.reduce((acc, section) => {
    return { ...acc, [section?.keyword]: getTranslationSection(section) || {} }
  }, {})

export const getTranslationLabels = (res = []) =>
  res.reduce((acc, curr) => {
    let { helpText, originalName } = curr
    const translation = curr.translations?.[0]

    if (translation) {
      originalName = translation?.originalName || originalName
      helpText = translation?.helpText || helpText
    }

    return {
      ...acc,
      [curr.keyword]: { helpText, originalName, id: curr.id }
    }
  }, {})

export const getTranslationMessages = (res = []) =>
  res.reduce((acc, curr) => {
    let { originalMessage } = curr
    const translation = curr.translations?.[0]

    if (translation) {
      originalMessage = translation?.translationMessage || originalMessage
    }

    return {
      ...acc,
      [curr.keyword]: originalMessage
    }
  }, {})
