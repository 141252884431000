import { combineReducers } from 'redux'
import ShowcasePrivacyPolicyTypes from 'store/actionTypes/showcasePrivacyPolicy'
import { createHandleRequest } from './helpers'

const getShowcasePrivacyPolicy = createHandleRequest(
  ShowcasePrivacyPolicyTypes.GET_SHOWCASE_PRIVACY_POLICY_REQUEST,
  ShowcasePrivacyPolicyTypes.GET_SHOWCASE_PRIVACY_POLICY_SUCCESS,
  ShowcasePrivacyPolicyTypes.GET_SHOWCASE_PRIVACY_POLICY_FAILED
)

export default combineReducers({
  getShowcasePrivacyPolicy
})
