import keyMirror from 'keymirror'

export default keyMirror({
  GET_SHOWCASE_SECTIONS_REQUEST: null,
  GET_SHOWCASE_SECTIONS_SUCCESS: null,
  GET_SHOWCASE_SECTIONS_FAILED: null,

  GET_SHOWCASE_SECTION_DETAIL_REQUEST: null,
  GET_SHOWCASE_SECTION_DETAIL_SUCCESS: null,
  GET_SHOWCASE_SECTION_DETAIL_FAILED: null,

  DELETE_SHOWCASE_SECTION_REQUEST: null,
  DELETE_SHOWCASE_SECTION_SUCCESS: null,
  DELETE_SHOWCASE_SECTION_FAILED: null,

  ADD_SHOWCASE_SECTION_REQUEST: null,
  ADD_SHOWCASE_SECTION_SUCCESS: null,
  ADD_SHOWCASE_SECTION_FAILED: null,

  UPDATE_SHOWCASE_SECTION_REQUEST: null,
  UPDATE_SHOWCASE_SECTION_SUCCESS: null,
  UPDATE_SHOWCASE_SECTION_FAILED: null,

  UPDATE_FILTER_SHOWCASE_SECTION_SUCCESS: null,
  CLEAR_FILTER_SHOWCASE_SECTION_SUCCESS: null,

  GET_SYSTEM_SECTIONS_REQUEST: null,
  GET_SYSTEM_SECTIONS_SUCCESS: null,
  GET_SYSTEM_SECTIONS_FAILED: null
})
