import DataMappingTypes from '../actionTypes/dataMapping'
import { dataMapping } from '../api'
import { bindRequestLogic } from './utils'

const getDataMappings = bindRequestLogic({
  clientFunc: dataMapping.getDataMappings,
  type: DataMappingTypes.GET_DATA_MAPPINGS_REQUEST,
  successType: DataMappingTypes.GET_DATA_MAPPINGS_SUCCESS,
  failType: DataMappingTypes.GET_DATA_MAPPINGS_FAILED,
  isQueryParam: true
})

const addDataMapping = bindRequestLogic({
  clientFunc: dataMapping.addDataMapping,
  type: DataMappingTypes.ADD_DATA_MAPPING_REQUEST,
  successType: DataMappingTypes.ADD_DATA_MAPPING_SUCCESS,
  failType: DataMappingTypes.ADD_DATA_MAPPING_FAILED
})

const updateDataMapping = bindRequestLogic({
  clientFunc: dataMapping.updateDataMapping,
  type: DataMappingTypes.UPDATE_DATA_MAPPING_REQUEST,
  successType: DataMappingTypes.UPDATE_DATA_MAPPING_SUCCESS,
  failType: DataMappingTypes.UPDATE_DATA_MAPPING_FAILED
})

const deleteDataMapping = bindRequestLogic({
  clientFunc: dataMapping.deleteDataMapping,
  type: DataMappingTypes.DELETE_DATA_MAPPING_REQUEST,
  successType: DataMappingTypes.DELETE_DATA_MAPPING_SUCCESS,
  failType: DataMappingTypes.DELETE_DATA_MAPPING_FAILED
})

export default [
  getDataMappings,
  addDataMapping,
  updateDataMapping,
  deleteDataMapping
]
