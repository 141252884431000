import BaseApi from './base'

class ConditionalFeature extends BaseApi {
  getConditionalFeatures = (query) =>
    this.client.get(`admin/conditionalFeature?${query}`)

  addConditionalFeature = (data) =>
    this.client.post('admin/conditionalFeature', data)

  updateConditionalFeature = (id, data) =>
    this.client.patch(`admin/conditionalFeature/${id}`, data)

  deleteConditionalFeature = (id) =>
    this.client.delete(`admin/conditionalFeature/${id}`)
}

export default new ConditionalFeature()
