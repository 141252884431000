import BaseApi from './base'

class CompanyMailTemplate extends BaseApi {
  upsetCompanyMailTemplate = (companyId, mailTemplateId, data) =>
    this.client.put(
      `company/${companyId}/mail-template/${mailTemplateId}`,
      data
    )

  getCompanyMailTemplateDetail = (query) =>
    this.client.get(
      `company/${query?.companyId}/mail-template/${query.mailTemplateId}/language/${query.languageId}`
    )

  deleteCompanyMailTemplate = (companyId, mailTemplateId) =>
    this.client.delete(`company/${companyId}/mail-template/${mailTemplateId}`)
}

export default new CompanyMailTemplate()
