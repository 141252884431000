import BaseApi from './base'

class LabelImport extends BaseApi {
  constructor() {
    super()
    this.client.defaults.headers.Accept = 'multipart/form-data'
  }

  importLabels = (id, data) => {
    this.client.post(`admin/label-interface/upload/${id}`, data)
  }
}

export default new LabelImport()
