import CIXIReferentType from '../actionTypes/cixiReferent'
import { cixiReferent as api } from '../api'
import { bindRequestLogic } from './utils'

const getCixiReferents = bindRequestLogic({
  clientFunc: api.getCixiReferents,
  type: CIXIReferentType.GET_CIXI_REFERENTS_REQUEST,
  successType: CIXIReferentType.GET_CIXI_REFERENTS_SUCCESS,
  failType: CIXIReferentType.GET_CIXI_REFERENTS_FAILED,
  isQueryParam: true
})

const addCixiReferent = bindRequestLogic({
  clientFunc: api.addCixiReferent,
  type: CIXIReferentType.ADD_CIXI_REFERENT_REQUEST,
  successType: CIXIReferentType.ADD_CIXI_REFERENT_SUCCESS,
  failType: CIXIReferentType.ADD_CIXI_REFERENT_FAILED
})

const getCixiReferentDetail = bindRequestLogic({
  clientFunc: api.getCixiReferentDetail,
  type: CIXIReferentType.GET_CIXI_REFERENT_DETAIL_REQUEST,
  successType: CIXIReferentType.GET_CIXI_REFERENT_DETAIL_SUCCESS,
  failType: CIXIReferentType.GET_CIXI_REFERENT_DETAIL_FAILED,
  modelName: 'companyCIXIReferent'
})

const deleteCixiReferent = bindRequestLogic({
  clientFunc: api.deleteCixiReferent,
  type: CIXIReferentType.DELETE_CIXI_REFERENT_REQUEST,
  successType: CIXIReferentType.DELETE_CIXI_REFERENT_SUCCESS,
  failType: CIXIReferentType.DELETE_CIXI_REFERENT_FAILED
})

const updateCixiReferent = bindRequestLogic({
  clientFunc: api.updateCixiReferent,
  type: CIXIReferentType.UPDATE_CIXI_REFERENT_REQUEST,
  successType: CIXIReferentType.UPDATE_CIXI_REFERENT_SUCCESS,
  failType: CIXIReferentType.UPDATE_CIXI_REFERENT_FAILED
})

const getSystemCixiReferents = bindRequestLogic({
  clientFunc: api.getSystemCixiReferents,
  type: CIXIReferentType.GET_SYSTEM_COMPANY_CIXI_REFERENTS_REQUEST,
  successType: CIXIReferentType.GET_SYSTEM_COMPANY_CIXI_REFERENTS_SUCCESS,
  failType: CIXIReferentType.GET_SYSTEM_COMPANY_CIXI_REFERENTS_FAILED,
  isQueryParam: true
})

export default [
  getCixiReferents,
  addCixiReferent,
  getCixiReferentDetail,
  deleteCixiReferent,
  updateCixiReferent,
  getSystemCixiReferents
]
