import VehicleBrandTypes from 'store/actionTypes/vehicleBrand'
import CompanyVehicleBrandLinkTypes from 'store/actionTypes/companyVehicleBrandLink'

import UserTypes from 'store/actionTypes/user'

const initVehicleBrand = {
  list: [],
  current: {},
  messages: {
    list: [],
    pagination: {}
  },
  pagination: {},
  systems: [],
  companyVehicleBrandLink: {
    list: [],
    pagination: {}
  }
}

const vehicleBrand = (state = initVehicleBrand, action) => {
  switch (action.type) {
    case VehicleBrandTypes.GET_VEHICLE_BRANDS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case VehicleBrandTypes.GET_VEHICLE_BRAND_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case VehicleBrandTypes.UPDATE_FILTER_VEHICLE_BRAND_SUCCESS:
      return { ...state, filter: action.payload }
    case VehicleBrandTypes.CLEAR_FILTER_VEHICLE_BRAND_SUCCESS:
      return { ...state, filter: initVehicleBrand.filter }
    case VehicleBrandTypes.GET_VEHICLE_BRAND_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: {
          list: action.payload?.items,
          pagination: action.payload?.meta
        }
      }
    case VehicleBrandTypes.GET_SYSTEM_VEHICLE_BRANDS_SUCCESS:
      return { ...state, systems: action.payload?.items }

    case CompanyVehicleBrandLinkTypes.GET_COMPANY_VEHICLE_BRAND_LINKS_SUCCESS:
      return {
        ...state,
        companyVehicleBrandLink: {
          list: action.payload?.items,
          pagination: action.payload?.meta
        }
      }
    case UserTypes.LOGOUT_SUCCESS:
      return initVehicleBrand
    default:
      return state
  }
}

export default vehicleBrand
