import BaseApi from './base'

class RolesConfiguration extends BaseApi {
  getRolesConfiguration = () => this.client.get(`admin/roles-configuration`)

  updateRolesConfiguration = (data) =>
    this.client.post(`admin/roles-configuration`, data)
}

export default new RolesConfiguration()
