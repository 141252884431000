import { combineReducers } from 'redux'
import VehicleBrandTypes from 'store/actionTypes/vehicleBrand'
import { createHandleRequest } from './helpers'

const getVehicleBrands = createHandleRequest(
  VehicleBrandTypes.GET_VEHICLE_BRANDS_REQUEST,
  VehicleBrandTypes.GET_VEHICLE_BRANDS_SUCCESS,
  VehicleBrandTypes.GET_VEHICLE_BRANDS_FAILED
)

export default combineReducers({
  getVehicleBrands
})
