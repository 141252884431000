import keyMirror from 'keymirror'

export default keyMirror({
  GET_NOTIFICATION_TEMPLATE_CONTENT_BY_NOTIFICATION_TEMPLATE_ID_REQUEST: null,
  GET_NOTIFICATION_TEMPLATE_CONTENT_BY_NOTIFICATION_TEMPLATE_ID_SUCCESS: null,
  GET_NOTIFICATION_TEMPLATE_CONTENT_BY_NOTIFICATION_TEMPLATE_ID_FAILED: null,

  ADD_NOTIFICATION_TEMPLATE_CONTENT_REQUEST: null,
  ADD_NOTIFICATION_TEMPLATE_CONTENT_SUCCESS: null,
  ADD_NOTIFICATION_TEMPLATE_CONTENT_FAILED: null,

  UPDATE_NOTIFICATION_TEMPLATE_CONTENT_REQUEST: null,
  UPDATE_NOTIFICATION_TEMPLATE_CONTENT_SUCCESS: null,
  UPDATE_NOTIFICATION_TEMPLATE_CONTENT_FAILED: null,

  DELETE_NOTIFICATION_TEMPLATE_CONTENT_REQUEST: null,
  DELETE_NOTIFICATION_TEMPLATE_CONTENT_SUCCESS: null,
  DELETE_NOTIFICATION_TEMPLATE_CONTENT_FAILED: null,

  GET_SYSTEM_NOTIFICATION_TEMPLATE_CONTENT_BY_ID_REQUEST: null,
  GET_SYSTEM_NOTIFICATION_TEMPLATE_CONTENT_BY_ID_SUCCESS: null,
  GET_SYSTEM_NOTIFICATION_TEMPLATE_CONTENT_BY_ID_FAILED: null
})
