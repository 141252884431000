import { DEFAULT_MAIL_TEMPLATE_FILTER } from 'constants/common'
import MailTemplateTypes from 'store/actionTypes/mailTemplate'
import UserTypes from 'store/actionTypes/user'
import { clearModuleFilter, updateModuleFilter } from 'helpers/filter'

const initMailTemplate = {
  list: [],
  current: {},
  pagination: {},
  filter: { ...DEFAULT_MAIL_TEMPLATE_FILTER },
  scwFilter: { ...DEFAULT_MAIL_TEMPLATE_FILTER },
  mobileFilter: { ...DEFAULT_MAIL_TEMPLATE_FILTER },
  fleetFilter: { ...DEFAULT_MAIL_TEMPLATE_FILTER },
  tokens: []
}

const mailTemplate = (state = initMailTemplate, action) => {
  const data = action?.payload || []
  switch (action.type) {
    case MailTemplateTypes.GET_MAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        list: data?.items,
        pagination: data?.meta
      }
    case MailTemplateTypes.GET_MAIL_TEMPLATE_DETAIL_SUCCESS:
      return { ...state, current: data }
    case MailTemplateTypes.GET_MAIL_TEMPLATE_TOKENS_SUCCESS:
      return { ...state, tokens: data }
    case MailTemplateTypes.UPDATE_FILTER_MAIL_TEMPLATE_SUCCESS:
      return updateModuleFilter(state, action.payload)
    case MailTemplateTypes.CLEAR_FILTER_MAIL_TEMPLATE_SUCCESS:
      return clearModuleFilter(state, action.payload, initMailTemplate)
    case UserTypes.LOGOUT_SUCCESS:
      return initMailTemplate
    default:
      return state
  }
}

export default mailTemplate
