import BaseApi from './base'

class PlatformPageTech extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.notificationURL}`
  }

  getPlatformPageTechs = (query) =>
    this.client.get(`admin/platform-page-tech?${query}`)

  getPlatformPageTech = (id) =>
    this.client.get(`admin/platform-page-tech/${id}`)

  addPlatformPageTech = (data) =>
    this.client.post('admin/platform-page-tech', data)

  updatePlatformPageTech = (id, data) =>
    this.client.patch(`admin/platform-page-tech/${id}`, data)

  deletePlatformPageTech = (id) =>
    this.client.delete(`admin/platform-page-tech/${id}`)

  getSystemPlatformPageTechs = (query) =>
    this.client.get(`platform-page-tech?${query}`)
}

export default new PlatformPageTech()
