import { createLogic } from 'redux-logic'
import SystemConfigTypes from '../actionTypes/systemConfig'
import { systemConfig as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const getSystemConfigList = bindRequestLogic({
  clientFunc: clientApi.getSystemConfigs,
  type: SystemConfigTypes.GET_SYSTEM_CONFIG_LIST_REQUEST,
  successType: SystemConfigTypes.GET_SYSTEM_CONFIG_LIST_SUCCESS,
  failType: SystemConfigTypes.GET_SYSTEM_CONFIG_LIST_FAILED,
  isQueryParam: true,
  modelName: 'systemConfig'
})

const updateSystemConfigs = bindRequestLogic({
  clientFunc: clientApi.updateSystemConfigs,
  type: SystemConfigTypes.UPDATE_SYSTEMS_CONFIG_REQUEST,
  successType: SystemConfigTypes.UPDATE_SYSTEMS_CONFIG_SUCCESS,
  failType: SystemConfigTypes.UPDATE_SYSTEMS_CONFIG_FAILED
})

const getModules = bindRequestLogic({
  clientFunc: clientApi.getModules,
  type: SystemConfigTypes.GET_MODULES_REQUEST,
  successType: SystemConfigTypes.GET_MODULES_SUCCESS,
  failType: SystemConfigTypes.GET_MODULES_FAILED,
  modelName: 'modules'
})

const getAllSystemConfigs = bindRequestLogic({
  clientFunc: clientApi.getAllSystemConfigs,
  type: SystemConfigTypes.GET_ALL_SYSTEM_CONFIGS_REQUEST,
  successType: SystemConfigTypes.GET_ALL_SYSTEM_CONFIGS_SUCCESS,
  failType: SystemConfigTypes.GET_ALL_SYSTEM_CONFIGS_FAILED
})

const getAdminPortals = bindRequestLogic({
  clientFunc: clientApi.getAdminPortals,
  type: SystemConfigTypes.GET_ADMIN_PORTALS_REQUEST,
  successType: SystemConfigTypes.GET_ADMIN_PORTALS_SUCCESS,
  failType: SystemConfigTypes.GET_ADMIN_PORTALS_FAILED,
  modelName: 'items'
})

const getModuleByName = createLogic({
  type: SystemConfigTypes.GET_MODULE_BY_NAME_REQUEST,
  latest: true,
  async process({ action }, dispatch, done) {
    const { onSuccess, onError } = action.meta
    try {
      const res = await clientApi.getModuleByName(action?.payload)
      dispatch({
        type: SystemConfigTypes.GET_MODULE_BY_NAME_SUCCESS,
        payload: res
      })
      onSuccess(res?.data?.module)
    } catch (error) {
      dispatch({
        type: SystemConfigTypes.GET_MODULE_BY_NAME_FAILED,
        payload: error.message,
        meta: {}
      })
      onError(error.message)
    }
    done()
  }
})

// const getVehicleTypes = bindRequestLogic({
//
//   clientFunc: clientApi.getVehicleTypes,
//   type: SystemConfigTypes.GET_SYSTEM_VEHICLE_TYPES_REQUEST,
//   successType: SystemConfigTypes.GET_SYSTEM_VEHICLE_TYPES_SUCCESS,
//   failType: SystemConfigTypes.GET_SYSTEM_VEHICLE_TYPES_FAILED,
//   modelName: 'items'
// })

export default [
  getSystemConfigList,
  updateSystemConfigs,
  getModules,
  getAllSystemConfigs,
  getAdminPortals,
  getModuleByName
  // getVehicleTypes
]
