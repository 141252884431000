import { DEFAULT_PAGE_LIMIT } from 'constants/common'
import PerTypes from 'store/actionTypes/per'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  systems: [],
  current: {},
  pagination: {},
  filter: DEFAULT_PAGE_LIMIT,
  alert: {
    list: [],
    pagination: {}
  },
  packageLog: {
    list: [],
    pagination: {}
  },
  vehicleTrip: {
    list: [],
    pagination: {},
    current: {},
    exportData: {}
  }
}

const per = (state = initialData, action) => {
  switch (action.type) {
    case PerTypes.GET_PERS_SUCCESS:
      return {
        ...state,
        list: action.payload?.pers?.items,
        pagination: action.payload?.pers?.meta
      }
    case PerTypes.GET_PER_DETAIL_SUCCESS:
      return { ...state, current: action.payload?.pers }
    case PerTypes.UPDATE_FILTER_PER_SUCCESS:
      return { ...state, filter: action.payload }
    case PerTypes.CLEAR_FILTER_PER_SUCCESS:
      return { ...state, filter: initialData.filter }
    case PerTypes.GET_SYSTEM_PERS_SUCCESS:
      return { ...state, systems: action.payload?.pers?.items }
    case PerTypes.GET_PER_ALERTS_SUCCESS:
      return {
        ...state,
        alert: {
          ...state.alert,
          list: action.payload?.items,
          pagination: action.payload?.meta
        }
      }

    case PerTypes.GET_PER_PACKAGE_LOGS_SUCCESS:
      return {
        ...state,
        packageLog: {
          ...state.packageLog,
          list: action.payload?.persPackageLog?.items,
          pagination: action.payload?.persPackageLog?.meta
        }
      }

    case PerTypes.GET_VEHICLE_TRIPS_SUCCESS:
      return {
        ...state,
        vehicleTrip: {
          ...state.vehicleTrip,
          list: action.payload?.items,
          pagination: action.payload?.meta
        }
      }

    case PerTypes.GET_VEHICLE_TRIP_DETAIL_SUCCESS:
      return {
        ...state,
        vehicleTrip: {
          ...state.vehicleTrip,
          current: action.payload
        }
      }

    case PerTypes.EXPORT_VEHICLE_TRIP_DETAIL_SUCCESS:
      return {
        ...state,
        vehicleTrip: {
          ...state.vehicleTrip,
          exportData: {
            data: action?.payload,
            responseHeaders: action.responseHeaders
          }
        }
      }

    case UserTypes.LOGOUT_SUCCESS:
      return initialData

    default:
      return state
  }
}

export default per
