import BaseApi from './base'

class MailTemplate extends BaseApi {
  getMailTemplates = (query) => this.client.get(`admin/mail-templates?${query}`)

  getMailTemplateDetail = (id) => this.client.get(`admin/mail-templates/${id}`)

  addMailTemplate = (data) => this.client.post('admin/mail-templates', data)

  updateMailTemplate = (id, data) =>
    this.client.patch(`admin/mail-templates/${id}`, data)

  deleteMailTemplate = (id) => this.client.delete(`admin/mail-templates/${id}`)

  upsertMailTemplateTranslation = (id, data) =>
    this.client.post(`admin/mail-templates/translation/bulk-upsert/${id}`, data)

  getMailTemplateTokens = (id) =>
    this.client.get(`admin/mail-templates/tokens/${id}`)

  getSystemMailTemplates = (query) =>
    this.client.get(`admin/system/mail-templates?${query}`)
}

export default new MailTemplate()
