import BaseApi from './base'

class Message extends BaseApi {
  getMessages = (query) => this.client.get(`admin/messages?${query}`)

  updateMessage = (id, data) => this.client.patch(`admin/messages/${id}`, data)

  createMessage = (data) => this.client.post('admin/messages', data)

  deleteMessage = (id) => this.client.delete(`admin/messages/${id}`)

  updateMessages = (languageId, data) =>
    this.client.post(
      `admin/messages/translation/bulk-upsert/${languageId}`,
      data
    )

  getSystemMessages = (query) =>
    this.client.get(`admin/system/messages?${query}`)
}

export default new Message()
