import VehicleTypes from 'store/actionTypes/vehicleType'
import { vehicleType as clientApi } from 'store/api'
import { bindRequestLogic } from './utils'

const getVehicleTypes = bindRequestLogic({
  clientFunc: clientApi.getVehicleTypes,
  type: VehicleTypes.GET_VEHICLE_TYPES_REQUEST,
  successType: VehicleTypes.GET_VEHICLE_TYPES_SUCCESS,
  failType: VehicleTypes.GET_VEHICLE_TYPES_FAILED,
  isQueryParam: true
})

const addVehicleType = bindRequestLogic({
  clientFunc: clientApi.addVehicleType,
  type: VehicleTypes.ADD_VEHICLE_TYPE_REQUEST,
  successType: VehicleTypes.ADD_VEHICLE_TYPE_SUCCESS,
  failType: VehicleTypes.ADD_VEHICLE_TYPE_FAILED
})

const getVehicleTypeDetail = bindRequestLogic({
  clientFunc: clientApi.getVehicleTypeDetail,
  type: VehicleTypes.GET_VEHICLE_TYPE_DETAIL_REQUEST,
  successType: VehicleTypes.GET_VEHICLE_TYPE_DETAIL_SUCCESS,
  failType: VehicleTypes.GET_VEHICLE_TYPE_DETAIL_FAILED,
  modelName: 'VehicleType'
})

const updateVehicleType = bindRequestLogic({
  clientFunc: clientApi.updateVehicleType,
  type: VehicleTypes.UPDATE_VEHICLE_TYPE_REQUEST,
  successType: VehicleTypes.UPDATE_VEHICLE_TYPE_SUCCESS,
  failType: VehicleTypes.UPDATE_VEHICLE_TYPE_FAILED
})

const deleteVehicleType = bindRequestLogic({
  clientFunc: clientApi.deleteVehicleType,
  type: VehicleTypes.DELETE_VEHICLE_TYPE_REQUEST,
  successType: VehicleTypes.DELETE_VEHICLE_TYPE_SUCCESS,
  failType: VehicleTypes.DELETE_VEHICLE_TYPE_FAILED
})

const getSystemVehicleTypes = bindRequestLogic({
  clientFunc: clientApi.getSystemVehicleTypes,
  type: VehicleTypes.GET_SYSTEM_VEHICLE_TYPES_REQUEST,
  successType: VehicleTypes.GET_SYSTEM_VEHICLE_TYPES_SUCCESS,
  failType: VehicleTypes.GET_SYSTEM_VEHICLE_TYPES_FAILED,
  isQueryParam: true
})

export default [
  getVehicleTypes,
  addVehicleType,
  getVehicleTypeDetail,
  deleteVehicleType,
  updateVehicleType,
  getSystemVehicleTypes
]
