import { DEFAULT_DATA_MAPPING_FILTER } from 'constants/common'
import DataMappingTypes from 'store/actionTypes/dataMapping'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  filter: DEFAULT_DATA_MAPPING_FILTER,
  pagination: {},
  current: {}
}

const DataMapping = (state = initialData, action) => {
  switch (action.type) {
    case DataMappingTypes.GET_DATA_MAPPINGS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }

    case DataMappingTypes.UPDATE_FILTER_DATA_MAPPING_SUCCESS:
      return { ...state, filter: action.payload }
    case DataMappingTypes.CLEAR_FILTER_DATA_MAPPING_SUCCESS:
      return { ...state, filter: DEFAULT_DATA_MAPPING_FILTER }

    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default DataMapping
