import { mapAlertDetail } from 'store/mapping/alert'
import AlertTypes from '../actionTypes/alert'
import { alert as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const getSystemAlerts = bindRequestLogic({
  clientFunc: clientApi.getSystemAlerts,
  type: AlertTypes.GET_SYSTEM_ALERTS_REQUEST,
  successType: AlertTypes.GET_SYSTEM_ALERTS_SUCCESS,
  failType: AlertTypes.GET_SYSTEM_ALERTS_FAILED,
  isQueryParam: true
})

const getAlerts = bindRequestLogic({
  clientFunc: clientApi.getAlerts,
  type: AlertTypes.GET_ALERTS_REQUEST,
  successType: AlertTypes.GET_ALERTS_SUCCESS,
  failType: AlertTypes.GET_ALERTS_FAILED,
  isQueryParam: true
})

const addAlert = bindRequestLogic({
  clientFunc: clientApi.addAlert,
  type: AlertTypes.ADD_ALERT_REQUEST,
  successType: AlertTypes.ADD_ALERT_SUCCESS,
  failType: AlertTypes.ADD_ALERT_FAILED
})

const getAlertDetail = bindRequestLogic({
  clientFunc: clientApi.getAlertDetail,
  type: AlertTypes.GET_ALERT_DETAIL_REQUEST,
  successType: AlertTypes.GET_ALERT_DETAIL_SUCCESS,
  failType: AlertTypes.GET_ALERT_DETAIL_FAILED,
  modelName: 'alerts',
  mapResponse: mapAlertDetail
})

const updateAlert = bindRequestLogic({
  clientFunc: clientApi.updateAlert,
  type: AlertTypes.UPDATE_ALERT_REQUEST,
  successType: AlertTypes.UPDATE_ALERT_SUCCESS,
  failType: AlertTypes.UPDATE_ALERT_FAILED
})

const deleteAlert = bindRequestLogic({
  clientFunc: clientApi.deleteAlert,
  type: AlertTypes.DELETE_ALERT_REQUEST,
  successType: AlertTypes.DELETE_ALERT_SUCCESS,
  failType: AlertTypes.DELETE_ALERT_FAILED
})

const addAlertTranslation = bindRequestLogic({
  clientFunc: clientApi.addAlertTranslation,
  type: AlertTypes.ADD_ALERT_TRANSLATION_REQUEST,
  successType: AlertTypes.ADD_ALERT_TRANSLATION_SUCCESS,
  failType: AlertTypes.ADD_ALERT_TRANSLATION_FAILED
})

const updateAlertTranslation = bindRequestLogic({
  clientFunc: clientApi.updateAlertTranslation,
  type: AlertTypes.UPDATE_ALERT_TRANSLATION_REQUEST,
  successType: AlertTypes.UPDATE_ALERT_TRANSLATION_SUCCESS,
  failType: AlertTypes.UPDATE_ALERT_TRANSLATION_FAILED
})

const deleteAlertTranslation = bindRequestLogic({
  clientFunc: clientApi.deleteAlertlation,
  type: AlertTypes.DELETE_ALERT_TRANSLATION_REQUEST,
  successType: AlertTypes.DELETE_ALERT_TRANSLATION_SUCCESS,
  failType: AlertTypes.DELETE_ALERT_TRANSLATION_FAILED
})

export default [
  getSystemAlerts,
  getAlerts,
  addAlert,
  getAlertDetail,
  updateAlert,
  deleteAlert,
  addAlertTranslation,
  updateAlertTranslation,
  deleteAlertTranslation
]
