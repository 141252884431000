import keyMirror from 'keymirror'

export default keyMirror({
  GET_APP_VERSIONS_REQUEST: null,
  GET_APP_VERSIONS_SUCCESS: null,
  GET_APP_VERSIONS_FAILED: null,

  ADD_APP_VERSION_REQUEST: null,
  ADD_APP_VERSION_SUCCESS: null,
  ADD_APP_VERSION_FAILED: null,

  GET_APP_VERSION_DETAIL_REQUEST: null,
  GET_APP_VERSION_DETAIL_SUCCESS: null,
  GET_APP_VERSION_DETAIL_FAILED: null,

  UPDATE_APP_VERSION_FILTER_SUCCESS: null,

  UPDATE_APP_VERSION_REQUEST: null,
  UPDATE_APP_VERSION_SUCCESS: null,
  UPDATE_APP_VERSION_FAILED: null,

  DELETE_APP_VERSION_REQUEST: null,
  DELETE_APP_VERSION_SUCCESS: null,
  DELETE_APP_VERSION_FAILED: null,

  DELETE_APP_VERSION_TRANSLATION_REQUEST: null,
  DELETE_APP_VERSION_TRANSLATION_SUCCESS: null,
  DELETE_APP_VERSION_TRANSLATION_FAILED: null,

  TRANSLATE_APP_VERSIONS_REQUEST: null,
  TRANSLATE_APP_VERSIONS_SUCCESS: null,
  TRANSLATE_APP_VERSIONS_FAILED: null,

  GET_SYSTEM_APP_VERSIONS_REQUEST: null,
  GET_SYSTEM_APP_VERSIONS_SUCCESS: null,
  GET_SYSTEM_APP_VERSIONS_FAILED: null
})
