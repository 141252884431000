import { combineReducers } from 'redux'
import LanguageTypes from 'store/actionTypes/language'
import { createHandleRequest } from './helpers'

const getLanguages = createHandleRequest(
  LanguageTypes.GET_LANGUAGES_REQUEST,
  LanguageTypes.GET_LANGUAGES_SUCCESS,
  LanguageTypes.GET_LANGUAGES_FAILED
)

export default combineReducers({
  getLanguages
})
