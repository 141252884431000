import { DEFAULT_ALERT_FILTER } from 'constants/common'
import AlertTypes from 'store/actionTypes/alert'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  systems: [],
  list: [],
  filter: DEFAULT_ALERT_FILTER,
  pagination: {},
  current: {}
}

const Alert = (state = initialData, action) => {
  switch (action.type) {
    case AlertTypes.GET_SYSTEM_ALERTS_SUCCESS:
      return {
        ...state,
        systems: action.payload?.items
      }

    case AlertTypes.GET_ALERT_DETAIL_SUCCESS:
      return {
        ...state,
        current: action.payload || []
      }

    case AlertTypes.UPDATE_FILTER_ALERT_SUCCESS:
      return { ...state, filter: action.payload }
    case AlertTypes.CLEAR_FILTER_ALERT_SUCCESS:
      return { ...state, filter: DEFAULT_ALERT_FILTER }

    case AlertTypes.GET_ALERTS_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default Alert
