import BaseApi from './base'

class ShowcasePrivacyPolicy extends BaseApi {
  getShowcasePrivacyPolicy = (languageId) =>
    this.client.get(`admin/privacy-policy?languageId=${languageId}`)

  updateShowcasePrivacyPolicy = (data) =>
    this.client.post(`admin/privacy-policy/translation`, data)
}

export default new ShowcasePrivacyPolicy()
