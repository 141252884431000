import BaseApi from './base'

class Topic extends BaseApi {
  getTopics = (query) => this.client.get(`admin/topic?${query}`)

  getTopicDetails = (id) => this.client.get(`admin/topic/${id}`)

  addTopicTranslation = (id, data) =>
    this.client.post(`admin/topic/${id}/translation`, data)

  updateTopicTranslation = this.addTopicTranslation

  deleteTopicTranslation = (id) =>
    this.client.delete(`admin/topic/translation/${id}`)

  addTopic = (data) => this.client.post('admin/topic', data)

  updateTopic = (id, data) => this.client.patch(`admin/topic/${id}`, data)

  deleteTopic = (id) => this.client.delete(`admin/topic/${id}`)
}

export default new Topic()
