import { DEFAULT_CONDITIONAL_FEATURE_FILTER } from 'constants/common'
import ConditionalFeatureType from 'store/actionTypes/conditionalFeature'

const initConditionalFeature = {
  list: [],
  pagination: {},
  filer: DEFAULT_CONDITIONAL_FEATURE_FILTER
}

const conditionalFeature = (state = initConditionalFeature, action) => {
  switch (action.type) {
    case ConditionalFeatureType.GET_CONDITIONAL_FEATURE_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    default:
      return state
  }
}

export default conditionalFeature
