import keyMirror from 'keymirror'

export default keyMirror({
  GET_PERS_PACKAGES_REQUEST: null,
  GET_PERS_PACKAGES_SUCCESS: null,
  GET_PERS_PACKAGES_FAILED: null,

  GET_PERS_PACKAGE_BY_ID_REQUEST: null,
  GET_PERS_PACKAGE_BY_ID_SUCCESS: null,
  GET_PERS_PACKAGE_BY_ID_FAILED: null,

  UPDATE_PERS_PACKAGE_FILTER: null,
  CLEAR_PERS_PACKAGE_FILTER: null,

  UPDATE_FILTER_PERS_PACKAGE_SUCCESS: null,
  CLEAR_FILTER_PERS_PACKAGE_SUCCESS: null
})
