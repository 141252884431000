import BaseApi from './base'

class SystemBusiness extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  getSystemGroupAlerts = () => this.client.get(`systems/group-alert?limit=0`)

  getSystemLevelAlerts = () => this.client.get(`systems/level-alert?limit=0`)

  getSystemTopicAlerts = (query) =>
    this.client.get(`systems/topic-alert?${query}`)
}

export default new SystemBusiness()
