import {
  PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_LABEL_MAPPING,
  PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_CODE_PREFIX
} from 'constants/companyCategory'
import { CATEGORY_PERMISSION_LABEL_MAPPING, CIXI_MODULES } from 'constants/role'

const separatorRegexp = /_(.*)/s

const filterByPro = (permissions = []) => {
  return permissions.filter((permission) => {
    const prefix = permission.code.split(separatorRegexp)[0]
    return prefix === PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_CODE_PREFIX
  })
}

const getCodeSuffix = (permission) => {
  if (typeof permission !== 'string') return permission
  const arr = permission.split(separatorRegexp)
  if (arr[0] === PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_CODE_PREFIX) {
    return arr[1].split(separatorRegexp)[1]
  }
  return arr[1]
}

const groupCategoryPermissions = (permissions, categoryMapping) => {
  const groups = {}

  permissions.forEach((permission) => {
    const { parentCategory, code } = permission
    const subCategory = categoryMapping[getCodeSuffix(code)]

    if (!subCategory) {
      return {}
    }

    if (groups[parentCategory]) {
      if (groups[parentCategory][subCategory]) {
        groups[parentCategory][subCategory].push(permission)
      } else {
        groups[parentCategory][subCategory] = [permission]
      }
      return {}
    }

    groups[parentCategory] = { [subCategory]: [permission] }
  })

  return groups
}

export const mapRoleDetail = (role) => {
  const { rolePermissions = [], ...args } = role || {}
  const permissions = rolePermissions.map((p) => p.permissions)
  const results = { ...args, permissions }
  switch (role.groupCode) {
    case CIXI_MODULES.backOffice:
      results.groupPermissions = groupCategoryPermissions(
        permissions,
        CATEGORY_PERMISSION_LABEL_MAPPING
      )
      break
    case CIXI_MODULES.proCenterMember:
      results.permissions = filterByPro(permissions)
      results.groupPermissions = groupCategoryPermissions(
        results.permissions,
        PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_LABEL_MAPPING
      )
      break
    default:
      break
  }
  results.codePermissions = (results.permissions || []).map(
    (permission) => permission.code
  )
  return results
}

export const mapCompanyCategoryDetail = (companyCategory = {}) => {
  const { permissions: categoryPermissions = [], ...args } = companyCategory
  const _permissions = categoryPermissions.map((p) => p.permissions)
  const permissions = filterByPro(_permissions) || []
  const codePermissions = permissions.map((permission) => permission.code)
  return {
    ...args,
    permissions,
    codePermissions,
    translations: (companyCategory.translations || []).map((l) => ({
      ...l,
      translationValue: l.labelCompanyCategoryTranslation
    })),
    groupPermissions: groupCategoryPermissions(
      permissions,
      PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_LABEL_MAPPING
    )
  }
}

export const mapCompanyRoleDetail = (role = {}) => {
  const { companyRolePermissions = [], ...args } = role
  const _permissions = companyRolePermissions.map((p) => p.permissions)
  const permissions = filterByPro(_permissions) || []
  return {
    ...args,
    permissions,
    codePermissions: permissions.map((permission) => permission?.code),
    translations: (role.companyRoleTranslation || []).map(
      ({ languages, ...val }) => ({ language: languages, ...val })
    ),
    groupPermissions: groupCategoryPermissions(
      permissions,
      PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_LABEL_MAPPING
    )
  }
}

export const mapPermissions = (permissions, payload) => {
  const { items } = permissions
  switch (payload?.groupCode) {
    case CIXI_MODULES.backOffice:
      return groupCategoryPermissions(items, CATEGORY_PERMISSION_LABEL_MAPPING)
    case CIXI_MODULES.proCenterMember:
      return groupCategoryPermissions(
        filterByPro(items),
        PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_LABEL_MAPPING
      )
    default:
      return items
  }
}

export const mapPermissionsByCompanyId = (companyPermissions = []) => {
  const _permissions = companyPermissions.map((p) => p.permissions)
  const permissions = filterByPro(_permissions)
  return groupCategoryPermissions(
    permissions,
    PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_LABEL_MAPPING
  )
}
