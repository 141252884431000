import { DEFAULT_CURRENCY_FILTER } from 'constants/common'
import CurrencyTypes from 'store/actionTypes/currency'
import UserTypes from 'store/actionTypes/user'

const initialData = {
  list: [],
  systems: [],
  current: {},
  pagination: {},
  filter: DEFAULT_CURRENCY_FILTER
}

const currency = (state = initialData, action) => {
  switch (action.type) {
    case CurrencyTypes.GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        list: action.payload?.items,
        pagination: action.payload?.meta
      }
    case CurrencyTypes.GET_SYSTEM_CURRENCIES_SUCCESS:
      return { ...state, systems: action.payload?.currencies?.items }
    case CurrencyTypes.GET_CURRENCY_DETAIL_SUCCESS:
      return { ...state, current: action.payload }
    case CurrencyTypes.UPDATE_FILTER_CURRENCY_SUCCESS:
      return { ...state, filter: action.payload }
    case CurrencyTypes.CLEAR_FILTER_CURRENCY_SUCCESS:
      return { ...state, filter: initialData.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initialData
    default:
      return state
  }
}

export default currency
