import BaseApi from './base'

class VehicleType extends BaseApi {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.baseURL}/admin/`
  }

  getVehicleTypes = (query) => {
    return this.client.get(`vehicle-types?${query}`)
  }

  getVehicleTypeDetail = (id) => this.client.get(`vehicle-types/${id}`)

  addVehicleType = (data) => this.client.post('vehicle-types', data)

  updateVehicleType = (id, data) =>
    this.client.patch(`vehicle-types/${id}`, data)

  deleteVehicleType = (id) => this.client.delete(`vehicle-types/${id}`)

  getSystemVehicleTypes = (query) => {
    return this.client.get(`backoffice/internals/vehicle-types?${query}`, {
      baseURL: this.fleetURL
    })
  }
}

export default new VehicleType()
