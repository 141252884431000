import BaseApi from './base'

class UploadMedia extends BaseApi {
  upload = (data) => {
    const isMediaKit = data.get('isMediaKit') || false
    data.delete('isMediaKit')
    return this.client.post(`s3/upload`, data, {
      params: { isMediaKit }
    })
  }
}

export default new UploadMedia()
