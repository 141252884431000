import BaseApi from './base'

class CompanyCategory extends BaseApi {
  getCompanyRoles = (id, query) =>
    this.client.get(`companies/${id}/company-roles?${query}`)

  getCompanyRole = (id, { roleId }) =>
    this.client.get(`companies/${id}/company-roles/${roleId}`)

  addCompanyRole = (id, data) =>
    this.client.post(`companies/${id}/company-roles`, data)

  updateCompanyRole = (id, { roleId, ...args }) =>
    this.client.patch(`companies/${id}/company-roles/${roleId}`, args)

  deleteCompanyRole = (id, { roleId }) =>
    this.client.delete(`companies/${id}/company-roles/${roleId}`)

  upsertCompanyRoleTranslation = (id, { roleId, ...args }) =>
    this.client.post(
      `companies/${id}/company-roles/${roleId}/translation`,
      args
    )

  deleteCompanyRoleTranslation = (id, { translationId }) =>
    this.client.delete(
      `companies/${id}/company-roles/translation/${translationId}`
    )

  getSystemCompanyRoles = (id, query) =>
    this.client.get(`admin/system/company/${id}/company-roles?${query}`)

  getCompanyRoleConfiguration = (id, query) =>
    this.client.get(`company/${id}/company-role-configuration?${query}`)

  updateCompanyRoleConfiguration = (id, data) =>
    this.client.patch(`company/${id}/company-role-configuration`, data)

  getCompanyAllowToManage = (id, query) =>
    this.client.get(`company/${id}/company-allow-to-manage?${query}`)

  updateCompanyAllowToManage = (id, data) =>
    this.client.patch(`company/${id}/company-allow-to-manage`, data)

  getPermissionsByCompanyId = (id) =>
    this.client.get(
      `companies/${id}/company-roles/company-category/permissions`
    )
}

export default new CompanyCategory()
