import { combineReducers } from 'redux'
import CompanyTypes from 'store/actionTypes/company'
import { createHandleRequest } from './helpers'

const getCompanies = createHandleRequest(
  CompanyTypes.GET_COMPANIES_REQUEST,
  CompanyTypes.GET_COMPANIES_SUCCESS,
  CompanyTypes.GET_COMPANIES_FAILED
)

const getCompanyMembers = createHandleRequest(
  CompanyTypes.GET_COMPANY_MEMBERS_REQUEST,
  CompanyTypes.GET_COMPANY_MEMBERS_SUCCESS,
  CompanyTypes.GET_COMPANY_MEMBERS_FAILED
)

const getCompanyPilots = createHandleRequest(
  CompanyTypes.GET_COMPANY_PILOTS_REQUEST,
  CompanyTypes.GET_COMPANY_PILOTS_SUCCESS,
  CompanyTypes.GET_COMPANY_PILOTS_FAILED
)

const getChildrenCompanies = createHandleRequest(
  CompanyTypes.GET_CHILDREN_COMPANIES_REQUEST,
  CompanyTypes.GET_CHILDREN_COMPANIES_SUCCESS,
  CompanyTypes.GET_CHILDREN_COMPANIES_FAILED
)

const getCompanyVehicles = createHandleRequest(
  CompanyTypes.GET_COMPANY_VEHICLES_REQUEST,
  CompanyTypes.GET_COMPANY_VEHICLES_SUCCESS,
  CompanyTypes.GET_COMPANY_VEHICLES_FAILED
)

const getCompanySubscriptions = createHandleRequest(
  CompanyTypes.GET_COMPANY_SUBSCRIPTIONS_REQUEST,
  CompanyTypes.GET_COMPANY_SUBSCRIPTIONS_SUCCESS,
  CompanyTypes.GET_COMPANY_SUBSCRIPTIONS_FAILED
)

const getSystemCompanies = createHandleRequest(
  CompanyTypes.GET_SYSTEM_COMPANIES_REQUEST,
  CompanyTypes.GET_SYSTEM_COMPANIES_SUCCESS,
  CompanyTypes.GET_SYSTEM_COMPANIES_FAILED
)

export default combineReducers({
  getCompanies,
  getCompanyMembers,
  getCompanyPilots,
  getChildrenCompanies,
  getCompanySubscriptions,
  getCompanyVehicles,
  getSystemCompanies
})
