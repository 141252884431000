import ShowcaseAnonymizeVisitor from '../actionTypes/showcaseAnonymizeVisitor'
import { showcaseAnonymizeVisitor as api } from '../api'
import { bindRequestLogic } from './utils'

const getAnonymizedVisitors = bindRequestLogic({
  clientFunc: api.getAnonymizedVisitors,
  type: ShowcaseAnonymizeVisitor.GET_ANONYMIZE_VISITOR_REQUEST,
  successType: ShowcaseAnonymizeVisitor.GET_ANONYMIZE_VISITOR_SUCCESS,
  failType: ShowcaseAnonymizeVisitor.GET_ANONYMIZE_VISITOR_FAILED,
  isQueryParam: true
})

const cancelAnonymizeVisitor = bindRequestLogic({
  clientFunc: api.cancelAnonymizeVisitor,
  type: ShowcaseAnonymizeVisitor.CANCEL_ANONYMIZE_VISITOR_REQUEST,
  successType: ShowcaseAnonymizeVisitor.CANCEL_ANONYMIZE_VISITOR_SUCCESS,
  failType: ShowcaseAnonymizeVisitor.CANCEL_ANONYMIZE_VISITOR_FAILED,
  isQueryParam: false
})
export default [getAnonymizedVisitors, cancelAnonymizeVisitor]
