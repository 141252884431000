import AppVersionTypes from '../actionTypes/appVersion'
import { appVersion as clientApi } from '../api'
import { bindRequestLogic } from './utils'
import { mapAppVersionDetail, mapAppVersions } from '../mapping'

const getAppVersions = bindRequestLogic({
  clientFunc: clientApi.getAppVersions,
  type: AppVersionTypes.GET_APP_VERSIONS_REQUEST,
  successType: AppVersionTypes.GET_APP_VERSIONS_SUCCESS,
  failType: AppVersionTypes.GET_APP_VERSIONS_FAILED,
  isQueryParam: true,
  modelName: 'appVersions',
  mapResponse: mapAppVersions
})

const getAppVersionDetail = bindRequestLogic({
  clientFunc: clientApi.getAppVersionDetail,
  type: AppVersionTypes.GET_APP_VERSION_DETAIL_REQUEST,
  successType: AppVersionTypes.GET_APP_VERSION_DETAIL_SUCCESS,
  failType: AppVersionTypes.GET_APP_VERSION_DETAIL_FAILED,
  modelName: 'appVersion',
  mapResponse: mapAppVersionDetail
})

const addAppVersion = bindRequestLogic({
  clientFunc: clientApi.addAppVersion,
  type: AppVersionTypes.ADD_APP_VERSION_REQUEST,
  successType: AppVersionTypes.ADD_APP_VERSION_SUCCESS,
  failType: AppVersionTypes.ADD_APP_VERSION_FAILED
})

const deleteAppVersion = bindRequestLogic({
  clientFunc: clientApi.deleteAppVersion,
  type: AppVersionTypes.DELETE_APP_VERSION_REQUEST,
  successType: AppVersionTypes.DELETE_APP_VERSION_SUCCESS,
  failType: AppVersionTypes.DELETE_APP_VERSION_FAILED
})

const updateAppVersion = bindRequestLogic({
  clientFunc: clientApi.updateAppVersion,
  type: AppVersionTypes.UPDATE_APP_VERSION_REQUEST,
  successType: AppVersionTypes.UPDATE_APP_VERSION_SUCCESS,
  failType: AppVersionTypes.UPDATE_APP_VERSION_FAILED
})

const translateAppVersion = bindRequestLogic({
  clientFunc: clientApi.appVersionTranslation,
  type: AppVersionTypes.TRANSLATE_APP_VERSIONS_REQUEST,
  successType: AppVersionTypes.TRANSLATE_APP_VERSIONS_SUCCESS,
  failType: AppVersionTypes.TRANSLATE_APP_VERSIONS_FAILED
})

const deleteAppVersionTranslation = bindRequestLogic({
  clientFunc: clientApi.deleteAppVersionTranslation,
  type: AppVersionTypes.DELETE_APP_VERSION_TRANSLATION_REQUEST,
  successType: AppVersionTypes.DELETE_APP_VERSION_TRANSLATION_SUCCESS,
  failType: AppVersionTypes.DELETE_APP_VERSION_TRANSLATION_FAILED
})

const getSystemAppVersions = bindRequestLogic({
  clientFunc: clientApi.getSystemAppVersions,
  type: AppVersionTypes.GET_SYSTEM_APP_VERSIONS_REQUEST,
  successType: AppVersionTypes.GET_SYSTEM_APP_VERSIONS_SUCCESS,
  failType: AppVersionTypes.GET_SYSTEM_APP_VERSIONS_FAILED
})

export default [
  getAppVersions,
  getAppVersionDetail,
  translateAppVersion,
  addAppVersion,
  updateAppVersion,
  deleteAppVersion,
  deleteAppVersionTranslation,
  getSystemAppVersions
]
