// User-related logics
import user from './user'
import appUser from './appUser'
import role from './role'
import rolesConfiguration from './rolesConfiguration'
import proCenterAdmin from './proCenterAdmin'

// System configuration logics
import systemConfig from './systemConfig'
import dateTimeFormat from './dateTimeFormat'
import language from './language'
import timezone from './timezone'
import conditionalFeature from './conditionalFeature'

// Notification and alert logics
import notificationTemplateContent from './notificationTemplateContent'
import notificationRequest from './notificationRequest'
import notificationTemplate from './notificationTemplate'
import notificationTemplateTech from './notificationTemplateTech'
import platformTech from './platformTech'
import platformPageTech from './platformPageTech'
import alert from './alert'
import alertGroup from './alertGroup'
import alertLevel from './alertLevel'
import alertTopic from './alertTopic'

// Showcase-related logics
import showcaseSection from './showcaseSection'
import showcaseComponent from './showcaseComponent'
import showcaseStory from './showcaseStory'
import showcasePrivacyPolicy from './showcasePrivacyPolicy'
import showcaseContactLog from './showcaseContactLog'
import showcaseAnonymizeVisitor from './showcaseAnonymizeVisitor'
import faq from './faq'

// Miscellaneous logics
import storage from './storage'
import country from './country'
import currency from './currency'
import labels from './label'
import message from './message'
import mailTemplate from './mailTemplate'
import messageAfterConnection from './messageAfterConnection'
import uploadMedia from './uploadMedia'
import portal from './portal'
import appVersion from './appVersion'
import topic from './topic'
import text from './text'
import formattedText from './formattedText'

// Logging and monitoring logics
import logMail from './logMail'
import logRoutine from './logRoutine'
import keyUsageSystemLog from './keyUsageSystemLog'
import usageSystemLog from './usageSystemLog'
import persPackage from './persPackage'

// Vehicle-related logics
import vehicle from './vehicle'
import vehicleModel from './vehicleModel'
import vehicleBrand from './vehicleBrand'
import vehicleElectronicKey from './vehicleElectronicKey'
import electronicKey from './electronicKey'
import electronicKeyModel from './electronicKeyModel'
import vehicleType from './vehicleType'
import companyVehicleBrandLink from './companyVehicleBrandLink'
import modelPer from './modelPer'
import subscriptionElectronicKey from './subscriptionElectronicKey'
import sharing from './sharing'
import softwarePer from './softwarePer'
import vehiclePer from './vehiclePer'
import per from './per'

// Company and activity logics
import company from './company'
import companyCategory from './companyCategory'
import companyRole from './companyRole'
import companyPerLink from './companyPerLink'
import activity from './activity'
import subscription from './subscription'
import cixiReferent from './cixiReferent'

// Odoo logics
import dataMapping from './dataMapping'
import odooSynchronization from './odooSynchronization'

export default {
  commons: [
    // User-related logics
    ...user,
    ...appUser,
    ...role,
    ...rolesConfiguration,
    ...proCenterAdmin,

    // System configuration logics
    ...systemConfig,
    ...dateTimeFormat,
    ...language,
    ...timezone,
    ...conditionalFeature,

    // Logging and monitoring logics
    ...logMail,
    ...logRoutine,
    ...keyUsageSystemLog,
    ...usageSystemLog,
    ...persPackage,

    // Notification and alert logics
    ...notificationTemplateContent,
    ...notificationRequest,
    ...notificationTemplate,
    ...notificationTemplateTech,
    ...platformTech,
    ...platformPageTech,
    ...alert,
    ...alertGroup,
    ...alertLevel,
    ...alertTopic,

    // Showcase-related logics
    ...showcaseSection,
    ...showcaseComponent,
    ...showcaseStory,
    ...showcasePrivacyPolicy,
    ...showcaseContactLog,
    ...showcaseAnonymizeVisitor,
    ...faq,

    // Miscellaneous logics
    ...storage,
    ...country,
    ...currency,
    ...labels,
    ...message,
    ...mailTemplate,
    ...messageAfterConnection,
    ...uploadMedia,
    ...portal,
    ...appVersion,
    ...topic,
    ...text,
    ...formattedText,

    // odoo logics
    ...dataMapping,
    ...odooSynchronization
  ],
  relatedToVehicles: [
    // Vehicle-related logics
    ...vehicle,
    ...vehicleModel,
    ...vehicleBrand,
    ...vehicleElectronicKey,
    ...electronicKey,
    ...electronicKeyModel,
    ...vehicleType,
    ...companyVehicleBrandLink,
    ...modelPer,
    ...subscriptionElectronicKey,
    ...sharing,
    ...softwarePer,
    ...vehiclePer,
    ...per,

    // Company and activity logics
    ...company,
    ...companyCategory,
    ...companyRole,
    ...companyPerLink,
    ...activity,
    ...subscription,
    ...cixiReferent
  ]
}
