import LanguageTypes from '../actionTypes/language'
import { language as clientApi } from '../api'
import { bindRequestLogic } from './utils'

const getLanguages = bindRequestLogic({
  clientFunc: clientApi.getLanguages,
  type: LanguageTypes.GET_LANGUAGES_REQUEST,
  successType: LanguageTypes.GET_LANGUAGES_SUCCESS,
  failType: LanguageTypes.GET_LANGUAGES_FAILED,
  isQueryParam: true,
  modelName: 'languages'
})

const addLanguage = bindRequestLogic({
  clientFunc: clientApi.addLanguage,
  type: LanguageTypes.ADD_LANGUAGE_REQUEST,
  successType: LanguageTypes.ADD_LANGUAGE_SUCCESS,
  failType: LanguageTypes.ADD_LANGUAGE_FAILED
})

const getLanguageDetail = bindRequestLogic({
  clientFunc: clientApi.getLanguageDetail,
  type: LanguageTypes.GET_LANGUAGE_DETAIL_REQUEST,
  successType: LanguageTypes.GET_LANGUAGE_DETAIL_SUCCESS,
  failType: LanguageTypes.GET_LANGUAGE_DETAIL_FAILED,
  modelName: 'language'
})

const updateLanguage = bindRequestLogic({
  clientFunc: clientApi.updateLanguage,
  type: LanguageTypes.UPDATE_LANGUAGE_REQUEST,
  successType: LanguageTypes.UPDATE_LANGUAGE_SUCCESS,
  failType: LanguageTypes.UPDATE_LANGUAGE_FAILED
})

const deleteLanguage = bindRequestLogic({
  clientFunc: clientApi.deleteLanguage,
  type: LanguageTypes.DELETE_LANGUAGE_REQUEST,
  successType: LanguageTypes.DELETE_LANGUAGE_SUCCESS,
  failType: LanguageTypes.DELETE_LANGUAGE_FAILED
})

const addLanguageTranslation = bindRequestLogic({
  clientFunc: clientApi.addLanguageTranslation,
  type: LanguageTypes.ADD_LANGUAGE_TRANSLATION_REQUEST,
  successType: LanguageTypes.ADD_LANGUAGE_TRANSLATION_SUCCESS,
  failType: LanguageTypes.ADD_LANGUAGE_TRANSLATION_FAILED
})

const updateLanguageTranslation = bindRequestLogic({
  clientFunc: clientApi.updateLanguageTranslation,
  type: LanguageTypes.UPDATE_LANGUAGE_TRANSLATION_REQUEST,
  successType: LanguageTypes.UPDATE_LANGUAGE_TRANSLATION_SUCCESS,
  failType: LanguageTypes.UPDATE_LANGUAGE_TRANSLATION_FAILED
})

const deleteLanguageTranslation = bindRequestLogic({
  clientFunc: clientApi.deleteLanguageTranslation,
  type: LanguageTypes.DELETE_LANGUAGE_TRANSLATION_REQUEST,
  successType: LanguageTypes.DELETE_LANGUAGE_TRANSLATION_SUCCESS,
  failType: LanguageTypes.DELETE_LANGUAGE_TRANSLATION_FAILED
})

export const getSystemLanguages = bindRequestLogic({
  clientFunc: clientApi.getSystemLanguages,
  type: LanguageTypes.GET_SYSTEM_LANGUAGES_REQUEST,
  successType: LanguageTypes.GET_SYSTEM_LANGUAGES_SUCCESS,
  failType: LanguageTypes.GET_SYSTEM_LANGUAGES_FAILED,
  isQueryParam: true
})

export default [
  getLanguages,
  addLanguage,
  getLanguageDetail,
  updateLanguage,
  deleteLanguage,
  addLanguageTranslation,
  deleteLanguageTranslation,
  updateLanguageTranslation,
  getSystemLanguages
]
