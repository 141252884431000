import { createLogic } from 'redux-logic'
import CompanyTypes from '../actionTypes/company'
import {
  company as clientApi,
  mailTemplate as mailTemplateApi,
  companyMailTemplate as companyMailTemplateApi
} from '../api'

import { bindRequestLogic } from './utils'

const getCompanies = bindRequestLogic({
  clientFunc: clientApi.getCompanies,
  type: CompanyTypes.GET_COMPANIES_REQUEST,
  successType: CompanyTypes.GET_COMPANIES_SUCCESS,
  failType: CompanyTypes.GET_COMPANIES_FAILED,
  isQueryParam: true
})

const addCompany = bindRequestLogic({
  clientFunc: clientApi.addCompany,
  type: CompanyTypes.ADD_COMPANY_REQUEST,
  successType: CompanyTypes.ADD_COMPANY_SUCCESS,
  failType: CompanyTypes.ADD_COMPANY_FAILED
})

const updateCompany = bindRequestLogic({
  clientFunc: clientApi.updateCompany,
  type: CompanyTypes.UPDATE_COMPANY_REQUEST,
  successType: CompanyTypes.UPDATE_COMPANY_SUCCESS,
  failType: CompanyTypes.UPDATE_COMPANY_FAILED
})

const getCompanyDetail = bindRequestLogic({
  clientFunc: clientApi.getCompanyDetail,
  type: CompanyTypes.GET_COMPANY_DETAIL_REQUEST,
  successType: CompanyTypes.GET_COMPANY_DETAIL_SUCCESS,
  failType: CompanyTypes.GET_COMPANY_DETAIL_FAILED,
  modelName: 'company'
})

const deleteCompany = bindRequestLogic({
  clientFunc: clientApi.deleteCompany,
  type: CompanyTypes.DELETE_COMPANY_REQUEST,
  successType: CompanyTypes.DELETE_COMPANY_SUCCESS,
  failType: CompanyTypes.DELETE_COMPANY_FAILED
})

const updateSheetAdditional = bindRequestLogic({
  clientFunc: clientApi.updateSheetAdditional,
  type: CompanyTypes.UPDATE_SHEET_ADDITIONAL_REQUEST,
  successType: CompanyTypes.UPDATE_SHEET_ADDITIONAL_SUCCESS,
  failType: CompanyTypes.UPDATE_SHEET_ADDITIONAL_FAILED
})

const convertToMotherCompany = bindRequestLogic({
  clientFunc: clientApi.convertToMotherCompany,
  type: CompanyTypes.CONVERT_TO_MOTHER_COMPANY_REQUEST,
  successType: CompanyTypes.CONVERT_TO_MOTHER_COMPANY_SUCCESS,
  failType: CompanyTypes.CONVERT_TO_MOTHER_COMPANY_FAILED
})

const getCompanyMembers = bindRequestLogic({
  clientFunc: clientApi.getCompanyMembers,
  type: CompanyTypes.GET_COMPANY_MEMBERS_REQUEST,
  successType: CompanyTypes.GET_COMPANY_MEMBERS_SUCCESS,
  failType: CompanyTypes.GET_COMPANY_MEMBERS_FAILED,
  isQueryParam: true
})

const addCompanyMember = bindRequestLogic({
  clientFunc: clientApi.addCompanyMember,
  type: CompanyTypes.ADD_COMPANY_MEMBER_REQUEST,
  successType: CompanyTypes.ADD_COMPANY_MEMBER_SUCCESS,
  failType: CompanyTypes.ADD_COMPANY_MEMBER_FAILED
})

const updateCompanyMember = bindRequestLogic({
  clientFunc: clientApi.updateCompanyMember,
  type: CompanyTypes.UPDATE_COMPANY_MEMBER_REQUEST,
  successType: CompanyTypes.UPDATE_COMPANY_MEMBER_SUCCESS,
  failType: CompanyTypes.UPDATE_COMPANY_MEMBER_FAILED
})

const getCompanyPilots = bindRequestLogic({
  clientFunc: clientApi.getCompanyPilots,
  type: CompanyTypes.GET_COMPANY_PILOTS_REQUEST,
  successType: CompanyTypes.GET_COMPANY_PILOTS_SUCCESS,
  failType: CompanyTypes.GET_COMPANY_PILOTS_FAILED,
  isQueryParam: true
})

const deleteCompanyMember = bindRequestLogic({
  clientFunc: clientApi.deleteCompanyMember,
  type: CompanyTypes.DELETE_COMPANY_MEMBER_REQUEST,
  successType: CompanyTypes.DELETE_COMPANY_MEMBER_SUCCESS,
  failType: CompanyTypes.DELETE_COMPANY_MEMBER_FAILED
})

const getChildrenCompanies = bindRequestLogic({
  clientFunc: clientApi.getChildrenCompanies,
  type: CompanyTypes.GET_CHILDREN_COMPANIES_REQUEST,
  successType: CompanyTypes.GET_CHILDREN_COMPANIES_SUCCESS,
  failType: CompanyTypes.GET_CHILDREN_COMPANIES_FAILED,
  isQueryParam: true
})

const addChildrenCompany = bindRequestLogic({
  clientFunc: clientApi.addChildrenCompany,
  type: CompanyTypes.ADD_CHILDREN_COMPANY_REQUEST,
  successType: CompanyTypes.ADD_CHILDREN_COMPANY_SUCCESS,
  failType: CompanyTypes.ADD_CHILDREN_COMPANY_FAILED
})

const deleteChildrenCompany = bindRequestLogic({
  clientFunc: clientApi.deleteChildrenCompany,
  type: CompanyTypes.DELETE_CHILDREN_COMPANY_REQUEST,
  successType: CompanyTypes.DELETE_CHILDREN_COMPANY_SUCCESS,
  failType: CompanyTypes.DELETE_CHILDREN_COMPANY_FAILED
})

const getCompanySheetVehicle = bindRequestLogic({
  clientFunc: clientApi.getCompanyVehicles,
  type: CompanyTypes.GET_COMPANY_VEHICLES_REQUEST,
  successType: CompanyTypes.GET_COMPANY_VEHICLES_SUCCESS,
  failType: CompanyTypes.GET_COMPANY_VEHICLES_FAILED,
  isQueryParam: true
})

const getCompanySubscriptions = bindRequestLogic({
  clientFunc: clientApi.getCompanySubscription,
  type: CompanyTypes.GET_COMPANY_SUBSCRIPTIONS_REQUEST,
  successType: CompanyTypes.GET_COMPANY_SUBSCRIPTIONS_SUCCESS,
  failType: CompanyTypes.GET_COMPANY_SUBSCRIPTIONS_FAILED,
  isQueryParam: true
})

const getSystemCompanies = bindRequestLogic({
  clientFunc: clientApi.getSystemCompanies,
  type: CompanyTypes.GET_SYSTEM_COMPANIES_REQUEST,
  successType: CompanyTypes.GET_SYSTEM_COMPANIES_SUCCESS,
  failType: CompanyTypes.GET_SYSTEM_COMPANIES_FAILED,
  isQueryParam: true
})

const getCompanyMailTemplates = bindRequestLogic({
  clientApi: mailTemplateApi,
  clientFunc: mailTemplateApi.getMailTemplates,
  type: CompanyTypes.GET_COMPANY_MAIL_TEMPLATES_REQUEST,
  successType: CompanyTypes.GET_COMPANY_MAIL_TEMPLATES_SUCCESS,
  failType: CompanyTypes.GET_COMPANY_MAIL_TEMPLATES_FAILED,
  isQueryParam: true,
  modelName: 'mailTemplates'
})

const getCompanyMailTemplateDetail = createLogic({
  type: CompanyTypes.GET_COMPANY_MAIL_TEMPLATE_DETAIL_REQUEST,
  latest: true,

  async process({ action }, dispatch, done) {
    const { onSuccess, onError } = action.meta
    try {
      const res = await companyMailTemplateApi.getCompanyMailTemplateDetail(
        action?.payload
      )
      dispatch({
        type: CompanyTypes.GET_COMPANY_MAIL_TEMPLATE_DETAIL_SUCCESS,
        payload: {},
        meta: {}
      })
      onSuccess(res?.data?.compMailTemplateTrans)
    } catch (error) {
      onError('')
      dispatch({
        type: CompanyTypes.GET_COMPANY_MAIL_TEMPLATE_DETAIL_FAILED,
        payload: error.response,
        meta: {}
      })
    }
    done()
  }
})

const upsertCompanyMailTemplate = createLogic({
  type: CompanyTypes.UPSERT_COMPANY_MAIL_TEMPLATE_REQUEST,
  latest: true,

  async process({ action }, dispatch, done) {
    const { onSuccess, onError } = action.meta
    try {
      const { companyId, mailTemplateId, data } = action.payload

      const res = await companyMailTemplateApi.upsetCompanyMailTemplate(
        companyId,
        mailTemplateId,
        data
      )
      dispatch({
        type: CompanyTypes.UPSERT_COMPANY_MAIL_TEMPLATE_SUCCESS,
        payload: {},
        meta: {}
      })
      onSuccess(res?.data?.compMailTemplateTrans)
    } catch (error) {
      onError('')
      dispatch({
        type: CompanyTypes.UPSERT_COMPANY_MAIL_TEMPLATE_FAILED,
        payload: error.response,
        meta: {}
      })
    }
    done()
  }
})

const deleteCompanyMailTemplate = createLogic({
  type: CompanyTypes.DELETE_COMPANY_MAIL_TEMPLATE_REQUEST,
  latest: true,

  async process({ action }, dispatch, done) {
    const { onSuccess, onError } = action.meta
    try {
      const { companyId, mailTemplateId } = action.payload

      const res = await companyMailTemplateApi.deleteCompanyMailTemplate(
        companyId,
        mailTemplateId
      )
      dispatch({
        type: CompanyTypes.DELETE_COMPANY_MAIL_TEMPLATE_SUCCESS,
        payload: {},
        meta: {}
      })
      onSuccess(res?.data?.compMailTemplateTrans)
    } catch (error) {
      onError('')
      dispatch({
        type: CompanyTypes.DELETE_COMPANY_MAIL_TEMPLATE_FAILED,
        payload: error.response,
        meta: {}
      })
    }
    done()
  }
})

export default [
  getCompanies,
  addCompany,
  getCompanyDetail,
  deleteCompany,
  updateCompany,
  updateSheetAdditional,
  addCompanyMember,
  deleteCompanyMember,
  updateCompanyMember,
  getCompanyMembers,
  getCompanyPilots,
  convertToMotherCompany,
  getChildrenCompanies,
  addChildrenCompany,
  deleteChildrenCompany,
  getCompanySheetVehicle,
  getCompanySubscriptions,
  getSystemCompanies,
  getCompanyMailTemplates,
  getCompanyMailTemplateDetail,
  upsertCompanyMailTemplate,
  deleteCompanyMailTemplate
]
