import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { createRoot } from 'react-dom/client'
import { persistor, store } from 'store'
import reportWebVitals from './reportWebVitals'

const App = React.lazy(() => import('App'))

const container = document.getElementById('root')

const root = createRoot(container)

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Suspense fallback={<div className='loading' />}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Suspense>
  </Provider>
  // </React.StrictMode>
)

reportWebVitals()
