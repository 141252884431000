import BaseAPI from './base'

class SubscriptionAPI extends BaseAPI {
  constructor() {
    super()
    this.client.defaults.baseURL = `${this.fleetURL}/backoffice`
  }

  getSubscriptions = (query) => this.client.get(`admin/subscriptions?${query}`)

  getSubscriptionDetail = (id) => this.client.get(`admin/subscriptions/${id}`)

  addSubscription = (data) => this.client.post('admin/subscriptions', data)

  updateSubscription = (id, data) =>
    this.client.patch(`admin/subscriptions/${id}`, data)

  deleteSubscription = (id) => this.client.delete(`admin/subscriptions/${id}`)

  getVehicles = (id, query) =>
    this.client.get(`admin/subscriptions/${id}/vehicle?${query}`)

  addVehicle = (id, data) =>
    this.client.post(`admin/subscriptions/${id}/vehicles`, data)

  updateVehicle = (id, data) =>
    this.client.patch(`admin/subscriptions/vehicles/${id}`, data)

  deleteVehicle = (id) =>
    this.client.delete(`admin/subscriptions/vehicles/${id}`)

  getAllowedPilots = (id) =>
    this.client.get(`admin/subscriptions/${id}/allowed-pilots`)

  addAllowedPilot = (id, data) =>
    this.client.post(`admin/subscriptions/${id}/allowed-pilots`, data)

  updateAllowedPilot = (id, data) =>
    this.client.patch(
      `admin/subscriptions/${id}/allowed-pilots/${data.pilotId}`,
      data
    )

  deleteAllowedPilot = (id) =>
    this.client.delete(`admin/subscriptions/allowed-pilots/${id}`)

  getVehicleSubscriptions = (id, query = '') =>
    this.client.get(`admin/subscriptions/vehicle/${id}?${query}`)
}

export default new SubscriptionAPI()
