import { combineReducers } from 'redux'
import LogRoutineTypes from 'store/actionTypes/logRoutine'
import { createHandleRequest } from './helpers'

const getLogRoutines = createHandleRequest(
  LogRoutineTypes.GET_LOG_ROUTINES_REQUEST,
  LogRoutineTypes.GET_LOG_ROUTINES_SUCCESS,
  LogRoutineTypes.GET_LOG_ROUTINES_FAILED
)

export default combineReducers({
  getLogRoutines
})
