import { DEFAULT_SHOWCASE_STORY_FILTER } from 'constants/common'
import ShowcaseAnonymizeVisitorTypes from 'store/actionTypes/showcaseAnonymizeVisitor'
import UserTypes from 'store/actionTypes/user'

const initShowcaseAnonymizeVisitor = {
  list: [],
  current: {},
  pagination: {},
  filter: DEFAULT_SHOWCASE_STORY_FILTER
}

const showcaseAnonymizeVisitor = (
  state = initShowcaseAnonymizeVisitor,
  action
) => {
  const data = action?.payload || []
  switch (action.type) {
    case ShowcaseAnonymizeVisitorTypes.GET_ANONYMIZE_VISITOR_SUCCESS:
      return {
        ...state,
        list: data?.items,
        pagination: data?.meta
      }
    case ShowcaseAnonymizeVisitorTypes.UPDATE_FILTER_ANONYMIZE_VISITOR_SUCCESS:
      return { ...state, filter: data }
    case ShowcaseAnonymizeVisitorTypes.CLEAR_FILTER_ANONYMIZE_VISITOR_SUCCESS:
      return { ...state, filter: initShowcaseAnonymizeVisitor.filter }
    case UserTypes.LOGOUT_SUCCESS:
      return initShowcaseAnonymizeVisitor
    default:
      return state
  }
}

export default showcaseAnonymizeVisitor
