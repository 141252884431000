import keyMirror from 'keymirror'

export default keyMirror({
  GET_TIMEZONES_REQUEST: null,
  GET_TIMEZONES_SUCCESS: null,
  GET_TIMEZONES_FAILED: null,

  ADD_TIMEZONE_REQUEST: null,
  ADD_TIMEZONE_SUCCESS: null,
  ADD_TIMEZONE_FAILED: null,

  GET_TIMEZONE_DETAIL_REQUEST: null,
  GET_TIMEZONE_DETAIL_SUCCESS: null,
  GET_TIMEZONE_DETAIL_FAILED: null,

  UPDATE_TIMEZONE_REQUEST: null,
  UPDATE_TIMEZONE_SUCCESS: null,
  UPDATE_TIMEZONE_FAILED: null,

  DELETE_TIMEZONE_REQUEST: null,
  DELETE_TIMEZONE_SUCCESS: null,
  DELETE_TIMEZONE_FAILED: null,

  UPDATE_FILTER_TIMEZONE_SUCCESS: null,
  CLEAR_FILTER_TIMEZONE_SUCCESS: null,

  GET_SYSTEM_TIMEZONES_REQUEST: null,
  GET_SYSTEM_TIMEZONES_SUCCESS: null,
  GET_SYSTEM_TIMEZONES_FAILED: null
})
