import VehicleTypes from 'store/actionTypes/vehicle'
import {
  subscriptionAPI,
  vehicle as vehicleAPI,
  vehicleFleet as vehicleFleetAPI
} from 'store/api'
import { bindRequestLogic } from './utils'
import {
  mapVehicleSubscriptionsResponse,
  mapVehicleUserResponse
} from '../mapping'

const getVehicleList = bindRequestLogic({
  clientFunc: vehicleAPI.getVehicles,
  type: VehicleTypes.GET_VEHICLES_REQUEST,
  successType: VehicleTypes.GET_VEHICLES_SUCCESS,
  failType: VehicleTypes.GET_VEHICLES_FAILED,
  isQueryParam: true,
  modelName: 'vehicles'
})

const getVehicleDetail = bindRequestLogic({
  clientFunc: vehicleAPI.getVehicleDetail,
  type: VehicleTypes.GET_VEHICLE_DETAIL_REQUEST,
  successType: VehicleTypes.GET_VEHICLE_DETAIL_SUCCESS,
  failType: VehicleTypes.GET_VEHICLE_DETAIL_FAILED,
  modelName: 'vehicle'
})

const deleteVehicle = bindRequestLogic({
  clientFunc: vehicleAPI.deleteVehicle,
  type: VehicleTypes.DELETE_VEHICLE_REQUEST,
  successType: VehicleTypes.DELETE_VEHICLE_SUCCESS,
  failType: VehicleTypes.DELETE_VEHICLE_FAILED
})

const addVehicle = bindRequestLogic({
  clientFunc: vehicleAPI.addVehicle,
  type: VehicleTypes.ADD_VEHICLE_REQUEST,
  successType: VehicleTypes.ADD_VEHICLE_SUCCESS,
  failType: VehicleTypes.ADD_VEHICLE_FAILED
})

const updateVehicle = bindRequestLogic({
  clientFunc: vehicleAPI.updateVehicle,
  type: VehicleTypes.UPDATE_VEHICLE_REQUEST,
  successType: VehicleTypes.UPDATE_VEHICLE_SUCCESS,
  failType: VehicleTypes.UPDATE_VEHICLE_FAILED
})

const deleteUserVehicle = bindRequestLogic({
  clientFunc: vehicleAPI.deleteUserVehicle,
  type: VehicleTypes.DELETE_USER_VEHICLE_REQUEST,
  successType: VehicleTypes.DELETE_USER_VEHICLE_SUCCESS,
  failType: VehicleTypes.DELETE_USER_VEHICLE_FAILED
})

const addUserVehicle = bindRequestLogic({
  clientFunc: vehicleAPI.addUserVehicle,
  type: VehicleTypes.ADD_USER_VEHICLE_REQUEST,
  successType: VehicleTypes.ADD_USER_VEHICLE_SUCCESS,
  failType: VehicleTypes.ADD_USER_VEHICLE_FAILED
})

const updateUserVehicle = bindRequestLogic({
  clientFunc: vehicleAPI.updateUserVehicle,
  type: VehicleTypes.UPDATE_USER_VEHICLE_REQUEST,
  successType: VehicleTypes.UPDATE_USER_VEHICLE_SUCCESS,
  failType: VehicleTypes.UPDATE_USER_VEHICLE_FAILED
})

const getVehicleSubscriptions = bindRequestLogic({
  clientFunc: subscriptionAPI.getVehicleSubscriptions,
  type: VehicleTypes.GET_VEHICLE_SUBSCRIPTIONS_REQUEST,
  successType: VehicleTypes.GET_VEHICLE_SUBSCRIPTIONS_SUCCESS,
  failType: VehicleTypes.GET_VEHICLE_SUBSCRIPTIONS_FAILED,
  isQueryParam: true,
  mapResponse: mapVehicleSubscriptionsResponse
})

const getVehicleUsers = bindRequestLogic({
  clientFunc: vehicleFleetAPI.getVehicleUsers,
  type: VehicleTypes.GET_VEHICLE_USERS_REQUEST,
  successType: VehicleTypes.GET_VEHICLE_USERS_SUCCESS,
  failType: VehicleTypes.GET_VEHICLE_USERS_FAILED,
  isQueryParam: true,
  mapResponse: mapVehicleUserResponse
})

const getSystemVehicles = bindRequestLogic({
  clientFunc: vehicleAPI.getSystemVehicles,
  type: VehicleTypes.GET_SYSTEM_VEHICLES_REQUEST,
  successType: VehicleTypes.GET_SYSTEM_VEHICLES_SUCCESS,
  failType: VehicleTypes.GET_SYSTEM_VEHICLES_FAILED,
  isQueryParam: true
})

const getDirectVehiclesByVehicleId = bindRequestLogic({
  clientFunc: vehicleAPI.getDirectVehicleByVehicleId,
  type: VehicleTypes.GET_DIRECT_VEHICLES_BY_VEHICLE_ID_REQUEST,
  successType: VehicleTypes.GET_DIRECT_VEHICLES_BY_VEHICLE_ID_SUCCESS,
  failType: VehicleTypes.GET_DIRECT_VEHICLES_BY_VEHICLE_ID_FAILED,
  isQueryParam: true
})

export default [
  getVehicleList,
  getVehicleDetail,
  deleteVehicle,
  addVehicle,
  updateVehicle,
  deleteUserVehicle,
  addUserVehicle,
  updateUserVehicle,
  getVehicleSubscriptions,
  getVehicleUsers,
  getSystemVehicles,
  getDirectVehiclesByVehicleId
]
