import BaseApi from './base'

class FAQ extends BaseApi {
  getFAQs = (query) => this.client.get(`admin/faq?${query}`)

  getFAQ = (id) => this.client.get(`admin/faq/${id}`)

  addFAQ = (data) => this.client.post('admin/faq', data)

  updateFAQ = (id, data) => this.client.patch(`admin/faq/${id}`, data)

  deleteFAQ = (id) => this.client.delete(`admin/faq/${id}`)

  addFAQContentTranslation = (id, data) =>
    this.client.post(`admin/faq/content/${id}/translation`, data)

  getFAQContent = (id) => this.client.get(`admin/faq/${id}/content`)

  addFAQContent = (id, data) =>
    this.client.post(`admin/faq/${id}/content`, data)
}

export default new FAQ()
