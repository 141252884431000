import { combineReducers } from 'redux'
import PersPackageTypes from 'store/actionTypes/persPackage'
import { createHandleRequest } from './helpers'

const getPersPackages = createHandleRequest(
  PersPackageTypes.GET_PERS_PACKAGES_REQUEST,
  PersPackageTypes.GET_PERS_PACKAGES_SUCCESS,
  PersPackageTypes.GET_PERS_PACKAGES_FAILED
)

export default combineReducers({
  getPersPackages
})
