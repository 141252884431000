import BaseApi from './base'

class MessageImport extends BaseApi {
  constructor() {
    super()
    this.client.defaults.headers.Accept = 'multipart/form-data'
  }

  importMessages = (languageId, data) => {
    this.client.post(`admin/messages/upload/${languageId}`, data)
  }
}

export default new MessageImport()
