import keyMirror from 'keymirror'

export default keyMirror({
  GET_LIST_PLATFORM_PAGE_TECH_REQUEST: null,
  GET_LIST_PLATFORM_TECH_PAGE_SUCCESS: null,
  GET_LIST_PLATFORM_TECH_PAGE_FAILED: null,

  GET_DETAIL_PLATFORM_TECH_PAGE_REQUEST: null,
  GET_DETAIL_PLATFORM_TECH_PAGE_SUCCESS: null,
  GET_DETAIL_PLATFORM_TECH_PAGE_FAILED: null,

  ADD_PLATFORM_TECH_PAGE_REQUEST: null,
  ADD_PLATFORM_TECH_PAGE_SUCCESS: null,
  ADD_PLATFORM_TECH_PAGE_FAILED: null,

  DELETE_PLATFORM_TECH_PAGE_REQUEST: null,
  DELETE_PLATFORM_TECH_PAGE_SUCCESS: null,
  DELETE_PLATFORM_TECH_PAGE_FAILED: null,

  UPDATE_PLATFORM_TECH_PAGE_REQUEST: null,
  UPDATE_PLATFORM_TECH_PAGE_SUCCESS: null,
  UPDATE_PLATFORM_TECH_PAGE_FAILED: null,

  UPDATE_FILTER_PLATFORM_PAGE_TECH_SUCCESS: null,
  CLEAR_FILTER_PLATFORM_PAGE_TECH_SUCCESS: null,

  GET_SYSTEM_PLATFORM_PAGE_TECHS_REQUEST: null,
  GET_SYSTEM_PLATFORM_PAGE_TECHS_SUCCESS: null,
  GET_SYSTEM_PLATFORM_PAGE_TECHS_FAILED: null
})
