import RequestStatus from 'constants/requestStatus'
// import { getModuleFilter } from 'helpers/filter'
// import { DEFAULT_LABEL_FILTER } from 'constants/common'
import { createSelector } from 'reselect'

export const getLabelList = (state) => state?.store?.label.list || []

export const getPagination = (state) => state?.store?.label.pagination || {}

export const memoizedSystemLabels = createSelector(
  [(state) => state?.store?.label.systems],
  (labels = []) => labels
)

export const getFilter = (state) => state?.store?.label.filter || {}

// export const getFilter = (state) =>
//   getModuleFilter(state, 'label', DEFAULT_LABEL_FILTER)

export const isLoading = (state) =>
  state.requests?.label?.getLabels.status === RequestStatus.STARTED
