import IntlMessages from 'helpers/IntlMessages'

export const PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_CODE_PREFIX = 'Pro'

const PRO_CENTER_PLATFORM_PARENT_CATEGORY_PERMISSION_CODE = {
  adminAndSetting: 'admin_and_setting',
  vehicleAndPers: 'vehicle_and_pers',
  configuration: 'configuration',
  marketing: 'marketing'
}

export const PRO_CENTER_PLATFORM_PARENT_CATEGORY_PERMISSION_ORDER = [
  PRO_CENTER_PLATFORM_PARENT_CATEGORY_PERMISSION_CODE.adminAndSetting,
  PRO_CENTER_PLATFORM_PARENT_CATEGORY_PERMISSION_CODE.vehicleAndPers,
  PRO_CENTER_PLATFORM_PARENT_CATEGORY_PERMISSION_CODE.configuration,
  PRO_CENTER_PLATFORM_PARENT_CATEGORY_PERMISSION_CODE.marketing
]

export const PRO_CENTER_PLATFORM_PARENT_CATEGORY_PERMISSION_LABEL = {
  [PRO_CENTER_PLATFORM_PARENT_CATEGORY_PERMISSION_CODE.adminAndSetting]: (
    <IntlMessages id='company_category_role.admin_and_settings' />
  ),
  [PRO_CENTER_PLATFORM_PARENT_CATEGORY_PERMISSION_CODE.vehicleAndPers]: (
    <IntlMessages id='company_category_role.vehicle_and_pers' />
  ),
  [PRO_CENTER_PLATFORM_PARENT_CATEGORY_PERMISSION_CODE.configuration]: (
    <IntlMessages id='company_category_role.configuration' />
  ),
  [PRO_CENTER_PLATFORM_PARENT_CATEGORY_PERMISSION_CODE.marketing]: (
    <IntlMessages id='company_category_role.marketing' />
  )
}

export const PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_CODE_SUFFIX = {
  companyProfileInformation: 'CompanyProfile_Information',
  pers: 'Pers',
  persToVehicle: 'PersToVehicle',
  vehicleModels: 'VehicleModels',
  vehicles: 'Vehicles',
  riders: 'Riders'
}

export const PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_LABEL_MAPPING = {
  [PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_CODE_SUFFIX.companyProfileInformation]:
    'Company Profile',
  [PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_CODE_SUFFIX.pers]: 'Pers',
  [PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_CODE_SUFFIX.vehicles]: 'Vehicles',
  [PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_CODE_SUFFIX.persToVehicle]:
    'Pers To Vehicle',
  [PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_CODE_SUFFIX.vehicleModels]:
    'Manage Vehicle',
  [PRO_CENTER_PLATFORM_CATEGORY_PERMISSION_CODE_SUFFIX.riders]: 'Riders'
}
